import { DataContext, } from "store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type JoinCodeContext = DataContext<string>;

const initialState: JoinCodeContext = {
    loading: false,
    data: "",
    error: null,
};

export const coconJoinCodeSlice = createSlice({
    name: 'cocon_join_code',
    initialState: initialState,
    reducers: {
        startLoadingJoinCode: (state) => {
            state.loading = true;
            state.error = null;
        },
        setJoinCode: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.data = payload;
        },
        setJoinCodeError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CoconJoinCodeActions = coconJoinCodeSlice.actions;

const CoconJoinCodeReducer =  coconJoinCodeSlice.reducer;

export default CoconJoinCodeReducer;