import { PartnerDbData } from "./Partner";
import { Coordinates } from 'helpers/geo';
import { RewardDbData } from "./Reward";
import { Collections, DBIdentifiers } from "constants/db";
import { AppDispatch } from "store/store";
import { StoresActions } from "store/reducers/stores";
import { CodeError, fetchAPI } from "actions/actions";
import urls from "constants/urls";
import { showError, showSuccess } from "store/reducers/snacks";
import { QueryFilter } from "constants/types";
import { getDocumentReference, listSubcollectionDocs } from "helpers/db";

type PartnerData = Pick<PartnerDbData, "name" | "description" | "imageURL"> & {
    id: string;
};

type RewardData = Pick<RewardDbData, "cost" | "name" | "description" | "imageURL"> & {
    id: string;
};

export type StoreDbData = {
    name: string;
    partner: PartnerData;
    address: string;
    coordinates: Coordinates;
    rewards: Array<{
        reward: RewardData;
        quantity: number;
    }>;
}

type APIStoreData = StoreDbData & {
    id: string;
}

type Store = StoreDbData & DBIdentifiers;

const COLLECTION = Collections.STORES;

function getDocRef(id: string, partnerId: string) {
    return getDocumentReference(id, COLLECTION, `${Collections.PARTNERS}/${partnerId}`);
}

function fromAPIData(storeData: APIStoreData): Store {
    return {
        ...storeData,
        // ref: getDocRef(storeData.id, storeData.partner.id),
    };
}

export const create = (storeData: StoreDbData) => async (dispatch: AppDispatch) => {
    dispatch(StoresActions.startLoadingSelected());
    const partnerId = storeData.partner.id;

    try {
        const apiStoreData: APIStoreData = await fetchAPI(`${urls.API}/partner/${partnerId}/store`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(storeData),
        });

        const store = fromAPIData(apiStoreData);

        console.debug("Store added: ", store);

        dispatch(StoresActions.setSelected(store));
        dispatch(showSuccess(`Le magasin '${store.name}' a bien été ajouté!`));

        return store;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(StoresActions.setSelectedError(error.message));
        dispatch(showError(error.message)); // show error message
        return null;
    }
}

export const list = (partnerId: string, filters: QueryFilter[]) => async (dispatch: AppDispatch) => {
    dispatch(StoresActions.startLoadingList());

    try {
        const stores = await listSubcollectionDocs<Store>(COLLECTION, `${Collections.PARTNERS}/${partnerId}`, filters);
        dispatch(StoresActions.setList(stores));
        return stores;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(StoresActions.setListError(error.message));
        dispatch(showError(error.message)); // show error message
        return [];
    }
}

export default Store;

export const StoresMethods = {
    create,
    list,
}