import Cocon, { CoconDbData } from "models/Cocons/Cocon";
import CoconsCluster from "models/Cocons/CoconsCluster";
import { DataContext, LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

const clustersAdapter = createEntityAdapter<CoconsCluster>({
    selectId: cluster => cluster.id,
    // sortComparer: (a, b) => b.date.localeCompare(a.date)
});

type ClustersContext = LoadableContext;

const initialState: ClustersContext = {
    loading: false,
    error: null,
};

export const clustersSlice = createSlice({
    name: 'cocons_clusters',
    initialState: clustersAdapter.getInitialState(initialState),
    reducers: {
        startLoadingCoconsClusters: (state) => {
            state.loading = true;
            state.error = null;
        },
        setClustersList: (state, { payload: clusters }: PayloadAction<CoconsCluster[]>) => {
            state.loading = false;
            clustersAdapter.setAll(state, clusters);
        },
        setClustersListError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CoconsClustersActions = clustersSlice.actions;

export const {
    selectAll: selectAllCoconsClusters,
    selectById: selectCoconsClusterById,
    selectIds: selectCoconsClustersIds,
} = clustersAdapter.getSelectors((state: RootState) => state.cocons.clusters);

const CoconsClustersReducer =  clustersSlice.reducer;

export default CoconsClustersReducer;