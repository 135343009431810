import { createTheme, PaletteColorOptions, PaletteOptions } from "@mui/material";
import { CSSProperties } from "react";

const defaultTheme = createTheme();

const spacing = (value: number) => {
    return `${value * 8}px`;
}

const palette = {
    ...defaultTheme.palette,
    success:{
        ...defaultTheme.palette.success,
        main: '#449966',
        dark: '#336644',
        
    },
    primary: {
        light: '#63ccff',
        main: '#4C69AF',
        dark: '#006db3',
    },
    secondary: {
        main: '#F14785',
    },
    white: {
        main: "#ffffff"
    },
};

const tableHead = {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    fontSize: 14,
    fontWeight: defaultTheme.typography.fontWeightBold,
};

let theme = createTheme({
    palette: palette,
    typography: {
        h1: {
            fontWeight: 700,
            fontSize: '2rem',
        },
        h4: {
            color: "#555",
            fontWeight: 500,
            fontSize: "1.5rem",
        },
        h5: {
            fontWeight: 900,
            fontSize: "1rem",
            lineHeight: "150%"
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    marginTop: spacing(2),
                    marginBottom: spacing(4),
                }
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    marginTop: spacing(4),
                    marginBottom: spacing(4),
                },
                h5: {
                    color: "#333333",
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#18202c',
                    margin: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    margin: "0.5rem",
                    textTransform: "none",
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: "#ffffff",
                },
            }
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    margin: '0 16px',
                    minWidth: 0,
                    padding: 0,
                    [defaultTheme.breakpoints.up('md')]: {
                        padding: 0,
                        minWidth: 0,
                    },
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: spacing(1),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    
                },
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: defaultTheme.typography.fontWeightMedium,
                },
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    marginRight: 0,
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: tableHead
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: tableHead
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: "1.5rem",
                    maxWidth: 400,
                    // width: '90%',
                }
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: "1.5rem",
                    maxWidth: 400,
                    width: '100%',
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    // position: "absolute",
                }
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: spacing(2),
                }
            },
        }
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
    spacing: spacing,
});

export default theme;

declare module '@mui/material/styles' {
    interface Palette {
        white: PaletteColorOptions;
    }
    interface PaletteOptions {
        white: PaletteColorOptions;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        white: true;
    }
}