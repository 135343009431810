import { Box } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import { MessageAttachmentType, MessagesMethods } from "models/Message";
import { useEffect, useMemo, useRef } from "react";
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useAppDispatch, useAppSelector } from "store/hooks";
import LinkBubble from "../MessagesBubbles/LinkBubble";
import MediaBubble from "../MessagesBubbles/MediaBubble";
import MessageBubble from "../MessagesBubbles/MessageBubble";

type UserChatFeedProps = {

}

function UserChatFeed(props: UserChatFeedProps) {

    const { } = props;

    const userId = useAppSelector(state => state.users.selectedUser.data?.id);
    const messages = useAppSelector(state => state.messages.messages.data);
    const messagesLoading = useAppSelector(state => state.messages.messages.loading);
    const firstItemIndex = useAppSelector(state => state.messages.messages.firstItemIndex);

    const dispatch = useAppDispatch();

    // hide messages when user has just changed and messages are not loading yet
    // const userMessages = usePrevious(userId) === userId ? messages : [];

    const listRef = useRef<VirtuosoHandle>(null);

    useEffect(() => {
        if (userId) {
            dispatch(MessagesMethods.list(userId, true, undefined, 20)); // load 20 last messages by default
        }
    }, [userId]);

    const ListComponents = useMemo(() => ({
        Header: () => <div style={{ textAlign: 'center', padding: '1rem' }}>{messagesLoading ? "Chargement..." : ""}</div>,
        Footer: () => <Box height={theme => theme.spacing(8)} />,
    }), []);

    const loadOlderMessages = () => {
        if (userId) {
            const nbMessagesToLoad = 10;
            dispatch(MessagesMethods.list(userId, false, messages[0].timestamp, nbMessagesToLoad));
        }
    };

    if (messagesLoading) {
        return (<SectionLoader />);
    }

    return (
        <Virtuoso
            ref={listRef}
            components={ListComponents}
            initialTopMostItemIndex={messages.length - 1}
            firstItemIndex={firstItemIndex}
            data={messages}
            startReached={loadOlderMessages}
            itemContent={(index, message) => {
                // message has an attachment (link, image, video...)
                switch (message.attachment?.type) {
                    case MessageAttachmentType.LINK:
                    case MessageAttachmentType.INTERNAL_LINK:
                        return (
                            <LinkBubble key={message.id} message={message} />
                        );

                    case MessageAttachmentType.VIDEO:
                    case MessageAttachmentType.IMAGE:
                        return (
                            <MediaBubble key={message.id} message={message} />
                        );
                }

                // simple message from user or Cocon
                return (
                    <MessageBubble key={message.id} message={message} />
                )
            }}
            followOutput={"smooth"}
        />
    );
}

export default UserChatFeed;