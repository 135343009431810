import Cocon, { CoconDbData } from "models/Cocons/Cocon";
import CoconsCluster from "models/Cocons/CoconsCluster";
import { DataContext, LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

const coconsAdapter = createEntityAdapter<Cocon>({
    selectId: cocon => cocon.id,
    // sortComparer: (a, b) => b.date.localeCompare(a.date)
});

type CoconsContext = LoadableContext;

const initialState: CoconsContext = {
    loading: false,
    error: null,
};

export const coconsSlice = createSlice({
    name: 'cocons',
    initialState: coconsAdapter.getInitialState(initialState),
    reducers: {
        startLoadingCocons: (state) => {
            state.loading = true;
            state.error = null;
        },
        setCoconsList: (state, { payload: cocons }: PayloadAction<Cocon[]>) => {
            state.loading = false;
            coconsAdapter.setAll(state, cocons);
        },
        updateListItem: (state, { payload: { coconId, data } }: PayloadAction<{ coconId: string, data: Partial<Cocon>}>) => {
            coconsAdapter.updateOne(state, {
                id: coconId,
                changes: data,
            });
        },
        setCoconsListError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CoconsActions = coconsSlice.actions;

const CoconsReducer =  coconsSlice.reducer;

export const {
    selectAll: selectAllCocons,
    selectEntities: selectAllCoconsEntities,
    selectById: selectCoconById,
    selectIds: selectCoconsIds,
} = coconsAdapter.getSelectors((state: RootState) => state.cocons.cocons);

export default CoconsReducer;