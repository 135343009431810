import ActionButton from "components/_include/ActionButton";
import { Namespaces } from "locales/translations";
import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { ParseResult } from 'papaparse';
import { useTranslation } from "react-i18next";

type UploadPromoCodesButtonProps = {
    onUpload: (promoCodes: string[]) => void;
}

function UploadPromoCodesButton({ onUpload }: UploadPromoCodesButtonProps) {
    const { t } = useTranslation([Namespaces.forms]);

    const [codesCount, setCodesCount] = useState<number>();

    const promoCodesCSVReader = useRef<CSVReader>(null);

    const chooseCSVToUpload = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        promoCodesCSVReader.current?.open(e);
    };

    const handlePromoCodesListLoaded = (data: ParseResult<any>[]) => {
        let promoCodes: string[] = [];
        data.forEach(row => {
            promoCodes.push(...row.data);
        });

        onUpload(promoCodes);

        setCodesCount(promoCodes.length);
    };

    return (
        <CSVReader
            ref={promoCodesCSVReader}
            noClick
            noDrag
            onFileLoad={handlePromoCodesListLoaded}
        >
            {() => (
                <aside>
                    {
                        codesCount ? ( // codes have been uploaded
                            <ActionButton
                                color="primary"
                                onClick={chooseCSVToUpload}
                            >
                                {t(`reward.promo_code.count`, { ns: Namespaces.forms, count: codesCount })}
                            </ActionButton>
                        ) : ( // no code uploaded yet
                            <ActionButton
                                color="primary"
                                onClick={chooseCSVToUpload}
                            >
                                {t(`reward.promo_code.list`, { ns: Namespaces.forms })}
                            </ActionButton>
                        )
                    }
                </aside>
            )}
        </CSVReader>
    );
}

export default UploadPromoCodesButton;