import * as React from 'react';
import Select, {
    selectClasses,
    SelectProps,
    SelectRootSlotProps,
} from '@mui/base/Select';
import Option, { optionClasses } from '@mui/base/Option';
import Popper from '@mui/base/Popper';
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { blue, grey } from '@mui/material/colors';

export type Option = {
    label: string;
    value: string;
}

interface DropdownMenuProps {
    title: string;
    // loading?: boolean;
    values: Option[];
    defaultValue: string;
    // startIcon?: JSX.Element;
    onChange: (value: string) => void;
}

export default function DropdownMenu({ title, values, defaultValue, onChange }: DropdownMenuProps) {
    return (
        <>
            <Label>{title}</Label>
            <CustomSelect defaultValue={defaultValue} onChange={(_, value) => onChange(value as string)}>
                {values.map(value => (
                    <StyledOption key={value.value} value={value.value}>{value.label}</StyledOption>
                ))}
            </CustomSelect>
        </>
    );
}

const CustomSelect = React.forwardRef(function CustomSelect<
    TValue extends {},
    Multiple extends boolean,
>(props: SelectProps<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
    const slots = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <Select {...props} ref={ref} slots={slots} />;
});

const Button = React.forwardRef(function Button<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const { ownerState, ...other } = props;
    return (
        <button type="button" {...other} ref={ref}>
            {other.children}
            <UnfoldMoreRoundedIcon />
        </button>
    );
});

const Label = styled('label')(
    ({ theme }) => `
    margin-left: 12px;
    font-size: 0.85rem;
    display: block;
    margin-bottom: 4px;
    font-weight: 400;
    color: ${grey[400]};
    `,
);

const StyledButton = styled(Button, { shouldForwardProp: () => true })(
    ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  background: ${grey[900]};
  border: 1px solid ${grey[700]};
  color: ${grey[300]};
  position: relative;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${grey[800]};
    border-color: ${grey[600]};
  }

  &.${selectClasses.focusVisible} {
    border-color: ${blue[400]};
    outline: 3px solid ${blue[500]};
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  min-width: 220px;
  padding: 6px;
  margin: 12px 0;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${grey[900]};
  border: 1px solid ${grey[700]};
  color: ${grey[300]};
  `,
);

const StyledOption = styled(Option)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${blue[900]};
    color: ${blue[100]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${grey[800]};
    color: ${grey[300]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${blue[900]};
    color: ${blue[100]};
  }

  &.${optionClasses.disabled} {
    color: ${grey[700]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${grey[800]};
    color: ${grey[300]};
  }
  `,
);

const StyledPopper = styled(Popper)`
  z-index: 9999;
`;