import Handlebars from "handlebars/runtime";

/**
 * Make the first character of a string uppercase
 * @param {string} string The string to transform
 * @return {string} The string with the first character uppercased
 */
export function ucFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Check if an email address has a valid format
 * @param emailAddress The email address to check
 * @return True if valid, false otherwise
 */
export function isValidEmail(emailAddress: string) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}

export function isValidURL(text: string, options?: { acceptFacebookScheme?: boolean }) {
    let URLRegExp = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/);
    if (options?.acceptFacebookScheme) { // accept "{{facebookScheme}}/123456" (e.g. to redirect to Ficha's page on Facebook mobile app)
        URLRegExp = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|{{facebookScheme}}(\/[a-zA-Z0-9]*)*)/);
    }

    return text.match(URLRegExp) !== null;
}

export const populateTemplate = (message: string, context: any) => {
    const template = Handlebars.compile(message);
    return template(context);
};

export function normalizeFilename(text: string) {
    return text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/ /g, "_");
}