import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.35291 38.0513V36.7806C4.35291 36.2284 4.80062 35.7806 5.35291 35.7806H9.49996C10.0522 35.7806 10.5 36.2284 10.5 36.7806V38.0513C10.5 38.6035 10.0522 39.0513 9.49996 39.0513H5.35291C4.80062 39.0513 4.35291 38.6035 4.35291 38.0513Z" fill="#795548" stroke="#795548" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.35291 2.05127V3.32188C4.35291 3.87416 4.80062 4.32188 5.35291 4.32188H9.49996C10.0522 4.32188 10.5 3.87416 10.5 3.32188V2.05127C10.5 1.49898 10.0522 1.05127 9.49996 1.05127H5.35291C4.80062 1.05127 4.35291 1.49899 4.35291 2.05127Z" fill="#795548" stroke="#795548" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.989 4.42555L39 8.88332V34.7806C39 35.3329 38.5523 35.7806 38 35.7806H14.1324H10.5H4.35294H2C1.44771 35.7806 1 35.3329 1 34.7806V5.32187C1 4.76958 1.44772 4.32187 2 4.32187H4.35294H10.5H14.1324H28.9412H29.5456C29.6994 4.32187 29.8511 4.35735 29.989 4.42555Z" fill="white" />
            <path d="M39 8.88332L29.989 4.42555C29.8511 4.35735 29.6994 4.32187 29.5456 4.32187H28.9412M39 8.88332V34.7806C39 35.3329 38.5523 35.7806 38 35.7806H14.1324H10.5H4.35294H2C1.44771 35.7806 1 35.3329 1 34.7806V5.32187C1 4.76958 1.44772 4.32187 2 4.32187H4.35294H10.5H14.1324H28.9412M39 8.88332H29.9412C29.3889 8.88332 28.9412 8.4356 28.9412 7.88332V4.32187" stroke="#CCCCCC" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 5.05127L14 35.0513" stroke="#C9D7EB" strokeLinecap="round" strokeDasharray="4 6" />
            <path d="M4 12.8682L6.75229 10.0513M19.9633 24.1358L34 15.6851M7.85321 30.0513L10.6055 27.2344M28.2202 26.1076L31.5229 24.1358M22.4404 16.2485L25.7431 14.2766" stroke="#C9D7EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);