import Purchase, { PurchaseStatuses, Timeline } from "models/Purchase";
import { createEntityAdapter, createSlice, PayloadAction, } from "@reduxjs/toolkit";
import { DataContext, LoadableContext, RootState } from "store/store";

const purchasesAdapter = createEntityAdapter<Purchase>({
    selectId: p => p.id,
    sortComparer: (p1, p2) => // oldest purchases first
        (p1.timeline.purchased?.toMillis() ?? 0) - (p2.timeline.purchased?.toMillis() ?? 0),
});

export type CurrentPurchasesContext = LoadableContext & {
    
}

const initialState: CurrentPurchasesContext = {
    loading: false,
    error: null,
};

const purchasesSlice = createSlice({
    name: 'current_purchases',
    initialState: purchasesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: purchases }: PayloadAction<Purchase[]>) => {
            state.loading = false;
            purchasesAdapter.setAll(state, purchases);
        },
        updateListItem:(state, { payload: purchase }: PayloadAction<Purchase>) => {
            purchasesAdapter.updateOne(state, {
                id: purchase.id,
                changes: purchase,
            });
        },
        setListError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CurrentPurchasesActions = purchasesSlice.actions;

export const {
    selectAll: selectAllCurrentPurchases,
    selectById: selectCurrentPurchaseById,
    selectIds: selectCurrentPurchasesIds
} = purchasesAdapter.getSelectors((state: RootState) => state.purchases.current);

const CurrentPurchasesReducer =  purchasesSlice.reducer;

export default CurrentPurchasesReducer;