import { Box, ButtonBase, IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import { getPictureURL, StorageType } from "helpers/storage";
import { useEffect, useState } from 'react';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BatchesMethods } from "models/Batches/Batch";
import SectionLoader from "components/_include/SectionLoader";

type ImageItemProps = {
    batchId: string;
    onSelect: (batchId: string) => void;
}

function ImageToAnnotateItem(props: ImageItemProps) {
    const { batchId, onSelect, } = props;

    const batch = useAppSelector(state => state.batches.list.entities[batchId]!);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.stats, Namespaces.wastes]);

    const [imageURL, setImageURL] = useState<string | null>(null);

    useEffect(() => {
        getPictureURL(StorageType.BATCHES, batch.imageURL)
            .then(url => {
                setImageURL(url || "");
            });
    }, [batch.imageURL]);

    const handleAnnotateClick = () => {
        dispatch(BatchesMethods.update(batchId, {
            toAnnotate: !batch.toAnnotate,
        }));
    }

    const handleImageClick = () => {
        onSelect(batchId);
    }

    return (
        <ImageListItem>
            {(imageURL === null || batch.loading) && (
                <SectionLoader />
            )}
            {imageURL !== null && (
                <ButtonBase
                    onClick={handleImageClick}
                    sx={{
                        width: "100%",
                        height: "100%"
                    }}
                >
                    <img
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        src={imageURL}
                        // alt={item.title}
                        loading="lazy"
                    />
                </ButtonBase>
            )}
            <ImageListItemBar
                title={t("datetime_full_day", { ns: Namespaces.stats, datetime: batch.timestamp.toDate() })}
                // subtitle={getErrorsLabel()}
                sx={{
                    "& .MuiImageListItemBar-subtitle": {
                        whiteSpace: "unset", // prevent wrapping of subtitle
                    }
                }}
                actionIcon={
                    <Box>
                        <IconButton
                            disabled={batch.loading}
                            sx={{
                                color: `rgba(255, 255, 255, ${batch.toAnnotate ? "1" : "0.18"})`
                            }}
                            onClick={handleAnnotateClick}
                        >
                            <FormatShapesIcon />
                            {batch.loading && (<SectionLoader />)}
                        </IconButton>
                    </Box>
                }
            />
        </ImageListItem >
    );
}

export default ImageToAnnotateItem;