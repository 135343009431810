import { Box } from "@mui/material";
import NumberInput from "components/Batches/BatchComponents/Card/Form/NumberInput";
import { Namespaces } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import AddIcon from "@mui/icons-material/AddRounded";
import CheckIcon from "@mui/icons-material/CheckRounded";
import { UsersMethods } from "models/User";
import { LoadingButton } from "@mui/lab";

/**
 * Button and number input to manually add points to a Cocon user.
 */
export default function AddPointsButton() {
    const { t } = useTranslation([Namespaces.actions]);

    const user = useAppSelector(state => state.users.selectedUser.data);
    const loading = useAppSelector(state => state.users.selectedUser.loading);

    const [open, setOpen] = useState(false);

    const [points, setPoints] = useState(0);

    const dispatch = useAppDispatch();

    const addPointsToUser = () => {
        if (user && points > 0) {
            dispatch(UsersMethods.update(user, {
                score: user.score + points,
                balance: user.balance + points,
            }))
                .then(() => {
                    setPoints(0);
                });
        }

        setOpen(false);
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
        >
            <Box
                sx={{
                    scale: open ? 1 : 0,
                    transition: "scale 300ms"
                }}
            >
                <NumberInput
                    size="medium"
                    value={points}
                    min={0}
                    onChange={setPoints}
                />
            </Box>
            <LoadingButton
                variant="contained"
                loading={loading}
                onClick={() => { !open ? setOpen(true) : addPointsToUser() }}
                startIcon={open ? <CheckIcon /> : <AddIcon />}
                sx={{
                    translate: open ? 0 : -120,
                    transition: `translate 300ms`,
                }}
            >
                <span>{t("add_points", { ns: Namespaces.actions })}</span>
            </LoadingButton>
        </Box>
    )
}