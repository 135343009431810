import { CodeError } from "actions/actions";
import { Collections, DBIdentifiers } from "constants/db";
import { QueryFilter } from "constants/types";
import { createDocument, getDocumentReference, listDocs } from "helpers/db";
import { StorageType, uploadFile } from "helpers/storage";
import { normalizeFilename } from "helpers/strings";
import { PartnersActions } from "store/reducers/partner";
import { showError, showSuccess } from "store/reducers/snacks";
import { AppDispatch } from "store/store";

export interface PartnerDbData {
    name: string;
    imageURL: string;
    description: string;
    email: string;
    phone: string;
    website: string;
    address?: string;
    category: string;
    tags?: string[];
}

export type NewPartnerData = Omit<PartnerDbData, "imageURL">;

type Partner = PartnerDbData & DBIdentifiers;

const COLLECTION = Collections.PARTNERS;

function getDocRef(id: string) {
    return getDocumentReference(id, COLLECTION);
}

const list = (filters: QueryFilter[]) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(PartnersActions.startLoading());

    try {
        const partners = await listDocs<Partner>(COLLECTION, filters, { fieldPath: "name", directionStr: "asc" });

        // dispatch loaded partners
        dispatch(PartnersActions.setList(partners));
        return partners;
    }
    catch (e) {
        const error = e as CodeError;
        // dispatch error
        dispatch(PartnersActions.setError(error.message));
        dispatch(showError(error.message));

        return [];
    }
}

const create = (partnerData: NewPartnerData, logo: File) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(PartnersActions.startLoading());
    try {
        // upload partner logo
        const normalizedPartnerName = normalizeFilename(partnerData.name);
        const fileExtension = logo.name.split('.').pop();
        const imageFullPath = `${normalizedPartnerName}/${normalizedPartnerName}.${fileExtension}`;
        const imageURL = await uploadFile(logo, StorageType.PARTNERS, imageFullPath);

        // add to database
        const partner = await createDocument<Partner>(COLLECTION, {
            ...partnerData,
            imageURL: imageURL,
        });
        console.debug("Partner added: ", partner);

        // set new partner as selected
        dispatch(PartnersActions.setSelected(partner));
        dispatch(showSuccess(`Le partenaire '${partner.name}' a bien été ajouté!`,));
        return partner;
    }
    catch (e) {
        const error = e as CodeError;
        // dispatch error
        dispatch(PartnersActions.setError(error.message));
        dispatch(showError(error.message));

        return null;
    }
}

export default Partner;

export const PartnersMethods = {
    list,
    create,
}