import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllCoconsEntities } from "store/reducers/cocons/cocons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CoconDescription from "../CoconDrawer/CoconDescription";

type SelectedCoconDrawerProps = {
    coconsIds: string[];
    onClose: () => void;
}

export default function SelectedCoconDrawer(props: SelectedCoconDrawerProps) {
    const { coconsIds, onClose, } = props;

    const dispatch = useAppDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const coconsEntities = useAppSelector(state => selectAllCoconsEntities(state));

    return (
        <Drawer
            anchor="right"
            open={coconsIds.length > 0}
            onClose={onClose}
            BackdropProps={{
                invisible: true,
            }}
        >
            <Box
                bgcolor="#eeeeee"
                flexGrow={1}
                width={(theme) => theme.spacing(50)}
            >
                {coconsIds.map(coconId => {
                    const cocon = coconsEntities[coconId]!;
                    return (
                        <Accordion
                            key={coconId}
                            TransitionProps={{ unmountOnExit: true }}
                            square
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography>{cocon.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CoconDescription cocon={cocon} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
        </Drawer>
    );
}