import CoconsCluster from "models/Cocons/CoconsCluster";
import { DataContext } from "store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CoconContext = DataContext<CoconsCluster | undefined>;

const initialState: CoconContext = {
    loading: false,
    data: undefined,
    error: null,
};

export const selectedCoconsClusterSlice = createSlice({
    name: 'selected_cocons_cluster',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setCluster: (state, { payload: cluster }: PayloadAction<CoconsCluster | undefined>) => {
            state.loading = false;
            state.data = cluster;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedCoconsClusterActions = selectedCoconsClusterSlice.actions;

const SelectedCoconsClusterReducer =  selectedCoconsClusterSlice.reducer;

export default SelectedCoconsClusterReducer;