import {Grid} from '@mui/material'
import { BatchesMethods } from "models/Batches/Batch";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { CoconsMethods } from 'models/Cocons/Cocon';
import { useAppSelector } from 'store/hooks';

interface BatchInfoProps {
    batchId: string,
}

function BatchInfo(props: BatchInfoProps) {
    const { batchId }  = props;

    const batch = useAppSelector(state => state.batches.list.entities[batchId]!);

    const { t } = useTranslation([Namespaces.batches, Namespaces.glossary]);

    return (
        <Grid
            container
            spacing={2}
            px={2}
            py={1}
            sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: "#ffffff",
                fontWeight: 500,
                fontSize: "1.1em",
            }}
            >
            <Grid item xs={12} sm={4} md={3}>{BatchesMethods.getTimestamp(batch).format("LL HH:mm")}</Grid>
            <Grid item xs={12} sm={8} md={5}>{CoconsMethods.getFullAddress(batch.cocon)}</Grid>
            <Grid item xs={6} md={2}>
                {batch.user?.id ? // correct user pin
                    `${t("cocon_pin", { ns: Namespaces.glossary })}: ${batch.user.pin}` 
                : 
                    batch.user ? // incorrect user pin
                        t("auto_pin", { ns: Namespaces.batches, pin: batch.user.pin })
                    : // auto batch without pin code
                        t("auto", { ns: Namespaces.batches })
                }
            </Grid>
            <Grid item xs={6} md={2}>{t("point", { ns: Namespaces.glossary, count: batch.score })}</Grid>
        </Grid>
    )
};

export default BatchInfo;
