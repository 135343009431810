import { useAppDispatch, useAppSelector } from 'store/hooks';
import DropdownMenu, { Option } from './DropdownMenu';
import { useTranslation } from 'react-i18next';
import { LOCALES_LABELS, LOCAL_STORAGE_LOCALE_KEY, Locale, Namespaces } from 'locales/translations';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import i18next from 'i18next';
import { Select, SelectProps } from '@mui/base';
import { forwardRef } from 'react';

type LanguageSelectProps = {

}

const LANGUAGES_OPTIONS: Option[] = Object.values(Locale).map(locale => ({ label: LOCALES_LABELS[locale], value: locale }));

// const CustomSelect = forwardRef(function CustomSelect<
//     TValue extends {},
//     Multiple extends boolean,
// >(props: SelectProps<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
//     const slots = {
//         root: StyledButton,
//         listbox: StyledListbox,
//         popper: StyledPopper,
//         ...props.slots,
//     };

//     return <Select {...props} ref={ref} slots={slots} />;
// });

function LanguageSelect({ }: LanguageSelectProps) {
    const { t } = useTranslation([Namespaces.glossary]);

    const onLanguageChange = (locale: string) => {
        localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
        i18next.changeLanguage(locale);
    }

    return (
        <DropdownMenu
            title={t("locale", { ns: Namespaces.glossary })}
            values={LANGUAGES_OPTIONS}
            defaultValue={i18next.language}
            // startIcon={<TranslateRoundedIcon />}
            onChange={onLanguageChange}
        />
    );
}

export default LanguageSelect;