import { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Link, List, CircularProgress, ListItem, Avatar, ListItemText, ListItemAvatar, styled, Box } from '@mui/material';
import Partner from 'models/Partner';
import Reward, { RewardsMethods } from 'models/Reward';
import Store, { StoresMethods } from 'models/Store';
import urls from 'constants/urls';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const DetailParagraph = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3)
}));

type PartnerDetailDialogProps = {
    partner: Partner | null;
    onClose: (event: {}) => void;
}

function PartnerDetailDialog(props: PartnerDetailDialogProps) {

    const { partner, onClose, } = props;
    const { rewards, rewardsLoading, stores, storesLoading, } = useAppSelector(state => ({
        rewards: state.rewards.list.data,
        rewardsLoading: state.rewards.list.loading,
        stores: state.stores.list.data,
        storesLoading: state.stores.list.loading,
    }))
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.commons, Namespaces.glossary]);

    useEffect(() => {
        if (partner) {
            dispatch(RewardsMethods.list([{ fieldPath: "partner.id", opStr: "==", value: partner.id }]));
            dispatch(StoresMethods.list(partner.id, []));
        }
    }, [partner]);

    return (
        <Dialog
            open={partner !== null}
            onClose={onClose}
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="partner-dialog-title"
            PaperProps={{
                sx: {
                    px: 2,
                    py: 4,
                    backgroundColor: (theme) => theme.palette.background.default,
                }
            }}>
            <DialogTitle id="partner-dialog-title">
                <Typography variant="h2" >
                    {partner?.name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DetailParagraph>
                    <Typography variant="h6">
                        {t("category", { ns: Namespaces.glossary })}
                    </Typography>
                    <Typography variant="body1">
                        {partner?.category}
                    </Typography>
                </DetailParagraph>

                <DetailParagraph>
                    <Typography variant="h6">
                        Description
                    </Typography>
                    <Typography variant="body1">
                        {partner?.description}
                    </Typography>
                </DetailParagraph>

                <DetailParagraph>
                    <Typography variant="h6">
                        {t("email", { ns: Namespaces.commons })}
                    </Typography>
                    <Typography variant="body1">
                        <Link
                            href={`mailto:${partner?.email}`}>
                            {partner?.email}
                        </Link>
                    </Typography>
                </DetailParagraph>

                <DetailParagraph>
                    <Typography variant="h6">
                        {t("website", { ns: Namespaces.commons })}
                    </Typography>
                    <Typography variant="body1">
                        <Link
                            target="_blank"
                            href={`${partner?.website}`}>
                            {partner?.website}
                        </Link>
                    </Typography>
                </DetailParagraph>

                <DetailParagraph>
                    <Typography variant="h6">
                        {t("reward_plural", { ns: Namespaces.glossary })}
                    </Typography>
                    {
                        rewardsLoading ?
                            <CircularProgress />
                            :
                            rewards.length ?
                                <List>
                                    {rewards.map((reward) => (
                                        <div key={reward.id}>
                                            {/* { index > 0 ? <Divider variant="inset" /> : null } */}
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar src={reward.imageURL} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={reward.name}
                                                />
                                            </ListItem>
                                        </div>
                                    ))}
                                </List>
                                :
                                <Typography variant="body2">
                                    {t("no_reward", { ns: Namespaces.glossary })}
                                </Typography>
                    }
                </DetailParagraph>

                <DetailParagraph>
                    <Typography variant="h6">
                        {t("store_plural", { ns: Namespaces.glossary })}
                    </Typography>
                    {
                        storesLoading ?
                            <CircularProgress />
                            :
                            stores.length ?
                                <List>
                                    {stores.map((store) => (
                                        <div key={store.id}>
                                            {/* { index > 0 ? <Divider variant="inset" /> : null } */}
                                            <ListItem>
                                                <ListItemText
                                                    primary={store.name}
                                                    secondary={
                                                        <Link
                                                            href={`${urls.GOOGLE_MAPS.PLACE}/${encodeURI(store.address)}`}
                                                            target="_blank"
                                                        >
                                                            {store.address}
                                                        </Link>
                                                    }
                                                />
                                            </ListItem>
                                        </div>
                                    ))}
                                </List>
                                :
                                <Typography variant="body2">
                                    {t("no_store", { ns: Namespaces.glossary })}
                                </Typography>
                    }
                </DetailParagraph>
            </DialogContent>
        </Dialog >
    );
}

export default PartnerDetailDialog;