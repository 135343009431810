import { APICoconData, CoconManager, CoconStats, CoconStatus, fromAPIData, NewCoconData } from "./Cocon";
import { Timestamp } from "firebase/firestore";
import { Collections, DBIdentifiers } from "constants/db";
import { QueryFilter } from "constants/types";
import { AppDispatch } from "store/store";
import { CoconsClustersActions } from "store/reducers/cocons/clusters";
import { CodeError, fetchAPI } from "actions/actions";
import { listDocs } from "helpers/db";
import { SelectedCoconsClusterActions } from "store/reducers/cocons/selectedCluster";
import urls from "constants/urls";
import { showTranslatedMessage } from "store/reducers/snacks";
import { SelectedCoconActions } from "store/reducers/cocons/selected";
import PartnerCompany from "models/PartnerCompany";

export type NewCoconsClusterData = {
    name: string;
    displayAddress: string;
    joinCode: string;
    managers: CoconManager[];
    dateDeployed?: number;
    status: CoconStatus;
    managedBy: PartnerCompany;
    cocons: Omit<NewCoconData, "clusterId">[];
}

export type CoconsClusterDbData = Omit<NewCoconsClusterData, "dateDeployed"> & {
    nextPin: number;
    dateDeployed?: Timestamp;
    pendingIssues: number;
} & CoconStats;

type CoconsCluster = DBIdentifiers & Omit<CoconsClusterDbData, "dateDeployed"> & {
    dateDeployed?: number;
};

type CoconsClusterAPIData = CoconsCluster & {
    cocons: APICoconData[];
};

const COLLECTION = Collections.COCONS_CLUSTERS;

const list = (filters: QueryFilter[]) => async (dispatch: AppDispatch) => {
    dispatch(CoconsClustersActions.startLoadingCoconsClusters());

    try {
        const clusters = await listDocs<CoconsCluster>(COLLECTION, filters);
        dispatch(CoconsClustersActions.setClustersList(clusters));
        return clusters;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(CoconsClustersActions.setClustersListError(error.message));
        return [];
    }
}

const create = (data: NewCoconsClusterData) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(SelectedCoconsClusterActions.startLoading());

    try {
        // add Cocon through API (need to send emails to managers)
        const addedCoconData: CoconsClusterAPIData = await fetchAPI(`${urls.API}/cocon/cluster`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        const coconsCluster = addedCoconData;
        
        // dispatch created cluser to state
        dispatch(SelectedCoconsClusterActions.setCluster(coconsCluster));

        // dispatch created cocon to state
        if (coconsCluster.cocons.length > 0) {
            dispatch(SelectedCoconActions.setSelectedCocon(fromAPIData(coconsCluster.cocons[0])));
        }

        // show success message
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "add_cocon.success"
        }));

        return coconsCluster;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(SelectedCoconsClusterActions.setError(error.message));

        // show error message
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "add_cocon.error"
        }));

        return null;
    }
}

export default CoconsCluster;

export const CoconsClustersMethods = {
    list,
    create,
};