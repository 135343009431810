import { Box, Collapse, } from "@mui/material";
import BatchesToDoSort from "./BatchesToDoSort";
import { useAppSelector } from "store/hooks";
import BatchesToDoFilters from "./BatchesToDoFilters";

type SortAndFilterProps = {
}

/**
 * Header for the "to do" batches list containing 
 * sorting and filtering options
 */
export default function BatchesToDoSortAndFilter(props: SortAndFilterProps) {

    const showHeader = useAppSelector(state => state.batches.list.showTodoHeader);

    return (
        <Collapse in={showHeader} orientation="vertical">
            <Box
                display="flex"
                gap={5}
                justifyContent="flex-start"
                mt={2}
            >
                <BatchesToDoSort />

                <BatchesToDoFilters />
            </Box>
        </Collapse>
    )
}