import { useState, FormEvent, useEffect } from 'react';
import { TextField, Grid, Box } from '@mui/material';
import ActionButton from 'components/_include/ActionButton';
import { FormInput } from 'helpers/forms';
import { CoconsMethods } from 'models/Cocons/Cocon';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { NewCoconsClusterData } from 'models/Cocons/CoconsCluster';
import PartnerCompany from 'models/PartnerCompany';
import SectionTitle from 'components/_include/Titles/SectionTitle';
import PartnerCompanySelect from 'components/_include/Filters/PartnerCompanySelect';
import NewPartnerModal from '../CreatePartnerCompany/CreatePartnerModal';

export type AddCoconsClusterData = Pick<NewCoconsClusterData, "displayAddress" | "joinCode" | "name" | "managedBy">;

type FormFieldsType = {
    name: FormInput<string>;
    joinCode: FormInput<string>;
    displayAddress: FormInput<string>;
    managedBy: FormInput<PartnerCompany>;
};

type CoconsClusterFormProps = {
    type: "create" | "edit";
    clusterData: AddCoconsClusterData;
    loading: boolean;
    submitForm: (data: AddCoconsClusterData) => void;
}

function CoconsClusterForm(props: CoconsClusterFormProps) {

    const { type, clusterData, loading, submitForm, } = props;

    const joinCodeLoading = useAppSelector(state => state.cocons.joinCode.loading);
    const newJoinCode = useAppSelector(state => state.cocons.joinCode.data);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.forms, Namespaces.glossary, Namespaces.titles]);

    const getLabel = (label: keyof FormFieldsType) => t(`props.${label}`, { ns: Namespaces.cocons });

    const initialInputs: FormFieldsType = {
        name: {
            value: clusterData.name,
            error: null,
        },
        joinCode: {
            value: clusterData.joinCode,
            error: null,
        },
        displayAddress: {
            value: clusterData.displayAddress,
            error: null,
        },
        managedBy: {
            value: clusterData.managedBy,
            error: null,
        },
    };

    const [inputs, setInputs] = useState(initialInputs);
    const [newPartnerModalOpen, setNewPartnerModalOpen] = useState(false);

    useEffect(() => {
        if (!inputs.joinCode.value) { // need to populate new join code
            if (!newJoinCode) { // join code not loaded yet
                dispatch(CoconsMethods.getJoinCode());
            }
            else {
                setInputs({
                    ...inputs,
                    joinCode: {
                        value: newJoinCode,
                        error: null,
                    },
                });
            }
        }
    }, [newJoinCode]);

    const handleCreatePartnerClick = () => {
        setNewPartnerModalOpen(true);
    };

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string | number | boolean | unknown) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let { name, joinCode, displayAddress, managedBy, } = inputs;

        let error = false;

        if (!name.value) {
            error = true;
            name.error = "";
        }

        if (!displayAddress.value) {
            error = true;
            displayAddress.error = "";
        }

        if (!managedBy.value) {
            error = true;
            managedBy.error = "";
        }

        if (error) {
            setInputs({
                ...inputs,
                name: name,
                displayAddress: displayAddress,
                managedBy: managedBy,
            });
        }
        else {
            const enteredData: AddCoconsClusterData = {
                name: name.value,
                joinCode: joinCode.value,
                displayAddress: displayAddress.value,
                managedBy: managedBy.value,
            };

            submitForm(enteredData)
        }
    }

    const { name, joinCode, displayAddress, managedBy } = inputs;

    const formIsValid = name.value && displayAddress.value && joinCode.value;

    return (
        <form
            method="post"
            action="#"
            onSubmit={(event) => handleSubmitPressed(event)} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        required
                        margin="normal"
                        value={name.value}
                        variant="outlined"
                        label={getLabel("name")}
                        InputProps={{
                            onChange: (event) => handleInputChange('name', event.target.value),
                        }}
                        error={Boolean(name.error)}
                        helperText={name.error || "Ex: CDC - 1-2-3 rue Dupont - Grenoble"}
                        style={{ maxWidth: "none" }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        required
                        margin="normal"
                        value={displayAddress.value}
                        variant="outlined"
                        label={getLabel("displayAddress")}
                        InputProps={{
                            onChange: (event) => handleInputChange('displayAddress', event.target.value),
                        }}
                        error={Boolean(displayAddress.error)}
                        helperText={displayAddress.error || "Ex: 1-2-3 rue Dupont à Grenoble"}
                        style={{ maxWidth: "none" }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        disabled
                        margin="normal"
                        value={joinCode.value}
                        variant="outlined"
                        label={joinCodeLoading ? t("generating", { ns: Namespaces.forms }) : getLabel("joinCode")}
                        error={Boolean(joinCode.error)}
                        helperText={joinCode.error}
                        style={{ maxWidth: "none" }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <SectionTitle title={t("props.managedBy", { ns: Namespaces.cocons })} />
                    <PartnerCompanySelect
                        value={managedBy.value.id}
                        parentLoading={loading}
                        noneSelectedLabel={t("add_cocon.select_partner_company", { ns: Namespaces.cocons })}
                        onChange={(value: PartnerCompany) => { handleInputChange('managedBy', value); }}
                        required
                    />
                    <ActionButton
                        sx={{ height: "45px", width: "200px" }}
                        color="primary"
                        onClick={handleCreatePartnerClick}
                        type="button"
                    >
                        {t("create_partner_company", { ns: Namespaces.actions })}
                    </ActionButton>
                </Grid>

                <Grid
                    item
                    xs={12}
                    textAlign="center"
                    mt={4}
                >
                    <Box>
                        <ActionButton
                            color="primary"
                            disabled={!formIsValid}
                            loading={loading}
                            type="submit"
                        >
                            {t(type === "create" ? "next" : "save", { ns: Namespaces.actions })}
                        </ActionButton>
                    </Box>
                </Grid>
            </Grid>
            <NewPartnerModal
                open={newPartnerModalOpen}
                onClose={() => setNewPartnerModalOpen(false)}
            />
        </form >
    )
}

export default CoconsClusterForm;