import { Box, Card, CardActions, CardMedia, Dialog, FormControlLabel, Modal, Switch } from "@mui/material";
import ActionButton from "components/_include/ActionButton";
import SectionLoader from "components/_include/SectionLoader";
import { TrashColors } from "constants/trash";
import { drawBox, drawPolygon } from "helpers/canvas";
import { getPictureURL, StorageType } from "helpers/storage";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectBatchById } from "store/reducers/batches/batch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BatchesMethods } from "models/Batches/Batch";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";

type DialogProps = {
    batchId: string | undefined;
    onClose: () => void;
}

export default function SelectedBatchDialog(props: DialogProps) {
    const { batchId, onClose, } = props;

    const { t } = useTranslation([Namespaces.actions]);

    const batch = useAppSelector(state => batchId ? selectBatchById(state, batchId) : undefined);
    const dispatch = useAppDispatch();

    const [pictureURL, setPictureURL] = useState<string>();
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        setPictureURL(undefined);
        setImageLoaded(false);
        if (batch?.imageURL) {
            getPictureURL(StorageType.BATCHES, batch.imageURL)
                .then((url) => {
                    if (url) setPictureURL(url);
                });
        }
    }, [batch?.imageURL]);

    const imageRef = useRef<HTMLImageElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        if (canvasRef.current && imageRef.current) {
            if (batch?.aiResults) {
                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    for (let result of batch.aiResults) {
                        if (result.points) {
                            drawPolygon(ctx, result.points, TrashColors[result.class], );
                        }
                        else if (result.box) {
                            drawBox(ctx, result.box, TrashColors[result.class], );
                        }
                    }
                }
            }
            else { // hide AI results
                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');
                ctx?.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
    }, [batch?.aiResults]);

    const [aiResultsVisible, toggleAIResults] = useState(false);
    useEffect(() => {
        toggleAIResults(false);
    }, [batch]);

    const handleAIButtonPressed = () => {
        if (batch) {
            if (!batch.aiResults) {
                dispatch(BatchesMethods.getAIResults(batch.id));
                toggleAIResults(true);
            }
            else { // results already loaded
                if (aiResultsVisible) { // hide results
                    toggleAIResults(false);
                    canvasRef.current?.setAttribute("hidden", "");
                }
                else { // show results
                    toggleAIResults(true);
                    canvasRef.current?.removeAttribute("hidden");
                }
            }
        }
    }

    const handleToAnnotateChanged = (checked: boolean) => {
        if (batch) {
            dispatch(BatchesMethods.update(batch.id, {
                toAnnotate: checked,
            }));
        }
    }

    return (
        <Modal
            open={batch !== undefined}
            onClose={onClose}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Card>
                <Box position="relative">
                    <CardMedia
                        ref={imageRef}
                        component="img"
                        image={pictureURL}
                        onLoad={() => { setImageLoaded(true) }}
                        sx={{
                            objectFit: "contain",
                            maxHeight: "80vh"
                        }}
                    />
                    {batch?.imageURL && imageRef?.current && imageLoaded && (
                        <canvas
                            ref={canvasRef}
                            width={imageRef.current.naturalWidth}
                            height={imageRef.current.naturalHeight}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: '100%',
                                maxHeight: '100%',
                                objectFit: "contain",
                            }} />
                    )}
                </Box>
                <CardActions
                    sx={{
                        justifyContent: "space-evenly"
                    }}
                >
                    <ActionButton
                        loading={batch?.loading}
                        icon={aiResultsVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        onClick={() => { handleAIButtonPressed() }}
                        color="inherit"
                        sx={{
                            backgroundColor: (theme) => theme.palette.success.light,
                            color: "#ffffff",
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.success.main,
                            }
                        }}
                    >
                        {t("view_ai_results", { ns: Namespaces.actions })}
                    </ActionButton>

                    <FormControlLabel
                        sx={{
                            color: (theme) => !batch?.toAnnotate ? "rgba(0,0,0,0.38)" : theme.palette.success.light,
                        }}
                        control={
                            <Switch
                                readOnly={batch?.loading}
                                checked={batch?.toAnnotate}
                                color="success"
                                onChange={(e, checked) => handleToAnnotateChanged(checked)}
                            />
                        }
                        label={t("to_annotate", { ns: Namespaces.actions }).toString()}
                    />
                </CardActions>
            </Card>
        </Modal>
    );
}