import { TrashColors } from "constants/trash";
import { drawBox, drawPolygon } from "helpers/canvas";
import _ from "lodash";
import { BatchesMethods } from "models/Batches/Batch";
import BatchAIResults from "models/Batches/BatchAIResults";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

type AIResultsProps = {
    width: number;
    naturalWidth: number;
    height: number;
    naturalHeight: number;
}

function DemoAIResults({ width, naturalWidth, height, naturalHeight }: AIResultsProps) {
    const dispatch = useAppDispatch();

    const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D>();
    const handleCanvasRef = useCallback((node: HTMLCanvasElement | null) => {
        setCanvasContext(node?.getContext("2d") ?? undefined);
    }, []);

    const batchId = useAppSelector(state => state.batches.selected.data?.id);
    const [aiResults, setAIResults] = useState<BatchAIResults[]>();

    const [loadTimeout, setLoadTimeout] = useState<NodeJS.Timeout>();

    /**
     * Keep trying to load selected batch's AI results, retrying every 5sec
     */
    const loadAIResults = async () => {
        if (batchId) {
            console.debug("loading last batch AI results");

            const aiResults = await dispatch(BatchesMethods.getAIResults(batchId));
            console.log("🚀 ~ file: DemoAIResults.tsx:41 ~ loadAIResults ~ aiResults:", aiResults)

            if (!aiResults || aiResults.length === 0) { // AI results not ready yet, retry in 5s
                setLoadTimeout(setTimeout(() => {
                    loadAIResults();
                }, 5000));
            }
            else {
                setAIResults(aiResults);
            }
        }
    };

    // load AI results when batch changes
    useEffect(() => {
        if (loadTimeout) clearTimeout(loadTimeout);

        setAIResults(undefined);

        loadAIResults();

        return () => {
            console.debug("clearing AI results timeout")
            if (loadTimeout) clearTimeout(loadTimeout);
        }
    }, [batchId]);

    // draw the masks or bounding boxes when AI results are loaded
    useEffect(() => {
        if (canvasContext) {
            canvasContext.clearRect(0, 0, width, height);
            console.debug("clearing");

            if (aiResults) {
                console.debug("drawing");
                for (let result of aiResults) {
                    // only draw batch results that were not in previous batch
                    // if (result.inPrevious) continue;

                    const points = result.points;
                    const box = result.box;
                    if (points) {
                        drawPolygon(canvasContext, points, TrashColors[result.class],);
                    }
                    else if (box) {
                        drawBox(canvasContext, box, TrashColors[result.class],);
                    }
                }
            }
        }
    }, [aiResults, canvasContext, width, height, naturalWidth, naturalHeight]);

    return (
        <canvas
            ref={handleCanvasRef}
            width={naturalWidth}
            height={naturalHeight}
            style={{
                position: "absolute",
                objectFit: "contain",
                top: 0,
                left: 0,
                width: '100%',
                maxHeight: '100%',
            }} />
    );
}

export default DemoAIResults;