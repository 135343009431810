import Partner from "models/Partner";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PartnerContext = {
    partners: Partner[],
    loading: boolean,
    error: string | null,
    selectedPartner: Partner | null,
}

const initialState: PartnerContext = {
    partners: [],
    loading: false,
    error: null,
    selectedPartner: null,
};

export const partnersSlice = createSlice({
    name: 'partners',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, action: PayloadAction<Partner[]>) => {
            state.loading = false;
            state.partners = action.payload;
        },
        setSelected: (state, action: PayloadAction<Partner>) => {
            state.loading = false;
            state.selectedPartner = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const PartnersActions = partnersSlice.actions;

const PartnersReducer = partnersSlice.reducer;

export default PartnersReducer;