import { ucFirst } from "helpers/strings";
import i18next, { Resource } from "i18next";
import _ from "lodash";
import moment from "moment";
import { initReactI18next } from "react-i18next";

const momentLocales = {
    fr: require('moment/locale/fr'),
    en: require('moment/locale/en-gb'),
};

const getMacrolanguage = (locale: string) => {
    if (locale.indexOf('-') === -1) return locale;
    return locale.substring(0, locale.indexOf('-'))
}

export enum Locale {
    ENGLISH = "en",
    FRENCH = "fr",
}

export const LOCALES_LABELS = {
    [Locale.ENGLISH]: "English",
    [Locale.FRENCH]: "Français",
}

export enum Namespaces {
    actions = 'actions',    // labels for buttons (eg "filter", "confirm")
    batches = 'batches',    // expressions relative to batches (eg "processed batches"...)
    cocons = 'cocons',      // vocabulary linked to cocons (eg "join code", ...)
    commons = 'commons',    // common words (eg "points", ...)
    forms = 'forms',        // forms placeholders/labels (eg "Entrez votre adresse...")
    glossary = 'glossary',  // ficha specific words (eg "cocon"...)
    issues = 'issues',      // relative to reported issues on cocons (eg "3 problèmes en attente")
    purchases = 'purchases',    // relative to rewards purchases (eg "Commandes en cours")
    snacks = 'snacks',      // messages for the global snackbar (eg "Action successful!")
    stats = 'stats',        // eg "kg of CO2"...
    titles = 'titles',      // eg "Chats", "Statistiques"...
    wastes = 'wastes',      // types of waste (eg "plastic bottle", ...)
}

const resources: Resource = _.reduce(Object.values(Locale), ((acc, locale) => {
    acc[locale] = {
        [Namespaces.actions]: require(`./${locale}/actions.json`),
        [Namespaces.batches]: require(`./${locale}/batches.json`),
        [Namespaces.cocons]: require(`./${locale}/cocons.json`),
        [Namespaces.commons]: require(`./${locale}/commons.json`),
        [Namespaces.forms]: require(`./${locale}/forms.json`),
        [Namespaces.glossary]: require(`./${locale}/glossary.json`),
        [Namespaces.issues]: require(`./${locale}/issues.json`),
        [Namespaces.purchases]: require(`./${locale}/purchases.json`),
        [Namespaces.snacks]: require(`./${locale}/snacks.json`),
        [Namespaces.stats]: require(`./${locale}/stats.json`),
        [Namespaces.titles]: require(`./${locale}/titles.json`),
        [Namespaces.wastes]: require(`./${locale}/wastes.json`),
    };
    return acc;
}), {} as Resource);

export const format = (value: any, format: string | undefined, lng: string | undefined) => {
    switch (format) {
        case "number":
            return new Intl.NumberFormat(lng, {
                style: 'decimal',
                maximumFractionDigits: 2,
            }).format(value);

        case "currency":
        case "rounded_currency":
            const fractionDigits = format === "rounded_currency" ? 0 : 2;
            return value.toLocaleString(lng, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });

        case "date":
            return moment(value).format("LL");

        case "day":
            return moment(value).format("ddd D MMM");

        case "day_full":
            return ucFirst(moment(value).format("dddd D MMMM"));

        case "datetime_full":
            return moment(value).format("LLL");

        case "datetime_full_day":
            return ucFirst(moment(value).format("LLLL"));

        case "datetime_full_seconds":
            return ucFirst(value.toDate().toLocaleString(lng?.toUpperCase(), { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" }));

        case "week":
            return `${moment(value).startOf("week").format("D MMM")}-${moment(value).endOf("week").format("D MMM")}`;

        case "week_full":
            return `Semaine du ${moment(value).startOf("week").format("D MMMM")} au ${moment(value).endOf("week").format("D MMMM")}`;

        case "month":
            return ucFirst(moment(value).format("MMMM"));
    }
    return value;
}

export const LOCAL_STORAGE_LOCALE_KEY = "preferred_locale";

export const configureTranslations = () => {
    i18next
        .use(initReactI18next)
        .init({
            resources,
            lng: localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) ?? 'fr',
            supportedLngs: Object.values(Locale),
            load: 'languageOnly',
            // debug: true,
            interpolation: {
                format: format,
            }
        });
}
