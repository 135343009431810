import { Box, Point, Polygon } from "constants/types";
import { hexToRgb } from "./colors";

export function drawPolygon(ctx: CanvasRenderingContext2D, polygons: Polygon, color?: string, ratio = { width: 1, height: 1 }, ) {
    const polygon = polygons[0]; // outter shape: Point[]
    const { width: width_ratio, height: height_ratio } = ratio;

    //polygon1--- usually the outside polygon, must be clockwise
    const firstPoint = polygon[0]; // Point
    ctx.beginPath();
    ctx.moveTo(firstPoint[0] * width_ratio, firstPoint[1] * height_ratio);
    for (let i = 1; i < polygon.length; i++) {
        ctx.lineTo(polygon[i][0] * width_ratio, polygon[i][1] * height_ratio);
    }
    ctx.lineTo(firstPoint[0] * width_ratio, firstPoint[1] * height_ratio);
    ctx.closePath();

    // //polygon2 --- usually hole,must be counter-clockwise 
    // ctx.moveTo(10, 10);
    // ctx.lineTo(10,100);
    // ctx.lineTo(100, 100);
    // ctx.lineTo(100, 10);
    // ctx.lineTo(10, 10);
    // ctx.closePath();

    //  add as many holes as you want
    if (!color) color = "#ffffff";
    const fillColor = hexToRgb(color);
    ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, 0.1)`;
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.fill();
    ctx.stroke();
}

export function drawBox(ctx: CanvasRenderingContext2D, box: Box, color?: string, ratio = { width: 1, height: 1 }) {
    const { width: width_ratio, height: height_ratio } = ratio;

    const topLeft: Point = [box[0], box[1]];
    const topRight: Point = [box[2], box[1]];
    const bottomRight: Point = [box[2], box[3]];
    const bottomLeft: Point = [box[0], box[3]];

    ctx.beginPath();
    ctx.moveTo(topLeft[0] * width_ratio, topLeft[1] * height_ratio);
    ctx.lineTo(topRight[0] * width_ratio, topRight[1] * height_ratio);
    ctx.lineTo(bottomRight[0] * width_ratio, bottomRight[1] * height_ratio);
    ctx.lineTo(bottomLeft[0] * width_ratio, bottomLeft[1] * height_ratio);
    ctx.lineTo(topLeft[0] * width_ratio, topLeft[1] * height_ratio);
    ctx.closePath();

    //  add as many holes as you want
    if (!color) color = "#ffffff";
    const fillColor = hexToRgb(color);
    ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, 0.1)`;
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.fill();
    ctx.stroke();
}