import Message, { MessageDbData, NewMessageData } from "../../models/Message";
import LastMessage from "../../models/LastMessage";
import Conversation from "models/Conversations/Conversation";
import { DataContext } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";

const MAX_NB_MESSAGES = 1000;

export type SerializedMessage = Omit<Message, "timestamp"> & {
    timestamp?: number;
}

export type SerializedLastMessage = Omit<LastMessage, "message"> & {
    message: SerializedMessage;
}

function serialize(message: Message): SerializedMessage;
function serialize(message: Partial<Message>): Partial<SerializedMessage>;
function serialize(message: Message | Partial<Message>) {
    const serializedMessage: SerializedMessage | Partial<SerializedMessage> = {
        ...message,
        timestamp: message.timestamp?.toMillis(),
    };
    return serializedMessage;
}

export function deserializeMessage(message: SerializedMessage): Message;
export function deserializeMessage(message: Partial<SerializedMessage>): Partial<Message>;
export function deserializeMessage(message: SerializedMessage | Partial<SerializedMessage>) {
    return {
        ...message,
        timestamp: message.timestamp ? Timestamp.fromMillis(message.timestamp) : undefined,
    };
}

function serializeLastMessage(lastMessage: LastMessage): SerializedLastMessage;
function serializeLastMessage(lastMessage: Partial<LastMessage>): Partial<SerializedLastMessage>;
function serializeLastMessage(lastMessage: LastMessage | Partial<LastMessage>) {
    const serializedCluster = {
        ...lastMessage,
        ...(lastMessage.message ? { message: serialize(lastMessage.message) } : {}),
    };
    return serializedCluster;
}

export function deserializeLastMessage(lastMessage: SerializedLastMessage): LastMessage;
export function deserializeLastMessage(lastMessage: Partial<SerializedLastMessage>): Partial<LastMessage>;
export function deserializeLastMessage(lastMessage: SerializedLastMessage | Partial<SerializedLastMessage>) {
    const message: MessageDbData | undefined = lastMessage.message ? deserializeMessage(lastMessage.message) : undefined;
    return {
        ..._.omit(lastMessage, "message"),
        ...(message ? { message: message } : {}),
    };
}

export type MessagesContext = {
    messages: DataContext<Message[]> & {
        loadingOlder: boolean,
        firstItemIndex: number,
    };
    lastMessages: DataContext<LastMessage[]>;
    sendingMessage: DataContext<NewMessageData | null>;
    conversations: DataContext<Conversation[]>;
    sendingConversation: DataContext<Conversation | null>;
}

const initialState: MessagesContext = {
    messages: {
        data: [],
        loading: false,
        loadingOlder: false,
        error: null,
        firstItemIndex: MAX_NB_MESSAGES,
    },
    lastMessages: {
        data: [],
        loading: false,
        error: null,
    },
    sendingMessage: {
        data: null,
        loading: false,
        error: null,
    },
    conversations: {
        data: [],
        loading: false,
        error: null,
    },
    sendingConversation: {
        data: null,
        loading: false,
        error: null,
    },
};

export const messagesSlice = createSlice({
    name: 'messages',
    initialState: initialState,
    reducers: {
        startLoadingMessages: (state) => {
            state.messages.loading = true;
            state.messages.error = null;
        },
        startLoadingOlderMessages: (state) => {
            state.messages.loadingOlder = true;
            state.messages.error = null;
        },
        startLoadingLastMessages: (state) => {
            state.lastMessages.loading = true;
            state.lastMessages.error = null;
        },
        startSendingMessage: (state, { payload }: PayloadAction<NewMessageData>) => {
            state.sendingMessage.loading = true;
            state.sendingMessage.error = null;
            state.sendingMessage.data = payload;
        },
        startLoadingConversations: (state) => {
            state.conversations.loading = true;
            state.conversations.error = null;
        },
        startSendingConversation: (state, { payload }: PayloadAction<Conversation>) => {
            state.sendingConversation.loading = true;
            state.sendingConversation.error = null;
            state.sendingConversation.data = payload;
        },
        setMessagesList: (state, { payload: messages }: PayloadAction<Message[]>) => {
            state.messages.firstItemIndex = MAX_NB_MESSAGES;
            state.messages.data = messages;
            console.debug("new loaded", messages);
            state.messages.loading = false;
        },
        addOlderMessages: (state, { payload: messages }: PayloadAction<Message[]>) => {
            state.messages.firstItemIndex -= messages.length;
            state.messages.data = [...messages, ...state.messages.data, ];
            console.debug("older loaded", messages);
            state.messages.loadingOlder = false;
        },
        setLastMessagesList: (state, { payload: lastMessages }: PayloadAction<LastMessage[]>) => {
            state.lastMessages.loading = false;
            state.lastMessages.data = lastMessages;
        },
        setLastMessageRead: (state, { payload }: PayloadAction<LastMessage>) => {
            state.lastMessages.data = state.lastMessages.data.map(m => {
                if (m.id === payload.id) m.read = true;
                return m;
            });
        },
        finishSendingMessage: (state, { payload: { usersIds, message } }: PayloadAction<{ usersIds: string[], message?: Message }>) => {
            state.sendingMessage.loading = false;
            state.sendingMessage.data = null;
            if (message) state.messages.data = [...state.messages.data, message];
        },
        finishSendingConversation: (state, { payload }: PayloadAction<{ usersIds: string[] }>) => {
            state.sendingConversation.loading = false;
            state.sendingConversation.data = null;
        },
        updateLastMessages: (state, { payload }: PayloadAction<LastMessage[]>) => {
            let lastMessages = state.lastMessages.data;
            for (const lastMessage of payload) {
                const oldLastMessageIndex = lastMessages.findIndex(m => m.user.id === lastMessage.user.id);
                lastMessages.splice(oldLastMessageIndex, 1);
                state.lastMessages.data.push(lastMessage);
            }
        },
        setConversationsList: (state, { payload }: PayloadAction<Conversation[]>) => {
            state.conversations.loading = false;
            state.conversations.data = payload;
        },
        setMessagesError: (state, { payload }: PayloadAction<string>) => {
            state.messages.loading = false;
            state.messages.error = payload;
        },
        setLastMessagesError: (state, { payload }: PayloadAction<string>) => {
            state.lastMessages.loading = false;
            state.lastMessages.error = payload;
        },
        setSendingMessageError: (state, { payload }: PayloadAction<string>) => {
            state.sendingMessage.loading = false;
            state.sendingMessage.error = payload;
            state.sendingMessage.data = null;
        },
        setConversationsError: (state, { payload }: PayloadAction<string>) => {
            state.conversations.loading = false;
            state.conversations.error = payload;
        },
        setSendingConversationnError: (state, { payload }: PayloadAction<string>) => {
            state.sendingConversation.loading = false;
            state.sendingConversation.error = payload;
            state.sendingConversation.data = null;
        },
    },
});

export const MessagesActions = messagesSlice.actions;

const MessagesReducer =  messagesSlice.reducer;

export default MessagesReducer;