import { useEffect, useState } from "react";
import { UsersMethods } from 'models/User';
import UserChat from './UserChat';
import ChatUsersNav from './UsersNav/ChatUsersNav';
import SectionLoader from '../../../_include/SectionLoader';
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MessagesMethods } from "models/Message";
import { MessagesActions } from "store/reducers/message";

type ChatPageProps = {

}

function ChatPage(props: ChatPageProps) {

    const { data: selectedUser, loading: selectedUserLoading, } = useAppSelector(state => state.users.selectedUser);
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const userId = searchParams.get("id");

    useEffect(() => {
        if (userId) {
            dispatch(UsersMethods.retrieve(userId));
        }
    }, [userId])

    return (
        <Box
            display='flex'
            flexDirection='row'
            width="100%"
        >
            <Box
                width={300}
                zIndex={100}
                height="100vh"
            >
                <ChatUsersNav />
            </Box>
            <Box
                flex={1}
                position="relative"
            >
                {selectedUserLoading ? (
                    <SectionLoader />
                ) : (
                    selectedUser && (
                        <UserChat />
                    )
                )}
            </Box>
        </Box>
    );
}


export default ChatPage;
