import { Collections, DBIdentifiers, DbOrder } from "constants/db";
import { TrashType } from "constants/trash";
import { QueryFilter } from "constants/types";
import { getDocs, query as queryDB, Query, Timestamp, updateDoc, startAfter, DocumentReference } from "firebase/firestore";
import moment from "moment";
import { BatchesActions } from "store/reducers/batches/batch";
import store, { AppDispatch } from "store/store";
import { CoconDbData } from "../Cocons/Cocon";
import { UserDbData } from "../User";
import BatchAIResults, { listAIResults } from "./BatchAIResults";
import urls from "constants/urls";
import { CodeError, fetchAPI } from "actions/actions";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import _ from "lodash";
import { applyQueryFilterToDoc, formatQuery, fromSnapshot, getCollectionRef, getDocumentReference } from "helpers/db";
import { SelectedBatchActions } from "store/reducers/batches/selected";

type CoconData = Pick<CoconDbData, "address" | "city" | "clusterId" | "postcode"> & {
    id: string;
};

type UserData = Pick<UserDbData, "pin"> & {
    id: string;
};

export type BatchDbData = {
    imageURL: string;
    previousBatchImage?: string;
    isProcessed: boolean;
    result: TrashType[];
    score: number;
    timestamp: Timestamp;
    user?: UserData;
    cocon: CoconData;
    toAnnotate?: boolean;
}

type APIBatchData = Omit<BatchDbData, "timestamp"> & {
    id: string;
    timestamp: number;
};

type Batch = BatchDbData & DBIdentifiers & { 
    aiResults?: BatchAIResults[], 
    loading: boolean,
    aiResultsVisible: boolean,
};

const COLLECTION = Collections.BATCH;

function getDocRef(id: string) {
    return getDocumentReference(id, COLLECTION);
}

const getTimestamp = (batch: Batch) => moment(batch.timestamp.toDate());

function fromAPIData(batchData: APIBatchData): Batch {
    return {
        ...batchData,
        timestamp: Timestamp.fromMillis(batchData.timestamp),
        loading: false,
        aiResultsVisible: false,
    }
}

const executeListQuery = (query: Query, limit: number, inequalityFilters?: QueryFilter[]) => async (dispatch: AppDispatch) => {
    try {
        const snapshot = await getDocs(query);

        const batches = snapshot.docs
            .filter(doc => inequalityFilters ? inequalityFilters.every(filter => applyQueryFilterToDoc(filter, doc)) : true)
            .map(doc => fromSnapshot(doc) as Batch);

        let nextQuery: { query: Query, limit: number } | null = null;
        if (batches.length === limit) { // there are still more batches to load
            nextQuery = {
                query: queryDB(query, startAfter(batches[limit - 1].timestamp)),
                limit: limit,
            };
        }
         
        dispatch(BatchesActions.addToList({ batches: batches, next: nextQuery }));
        return batches;
    }
    catch (e) {
        const error = e as CodeError;
        console.error("Failed loading batches", error);

        dispatch(BatchesActions.setListError(error.message));
        dispatch(showError(error.message));
        return [];
    }
}

/**
 * Start loading list of batches
 */
const list = (filters: QueryFilter[], limit: number, orderBy?: DbOrder) => async (dispatch: AppDispatch) => {
    dispatch(BatchesActions.startLoadingList());

    let order = orderBy ?? { fieldPath: "timestamp", directionStr: "desc" };

    const inequalityFilters: QueryFilter[] = filters.filter(f => f.opStr !== "==" && f.fieldPath !== "timestamp");
    
    let appliedFilters = [...filters];

    if (inequalityFilters.length > 0) {
        appliedFilters = appliedFilters.filter(f => inequalityFilters.every(f2 => f2.fieldPath !== f.fieldPath));
    }

    // directly query Firestore
    const collectionRef = getCollectionRef(COLLECTION);
    const { query } = formatQuery(collectionRef, appliedFilters, order, limit);

    // build url with query params
    return dispatch(executeListQuery(query, limit, inequalityFilters));
}

/**
 * Load older batches to append to the ones already loaded
 */
const listOlder = () => async (dispatch: AppDispatch) => {
    const nextQuery = store.getState().batches.list.next;
    
    if (nextQuery) {
        const { query, limit } = nextQuery;
        dispatch(BatchesActions.startLoadingOlder());
        return dispatch(executeListQuery(query, limit));
    }

    return [];
}

/**
 * Retrieve a single batch matching filters, the last one received
 */
const getLast = (filters: QueryFilter[], startAfterTimestamp?: Timestamp) => async (dispatch: AppDispatch) => {
    dispatch(SelectedBatchActions.startLoading());

    try {
        const collectionRef = getCollectionRef(COLLECTION);
        let { query } = formatQuery(collectionRef, filters, { fieldPath: "timestamp", directionStr: "desc"}, 1); // only get the latest batch matching filters
        
        if (startAfterTimestamp) query = queryDB(query, startAfter({ "timestamp": startAfterTimestamp }));

        const snapshot = await getDocs(query);
        const batches = snapshot.docs.map(doc => fromSnapshot(doc) as Batch);

        const batch = batches.length > 0 ? batches[0] : null;

        if (batch) dispatch(SelectedBatchActions.set(batch)); // update selected Batch in store if one found

        return batch;
    }
    catch (e) {
        const error = e as CodeError;
        console.error("Failed loading batch", error);

        dispatch(SelectedBatchActions.setError(error.message));
        dispatch(showError(error.message));
        return null;
    }
};

const updateScore = (batchId: string, data: Partial<BatchDbData>, removeFromList: boolean) => async (dispatch: AppDispatch) => {
    // set loading status on batch
    dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { loading: true } }));

    const apiBatchData: Partial<APIBatchData> = {
        ..._.omit(data, "timestamp"),
        ...(data.timestamp ? { timestamp: data.timestamp.toMillis() } : {}),
    };

    try {
        // send request to server (may trigger stats updates, notifications...)
        await fetchAPI(`${urls.API}/batch/${batchId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiBatchData),
        });

        if (removeFromList) {
            dispatch(BatchesActions.removeItem(batchId));
        }
        else {
            dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { ...data, loading: false } }));
        }

        // show success message
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "update_batch.success"
        }));
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(BatchesActions.setListError(error.message));
        // show error message
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "update_batch.error"
        }));
    }
    return this;
}

const update = (batchId: string, data: Partial<BatchDbData>) => async (dispatch: AppDispatch) => {
    // set loading status on batch
    dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { loading: true } }));

    const apiBatchData: Partial<APIBatchData> = {
        ..._.omit(data, "timestamp"),
        ...(data.timestamp ? { timestamp: data.timestamp.toMillis() } : {}),
    };

    try {
        // direct update in Firestore
        await updateDoc(getDocRef(batchId), data);
        
        dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { ...data, loading: false } }));

        // show success message
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "update_batch.success"
        }));
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(BatchesActions.setListError(error.message));
        // show error message
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "update_batch.error"
        }));
    }
    return this;
}

const deleteBatch = (batchId: string) => async (dispatch: AppDispatch) => {
    // set loading status on batch
    dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { loading: true } }));

    try {
        await fetchAPI(`${urls.API}/batch/${batchId}`, {
            method: 'DELETE',
        });

        dispatch(BatchesActions.removeItem(batchId));

        // show success message
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "delete_batch.success"
        }));
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { loading: false } }));
        dispatch(BatchesActions.setListError(error.message));

        // show error message
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "delete_batch.error"
        }));
    }
}

const getAIResults = (batchId: string) => async (dispatch: AppDispatch) => {
    // set loading status on batch
    dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { loading: true } }));

    try {
        const results = await listAIResults(batchId);
        dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { loading: false, aiResults: results } }));

        return results;
    }
    catch (e) {
        const error = e as CodeError;
        console.error(error);
        dispatch(BatchesActions.updateListItem({ batchId: batchId, data: { loading: false } }));
        dispatch(BatchesActions.setListError(error.message));

        // show error message
        dispatch(showError(error.message));

        return null;
    }
};


export default Batch;

export const BatchesMethods = {
    getTimestamp,
    list,
    listOlder,
    getLast,
    updateScore,
    update, 
    deleteBatch,
    getAIResults,
};