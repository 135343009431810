import { Box, ButtonBase } from '@mui/material';
import PartnerLogoBackdrop from './PartnerLogoBackdrop';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { uploadFile, StorageType } from 'helpers/storage';
import { PartnerCompaniesActions } from 'store/reducers/partnerCompanies';

/**
 * Props for the PartnerLogoButton component.
 */
type PartnerLogoButtonProps = {
    /**
     * Callback function triggered when the logo changes.
     * @param logoURL The URL of the selected logo.
     */
    onChange?: (logoURL: string | undefined) => void;
}

/**
 * Component for selecting and displaying a partner logo.
 * @param {PartnerLogoButtonProps} props - The props for the component.
 * @returns {JSX.Element} The PartnerLogoButton component.
 */
export default function PartnerLogoButton({ onChange }: PartnerLogoButtonProps): JSX.Element {
    const [logoURL, setLogoURL] = useState<string>();
    const [fullURL, setFullURL] = useState<string>();

    const dispatch = useAppDispatch();

    /**
     * Updates the selected logo.
     * @param {React.ChangeEvent<HTMLInputElement>} e - The change event.
     */
    const updateLogo = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        dispatch(PartnerCompaniesActions.startLoading());
        const selectedFiles = e.target.files;
        if (!selectedFiles || selectedFiles.length < 1) return; // User didn't select any file

        const newLogoFile = selectedFiles[0];
        const filename = newLogoFile.name;

        // Upload the selected image to Google Cloud Storage
        const fullURL = await uploadFile(newLogoFile, StorageType.PARTNERS, "Normalized_Partners_Logos/" +filename);
        setFullURL(fullURL);
        setLogoURL(filename);
        dispatch(PartnerCompaniesActions.stopLoading());
    }

    useEffect(() => {
        // Call parent callback when logoURL changes
        if (onChange) onChange(logoURL);
    }, [logoURL]);

    return (
        <ButtonBase
            component="label" // Use label so that click forwards to hidden file input
            sx={{
                width: "100%",
                height: theme => theme.spacing(20),
            }}>
            {fullURL && ( // Show logo chosen for a new partner
                 <Box 
                 width="100%"
                 height="100%"
                 sx={{
                     backgroundImage: `url(${fullURL})`,
                     backgroundSize: "contain",
                     backgroundPosition: "center",
                     backgroundRepeat: "no-repeat",
                 }}
                 />
            )}
            <PartnerLogoBackdrop noLogo={!logoURL} />
            <input type="file" style={{ display: "none" }} onChange={updateLogo} />
        </ButtonBase>
    );
}
