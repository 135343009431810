import { useEffect } from "react";
import { useParams } from 'react-router-dom'
import { Box, Container } from "@mui/material";
import BatchCard from "../../Batches/BatchComponents/Card/BatchCard";
import UserData from "./UserData";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BatchesMethods } from "models/Batches/Batch";
import { UsersMethods } from "models/User";
import TrashCount from "./TrashCount";
import UserBatchesList from "./UserBatchesList";

type UserDetailPageProps = {

};

function UserDetailPage({ }: UserDetailPageProps) {

    const dispatch = useAppDispatch();

    const { userId } = useParams();

    useEffect(() => {
        if (userId) {
            dispatch(UsersMethods.retrieve(userId));
            dispatch(BatchesMethods.list([{
                fieldPath: "user.id",
                opStr: "==",
                value: userId,
            }], 50));
        }
    }, [userId]);

    return (
        <Container >
            <UserData />
            <TrashCount />
            <UserBatchesList />
        </Container>
    )
};

export default UserDetailPage;
