import Batch from "models/Batches/Batch";
import { PayloadAction, createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { Query } from "firebase/firestore";
import { TrashType } from "constants/trash";

const batchesAdapter = createEntityAdapter<Batch & { zoomLevel?: number }>({
    selectId: batch => batch.id,
    // sortComparer: (a, b) => b.date.localeCompare(a.date)
});

export enum BatchesToDoSorting {
    OLDEST_FIRST = "oldest_first",
    NEWEST_FIRST = "newest_first",
    IDENTIFIED = "identified",
}

export type BatchesContext = {
    loading: boolean;
    error: string | null;
    next: { query: Query, limit: number } | null;
    todoListSorting: BatchesToDoSorting;
    showTodoHeader: boolean;
};

const initialState: BatchesContext = {
    loading: false,
    error: null,
    next: null,
    todoListSorting: BatchesToDoSorting.NEWEST_FIRST,
    showTodoHeader: true,
};

export const batchesSlice = createSlice({
    name: 'batches',
    initialState: batchesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            batchesAdapter.setAll(state, []);
            state.loading = true;
            state.error = null;
        },
        startLoadingOlder: (state) => {
            state.loading = true;
            state.error = null;
        },
        addToList: (state, { payload: { batches, next } }: PayloadAction<{ batches: Batch[], next: { query: Query, limit: number } | null }>) => {
            state.loading = false;
            batchesAdapter.addMany(state, batches);
            state.next = next;
        },
        updateListItem: (state, { payload: { batchId, data } }: PayloadAction<{ batchId: string, data: Partial<Batch> }>) => {
            batchesAdapter.updateOne(state, { id: batchId, changes: data });
        },
        /** Update the count of a given waste class for a single batch */
        updateItemResult: (state, { payload: { batchId, trashType, value }}: PayloadAction<{ batchId: string, trashType: TrashType, value: number }>) => {
            const previousResults = state.entities[batchId]?.result ?? [];
            batchesAdapter.updateOne(state, { 
                id: batchId,
                changes: {
                    result: [
                        ...previousResults.filter(trash => trash !== trashType),
                        ...Array(value).fill(trashType),
                    ]
                }
            });
        },
        /** Define how the batches "to do" should be sorted */
        setToDoListSorting: (state, { payload: order }: PayloadAction<BatchesToDoSorting>) => {
            state.todoListSorting = order;
        },
        updateZoomLevel: (state, { payload: { batchId, zoomLevel }}: PayloadAction<{ batchId: string, zoomLevel: number }>) => {
            batchesAdapter.updateOne(state, { id: batchId, changes: { zoomLevel } });
        },
        /** Remove a single batch from the list */
        removeItem: (state, { payload: batchId }: PayloadAction<string>) => {
            batchesAdapter.removeOne(state, batchId);
        },
        /** Remove all of the loaded batches */
        clearList: (state) => {
            batchesAdapter.setAll(state, []);
        },
        setListError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        /** Set the visibility of the "Sorting and filters" header on top of the "to do" batches list */
        toggleToDoHeader: (state, { payload: show }: PayloadAction<boolean>) => {
            state.showTodoHeader = show;
        },
    },
});

export const BatchesActions = batchesSlice.actions;

export const {
    selectAll: selectAllBatches,
    selectById: selectBatchById,
    selectIds: selectBatchesIds,
} = batchesAdapter.getSelectors((state: RootState) => state.batches.list);

const BatchesReducer = batchesSlice.reducer;

export default BatchesReducer;