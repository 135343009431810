import { Box, useTheme } from "@mui/material";
import UserChatFeed from "./UserChatFeed";
import UserChatHeader from "./ChatHeader.tsx/UserChatHeader";
import UserChatFooter from "./UserChatFooter";

type UserChatProps = {
}

function UserChat({}: UserChatProps) {

    const theme = useTheme();
    const headerVerticalPadding = theme.spacing(3);
    const headerHeight = theme.spacing(10); // vertical padding + line height

    return (
        <Box>
            <UserChatHeader
                headerVerticalPadding={headerVerticalPadding}
                headerHeight={headerHeight} 
                />
            <Box 
                position="absolute"
                top={headerHeight}
                right={0}
                bottom={theme => theme.spacing(8)} // make messages disappear behind the input field
                left={0}
            >
                <UserChatFeed />
            </Box>
            <UserChatFooter />
        </Box>
    );
}

export default UserChat;