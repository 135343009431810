import BatchesToDoPage from "components/Batches/Todo/BatchesToDoPage";
import * as React from "react";

function HomePage() {
    return (
        <BatchesToDoPage />
    );
};

export default HomePage;
