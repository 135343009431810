import ActionButton from "components/_include/ActionButton";
import { Namespaces } from "locales/translations";
import Purchase, { PurchasesMethods, PurchaseStatuses } from "models/Purchase";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";

type ItemProps = {
    purchase: Purchase;
}

export default (props: ItemProps) => {
    const { purchase } = props;

    const { t } = useTranslation([Namespaces.glossary]);

    const { data: selectedPurchase, loading: purchaseUpdating } = useAppSelector(state => state.purchases.selected);
    const dispatch = useAppDispatch();

    let label: string = "";
    let newStatus: PurchaseStatuses;
    switch (purchase.status) {
        case PurchaseStatuses.PURCHASED:
            newStatus = PurchaseStatuses.SENT;
            label = t(`set_status`, { ns: Namespaces.purchases, status: newStatus });
            break;

        case PurchaseStatuses.SENT:
            newStatus = PurchaseStatuses.RECEIVED;
            label = t(`set_status`, { ns: Namespaces.purchases, status: newStatus });
            break;
    }

    const isLoading = selectedPurchase?.id === purchase.id && purchaseUpdating;

    return (
        <ActionButton
            color="primary"
            onClick={() => dispatch(PurchasesMethods.updateStatus(purchase, newStatus))}
            disabled={isLoading}
            loading={isLoading}
        >
            {label}
        </ActionButton>
    );
}
