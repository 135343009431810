import { MenuItem, Select } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";

export enum IdentifiedBatchesOptions {
    /** Show all batches, regardless whether they are linked to a user. */
    ALL = "all",
    /** Only show batches from authenticated users (pin associated to user). */
    IDENTIFIED_ONLY = "identified_only",
    /** Only show automatic batches (at regular intervals) from unauthenticated users (pin 9999). */
    // AUTO_ONLY = "auto_only",
};

type FilterProps = {
value: IdentifiedBatchesOptions;
    onChange: (val: IdentifiedBatchesOptions) => void;
}

export default function IdentifiedBatchesFilter({ value, onChange }: FilterProps) {
    const { t } = useTranslation([Namespaces.batches]);
    
    return (
        <Select
            value={value}
            onChange={(event) => onChange(event.target.value as IdentifiedBatchesOptions)}
            size="small"
        >
            {Object.values(IdentifiedBatchesOptions).map(type => (
                <MenuItem
                    value={type}
                    key={type}
                    >
                    {t(`filters.identified.${type}`, { ns: Namespaces.batches })}
                </MenuItem>
            ))}
        </Select>
    )
}