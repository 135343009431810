import { useEffect, useState } from "react";
import BatchCard from "../BatchComponents/Card/BatchCard";
import { Box, Container } from "@mui/material";
import { BatchesMethods } from "models/Batches/Batch";
import SectionLoader from "../../_include/SectionLoader";
import { useAppDispatch, useAppSelector } from "store/hooks";
import LoadMoreBatches from "../ImagesToAnnotate/LoadMoreBatches";

interface BatchesProcessedPageProps {

}

function BatchesProcessedPage(props: BatchesProcessedPageProps) {

    const loading = useAppSelector(state => state.batches.list.loading);
    const batchesIds = useAppSelector(state => state.batches.list.ids);
    const nextQuery = useAppSelector(state => state.batches.list.next);
    
    const dispatch = useAppDispatch();

    
    useEffect(() => {
        dispatch(BatchesMethods.list([{ fieldPath: "isProcessed", opStr: "==", value: true }], 10));
    }, []);
    
    const [isLoaded, setIsLoaded] = useState(false);

    /** Prevent rendering issues when a lot of batches 
     * have already been loaded on the "to do" page */
    useEffect(() => {
        if (batchesIds.length === 0) setIsLoaded(false);
        else if (!isLoaded) setIsLoaded(true);
    }, [batchesIds])

    return (
        <Container style={{
            paddingLeft: 0,
            paddingRight: 0,
        }}>
            {isLoaded && batchesIds.map((batchId) => (
                <BatchCard
                    key={batchId}
                    batchId={batchId.toString()}
                    />
            ))}
            <Box 
                margin={8}
                textAlign="center"
            >
                {!isLoaded || loading ? (
                    <SectionLoader />
                ) : (
                    nextQuery && (
                        <LoadMoreBatches />
                    )
                )}
            </Box>
        </Container>
    )
}

export default BatchesProcessedPage;
