import React from "react";
import { Box, BoxProps, Theme, Typography, TypographyProps } from "@mui/material";

type PieChartCenterTextProps = {
    centerText: string;
    margin: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
}

const Overlay = ({ sx, ...props }: BoxProps) => {
    return (
        <Box
            position="absolute"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={sx}
            {...props}
        />
    );
}

const CenterText = (props: TypographyProps) => {
    return (<Typography variant="subtitle2" style={{ textAlign: "center" }} {...props} />);
}

export default (props: PieChartCenterTextProps) => {

    const { centerText, margin } = props;

    return (
        window.innerWidth > 800 ?
            <Overlay sx={{
                top: margin.top + 100,
                right: margin.right + 100,
                bottom: margin.bottom + 100,
                left: margin.left + 100,
                pointerEvents: "none", // This is important to preserve the chart interactivity
            }}
            >
                <CenterText>{centerText}</CenterText>
            </Overlay>
            :
            <Overlay sx={{
                top: 16,
            }}>
                <CenterText>{centerText}</CenterText>
            </Overlay>
    );
}