import { useEffect } from 'react';
import { Snackbar, Alert, Typography, AlertColor } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideMessage, showMessage } from 'store/reducers/snacks';
import { AppDispatch } from 'store/store';

type SnackbarProps = {

}

function GlobalSnackbar(props: SnackbarProps) {

    const { } = props;

    const { variant, message } = useAppSelector(state => state.snacks);
    const dispatch = useAppDispatch();

    const displayMessage = (variant: AlertColor, message: string) => dispatch(showMessage({ variant: variant, message: message }));
    const hideTest = () => async (dispatch: AppDispatch) => {
        dispatch(hideMessage());
        return;
    };
    const hideSnack = () => {
        console.debug("start dispatching");
        dispatch(hideTest())
            .then(() => {
                console.debug("done dispatching");
            });
    }

    // callback to display messages pushed into localStorage
    useEffect(() => {
        let storedActionResult = localStorage.getItem("actionResult");
        if (storedActionResult) {
            const actionResult = JSON.parse(storedActionResult);
            localStorage.removeItem("actionResult");
            displayMessage(actionResult.variant, actionResult.message);
        }
    }, [localStorage]);

    return (
        <Snackbar
            open={message.length > 0}
            onClose={hideSnack}
            sx={{
                margin: '0 auto 2rem',
            }}
            >
            <Alert
                severity={variant}
                onClose={hideSnack}
                aria-describedby="message-id"
                >
                <Typography id="message-id" variant="body1" >
                    {message}
                </Typography>
            </Alert>
        </Snackbar>
    );
}

export default GlobalSnackbar;