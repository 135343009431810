import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

export enum StorageType {
    BATCHES = "batches",
    ISSUES = "issues",
    PARTNERS = "partners",
}

export async function getPictureURL(bucket: StorageType, imageURL: string) {
    const storage = getStorage();
    const gsReference = ref(storage, `gs://${bucket}_${process.env.REACT_APP_PROJECT_ID}/${imageURL}`);
    // return "https://firebasestorage.googleapis.com/v0/b/truck-332414-batches/o/Grand%20Lyon%2FXX-000-XX%2F2022-03-09%2FB6geDGDZhAo6KzyLOu96.jpg?alt=media&token=158562c3-3f41-417c-ba48-39dd35f1ead7";
    return getDownloadURL(gsReference)
        .then((url) => {
            return url;
        })
        .catch((error) => {
            return null;
        });
}

export async function uploadFile(file: File, bucket: StorageType, fullPath: string) {
    const storage = getStorage();
    const storageRef = ref(storage, `gs://${bucket}_${process.env.REACT_APP_PROJECT_ID}/${fullPath}`);

    // 'file' comes from the Blob or File API
    const snapshot = await uploadBytes(storageRef, file);
    const imageURL = await getDownloadURL(snapshot.ref);
    return imageURL;
}