import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { QueryFilter, QueryFilters } from "../../../constants/types";
import { Namespaces } from "../../../locales/translations";
import ContactsFilterForm from "./ContactsFilterForm";
import ContactsList from "./ContactsList";
import { useSearchParams } from 'react-router-dom';
import { UsersMethods } from "models/User";
import { useAppDispatch } from "store/hooks";

type UsersContactsPageProps = {

}

function UsersContactsPage(props: UsersContactsPageProps) {
    const { t } = useTranslation([Namespaces.titles]);

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    const [searchMade, setSearchMade] = useState(false);

    const queryParams: QueryFilters = [];
    searchParams.forEach((value, key) => {
        queryParams[key] = value;
    });

    const onSearchPressed = (filters: QueryFilter[]) => {
        // add filters to URL by removing comparators
        const newSearchParams = new URLSearchParams();
        filters.forEach(filter => {
            newSearchParams.set(filter.fieldPath, filter.value);
        });
        setSearchParams(newSearchParams);

        // apply search
        dispatch(UsersMethods.list(filters));

        // indicate results child that search was made
        setSearchMade(true);
    }

    return (
        <Container>
            <Typography variant="h1">
                {t("contacts", { ns: Namespaces.titles })}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3} /*sx={{ width: theme.spacing(32),}}*/>
                    <ContactsFilterForm
                        search={(filters) => onSearchPressed(filters)}
                        defaultValues={queryParams}
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                    <ContactsList
                        searchMade={searchMade}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default UsersContactsPage;