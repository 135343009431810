import { Box, Button, Typography } from "@mui/material";
import { Namespaces } from "locales/translations";
import { UsersMethods } from "models/User";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import CoconIssue, { CoconIssuesMethods, IssueStatus } from "models/Cocons/CoconIssue";
import CloseSupportTicketButton from "./CloseSupportTicketButton";

type UserChatHeaderProps = {
    headerVerticalPadding: string;
    headerHeight: string;
}

function UserChatHeader({ headerVerticalPadding, headerHeight }: UserChatHeaderProps) {
    const { t } = useTranslation([Namespaces.actions]);

    const userId = useAppSelector(state => state.users.selectedUser.data?.id);

    const displayName = useAppSelector(state => {
        const selectedUser = state.users.selectedUser.data;
        return selectedUser ? UsersMethods.getDisplayName(selectedUser) : "N/A";
    });

    const dispatch = useAppDispatch();

    const hasOpenTicket = useAppSelector(state => state.users.selectedUser.data?.hasOpenTicket);

    useEffect(() => {
        if (userId && hasOpenTicket) { // current user has an open ticket --> load all their current issues
            dispatch(CoconIssuesMethods.getUserIssues(userId, [IssueStatus.REPORTED, IssueStatus.FIXING]));
        }
    }, [userId, hasOpenTicket]);

    return (
        <Box
            bgcolor={theme => theme.palette.primary.main}
            px={5}
            py={headerVerticalPadding}
            boxShadow="0 4px 4px rgba(0,0,0,0.2)"
            height={headerHeight}
            display="flex"
            alignItems="center"
        >
            <Typography
                variant="h4"
                sx={{
                    color: "#ffffff",
                    fontSize: "1.6rem",
                }}
            >
                {displayName}
            </Typography>
            <Button
                href={`/user/${userId}`}
                target="_blank"
                variant="outlined"
                sx={{
                    color: "#ffffff",
                    borderColor: "#ffffff",
                    ml: 4,
                }}
            >
                {t("view_profile", { ns: Namespaces.actions })}
            </Button>
            {hasOpenTicket && (
                <CloseSupportTicketButton />
            )}
        </Box>
    );
}

export default UserChatHeader;