import "wdyr";

import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import moment from "moment";

import { configureTranslations } from "locales/translations";

import { initializeApp } from "firebase/app";

moment.locale("fr", require('moment/locale/fr'));

configureTranslations();

initializeApp(require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`));

ReactDOM.render(App(), document.getElementById('root'));

serviceWorker.unregister();
