import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Checkbox, Box } from "@mui/material";
import User from 'models/User';
import { CSVLink } from "react-csv";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '../../../locales/translations';
import SectionLoader from '../../_include/SectionLoader';
import SendIcon from '@mui/icons-material/Send';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ContactsChatDialog from './ContactsChatDialog/ContactsChatDialog';
import { usePrevious } from 'helpers/refs';
import { useAppSelector } from "store/hooks";
import { Link } from "react-router-dom";

type ContactsListProps = {
    searchMade: boolean;
}

function ContactsList(props: ContactsListProps) {
    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.glossary,]);

    const { searchMade, } = props;
    
    const { users, usersLoading, sendingMessage, sendingConversation, } = useAppSelector(state => ({
        users: state.users.users.data,
        usersLoading: state.users.users.loading,
        sendingMessage: state.messages.sendingMessage.loading,
        sendingConversation: state.messages.sendingConversation.loading,
    }))

    const [showChatDialog, toggleChatDialog] = useState(false);

    const wasSendingMessage = usePrevious(sendingMessage);
    useEffect(() => {
        if (wasSendingMessage && !sendingMessage) { // close chat dialog after message sent
            toggleChatDialog(false);
        }
    }, [sendingMessage]);

    const wasSendingConversation = usePrevious(sendingConversation);
    useEffect(() => {
        if (wasSendingConversation && !sendingConversation) { // close chat dialog after message sent
            toggleChatDialog(false);
        }
    }, [sendingConversation]);

    const [selectedUsers, setSelectedUsers] = useState<{
        users: User[],
        checked: boolean[],
    }>({
        users: [],
        checked: []
    });

    const resetSelectedUsers = (allSelected: boolean) => {
        let selectedUsers: boolean[] = new Array(users.length);
        selectedUsers.fill(allSelected ? true : false);
        setSelectedUsers({
            users: allSelected ? Array.from(users) : [],
            checked: selectedUsers,
        });
    };

    useEffect(() => {
        resetSelectedUsers(true);
    }, [users]);

    const toggleUserSelect = (index: number, checked: boolean) => {
        let newUserIndex = 0;
        let newUsers = selectedUsers.users;
        let newChecked = selectedUsers.checked;
        newChecked[index] = checked;
        for (let i = 0; i < newChecked.length; i++) {
            if (i === index) { // user to toggle
                break;
            }
            else {
                if (selectedUsers.checked[i]) newUserIndex++;
            }
        }

        if (checked) { // add user
            newUsers.splice(newUserIndex, 0, users[index]);
        }
        else { // remove user
            newUsers.splice(newUserIndex, 1);
        }
        setSelectedUsers({
            users: newUsers,
            checked: newChecked,
        });
    };

    const nbSelectedUsers = selectedUsers.users.length;
    const usersCheckboxProps = {
        checked: users.length === nbSelectedUsers,
        indeterminate: users.length > nbSelectedUsers && nbSelectedUsers > 0,
    };

    /**
     * Triggered when main checkbox changes
     */
    const onGlobalCheckboxChange = () => {
        resetSelectedUsers(!usersCheckboxProps.checked);
    }

    if (usersLoading) {
        return (<SectionLoader />);
    }

    console.debug("selectedUsers", selectedUsers);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
        >
            {selectedUsers.users.length > 0 &&
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    width="100%"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => toggleChatDialog(true)}
                        startIcon={<SendIcon />}
                    >
                        {t("send_message", { ns: Namespaces.actions })}
                    </Button>

                    <CSVLink
                        style={{
                            textTransform: "capitalize",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            boxSizing: "border-box",
                            outline: 0,
                            border: 0,
                            cursor: "pointer",
                            userSelect: "none",
                            appearance: "none",
                            textDecoration: "none",
                            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 500,
                            fontSize: "0.875rem",
                            lineHeight: 1.75,
                            letterSpacing: "0.02857em",
                            minWidth: 64,
                            padding: `6px 16px`,
                            borderRadius: 8,
                            transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                            color: "white",
                            backgroundColor: "#4C69AF",
                            margin: "0.5rem",
                            boxShadow: "none",
                        }}
                        data={selectedUsers.users.map(user => [user.email, user.firstName, user.lastName])}
                        headers={['email', 'first_name', 'last_name']}
                        filename="ficha_contacts.csv"
                    >
                        <ImportContactsIcon sx={{
                            ml: -.5,
                            mr: 1,
                        }} />
                        {t("export", { ns: Namespaces.actions })}
                    </CSVLink>
                </Box>
            }
            {selectedUsers.checked.length === 0
                ?
                <Box sx={{
                    mt: "20%"
                }}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: "1.6rem",
                            color: "rgba(0, 0, 0, .3)"
                        }}
                    >
                        {searchMade
                            ? t("no_user_found", { ns: Namespaces.glossary })
                            : t("click_on_filter", { ns: Namespaces.actions })
                        }
                    </Typography>
                </Box>
                :
                <TableContainer
                    component={Paper}
                    sx={{
                        mt: 2,
                    }}>
                    <Table sx={{ minWidth: 500 }} aria-label="customized table">
                        <TableHead>
                            <TableRow sx={{
                                // backgroundColor: '',
                                color: 'white',
                            }}>
                                <TableCell align="center">
                                    <Checkbox
                                        sx={{
                                            color: "white",
                                            '&.Mui-checked': {
                                                color: "white",
                                            },
                                        }}
                                        {...usersCheckboxProps}
                                        onChange={() => onGlobalCheckboxChange()}
                                    />
                                    {t("nb_selected", { count: nbSelectedUsers, ns: Namespaces.commons })}
                                </TableCell>
                                <TableCell align="center">
                                    {t("name", { ns: Namespaces.commons })}
                                </TableCell>
                                <TableCell align="center">
                                    {t("cocon_pin", { ns: Namespaces.glossary })}
                                </TableCell>
                                {/* <TableCell align="center">
                                    {i18next.t("email", { ns: Namespaces.commons })}
                                </TableCell> */}
                                <TableCell align="center">
                                    {t("actions", { ns: Namespaces.actions })}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.length === selectedUsers.checked.length && users.map((user, index) => (
                                <TableRow key={user.id}>
                                    <TableCell align="center">
                                        <Checkbox
                                            checked={selectedUsers.checked[index]}
                                            onChange={(e, checked) => toggleUserSelect(index, checked)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">{user.firstName} {user.lastName}</TableCell>
                                    <TableCell align="center">{user.pin}</TableCell>
                                    {/* <TableCell align="center">{user.email}</TableCell> */}
                                    <TableCell align='center'>
                                        <Link to={`/user/${user.id}`}>
                                            <Button variant="contained" color="primary" href={"/user/" + user.id}>
                                                {t("details", { ns: Namespaces.actions })}
                                            </Button>
                                        </Link>
                                        <Link to={`/chat?id=${user.id}`}>
                                            <Button variant="contained" color="primary">
                                                {t("chat", { ns: Namespaces.actions })}
                                            </Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {selectedUsers.users.length > 0 &&
                <ContactsChatDialog
                    open={showChatDialog}
                    onClose={() => toggleChatDialog(false)}
                    recipients={selectedUsers.users}
                />
            }
        </Box>
    );
}

export default ContactsList;
