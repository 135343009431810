import { Paper, PaperProps } from "@mui/material";

export default function TooltipWrapper(props: PaperProps) {
    return (
        <Paper
            {...props}
            sx={{
                px: 2,
                py: 1,
            }}
            elevation={4}
            />
    )
}