import { CssBaseline, Box } from '@mui/material';
import { Route, Routes } from "react-router-dom";
import UserDetailPage from "./Users/Details/UserDetails";
import ListUsersPage from "./Users/List/ListUsersPage";
import LoginPage from './Login/LoginPage';
import ListPartnersPage from './Partners/ListPartners/ListPartnersPage';
import AddPartnerPage from './Partners/AddPartner/AddPartnerPage';
import AddRewardPage from './Rewards/AddRewardPage/AddRewardPage';
import ListRewardsPage from './Rewards/ListRewardsPage/ListRewardsPage';
import EditRewardPage from './Rewards/EditRewardPage/EditRewardPage';
import AddStorePage from './Partners/AddStore/AddStorePage';
import ListCoconUsersPage from 'components/Users/List/ListCoconUsersPage';
import ListPurchasesPage from './Purchases/ListPurchasesPage';
import HomePage from './Home/HomePage';
import BatchesProcessedPage from './Batches/Processed/BatchesProcessedPage';
import StatisticsPage from './Batches/Statistics/StatisticsPage';
import BatchesToDoPage from './Batches/Todo/BatchesToDoPage';
import ViewCoconPage from './Cocons/EditCoconPage';
import CompleteProfilePage from './Users/Profile/CompleteProfilePage';
import UsersContactsPage from './Users/UsersContacts/UsersContactsPage';
import VerifyEmailPage from './Users/Profile/VerifyEmailPage';
import ChatPage from './Users/Chat/UsersChatPage/ChatPage';
import LoggedOutLayout from './LoggedOutLayout';
import LoggedInLayout from './LoggedInLayout';
import ImagesToAnnotatePage from './Batches/ImagesToAnnotate/ImagesToAnnotatePage';
import CoconsMapPage from './Cocons/CoconsMap/CoconsMapPage';
import CoconIssuesList from './Cocons/CoconIssues/CoconIssuesList';
import CoconBatchesList from './Cocons/CoconBatches/CoconBatchesList';
import AddCoconStepsPage from './Cocons/AddCocon/AddCoconStepsPage';
import BatchDemoPage from './Batches/Demo/BatchDemoPage';

interface PageProps {

}

function Pages(props: PageProps) {
    const { } = props;

    return (
        <Box
            display="flex"
            minHeight="100vh"
        >
            <CssBaseline />
            <Routes>
                <Route element={<LoggedInLayout />}>
                    <Route path='/'>
                        <Route index element={<HomePage />} />
                        <Route path='batch'>
                            <Route path='processed' element={<BatchesProcessedPage />} />
                            <Route path='todo' element={<BatchesToDoPage />} />
                            <Route path='demo' element={<BatchDemoPage />} />
                            <Route path='statistics' element={<StatisticsPage />} />
                            <Route path='to-annotate' element={<ImagesToAnnotatePage />} />
                        </Route>
                        <Route path='user'>
                            <Route index element={<ListUsersPage />} />
                            <Route path='contacts' element={<UsersContactsPage />} />
                            <Route path=':userId' element={<UserDetailPage />} />
                        </Route>
                        <Route path='chat' element={<ChatPage />} />
                        <Route path='partner'>
                            <Route index element={<ListPartnersPage />} />
                            <Route path="create" element={<AddPartnerPage />} />
                        </Route>
                        <Route path='store'>
                            <Route path="create" element={<AddStorePage />} />
                        </Route>
                        <Route path='reward'>
                            <Route index element={<ListRewardsPage />} />
                            <Route path="create" element={<AddRewardPage />} />
                            <Route path=":rewardId/edit" element={<EditRewardPage />} />
                        </Route>
                        <Route path='purchase'>
                            <Route index element={<ListPurchasesPage />} />
                        </Route>

                        <Route path='cocon'>
                            <Route index element={<CoconsMapPage />} />
                            <Route path="create" element={<AddCoconStepsPage />} />
                            <Route path=":coconId" element={<ViewCoconPage />} />
                            <Route path=":coconId/batches" element={<CoconBatchesList />} />
                            <Route path=":coconId/issues" element={<CoconIssuesList />} />
                            <Route path=":coconId/statistics" element={<StatisticsPage />} />
                            <Route path=":coconId/user">
                                <Route index element={<ListCoconUsersPage />} />
                                <Route path=':userId' element={<UserDetailPage />} />
                            </Route> 
                        </Route>
                    </Route>
                </Route>

                <Route element={<LoggedOutLayout />}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/verify-email" element={<VerifyEmailPage />} />
                    <Route path="/complete-profile" element={<CompleteProfilePage />} />
                </Route>
            </Routes>
        </Box>
    );
}

export default Pages;