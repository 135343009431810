import { ChatRecipients } from "constants/types";
import React from "react";
import ChatInput from "components/Users/Chat/UsersChatPage/ChatInput";
import { MessageAttachment } from "models/Message";
import { Box } from "@mui/material";

interface SendMessagePanelProps {
    recipients: ChatRecipients;
    sendingMessage: boolean;
    onSendMessage: (message: string, attachment?: MessageAttachment) => Promise<void>;
}

function SendMessagePanel(props: SendMessagePanelProps) {
    const { recipients, sendingMessage, onSendMessage, } = props;

    return (
        <Box margin={1}>
            <ChatInput
                disabled={sendingMessage}
                sending={sendingMessage}
                recipients={recipients}
                onSendPressed={onSendMessage}
            />
        </Box>
    );
}

export default SendMessagePanel;