import { MAX_ZOOM } from "constants/geo";
import { Bounds, Coords, Maps } from "google-map-react";
import { CoconStatus, NetworkStatus } from "models/Cocons/Cocon";

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export type MarkerColor = "success" | "warning" | "error" | "info";

export type MapMarkerProps = {
    coconId: string;
    position: Coords;
    pendingIssues: number;
    status: CoconStatus;
    networkStatus: NetworkStatus;
}

export type MapPointFeature = {
    type: "Feature";
    properties: MapMarkerProps;
    geometry: {
        type: "Point";
        coordinates: [number, number]; //WARNING: GeoJSON Point geometry is [lng, lat] and not [lat, lng] 
    };
}

export const getMapOptions = (maps: Maps) => ({
    maxZoom: MAX_ZOOM,
    mapTypeControl: true,
    mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
            maps.MapTypeId.ROADMAP,
            maps.MapTypeId.SATELLITE,
        ],
    },
    clickableIcons: false,
});


export function getBoundsZoomLevel(bounds: Bounds, mapDim: { width: number, height: number }) {
    var WORLD_DIM = { height: 360, width: 360 };

    function latRad(lat: number) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.ne;
    var sw = bounds.sw;

    var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    var lngDiff = ne.lng - sw.lng;
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, MAX_ZOOM);
}

export const getCenter = (bounds: Bounds) => {
    const maxLat = bounds.nw.lat;
    const minLat = bounds.se.lat;
    const maxLng = bounds.se.lng;
    const minLng = bounds.nw.lng;
    return {
        lat: (maxLat + minLat) / 2,
        lng: (maxLng + minLng) / 2,
    };
};

/**
 * Calculate map bounds, center and zoom to view all batches
 */
export function getMapState(points: MapPointFeature[]) {
    let center: Coords;
    let zoom: number;
    let bounds: Bounds;
    let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;

    // find the most extreme points
    for (const point of points) {
        const [lng, lat] = point.geometry.coordinates;
        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
    }

    center = {
        lat: (maxLat + minLat) / 2,
        lng: (maxLng + minLng) / 2,
    };

    bounds = {
        nw: { lat: maxLat, lng: minLng },
        ne: { lat: maxLat, lng: maxLng },
        se: { lat: minLat, lng: maxLng },
        sw: { lat: minLat, lng: minLng },
    };

    return {
        center: center,
        bounds: bounds,
    };
}