import ImagesToAnnotateArchive from "models/Batches/ImagesToAnnotateArchive";
import { PayloadAction, createSlice, createEntityAdapter, combineReducers } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";

const toAnnotateArchivesAdapter = createEntityAdapter<ImagesToAnnotateArchive>({
    selectId: batch => batch.id,
    // sortComparer: (a, b) => b.date.localeCompare(a.date)
});

const initialLoadableState: LoadableContext = { loading: false, error: null };

const initialState: LoadableContext & { link: string | null } = {
    ...initialLoadableState,
    link: null,
}

export const imagesToAnnotateSlice = createSlice({
    name: 'to_annotate',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setLink: (state, { payload: link }: PayloadAction<string>) => {
            state.loading = false;
            state.link = link;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const toAnnotateArchivesSlice = createSlice({
    name: 'to_annotate_archives',
    initialState: toAnnotateArchivesAdapter.getInitialState({ ...initialLoadableState }),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: archives }: PayloadAction<ImagesToAnnotateArchive[]>) => {
            state.loading = false;
            toAnnotateArchivesAdapter.setAll(state, archives);
        },
        setListError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const ImagesToAnnotateActions = imagesToAnnotateSlice.actions;
export const ToAnnotateArchivesActions = toAnnotateArchivesSlice.actions;

export const {
    selectAll: selectAllToAnnotateArchives,
    selectById: selectBatchById,
    selectIds: selectToAnnotateArchivesIds
} = toAnnotateArchivesAdapter.getSelectors((state: RootState) => state.toAnnotate.archives)

const ToAnnotateReducer = combineReducers({
    download: imagesToAnnotateSlice.reducer, 
    archives: toAnnotateArchivesSlice.reducer
});

export default ToAnnotateReducer;