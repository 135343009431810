import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListSubheader, MenuItem, Select } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import { MessageAttachment, MessageAttachmentType } from "models/Message";
import urls from "constants/urls";
import { isValidURL } from "helpers/strings";
import { Namespaces } from "locales/translations";
import { ChatRecipients } from "constants/types";

const INPUT_HEIGHT = 40;

const useStyles = makeStyles({
    inputField: {
        flex: 1,
        width: "100%",
        maxWidth: "initial",
        border: 0,
        background: "none",
        fontSize: "1rem",

        "&:focus": {
            outline: 0,
        }
    },
});

const MessageType = {
    TEXT: "text",
    LINK: MessageAttachmentType.LINK,
    IMAGE: MessageAttachmentType.IMAGE,
    VIDEO: MessageAttachmentType.VIDEO,
};

interface ChatInputProps {
    disabled: boolean;
    sending: boolean;
    recipients: ChatRecipients;
    onSendPressed: (message: string, attachment?: MessageAttachment) => Promise<void>;
}

function ChatInput(props: ChatInputProps) {
    const { t } = useTranslation([Namespaces.glossary]);

    const classes = useStyles();

    const { disabled, sending, recipients, onSendPressed } = props;

    const [message, setMessage] = useState("");
    const [link, setLink] = useState("");
    const [messageType, setMessageType] = useState(MessageType.TEXT);

    const [showHelpDialog, toggleHelpDialog] = useState(false);

    const URLIsValid = isValidURL(link, {
        acceptFacebookScheme: messageType === MessageType.LINK,
    });

    const handleSendPressed = () => {
        switch (messageType) {
            case MessageType.TEXT:
                onSendPressed(message)
                    .then(() => {
                        onMessageSent();
                    });
                break;

            case MessageType.LINK:
            case MessageType.IMAGE:
            case MessageType.VIDEO:
                onSendPressed(message, { url: link, type: messageType })
                    .then(() => {
                        onMessageSent();
                    });
                break;
        }
    };

    const onMessageSent = () => {
        setMessage("");
        setLink("");
    }

    const canBeSent = () => {
        switch (messageType) {
            case MessageType.TEXT:
                return message.length > 0;

            case MessageType.LINK:
            case MessageType.IMAGE:
            case MessageType.VIDEO:
                return message.length > 0 && link.length > 0 && URLIsValid;
        }

        return false;
    };

    return (
        <Box
            display="flex"
            bgcolor="#ffffff"
            border="1px solid rgba(0,0,0,.4)"
            px={2}
            py={1}
            borderRadius={2}
            width="100%"
        >
            <Select
                variant="standard"
                sx={{
                    "& .MuiSelect-select:focus": {
                        background: "none",
                    },
                    "&::before": {
                        content: "none",
                    },
                    "&::after": {
                        content: "none",
                    },
                }}
                value={messageType}
                onChange={(e) => setMessageType(e.target.value as string)}
            >
                {Object.values(MessageType).map(type => (
                    <MenuItem
                        key={type}
                        value={type}
                    >
                        {t(`message_type.${type}`, { ns: Namespaces.glossary })}
                    </MenuItem>
                ))}
            </Select>

            <Divider
                orientation="vertical"
                flexItem
                light
                sx={{ mx: 2 }}
            />

            <Box
                width="100%"
                display="flex"
                flexDirection="column"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    height={INPUT_HEIGHT}
                >
                    <input
                        type="text"
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        className={classes.inputField}
                        placeholder={t(`placeholders.${messageType === MessageType.LINK ? "button_label" : "send_message_to"}`, {
                            ns: Namespaces.glossary,
                            ...recipients,
                        })}
                        disabled={disabled}
                    />

                    <IconButton
                        disabled={!canBeSent() || disabled}
                        onClick={handleSendPressed}
                        color="primary"
                    >
                        {sending &&
                            <CircularProgress
                                sx={{ ml: -.5 }}
                            />
                        }
                        <SendIcon />
                    </IconButton>
                </Box>

                {[MessageType.LINK, MessageType.IMAGE, MessageType.VIDEO, ""].indexOf(messageType) >= 0 &&
                    <div>
                        <Divider light style={{ margin: "4px 0 " }} />
                        <Box  
                            display="flex"
                            alignItems="center"
                            height={INPUT_HEIGHT}
                            >
                            <input
                                type="text"
                                value={link}
                                onChange={(event) => setLink(event.target.value)}
                                className={classes.inputField}
                                placeholder={t(`placeholders.${messageType}`, {
                                    ns: Namespaces.glossary,
                                })}
                                disabled={disabled}
                            />
                            {!URLIsValid &&
                                <Button
                                    color="error"
                                    onClick={() => toggleHelpDialog(true)}
                                    disabled={messageType !== MessageType.LINK}
                                >
                                    {t(`invalid_link`, {
                                        ns: Namespaces.glossary,
                                    })}
                                </Button>
                            }
                        </Box>
                    </div>
                }

            </Box>

            <Dialog
                open={showHelpDialog}
                onClose={() => toggleHelpDialog(false)}
                // fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    {t(`accepted_link_formats`, {
                        ns: Namespaces.glossary
                    })}
                </DialogTitle>
                <DialogContent>
                    <List sx={{
                        pt: 0,
                        color: "rgba(0, 0, 0, .7)",
                    }}>
                        <ListSubheader sx={{
                            color: "rgba(0, 0, 0, .8)",
                            lineHeight: "2rem",
                            fontSize: "1rem",
                        }}>
                            {t(`website`, {
                                ns: Namespaces.commons,
                                count: 2
                            })}
                        </ListSubheader>
                        <ListItem>https://unsiteweb.io</ListItem>
                        <ListItem>https://www.unautre.site.com</ListItem>

                        <ListSubheader sx={{
                            color: "rgba(0, 0, 0, .8)",
                            lineHeight: "2rem",
                            fontSize: "1rem",
                            mt: 2,
                        }}>
                            Facebook
                        </ListSubheader>
                        <ListItem>{`{{facebookScheme}}/IDdUnePageFacebook`}</ListItem>
                    </List>

                    <Divider light sx={{ my: 2 }} />

                    <Box textAlign="center">
                        <Button
                            color="primary"
                            onClick={() => {
                                toggleHelpDialog(false);
                                setLink(urls.MOBILE_APP_LINKS.FACEBOOK_PAGE);
                            }}
                        >
                            {t(`facebook_page`, {
                                ns: Namespaces.glossary,
                                name: "Ficha",
                            })}
                        </Button>

                        <Button
                            color="primary"
                            onClick={() => {
                                toggleHelpDialog(false);
                                setLink(urls.FEEDBACK_FORM);
                            }}
                        >
                            {t(`feedback_form`, {
                                ns: Namespaces.glossary,
                            })}
                        </Button>

                        <Button
                            color="primary"
                            onClick={() => {
                                toggleHelpDialog(false);
                                setLink(`${urls.COMPLETE_ADDRESS_FORM}?t=${Math.random().toString(16).substr(2, 16)}`);
                            }}
                        >
                            {t(`complete_profile_form`, {
                                ns: Namespaces.glossary,
                            })}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default ChatInput;