import { Box, Container, Typography } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddCoconStepper from "./AddCoconStepper";
import SelectClusterStep from "./SelectClusterStep";
import CoconsCluster, { CoconsClustersMethods, NewCoconsClusterData } from "models/Cocons/CoconsCluster";
import { AddCoconsClusterData } from "./CoconsClusterForm";
import _ from "lodash";
import EnterCoconDataStep from "./EnterCoconDataStep";
import { AddCoconFormData } from "../CoconForm/CoconForm";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CoconsMethods, NewCoconData } from "models/Cocons/Cocon";
import urls from "constants/urls";
import ConfirmationDialog from "components/_include/Dialogs/ConfirmationDialog";

type AddCoconStepsPageProps = {

}

const STEPS = ["select_cluster", "enter_cocon_data"];

function AddCoconStepsPage(props: AddCoconStepsPageProps) {
    const { } = props;

    const { t } = useTranslation([Namespaces.titles]);

    const [activeStep, setActiveStep] = useState(0);

    const [selectedCluster, setSelectedCluster] = useState<CoconsCluster>();
    const [newClusterData, setNewClusterData] = useState<AddCoconsClusterData>();

    const handleClusterSelected = (coconsCluster: CoconsCluster | AddCoconsClusterData) => {
        console.debug("cocons cluster", coconsCluster);
        if (_.has(coconsCluster, "id")) { // existing cocon
            setSelectedCluster(coconsCluster as CoconsCluster);
        }
        else { // new cocon
            setNewClusterData(coconsCluster as AddCoconsClusterData);
            setSelectedCluster(undefined);
        }

        setActiveStep(activeStep + 1);
    }

    const clusterAdded = useAppSelector(state =>  state.cocons.selectedCluster.data);
    const coconAdded = useAppSelector(state =>  state.cocons.selected.data);

    const dispatch = useAppDispatch();

    const addNewCocon = async (coconData: NewCoconData) => {
        return await dispatch(CoconsMethods.create(coconData)) !== null;
    }

    const addNewCoconsCluster = async (clusterData: NewCoconsClusterData) => {
        return await dispatch(CoconsClustersMethods.create(clusterData)) !== null;
    }

    const handleCoconSubmitted = async (coconData: AddCoconFormData) => {
        if (selectedCluster) { // add cocon to existing cluster
            return await addNewCocon({
                ...coconData,
                clusterId: selectedCluster.id,
                joinCode: selectedCluster.joinCode,
            });
        }
        else if (newClusterData) { // add new cluster and cocon
            return await addNewCoconsCluster({
                ...newClusterData,
                cocons: [{
                    ...coconData,
                    joinCode: newClusterData.joinCode,
                }],
                managers: [], // managers are added from cocons' managers on back-end
                status: coconData.status,
                dateDeployed: coconData.dateDeployed,
            });
        }
        
        return false;
    }

    useEffect(() => {
        if (clusterAdded) {
            setSelectedCluster(clusterAdded);
        }
    }, [clusterAdded])

    useEffect(() => {
        if (coconAdded) {
            // show dialog to download credentials file
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify({
                id: coconAdded.id,
                token: coconAdded.token,
                apiURL: urls.API,
            }));
            var dlAnchorElem = document.getElementById('downloadAnchorElem');
            if (dlAnchorElem) {
                dlAnchorElem.setAttribute("href", dataStr);
                dlAnchorElem.setAttribute("download", "credentials.json");
                dlAnchorElem.click();
            }
        }
    }, [coconAdded]);

    return (
        <Container>
            <Typography variant="h1">
                {t("add_cocon", { ns: Namespaces.titles })}
            </Typography>

            <AddCoconStepper
                steps={STEPS}
                activeStep={activeStep}
                onBackClicked={() => setActiveStep(activeStep - 1)}
            />

            <Box
                display="flex"
                flexDirection="column"
            >
                {activeStep === 0 && (
                    <SelectClusterStep 
                        selectCluster={handleClusterSelected}
                        />
                )}
                {activeStep === 1 && (
                    <EnterCoconDataStep 
                        handleSubmit={handleCoconSubmitted}
                        />
                )}
            </Box>

            {/* Hidden link to trigger download of credendtials json file after Cocon creation */}
            <a
                id="downloadAnchorElem"
                style={{ display: "none" }} />
        </Container>
    );
}

export default AddCoconStepsPage;