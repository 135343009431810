import { useEffect, useState } from 'react';
import { Typography, Container, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, Box, Tabs, Tab, styled, } from '@mui/material';
import Purchase, { PurchasesMethods } from 'models/Purchase';
import { Namespaces } from 'locales/translations';
import SectionLoader from '../_include/SectionLoader';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PurchaseListItem from './CurrentPurchasesList/PurchaseListItem';
import CurrentPurchasesList from './CurrentPurchasesList/CurrentPurchasesList';
import PastPurchasesList from './PastPurchasesList/PastPurchasesList';

type ListPurchasesPageProps = {

}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = ({ index, value, children }: TabPanelProps) => (
    <Box sx={{ ...(value !== index && { display: "none" }) }}>{children}</Box>
)

const StyledTab = styled((props: { label: string }) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
        color: '#fff',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));

function ListPurchasesPage({ }: ListPurchasesPageProps) {

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.purchases, Namespaces.titles]);

    useEffect(() => {
        dispatch(PurchasesMethods.list([]));
    }, [dispatch]);

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Container>
            <Typography variant="h1">
                {t("purchases", { ns: Namespaces.titles })}
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant='fullWidth'
                    value={selectedTab}
                    onChange={(e, val) => setSelectedTab(val)}
                    sx={{
                        backgroundColor: theme => theme.palette.primary.main,
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                    }}
                >
                    <StyledTab label={t("current", { ns: Namespaces.purchases })} />
                    <StyledTab label={t("past", { ns: Namespaces.purchases })} />
                </Tabs>
            </Box>

            <TabPanel
                index={0}
                value={selectedTab}
            >
                <CurrentPurchasesList />
            </TabPanel>
            <TabPanel
                index={1}
                value={selectedTab}
            >
                <PastPurchasesList />
            </TabPanel>
        </Container>
    )
}

export default ListPurchasesPage;