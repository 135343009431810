import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6045 39.9858C25.6979 40.0118 25.7969 40.0101 25.8893 39.9809L35.4483 36.9603C35.6563 36.8946 35.7976 36.7016 35.7976 36.4835V11.1426C35.7976 11.0285 35.8367 10.9178 35.9082 10.8289L38.3974 7.73887C38.6609 7.41176 38.4281 6.92521 38.008 6.92521H30.213C30.0533 6.92521 29.9032 7.00153 29.8091 7.13058L26.0958 12.2219C25.9136 12.4717 25.5506 12.4979 25.3344 12.2769L20.2083 7.03629C20.1375 6.96395 20.0465 6.91478 19.9472 6.89528L1.89622 3.3522C1.38971 3.25278 1.08302 3.89272 1.4778 4.22525L6.90458 8.79631C7.01736 8.89131 7.08246 9.03125 7.08246 9.17872V34.4496C7.08246 34.6741 7.23209 34.871 7.44836 34.9312L25.6045 39.9858ZM10.3697 23.4561C10.4233 19.594 13.3221 16.5682 16.8452 16.6981C20.3696 16.828 23.1824 20.0645 23.1288 23.9274C23.076 27.7907 20.1768 30.8169 16.6537 30.687C13.1293 30.5563 10.3165 27.3191 10.3697 23.4561Z" fill="#336699" />
            <path d="M20.6114 5.93643C20.7141 5.95473 20.8085 6.00471 20.8814 6.07936L25.332 10.6375C25.549 10.8597 25.9139 10.8324 26.0955 10.5804L29.3068 6.12285C29.4008 5.99241 29.5518 5.91512 29.7125 5.91512H37.0852C37.2529 5.91512 37.4094 5.83109 37.502 5.69135L39.5283 2.63405C39.7393 2.31577 39.53 1.88804 39.1493 1.85925L15.2039 0.0485463C15.0177 0.0344698 14.8392 0.125271 14.741 0.28402L12.6003 3.7434C12.4046 4.05967 11.9444 4.05916 11.7494 3.74246L10.0038 0.907572C9.91318 0.760448 9.75305 0.670523 9.58028 0.669742L3.75796 0.643455C3.35837 0.641651 3.11822 1.08612 3.33872 1.41936L4.29984 2.87186C4.37536 2.986 4.49437 3.06418 4.6291 3.08819L20.6114 5.93643Z" fill="#FFA726" />
            <path d="M16.6693 29.5317C19.6117 29.6403 22.0317 27.114 22.0761 23.8888C22.0784 23.8411 22.0652 23.7939 22.0442 23.7512C21.1073 21.8504 18.9813 20.5984 16.5557 20.7432C14.3552 20.8747 12.4828 22.1197 11.5602 23.8441C11.4782 23.9974 11.4462 24.1724 11.4667 24.3449C11.8029 27.1831 13.9903 29.4327 16.6693 29.5317ZM16.5901 21.8871C18.2481 21.7951 19.7375 22.4375 20.6473 23.4913C20.9366 23.8264 20.876 24.327 20.5213 24.592C19.6017 25.2791 18.3077 25.7359 16.8553 25.8003C15.3591 25.8653 13.9894 25.5001 12.9986 24.8619C12.6167 24.6159 12.5312 24.104 12.8151 23.7495C13.656 22.6995 15.0231 21.9751 16.5901 21.8871Z" fill="#FFB74D" />
        </svg>
    </SvgIcon>
);