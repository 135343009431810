import { Box } from "@mui/material";
import BatchCard from "components/Batches/BatchComponents/Card/BatchCard";
import SectionLoader from "components/_include/SectionLoader";
import { useAppSelector } from "store/hooks";
import { selectBatchesIds } from "store/reducers/batches/batch";

export default function UserBatchesList() {
    const loading = useAppSelector(state => state.batches.list.loading);
    const batchesIds = useAppSelector(state => selectBatchesIds(state));
    
    return (
        !loading ? (
            <Box>
                {batchesIds.map((batchId) =>
                    <BatchCard
                        key={batchId}
                        batchId={batchId.toString()}
                        />
                )}
            </Box>
        ) : ( // batches loading
            <SectionLoader />
        )
    )
}