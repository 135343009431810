import { Drawer } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllCocons, selectAllCoconsEntities, selectCoconById } from "store/reducers/cocons/cocons";
import { SelectedCoconActions } from "store/reducers/cocons/selected";
import SelectedCoconDrawerBody from "./SelectedCoconDrawerBody";

type SelectedCoconDrawerProps = {

}

export default function SelectedCoconDrawer(props: SelectedCoconDrawerProps) {
    const { } = props;

    const dispatch = useAppDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedCoconId = searchParams.get("coconId");

    const selectedCocon = useAppSelector(state => state.cocons.selected.data);
    const coconsEntities = useAppSelector(state => selectAllCoconsEntities(state));

    // select automatically Cocon in search param (eg: from shared link)
    useEffect(() => {
        if (selectedCoconId && selectedCocon?.id !== selectedCoconId) {
            dispatch(SelectedCoconActions.setSelectedCocon(coconsEntities[selectedCoconId]));
        }
    }, [selectedCoconId]);

    // set coconId in search params (eg: to share link to Cocon)
    useEffect(() => {
        if (selectedCocon && !selectedCoconId) {
            searchParams.set("coconId", selectedCocon.id);
            setSearchParams(searchParams);
        }
    }, [selectedCocon]);

    const handleClose = () => {
        searchParams.delete("coconId");
        setSearchParams(searchParams);
        dispatch(SelectedCoconActions.setSelectedCocon());
    };

    return (
        <Drawer
            anchor="right"
            open={!!selectedCocon}
            onClose={handleClose}
            BackdropProps={{
                invisible: true,
            }}
        >
            <SelectedCoconDrawerBody />
        </Drawer>
    );
}