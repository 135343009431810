import { DocumentData, DocumentReference, FieldPath, OrderByDirection, QuerySnapshot } from "firebase/firestore";

export enum Collections {
    BATCH = 'batch',
    BATCH_AI_RESULTS = 'ai_results',
    COCONS = 'cocons',
    COCONS_CLUSTERS = 'cocons_clusters',
    COCONS_CODE_VERSIONS = 'cocons_code_versions',
    DEVICES = 'devices',
    IDENTIFICATION_CODES = 'identification_codes',
    IMAGES_TO_ANNOTATE = 'images_to_annotate',
    LAST_MESSAGES = 'last_messages',
    MESSAGES = 'messages',
    NOTIFICATIONS = 'notifications',
    PARTNER_COMPANIES = 'partner_companies',
    PARTNERS = 'partners',
    PROMO_CODES = 'promo_codes',
    PURCHASES = 'purchases',
    REPORTED_ISSUES = 'reported_issues',
    REWARDS = 'rewards',
    RANKS = 'ranks',
    STATS = 'statistics',
    STORES = 'stores',
    TOKENS = 'tokens',
    USERS = 'user',
    WASTE_REPORTS = 'waste_reports',
};

export type DBIdentifiers = { 
    id: string;
    // ref: DocumentReference;
    // constructFromDbDoc: (doc: QuerySnapshot<DocumentData>) => any;
}

export type DbOrder = {
    fieldPath: string | FieldPath, 
    directionStr?: OrderByDirection | undefined
};