import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

type SearchBarProps = {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
}

export default (props: SearchBarProps) => {
    const { placeholder, value, onChange, } = props;

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <TextField
            variant="outlined"
            placeholder={placeholder}
            sx={{
                mb: 1,
                '& .MuiInputBase-root': {
                    borderRadius: 3,
                },
                '& .MuiInputBase-input': {
                    py: 1.5,
                },
                '& .MuiInputAdornment-root': {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                onChange: (event) => handleChange(event),
            }}
            value={value}
        />
    );
}