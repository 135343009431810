import MapMarker from "components/_include/Map/MapMarker";
import { DEFAULT_BOUNDS, DEFAULT_ZOOM, DEFAULT_CENTER, MAX_ZOOM } from "constants/geo";
import { GeoPoint } from "firebase/firestore";
import GoogleMapReact, { Bounds, Coords } from "google-map-react";
import { getBoundsZoomLevel, getCenter, getMapOptions, MapMarkerProps, MapPointFeature, MarkerColor } from "helpers/geo";
import _ from "lodash";
import Cocon, { CoconsMethods, CoconStatus, NetworkStatus } from "models/Cocons/Cocon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SelectedCoconActions } from "store/reducers/cocons/selected";
import Supercluster from "supercluster";
import CoconMarker from "./CoconMarker";
import SelectedCoconsClusterDrawer from "./CoconsClusterDrawer/SelectedCoconsClusterDrawer";
import CoconsMarkersCluster from "./CoconsMarkersCluster";

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

type MapState = {
    zoom: number;
    center: Coords;
    bounds: Bounds;
}

type MarkerMappedProps = {
    id: string;
    coconId: string;
    position: Coords;
    hasIssue: boolean;
    isOffline: boolean;
}

type ClusteredPointsProps = {
    coconsIds: string[];
    bounds: Bounds;
    hasIssue: boolean;
    isOffline: boolean;
}

type AccumulatingClusterProps = MarkerMappedProps & Partial<ClusteredPointsProps>;

type AccumulatedClusterProps = (Supercluster.ClusterProperties & MarkerMappedProps & ClusteredPointsProps);

type CoconsMapClustersProps = {
    width?: number;
    height?: number;
}

const getSuperCluster = () => {
    return new Supercluster({
        radius: 60, // radius to cluster, in pixels
        maxZoom: MAX_ZOOM, // max zoom to cluster
        map: (props: MapMarkerProps) => {
            const mappedProps: MarkerMappedProps = {
                id: JSON.stringify(props.position),
                coconId: props.coconId,
                position: props.position,
                hasIssue: props.pendingIssues > 0,
                isOffline: props.status === CoconStatus.DEPLOYED && props.networkStatus === NetworkStatus.OFFLINE,
            };
            return mappedProps;
        },
        // /**
        //  * Called when either of the following happens: 
        //  *  - 2 points need to be merged into a new cluster
        //  *  - a point is merged with a cluster
        //  *  - a cluster is merged with a point
        //  */
        reduce: (acc: AccumulatingClusterProps, props: AccumulatingClusterProps) => {
            // accumulating props
            if (!acc.coconsIds) acc.coconsIds = [acc.coconId];
            if (!props.coconsIds) props.coconsIds = [props.coconId];
            acc.coconsIds = [...acc.coconsIds, ...props.coconsIds];

            acc.hasIssue = acc.hasIssue || props.hasIssue;
            acc.isOffline = acc.isOffline || props.isOffline;

            let bounds = acc.bounds || {
                nw: acc.position,
                ne: acc.position,
                sw: acc.position,
                se: acc.position,
            };
            const { lat, lng } = props.position;
            const maxLat = Math.max(lat, bounds.nw.lat);
            const minLat = Math.min(lat, bounds.sw.lat);
            const maxLng = Math.max(lng, bounds.ne.lng);
            const minLng = Math.min(lng, bounds.nw.lng);
            acc.bounds = {
                nw: { lat: maxLat, lng: minLng },
                ne: { lat: maxLat, lng: maxLng },
                sw: { lat: minLat, lng: minLng },
                se: { lat: minLat, lng: maxLng },
            };
        }
    });
}

function CoconsMap(props: CoconsMapClustersProps) {

    const { width, height } = props;

    // handle map
    const [{ zoom, center, bounds }, setMapState] = useState<MapState>({
        zoom: DEFAULT_ZOOM,
        center: DEFAULT_CENTER,
        bounds: DEFAULT_BOUNDS,
    });

    const points = useAppSelector(state => state.cocons.map.points);
    const autoCenter = useAppSelector(state => state.cocons.map.defaultCenter);
    const autoBounds = useAppSelector(state => state.cocons.map.defaultBounds);

    useEffect(() => {
        const autoZoom = (!width || !height) ? DEFAULT_ZOOM : getBoundsZoomLevel(autoBounds, { width, height });
        setMapState({ zoom: autoZoom, center: autoCenter, bounds: autoBounds });
    }, [autoBounds, autoCenter]);

    const [superCluster, setSuperCluster] = useState<Supercluster>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(CoconsMethods.list([]));
    }, []);

    useEffect(() => {
        const s = getSuperCluster();
        s.load(points);
        setSuperCluster(s);
    }, [points]);

    const clusters = useMemo(() => {
        if (!superCluster) return [];
        const bbox: [number, number, number, number] = [ //[westLng, southLat, eastLng, northLat]
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
        ];
        return superCluster.getClusters(bbox, zoom);
    }, [bounds, zoom]);

    const mapRef = useRef<GoogleMapReact>(null);

    const handleMarkerClicked = useCallback((cocon: Cocon) => {
        dispatch(SelectedCoconActions.setSelectedCocon(cocon));
    }, [points]);

    const [selectedCluster, setSelectedCluster] = useState<{ id: string, coconsIds: string[] }>();

    const handleClusterSelect = useCallback((clusterId: string, coconsIds: string[]) => {
        setSelectedCluster({ id: clusterId, coconsIds });
    }, [points]);

    const zoomOnCluster = useCallback((clusterBounds: Bounds) => {
        if (!width || !height) {
            alert("Cannot zoom yet!");
            return;
        }
        const mapDims = {
            width: width,
            height: height,
        };
        const newCenter = getCenter(clusterBounds);
        const newZoom = getBoundsZoomLevel(clusterBounds, mapDims);
        console.debug("clusterBounds", clusterBounds);
        console.debug("newCenter", newCenter);
        console.debug("newZoom", newZoom);
        setMapState({ zoom: newZoom, center: newCenter, bounds: clusterBounds });
    }, [points, width, height]);

    return (
        <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: FIREBASE_CONFIG.apiKey }}
            center={center}
            zoom={zoom}
            options={getMapOptions}
            onChange={({ center, zoom, bounds }) => {
                console.debug("center", center);
                console.debug("bounds", bounds);
                console.debug("zoom", zoom);
                setMapState({
                    center,
                    zoom,
                    bounds,
                });
            }}
        >
            {clusters.map((cluster) => {
                const [lng, lat] = cluster.geometry.coordinates;
                if (_.has(cluster.properties, "cluster")) { // cluster
                    const {
                        id,
                        point_count: pointCount,
                        coconsIds,
                        bounds,
                        hasIssue,
                        isOffline,
                    }: AccumulatedClusterProps = cluster.properties as unknown as AccumulatedClusterProps;

                    let clusterColor: MarkerColor = isOffline ? "error" : (hasIssue ? "warning" : "success");

                    return (
                        <CoconsMarkersCluster
                            key={id}
                            id={id}
                            coconsCount={pointCount}
                            coconsIds={coconsIds}
                            color={clusterColor}
                            lat={lat}
                            lng={lng}
                            bounds={bounds}
                            isSelected={selectedCluster?.id === id}
                            onClick={() => {
                                if (zoom === MAX_ZOOM) { // zoomed at the maximum but cocons still clustered, display them all in drawer
                                    handleClusterSelect(id, coconsIds);
                                }
                                else { // zoom in on the clustered batches
                                    zoomOnCluster(bounds);
                                }
                            }}
                        />
                    );
                }

                // single marker
                const { coconId, pendingIssues, status, networkStatus, } = cluster.properties as unknown as MapMarkerProps;

                let color: MarkerColor = "success";
                if (status !== CoconStatus.DEPLOYED) color = "info";
                else if (networkStatus === NetworkStatus.OFFLINE) color = "error";
                else if (pendingIssues > 0) color = "warning";

                return (
                    <CoconMarker
                        key={coconId}
                        coconId={coconId}
                        color={color}
                        lat={lat}
                        lng={lng}
                        onClick={(cocon) => handleMarkerClicked(cocon)}
                    />
                );
            })}

            <SelectedCoconsClusterDrawer 
                coconsIds={selectedCluster?.coconsIds || []} 
                onClose={() => setSelectedCluster(undefined)}
                />

        </GoogleMapReact>
    );
}

CoconsMap.whyDidYouRender = true;

export default CoconsMap;