import SectionLoader from "components/_include/SectionLoader";
import { useCallback, useRef, useState } from "react";
import _ from "lodash";
import { useAppSelector } from "store/hooks";
import CoconsMap from "./CoconsMap";
import SelectedCoconDrawer from "./CoconDrawer/SelectedCoconDrawer";
import CoconsSearchBar from "./CoconsSearchBar";

type CoconsMapPageProps = {

}

function CoconsMapPage(props: CoconsMapPageProps) {

    const [mapWrapper, setMapWrapper] = useState<HTMLDivElement>();
    const handleMapWrapperRef = useCallback((node) => {
        setMapWrapper(node);
    }, []);

    return (
        <div ref={handleMapWrapperRef} style={{ height: '100%', width: '100%', position: "relative" }}>
            <CoconsMap
                width={mapWrapper?.offsetWidth}
                height={mapWrapper?.offsetHeight}
                />
            <CoconsSearchBar />
            <SelectedCoconDrawer />
        </div>
    );
}

export default CoconsMapPage;