import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { getPictureURL, StorageType } from "helpers/storage";
import AIResultsCanvas from "./AIResultsCanvas";
import { Box } from "@mui/material";

type BatchImageOnlyProps = {
    batchId: string;
    imageURL: string;
};

function BatchImageOnly(props: BatchImageOnlyProps) {
    const { batchId, imageURL, } = props;

    const [originalImageDimensions, setOriginalImageDimensions] = useState({
        width: 600,
        height: 600 / 1.5,
        ratio: 1.5,
    });

    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const handleImageRef = useCallback((node: HTMLImageElement) => {
        setImage(node);
    }, []);

    useEffect(() => {
        if (image) {
            image.onload = () => {
                setOriginalImageDimensions({
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                    ratio: image.naturalWidth / image.naturalHeight,
                });
            }
        }
    }, [image]);

    const [pictureURL, setPictureURL] = useState("");

    useEffect(() => {
        getPictureURL(StorageType.BATCHES, imageURL)
            .then(url => {
                if (url) setPictureURL(url);
            });
    }, [imageURL]);

    return (
        <Box 
            width="100%"
            position="relative"
            maxWidth={600}
            maxHeight={600 / originalImageDimensions.ratio}
            overflow="hidden"
            sx={{
                aspectRatio: `${originalImageDimensions.ratio}`,
            }}
        >
            <img
                ref={handleImageRef}
                src={pictureURL}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: '100%',
                    maxHeight: '100%',
                    objectFit: "contain",
                }} />
            {image !== null && (
                <AIResultsCanvas
                    batchId={batchId}
                    width={image.width}
                    height={image.width}
                    naturalWidth={image.naturalWidth}
                    naturalHeight={image.naturalHeight}
                />
            )}
        </Box>
    );
};

export default BatchImageOnly;