import SectionLoader from "components/_include/SectionLoader";
import { useAppSelector } from "store/hooks";
import CoconDescription from "./CoconDescription";

type SelectedCoconDrawerBodyProps = {

}

function SelectedCoconDrawerBody(props: SelectedCoconDrawerBodyProps) {
    const { } = props;

    const cocon = useAppSelector(state => state.cocons.selected.data);
    
    return (
        cocon ? (
            <CoconDescription cocon={cocon} />
        ) : (
            <SectionLoader />
        )
    );
}

export default SelectedCoconDrawerBody;