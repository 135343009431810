import React from 'react';
import clsx from 'clsx';
import { Box, Divider, Drawer, DrawerProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import SendIcon from '@mui/icons-material/Send';
import PreviewIcon from '@mui/icons-material/PreviewRounded';
import ContactsIcon from '@mui/icons-material/Contacts';
import { Link } from "react-router-dom";
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import LanguageSelect from 'components/_include/LanguageSelect';

const categories = [
    {
        id: 'batches.main',
        children: [
            { label: 'batches.processed', icon: <AssignmentTurnedInIcon />, active: true, link: "/batch/processed" },
            { label: 'batches.to_do', icon: <AssignmentIcon />, active: true, link: "/batch/todo" },
            { label: 'batches.demo', icon: <PreviewIcon />, active: true, link: "/batch/demo" },
            // { label: 'Label', icon: <EditIcon />, active: true, link: "/batch/label" },
            { label: 'batches.stats', icon: <AssessmentIcon />, active: true, link: "/batch/statistics" },
            { label: 'to_annotate.page', icon: <FormatShapesIcon />, active: true, link: "/batch/to-annotate" },
        ]
    },
    {
        id: 'users.main',
        children: [
            { label: 'users.list', icon: <PeopleIcon />, active: true, link: "/user" },
            { label: 'users.chat', icon: <QuestionAnswerIcon />, active: true, link: "/chat" },
            { label: 'users.contacts', icon: <ContactsIcon />, active: true, link: "/user/contacts" }
        ]
    },
    {
        id: 'partners.main',
        children: [
            { label: 'partners.list', icon: <AssignmentIndIcon />, active: true, link: "/partner/" },
            { label: 'partners.add', icon: <PersonAddIcon />, active: true, link: "/partner/create" },
            // {label: 'Manage rewards', icon: <PeopleIcon/>, active: false, link:'/partner/rewards'}
        ]
    },
    {
        id: 'rewards.main',
        children: [
            { label: 'rewards.list', icon: <CardGiftcardIcon />, active: true, link: "/reward/" },
            { label: 'rewards.purchases', icon: <SendIcon />, active: true, link: "/purchase" },
            { label: 'rewards.add', icon: <AddBoxIcon />, active: true, link: "/reward/create" },
        ]
    },
    {
        id: 'cocons.main',
        children: [
            { label: 'cocons.list', icon: <MoveToInboxIcon />, active: true, link: "/cocon" },
            { label: 'cocons.add', icon: <AddBoxIcon />, active: true, link: "/cocon/create" },
        ]
    },
];

function Drawers(props: DrawerProps) {

    const { t } = useTranslation([Namespaces.titles]);

    return (
        <Drawer variant="permanent" {...props}>
            <Box mt={2} mx={2}>
                <LanguageSelect />
            </Box>
            <List disablePadding>
                {categories.map(({ id, children }) => (
                    <React.Fragment key={id}>
                        <ListItem sx={{ py: 2, }}>
                            <ListItemText
                                primaryTypographyProps={{
                                    color: (theme) => theme.palette.common.white,
                                }}
                            >
                                {t(id, { ns: Namespaces.titles })}
                            </ListItemText>
                        </ListItem>
                        {children.map(({ label: childLabel, icon, active, link }) => (
                            <Link key={childLabel} style={{ textDecoration: 'none' }} to={link}>
                                <ListItemButton
                                    sx={{
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        color: active ? '#4fc3f7' : 'rgba(255, 255, 255, 0.7)',
                                        '&:hover,&:focus': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                        },
                                    }}
                                >
                                    <ListItemIcon sx={{
                                        minWidth: 'auto',
                                        mr: 2,
                                    }}>
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: '0.9rem',
                                        }}
                                    >
                                        {t(childLabel, { ns: Namespaces.titles })}
                                    </ListItemText>
                                </ListItemButton>
                            </Link>
                        ))}
                        <Divider sx={{
                            backgroundColor: '#404854',
                            mt: 2,
                        }} />
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
}

export default Drawers;