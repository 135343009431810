import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { QueryFilter } from "constants/types";
import { Timestamp } from "firebase/firestore";
import { Namespaces } from "locales/translations";
import CoconsCluster, { CoconsClustersMethods } from "models/Cocons/CoconsCluster";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllCoconsClusters } from "store/reducers/cocons/clusters";

type SelectProps = {
    value: string;
    parentLoading: boolean;
    noClusterSelectedLabel: string;
    deployed?: boolean;
    onChange: (value: CoconsCluster | undefined) => void;
}

export default function CoconsClusterSelect(props: SelectProps) {
    const { value, deployed, parentLoading, noClusterSelectedLabel, onChange } = props;

    const { t } = useTranslation([Namespaces.glossary]);

    const coconsClusters = useAppSelector(state => selectAllCoconsClusters(state));
    const coconsLoading = useAppSelector(state => state.cocons.clusters.loading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // load list of cocons
        let filters: QueryFilter[] = [];
        if (deployed) {
            filters.push({
                fieldPath: "dateDeployed",
                opStr: "<",
                value: Timestamp.now(),
            });
        }
        dispatch(CoconsClustersMethods.list(filters));
    }, []);

    const handleChange = (clusterId: string) => {
        const selectedCluster = coconsClusters.find(cc => cc.id === clusterId);
        onChange(selectedCluster);
    };

    const label = value ? t("cocon", { ns: Namespaces.glossary }) : noClusterSelectedLabel;

    return (
        <FormControl
            variant="outlined"
            disabled={coconsLoading}
            sx={{
                margin: 0,
                flex: 1,
            }}
        >
            <InputLabel 
                id="cocon-select-label"
                sx={!value ? { fontStyle: "italic" } : {}}
                >
                {label}
            </InputLabel>
            <Select
                id="cocon-select"
                labelId="cocon-select-label"
                label={label}
                value={value}
                onChange={(event) => handleChange(event.target.value as string)}
            >
                <MenuItem
                    value=""
                    sx={{ fontStyle: "italic" }}
                >
                    {noClusterSelectedLabel}
                </MenuItem>
                {coconsClusters.map(cluster => (
                    <MenuItem
                        value={cluster.id}
                        key={cluster.id}
                        >
                        {cluster.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}