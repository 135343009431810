import React from "react";
import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { IconButton, SxProps, TextField, TextFieldProps, Theme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

type PickerProps = Omit<DateTimePickerProps, "onChange" | "renderInput"> & {
    inputStyle?: SxProps<Theme>;
    onChange: (date: moment.Moment | null) => void;
}

function Datetimepicker(props: PickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                {...props}
                onChange={(date) => { props.onChange(date as moment.Moment | null) }}
                InputProps={{
                    ...props.InputProps,
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        helperText={params.InputProps?.placeholder}
                        sx={{
                            "& input": {
                                cursor: "pointer",
                            },
                            ...props.inputStyle,
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
}

export default Datetimepicker;