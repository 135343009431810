import { TableRow, TableCell, Typography, Box, Avatar, Chip } from "@mui/material";
import { Namespaces } from "locales/translations";
import Purchase, { PurchasesMethods, PurchaseStatuses } from "models/Purchase";
import { useTranslation } from "react-i18next";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

type ItemProps = {
    purchase: Purchase;
}

export default (props: ItemProps) => {
    const { purchase } = props;

    const { t } = useTranslation([Namespaces.glossary]);

    return (
        <TableRow>
            <TableCell>
                <Typography variant="body1">{purchase.user?.firstName} {purchase.user?.lastName}</Typography>
                <Typography variant="body2">{purchase.user?.email}</Typography>
            </TableCell>
            <TableCell>
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <Avatar
                        src={purchase.reward.imageURL}
                        sx={{
                            width: (theme) => theme.spacing(7),
                            height: (theme) => theme.spacing(7),
                        }}
                    />
                    <div>
                        <Typography variant="body1" sx={{ ml: 2 }}>{purchase.reward.name}</Typography>
                        <Typography variant="body2" sx={{ ml: 2 }}>{purchase.reward.partner.name}</Typography>
                    </div>
                </Box>
            </TableCell>
            <TableCell>
                {t(`rewards.category.${purchase.reward.category}`, { ns: Namespaces.glossary })}
            </TableCell>
            <TableCell>
                <Chip
                    sx={{
                        color: "#ffffff",
                        "& .MuiChip-icon": {
                            color: "#ffffff",
                        },
                        padding: 1,
                        height: (theme) => theme.spacing(5),
                        backgroundColor: (theme) => {
                            switch (purchase.status) {
                                case PurchaseStatuses.PURCHASED: return theme.palette.warning.dark;
                                case PurchaseStatuses.SENT: return theme.palette.info.main;
                                case PurchaseStatuses.RECEIVED: return theme.palette.success.main;
                            }
                        }
                    }}
                    icon={purchase.status === PurchaseStatuses.PURCHASED ?
                        <ShoppingBasketIcon />
                        :
                        <AssignmentTurnedInIcon />
                    }
                    label={t(`status.status_on`, {
                        ns: Namespaces.purchases,
                        lastUpdate: PurchasesMethods.getTimeline(purchase)[purchase.status],
                        status: purchase.status
                    })}
                />
            </TableCell>
        </TableRow>
    );
}