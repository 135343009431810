import { Typography, useTheme } from "@mui/material";
import { ChartsStrings } from "constants/charts";
import { Namespaces } from "locales/translations";
import SectionLoader from "../../../_include/SectionLoader";
import { useTranslation } from "react-i18next";
import ChartTitle from "./ChartTitle";
import ChartWrapper from "../ChartWrapper";
import { useAppSelector } from "store/hooks";
import LineChart from "./LineChart";
import moment from "moment";
import TooltipWrapper from "./TooltipWrapper";

type ChartProps = {

}

function SortingQualityChart(props: ChartProps) {

    const { t } = useTranslation([Namespaces.glossary, Namespaces.stats]);

    const theme = useTheme();

    const timeUnit = useAppSelector(state => state.stats.data.timeUnit);
    const qualityStats = useAppSelector(state => state.stats.quality);

    const loading = useAppSelector(state => state.stats.loading);

    return (
        <ChartWrapper>
            <ChartTitle
                title={t("sorting_quality", {
                    ns: Namespaces.stats,
                })}
            />
            {
                loading ?
                    <SectionLoader />
                    :
                    <LineChart
                        data={qualityStats}
                        margin={{ top: 50, right: 60, bottom: 88, left: 68 }}
                        colors={{
                            [ChartsStrings.WELL_SORTED_TRASH]: theme.palette.success.main,
                            [ChartsStrings.BADLY_SORTED_TRASH]: theme.palette.error.light,
                        }}
                        min={0}
                        max={105}
                        stacked={true}
                        axisLeft={{
                            legend: 'Qualité du tri',
                            format: (value: any) => `${value}%`,
                        }}
                        axisBottom={{
                            legend: t(timeUnit, { ns: Namespaces.commons }),
                            format: (value: moment.Moment) =>
                                t("date", {
                                    ns: Namespaces.stats,
                                    context: timeUnit,
                                    date: moment(value),
                                })
                        }}
                        areaBlendMode="normal"
                        areaOpacity={.5}
                        enablePoints={false}
                        lineWidth={2}
                        defs={[
                            {
                                id: 'redToGreen',
                                type: 'linearGradient',
                                colors: [
                                    { offset: 0, color: theme.palette.success.main },
                                    { offset: 100, color: theme.palette.warning.main },
                                ],
                            },
                        ]}
                        fill={[
                            {
                                match: {
                                    id: ChartsStrings.WELL_SORTED_TRASH
                                },
                                id: 'redToGreen'
                            },
                        ]}
                        tooltip={({ point }) => {
                            const date: moment.Moment = point.data.x;
                            console.debug(date);
                            let value: number = point.data.y;
                            if (point.serieId === ChartsStrings.BADLY_SORTED_TRASH) {
                                value = 100 - value;
                            }

                            return (
                                <TooltipWrapper>
                                    <Typography variant="caption">
                                        {t("date_full", {
                                            ns: Namespaces.stats,
                                            context: timeUnit,
                                            date: date,
                                        })}
                                    </Typography>
                                    <Typography variant="body1">{`${value.toLocaleString()}`}% tri correct</Typography>
                                </TooltipWrapper>
                            );
                        }}
                    />
            }
        </ChartWrapper>
    )
};

export default SortingQualityChart;