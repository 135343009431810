import { Box } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { CoconStatus } from "models/Cocons/Cocon";
import { useAppSelector } from "store/hooks";
import CoconForm, { AddCoconFormData } from "../CoconForm/CoconForm";

type EnterCoconDataStepProps = {
    handleSubmit: (data: AddCoconFormData) => Promise<boolean>;
}

function EnterCoconDataStep(props: EnterCoconDataStepProps) {
    const { handleSubmit } = props;

    const coconLoading = useAppSelector(state => state.cocons.selected.loading || state.cocons.selectedCluster.loading);

    const initialData: AddCoconFormData = {
        name: "",
        deviceId: "",
        address: "",
        postcode: "",
        city: "",
        status: CoconStatus.PREPARING,
        managers: [],
        dateDeployed: Timestamp.now().toMillis(),
    };

    return (
        <Box>
            <CoconForm
                type="create"
                loading={coconLoading}
                coconData={initialData}
                submitForm={handleSubmit}
                />
        </Box>
    );
}

export default EnterCoconDataStep;