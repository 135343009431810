import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { Namespaces } from "locales/translations";
import React from "react";
import { useTranslation } from "react-i18next";

type ConfirmationDialogProps = DialogProps & {
    title: string;
    content: string | JSX.Element;
    confirmLabel?: string;
    confirmColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    onClose: () => void;
    onConfirm: () => void;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
    const { title, content, confirmLabel, confirmColor, onClose, onConfirm, ...other } = props;

    const { t } = useTranslation([Namespaces.actions]);

    return (
        <Dialog
            {...other} 
            onClose={onClose}
            >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    color="primary"
                >
                    {t("cancel", { ns: Namespaces.actions })}
                </Button>
                <Button
                    onClick={() => onConfirm()}
                    color={confirmColor}
                >
                    {confirmLabel || t("confirm", { ns: Namespaces.actions })}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;