import { Chip } from "@mui/material";
import { ucFirst } from "helpers/strings";
import { Namespaces } from "locales/translations";
import { IssueStatus } from "models/Cocons/CoconIssue";
import { useTranslation } from "react-i18next";


interface IssueChipProps {
    status: IssueStatus;
}

function IssueChip(props: IssueChipProps) {
    const { status } = props;

    const { t } = useTranslation([Namespaces.issues]);

    return (
        <Chip
            sx={{
                // padding: 1,
                // height: (theme) => theme.spacing(5),
                color: "white.main",
                backgroundColor: status === IssueStatus.REPORTED
                    ? "warning.main"
                    : status === IssueStatus.FIXING
                        ? "info.main"
                        : "success.main",
            }}
            label={ucFirst(t(`${status}`, {
                ns: Namespaces.issues,
            }))}
        />
    );
}

export default IssueChip;