import { Grid, GridProps } from "@mui/material";

export default (props: GridProps) => {
    return (
        <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            sx={{
                height: (theme) => theme.spacing(50),
                mb: 7.5,
            }}
            {...props}
        />
    )
}