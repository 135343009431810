import { useState, useEffect } from 'react';
import { Typography, Container, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Box, } from '@mui/material';
import Reward, { RewardsMethods } from 'models/Reward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SectionLoader from '../../_include/SectionLoader';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '../../../locales/translations';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { Link } from 'react-router-dom';

type ListRewardsPageProps = {

}

function ListRewardsPage(props: ListRewardsPageProps) {

    const { rewardsLoading, rewards, } = useAppSelector(state => {
        const rewards = state.rewards.list;
        return {
            rewardsLoading: rewards.loading,
            rewards: rewards.data,
        };
    });
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.glossary, Namespaces.titles,])

    useEffect(() => {
        dispatch(RewardsMethods.list([]));
    }, []);

    const [rewardSelected, setRewardSelected] = useState<Reward | null>(null);

    const handleDeleteClicked = () => {
        dispatch(RewardsMethods.deleteReward(rewardSelected!))
            .then(() => {
                setRewardSelected(null);
            });
    }

    return (
        <Container>
            <Typography variant="h1">
                {t("rewards.main", { ns: Namespaces.titles })}
            </Typography>
            {
                rewardsLoading ?
                    <SectionLoader />
                    :
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("name", { ns: Namespaces.commons })}</TableCell>
                                    <TableCell>{t("partner", { ns: Namespaces.glossary })}</TableCell>
                                    <TableCell>{t("description", { ns: Namespaces.commons })}</TableCell>
                                    <TableCell>{t("category", { ns: Namespaces.glossary })}</TableCell>
                                    <TableCell>{t("actions", { ns: Namespaces.actions })}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rewards.map((reward: Reward, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: (theme) => theme.spacing(7),
                                                        height: (theme) => theme.spacing(7),
                                                    }}
                                                    src={reward.imageURL}
                                                />
                                                <Typography variant="body1" sx={{ ml: 2 }}>{reward.name}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Avatar src={reward.partner.imageURL} />
                                                <Typography variant="body1" sx={{ ml: 2 }}>{reward.partner.name}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {reward.description}
                                        </TableCell>
                                        <TableCell>
                                            {t(`rewards.category.${reward.category}`, { ns: Namespaces.glossary })}
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                to={`${reward.id}/edit/`}
                                                >
                                                <IconButton
                                                    aria-label="Modifier"
                                                    color="primary"
                                                    >
                                                    <EditIcon />
                                                </IconButton>
                                            </Link>
                                            <IconButton
                                                aria-label="Supprimer"
                                                color="primary"
                                                onClick={(event) => setRewardSelected(reward)}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }

            {rewardSelected && (
                <Dialog
                    open={rewardSelected !== null}
                    onClose={(event) => setRewardSelected(null)} >
                    <DialogTitle>
                        {t("delete_something.title", { ns: Namespaces.actions, something: rewardSelected.name })}
                    </DialogTitle>
                    <DialogContent>
                        {t("delete_something.body", { ns: Namespaces.actions })}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(event) => setRewardSelected(null)}
                            color="primary"
                        >
                            {t("cancel", { ns: Namespaces.actions })}
                        </Button>
                        <Button
                            onClick={handleDeleteClicked}
                            color="error"
                        >
                            {t("delete", { ns: Namespaces.actions })}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    )
}

export default ListRewardsPage;