import { UNAUTHORIZED_ERROR } from "constants/api_errors";
import { getAuth } from "firebase/auth";

export class CodeError extends Error {
    code: number;

    constructor(code: number, message: string) {
        super(message);
        this.code = code;
    }
}

export async function fetchAPI(input: RequestInfo, init?: RequestInit): Promise<any> {
    // retrieve API token
    const user = getAuth().currentUser;
    let token = await user?.getIdToken();
    if (!token) {
        throw UNAUTHORIZED_ERROR;
    }
    else {
        let headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            ...init?.headers,
        };

        return fetch(input, {
            ...init,
            headers: headers,
        })
            .then(handleAPIResponse);
    }
}

/**
 * Format body of the response to be readable.
 * Throw an Error to be caught and handled in reducers upon API errors (e.g. 400, 404...)
 * @param res The Response of the request to the API
 */
 const handleAPIResponse = async (res: Response) => {
    try {
        const body = await res.text();
        try {
            const json = JSON.parse(body);
            if (!res.ok) throw new CodeError(res.status, json); // failed request
            return json; // successful request
        }
        catch (e) { // not json
            if (!res.ok) throw new CodeError(res.status, body || res.statusText); // failed request
            return body; // successful request but not JSON was returned
        }                               
    }
    catch (e) { // failed parsing json
        const error = e as CodeError;
        console.error(error); // print the error in console
        throw error;
    }
};