import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Namespaces } from "locales/translations";
import CoconIssue, { CoconIssuesMethods, IssueStatus } from "models/Cocons/CoconIssue";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllCoconIssues } from "store/reducers/cocons/issues";
import { UsersActions } from "store/reducers/users";

type CloseSupportTicketButtonProps = {

}

function CloseSupportTicketButton({ }: CloseSupportTicketButtonProps) {
    const { t } = useTranslation([Namespaces.issues]);

    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const reportedIssues = useAppSelector(selectAllCoconIssues);

    const closeSupportTicket = () => {
        setLoading(true);

        const closeIssuesRecursively: (issues: CoconIssue[]) => Promise<void> = async (issues) => {
            const issue = issues.pop();

            if (!issue) { // all issues marked as fixed
                return;
            }

            // mark next issue as fixed
            await dispatch(CoconIssuesMethods.update(issue.cocon.clusterId, issue.cocon.id, issue.id, { status: IssueStatus.FIXED }));

            // keep marking remaining issues as fixed
            return await closeIssuesRecursively(issues);
        }

        closeIssuesRecursively(reportedIssues)
            .then(() => {
                // remove hasOpenTicket flag from user
                dispatch(UsersActions.updateSelected({
                    hasOpenTicket: false,
                }));

                // remove loader
                // setLoading(false);
            });
    }

    return (
        <LoadingButton
            color="success"
            variant="contained"
            sx={{
                ml: 1,
                border: `1px #ffffff solid`,
            }}
            onClick={closeSupportTicket}
            loading={reportedIssues.length === 0 || loading}
        >
            <span>
                {t("close_ticket", { ns: Namespaces.issues })}
            </span>
        </LoadingButton>
    );
}

export default CloseSupportTicketButton;