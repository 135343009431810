import { Box, Button, Divider, DividerProps, Grid, GridProps, Typography, TypographyProps } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import TimelineIcon from '@mui/icons-material/Timeline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ImageIcon from '@mui/icons-material/Image';
import { DATE_SELECT_TYPES } from "helpers/dates";
import moment from "moment";
import urls from "constants/urls";
import CopyToClipboardButton from "components/_include/CopyToClipboardButton";
import NetworkStatusRipple from "./NetworkStatusRipple";
import Cocon from "models/Cocons/Cocon";

const PropertyField = (props: TypographyProps & { label: string, value: React.ReactNode }) => {
    const { label, value, ...other } = props;
    return (
        <Typography mb={1} {...other}>
            {label}&nbsp;:&nbsp;<strong style={{ display: "inline-flex", alignItems: "center" }}>{value}</strong>
        </Typography>
    )
}

const SectionDivider = (props: DividerProps) => (
    <Divider
        light
        sx={{
            my: 2,
            mx: 2,
        }}
    />
);

const ButtonWrapper = (props: GridProps) => (
    <Grid
        item
        md={6}
        xs={12}
        {...props}
    />
);

type CoconDescriptionProps = {
    cocon: Cocon;
}

function CoconDescription(props: CoconDescriptionProps) {
    const { cocon } = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.cocons, Namespaces.commons, Namespaces.glossary, Namespaces.issues]);

    return (
        <Box
            width={(theme) => theme.spacing(50)}
            height="100%"
            bgcolor="#ffffff"
            p={4}
        >
            {(!cocon) ? ( // cocon not found or not loaded yet
                <SectionLoader />
            ) : (
                <Box>
                    <Typography variant="h6">
                        {cocon.name || "No name defined"}
                    </Typography>
                    <Typography variant="subtitle1">
                        {cocon.address}
                    </Typography>

                    <SectionDivider />

                    <PropertyField
                        label={t("status", { ns: Namespaces.glossary })}
                        value={t(`cocon_status.${cocon.status}`, { ns: Namespaces.glossary })}
                    />
                    <PropertyField
                        label={t("network_status", { ns: Namespaces.glossary })}
                        value={<>
                            {t(cocon.networkStatus, { ns: Namespaces.glossary })}
                            <NetworkStatusRipple
                                status={cocon.networkStatus}
                            />
                        </>}
                    />

                    <SectionDivider />

                    <Grid container spacing={1} sx={{ my: 2 }}>
                        <ButtonWrapper>
                            <Button
                                href={
                                    `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify({
                                        id: cocon.id,
                                        token: cocon.token,
                                        apiURL: urls.API,
                                        codeVersion: {
                                            label: "Main",
                                            branch: "main",
                                        },
                                    }))}`
                                }
                                download="credentials.json"
                                color="info"
                                variant="outlined"
                                startIcon={<VpnKeyIcon />}
                                sx={{
                                    width: "100%",
                                    margin: 0,
                                }}>
                                {t("credentials", { ns: Namespaces.actions })}
                            </Button>
                        </ButtonWrapper>

                        <ButtonWrapper>
                            <Link
                                to={`${cocon.id}/batches`}
                                >
                                <Button
                                    color="info"
                                    variant="outlined"
                                    startIcon={<ImageIcon />}
                                    sx={{
                                        width: "100%",
                                        margin: 0,
                                    }}
                                >
                                    {t("view_batches", { ns: Namespaces.actions })}
                                </Button>
                            </Link>
                        </ButtonWrapper>

                        <ButtonWrapper>
                            <Link
                                to={`${cocon.id}/user`}
                            >
                                <Button
                                    color="info"
                                    variant="outlined"
                                    startIcon={<GroupIcon />}
                                    sx={{
                                        width: "100%",
                                        margin: 0,
                                    }}
                                >
                                    {t("view_users", { ns: Namespaces.actions })}
                                </Button>
                            </Link>
                        </ButtonWrapper>

                        <ButtonWrapper>
                            <Link
                                to={`${cocon.id}/statistics?${cocon.dateDeployed
                                    ? `dateSelect=${DATE_SELECT_TYPES.SINCE_INSTALLATION}&startDate=${moment(cocon.dateDeployed).format("YYYY-MM-DD")}`
                                    : ""}`}
                            >
                                <Button
                                    color="info"
                                    variant="outlined"
                                    startIcon={<TimelineIcon />}
                                    sx={{
                                        width: "100%",
                                        margin: 0,
                                    }}
                                >
                                    {t("view_stats", { ns: Namespaces.actions })}
                                </Button>
                            </Link>
                        </ButtonWrapper>
                        <Grid
                            item
                            xs={12}
                        >
                            <Link
                                to={cocon.id}
                            >
                                <Button
                                    color="info"
                                    variant="outlined"
                                    startIcon={<EditIcon />}
                                    sx={{
                                        width: "100%",
                                        margin: 0,
                                    }}
                                >
                                    {t("edit", { ns: Namespaces.actions })}
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <SectionDivider />

                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <PropertyField
                            label={t("join_code", { ns: Namespaces.glossary })}
                            value={cocon.joinCode}
                        />
                        <CopyToClipboardButton
                            text={cocon.joinCode}
                            size="small"
                        />
                    </Box>

                    <PropertyField
                        label={t("props.unitsCount", { ns: Namespaces.cocons })}
                        value={cocon.unitsCount ?? t("unknown", { ns: Namespaces.commons })}
                    />
                    <PropertyField
                        label={t("number_of_users", { ns: Namespaces.glossary })}
                        value={cocon.usersCount}
                    />
                    <PropertyField
                        label={t("number_of_batches", { ns: Namespaces.glossary })}
                        value={cocon.batchesCount}
                    />

                    <SectionDivider />

                    {cocon.pendingIssues > 0 && (
                        <Box>
                            <Link
                                to={`${cocon.id}/issues`}
                            >
                                <Button
                                    color="warning"
                                    variant="outlined"
                                    sx={{ width: "100%", mx: 0 }}
                                >
                                    {t("pending_issue", { ns: Namespaces.issues, count: cocon.pendingIssues })}
                                </Button>
                            </Link>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
}

export default CoconDescription;