import { Box, CssBaseline } from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, getAuth, User as FirebaseUser } from "firebase/auth";
import { useLayoutEffect, useState } from "react";
import Navigator from 'components/Navigation/Navigator';

type LoggedInLayoutProps = {

}

function LoggedInLayout(props: LoggedInLayoutProps) {
    const { } = props;

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [user, setUser] = useState<FirebaseUser>();

    // check if user is logged in
    onAuthStateChanged(getAuth(), firebaseUser => {
        if (!firebaseUser) { // user needs to log in
            navigate(`/login/?next=${pathname}`);
        }
        else {
            setUser(firebaseUser); // populate user to store
        }
    });

    // scroll to top when navigating to different page
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [pathname]);

    if (!user) return null;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            minHeight: '100vh',
            flexGrow: 1,
        }}>
            <CssBaseline />
            <Navigator />
            <Outlet />
        </Box>
    );
}

export default LoggedInLayout;