import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Box, Container, Typography, } from "@mui/material";
import ImagesFilterAndAction from "./ImagesFilterAndAction";
import ImagesToAnnotateList from "./ImagesToAnnotateList";

type ImagesToAnnotateProps = {

}

const LOADING_LIMIT = 50;

function ImagesToAnnotatePage(props: ImagesToAnnotateProps) {

    const { t } = useTranslation([Namespaces.actions, Namespaces.titles]);

    return (
        <Container
            sx={{
                // bgcolor: "#EFEFEF",
            }}
        >
            <Typography variant="h1">
                {t("to_annotate.page", { ns: Namespaces.titles })}
            </Typography>
            <Box>
                <Box
                    position="sticky"
                    top={0}
                    py={2}
                    zIndex={1000}
                    borderBottom="1px solid #fff"
                    sx={{
                        bgcolor: (theme) => theme.palette.background.default,
                    }}
                >
                    <ImagesFilterAndAction
                        loadingLimit={LOADING_LIMIT}
                    />
                </Box>

                <ImagesToAnnotateList />
            </Box>

            {/* <UpdateBatchDialog /> */}
        </Container>
    );
};

export default ImagesToAnnotatePage;
