import Purchase, {  } from "models/Purchase";
import { createSlice, PayloadAction, } from "@reduxjs/toolkit";
import { DataContext } from "store/store";

export type SelectedPurchaseContext = DataContext<Purchase | null> & {
    
}

const initialState: SelectedPurchaseContext = {
    data: null,
    loading: false,
    error: null,
};

const selectedPurchaseSlice = createSlice({
    name: 'selected_purchase',
    initialState: initialState,
    reducers: {
        startLoadingSelected: (state) => {
            state.loading = true;
            state.error = null;
        },
        setSelected: (state, { payload: purchase }: PayloadAction<Purchase | null>) => {
            state.loading = false;
            state.data = purchase;
        },
        setSelectedError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedPurchaseActions = selectedPurchaseSlice.actions;

const SelectedPurchaseReducer =  selectedPurchaseSlice.reducer;

export default SelectedPurchaseReducer;