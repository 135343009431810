import { useState, FormEvent } from 'react';
import { TextField, Typography, styled, Box, Alert } from '@mui/material';
import ActionButton from 'components/_include/ActionButton';
import BackToWebsiteLink from '../../_include/BackToWebsiteLink';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '../../../locales/translations';
import { usePrevious } from 'helpers/refs';
import { useSearchParams } from 'react-router-dom';
import User, { AddressData, UsersMethods } from 'models/User';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Content = styled(Box)({
    maxWidth: 1280,
    margin: "0 auto",
    mt: 4,
    padding: 4,
    pt: 6,
    textAlign: 'center',
});

type CompleteProfilePageProps = {

}

interface AddressDataInputs {
    street_address: {
        value: string,
        error: string | null
    },
    additional_information: {
        value: string,
        error: string | null
    },
    city: {
        value: string,
        error: string | null
    },
    postcode: {
        value: string,
        error: string | null
    },
}

function CompleteProfilePage(props: CompleteProfilePageProps) {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("t");

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.snacks, Namespaces.titles]);

    const getPlaceholder = (key: string) => {
        return t(`profile.${key}`, { ns: Namespaces.forms });
    }

    const { loading: userLoading, error: userError } = useAppSelector(state => state.users.user);
    const dispatch = useAppDispatch();

    const wasLoading = usePrevious(userLoading);

    const [inputs, setInputs] = useState<AddressDataInputs>({
        street_address: {
            value: "",
            error: null
        },
        additional_information: {
            value: "",
            error: null
        },
        city: {
            value: "",
            error: null
        },
        postcode: {
            value: "",
            error: null
        },
    });

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        if (!token) return;

        let { street_address, additional_information, city, postcode } = inputs;
        let error = false;

        if (!street_address.value) {
            error = true;
            street_address.error = "";
        }

        if (!city.value) {
            error = true;
            city.error = "";
        }

        if (!postcode.value) {
            error = true;
            postcode.error = "";
        }

        if (error) {
            setInputs({
                street_address: street_address,
                additional_information: additional_information,
                city: city,
                postcode: postcode,
            });
        }
        else {
            dispatch(UsersMethods.completeProfile(token, {
                street_address: street_address.value,
                additional_information: additional_information.value,
                city: city.value,
                postcode: postcode.value,
            }));
        }
    }

    const { street_address, additional_information, city, postcode } = inputs;
    const formIsValid = street_address.value.length && city.value.length && postcode.value.length;

    if (!token) {
        return (
            <Content>
                <Alert
                    severity='error'
                    sx={{
                        mb: 2,
                    }}
                >
                    {t("invalid_link", { ns: Namespaces.snacks })}
                </Alert>
                <BackToWebsiteLink />
            </Content>
        );
    }

    if (wasLoading && !userLoading && !userError) { // request completed successfully
        return (
            <Content>
                <Alert
                    severity='success'
                >
                    {t("update_profile.success", { ns: Namespaces.snacks })}
                </Alert>
            </Content>
        );
    }

    return (
        <Content>
            <Typography variant="h1" sx={{ mt: 0 }}>
                {t("complete_profile", { ns: Namespaces.titles })}
            </Typography>
            <form
                autoComplete="on"
                method="post"
                action="#"
            >
                {userError ?
                    <Alert
                        severity='error'
                    >
                        {t("update_profile.error", { ns: Namespaces.snacks })}
                    </Alert>
                    :
                    null
                }

                <TextField
                    id="street_address"
                    label={getPlaceholder("street_address")}
                    value={street_address.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('street_address', event.target.value) }
                    }}
                    error={Boolean(street_address.error)}
                    helperText={street_address.error}
                    required
                />

                <br />

                <TextField
                    id="additional_information"
                    label={getPlaceholder("additional_information")}
                    value={additional_information.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('additional_information', event.target.value) }
                    }}
                    error={Boolean(additional_information.error)}
                    helperText={additional_information.error}
                />

                <TextField
                    id="postcode"
                    label={getPlaceholder("postcode")}
                    value={postcode.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('postcode', event.target.value) }
                    }}
                    error={Boolean(postcode.error)}
                    helperText={postcode.error}
                    required
                />

                <TextField
                    id="city"
                    label={getPlaceholder("city")}
                    value={city.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('city', event.target.value) }
                    }}
                    error={Boolean(city.error)}
                    helperText={city.error}
                    required
                />

                <Box textAlign="center">
                    <ActionButton
                        color="primary"
                        disabled={!formIsValid}
                        loading={userLoading}
                        onClick={(event) => handleSubmitPressed(event)}
                        type="submit"
                    >
                        {t("update", { ns: Namespaces.actions })}
                    </ActionButton>
                </Box>
            </form>
        </Content>
    )
}

export default CompleteProfilePage;