import { Box } from "@mui/material";
import { QueryFilter } from "constants/types";
import { BatchesMethods } from "models/Batches/Batch";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import DemoBatchCard from "../BatchComponents/DemoCard/DemoBatchCard";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

type BatchDemoDetailProps = {

}

function BatchDemoDetail({ }: BatchDemoDetailProps) {
    const [searchParams] = useSearchParams();
    const coconId = searchParams.get("coconId");

    const [loadingInProgress, setLoadingInProgress] = useState(false);

    const dispatch = useAppDispatch();

    const loadLastBatch = async (timestamp?: Timestamp) => {
        if (loadingInProgress) return;

        setLoadingInProgress(true);
        let filters: QueryFilter[] = [
            { fieldPath: "cocon.id", opStr: "==", value: coconId },
        ];

        const res = await dispatch(BatchesMethods.getLast(filters));
        console.debug("latest batch", res);

        setLoadingInProgress(false);
    };

    /**
     * Load last batch upon page load, then every 10s
     */
    useEffect(() => {

        loadLastBatch();

        const intervalId = setInterval(loadLastBatch, 10000);

        return () => clearInterval(intervalId);
    }, [coconId]);

    return (
        <Box
            width="100%"
            zIndex={9}
        >
            <DemoBatchCard />
        </Box>
    );
}

export default BatchDemoDetail;