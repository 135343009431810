import { useState, FormEvent, useEffect } from 'react';
import { Typography, Container, TextField, Button, Box, } from '@mui/material';
import ActionButton from 'components/_include/ActionButton';
import { isValidEmail } from 'helpers/strings';
import InputMask from 'react-input-mask';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import Partner, { NewPartnerData, PartnersMethods } from 'models/Partner';
import { useNavigate } from 'react-router-dom';
import { FileInput, FormInput } from 'helpers/forms';

type AddPartnerPageProps = {

}

function AddPartnerPage(props: AddPartnerPageProps) {

    const { } = props;

    const navigate = useNavigate();

    const { loading, error } = useAppSelector(state => state.partners);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.forms, Namespaces.titles]);

    const initialInputs = {
        name: {
            value: "",
            error: null,
        },
        description: {
            value: "",
            error: null,
        },
        category: {
            value: "",
            error: null,
        },
        email: {
            value: "",
            error: null,
        },
        phone: {
            value: "",
            error: null,
        },
        website: {
            value: "",
            error: null,
        },
        logo: {
            file: null,
            error: null,
        },
    };

    const [inputs, setInputs] = useState<{
        name: FormInput<string>;
        description: FormInput<string>;
        category: FormInput<string>;
        email: FormInput<string>;
        phone: FormInput<string>;
        website: FormInput<string>;
        logo: FileInput;
    }>(initialInputs);

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        // props.resetUserError();

        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleLogoSelected = (input: HTMLInputElement) => {
        if (input.files && input.files.length > 0) {
            setInputs({
                ...inputs,
                logo: {
                    file: input.files[0],
                    error: null,
                },
            });
        }
        else {
            setInputs({
                ...inputs,
                logo: {
                    file: null,
                    error: null,
                },
            });
        }
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let { name, description, category, email, phone, website, logo } = inputs;
        let error = false;

        if (!name.value) {
            error = true;
            name.error = "";
        }

        if (!description.value) {
            error = true;
            description.error = "";
        }

        if (!category.value) {
            error = true;
            category.error = "";
        }

        if (!email.value) {
            error = true;
            email.error = "";
        }
        else if (!isValidEmail(email.value)) {
            error = true;
            email.error = t("invalid_email", { ns: Namespaces.commons });
        }

        if (!logo.file) {
            error = true;
            const errorString = t("partner.logo_required", { ns: Namespaces.forms }).toString();
            logo.error = errorString;
            alert(errorString);
        }

        if (error) {
            setInputs({
                ...inputs,
                name: name,
                description: description,
                category: category,
                email: email,
                logo: logo,
            });
        }
        else if (logo.file) {
            // logFormData(partnerData);
            dispatch(PartnersMethods.create({
                name: name.value,
                description: description.value,
                category: category.value,
                email: email.value,
                phone: phone.value,
                website: website.value,
            }, logo.file))
                .then((partnerAdded) => {
                    // redirect after partner successfully added
                    if (partnerAdded) {
                        setInputs(initialInputs);
                        navigate("/partner");
                    }
                });
        }
    }

    const { name, description, category, email, phone, website, logo } = inputs;
    const formIsValid = name.value && description.value && category.value && email.value && logo.file;

    return (
        <Container>
            <Typography variant="h1">
                {t("add_partner", { ns: Namespaces.titles })}
            </Typography>

            <form
                method="post"
                action="#"
                onSubmit={(event) => handleSubmitPressed(event)} >
                <div>
                    <TextField
                        required
                        id="name"
                        label={t("partner.name", { ns: Namespaces.forms })}
                        value={name.value}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            onChange: (event) => { handleInputChange('name', event.target.value) }
                        }}
                        error={Boolean(name.error)}
                        helperText={name.error}
                    />
                </div>

                <Box maxWidth="initial">
                    <TextField
                        required
                        id="description"
                        margin="normal"
                        multiline
                        rows={3}
                        value={description.value}
                        variant="outlined"
                        label={t("partner.description", { ns: Namespaces.forms })}
                        placeholder="Description du partenaire, vision, produits..."
                        InputProps={{
                            onChange: (event) => handleInputChange('description', event.target.value),
                        }}
                        error={Boolean(description.error)}
                        helperText={description.error}
                        sx={{ maxWidth: "initial" }}
                    />
                </Box>

                <Box>
                    <TextField
                        required
                        id="category"
                        label={t("partner.category", { ns: Namespaces.forms })}
                        value={category.value}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            onChange: (event) => { handleInputChange('category', event.target.value) }
                        }}
                        error={Boolean(category.error)}
                        helperText={category.error}
                    />
                </Box>

                <Box>
                    <TextField
                        required
                        id="email"
                        label={t("partner.email", { ns: Namespaces.forms })}
                        value={email.value}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            onChange: (event) => { handleInputChange('email', event.target.value) }
                        }}
                        error={Boolean(email.error)}
                        helperText={email.error}
                    />
                </Box>

                <Box>
                    <InputMask
                        mask="99 99 99 99 99"
                        value={phone.value}
                        onChange={(event: any) => { handleInputChange('phone', event.target.value) }}
                    >
                        {
                            () => (
                                <TextField
                                    id="phone"
                                    label={t("partner.phone", { ns: Namespaces.forms })}
                                    value={phone.value}
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{}}
                                    error={Boolean(phone.error)}
                                    helperText={phone.error}
                                />
                            )
                        }
                    </InputMask>
                </Box>

                <Box>
                    <TextField
                        id="website"
                        label={t("partner.website", { ns: Namespaces.forms })}
                        value={website.value}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            onChange: (event) => { handleInputChange('website', event.target.value) }
                        }}
                        error={Boolean(website.error)}
                        helperText={website.error}
                    />
                </Box>

                <Box>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                    >
                        {logo.file ?
                            logo.file.name
                            :
                            `${t("partner.pick_logo", { ns: Namespaces.forms })} *`
                        }
                        <input
                            id="logo"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(event) => { handleLogoSelected(event.target) }}
                        />
                    </Button>
                </Box>

                <Box
                    textAlign="center"
                    mt={4}
                >
                    <ActionButton
                        color="primary"
                        disabled={!formIsValid}
                        loading={loading}
                        type="submit"
                    >
                        {t("save", { ns: Namespaces.actions })}
                    </ActionButton>
                </Box>
            </form>
        </Container >
    )
}

export default AddPartnerPage;
