import { red, pink, purple, indigo, blue, cyan, teal, green, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey, deepPurple, } from "@mui/material/colors";

export enum TrashType {
    // sortable waste
    LIGHT_PET_BOTTLE = "light_PET_bottle",
    DARK_PET_BOTTLE = "dark_PET_bottle",
    OPAQUE_PET_BOTTLE = "opaque_PET_bottle",
    LIQUID_FOOD_PACKAGING = "liquid_food_packaging",
    PLASTIC_POT = "plastic_pot",
    PLASTIC_FILM = "plastic_film",
    BROWN_CARDBOARD = "brown_cardboard",
    PRINTED_CARDBOARD = "printed_cardboard",
    GROSS_STORE_PAPER = "gross_store_paper",
    PAPER = "paper",
    METAL = "metal",
    
    // sorting mistakes
    GLASS = "glass",
    MASK = "mask",
    COLLECTION_BAG = "collection_bag",
    NESTED_ITEMS = "nested_items",
    FOOD_LEFTOVERS = "food_leftovers",
    ELECTRONIC_WASTE = "electronic_waste",
    OTHER = "other",
}

export const NOT_ACCEPTED_TRASH = [
    TrashType.FOOD_LEFTOVERS,
    TrashType.OTHER,
    TrashType.MASK,
    TrashType.COLLECTION_BAG,
    TrashType.GLASS,
    TrashType.ELECTRONIC_WASTE,
    TrashType.NESTED_ITEMS,
];

export const TrashColors = {
    [TrashType.LIGHT_PET_BOTTLE]: red[500],
    [TrashType.DARK_PET_BOTTLE]: pink[500],
    [TrashType.OPAQUE_PET_BOTTLE]: purple[500],
    [TrashType.LIQUID_FOOD_PACKAGING]: deepPurple[500],
    [TrashType.PLASTIC_POT]: indigo[500],
    [TrashType.PLASTIC_FILM]: blue[500],
    [TrashType.BROWN_CARDBOARD]: cyan[500],
    [TrashType.PRINTED_CARDBOARD]: blueGrey[500],
    [TrashType.GROSS_STORE_PAPER]: teal[500],
    [TrashType.PAPER]: green[500],
    [TrashType.METAL]: lime[500],
    [TrashType.GLASS]: yellow[500],
    [TrashType.MASK]: amber[500],
    [TrashType.COLLECTION_BAG]: orange[500],
    [TrashType.NESTED_ITEMS]: deepOrange[500],
    [TrashType.FOOD_LEFTOVERS]: brown[500],
    [TrashType.ELECTRONIC_WASTE]: grey[300],
    [TrashType.OTHER]: grey[700],
}