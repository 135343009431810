import * as React from "react";
import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import User from 'models/User';
import SectionLoader from '../../_include/SectionLoader';
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { Link } from "react-router-dom";

interface UsersListProps {
    users: User[];
    loading: boolean;
}

function UsersList(props: UsersListProps) {

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.glossary]);

    const { users, loading, } = props;

    if (loading) {
        return (<SectionLoader />);
    }

    return (
        <TableContainer
            component={Paper}
            sx={{
                bottom: 0,
                maxWidth: theme => `calc(100% - ${theme.spacing(6)}px)`
            }}
        >
            <Table
                stickyHeader
                sx={{
                    minWidth: 500,
                }}
            >
                <TableHead>
                    <TableRow sx={{
                        "& th": {
                            backgroundColor: theme => theme.palette.primary.main,
                            color: 'white',
                        }
                    }}>
                        <TableCell>{t("rank", { ns: Namespaces.glossary })}</TableCell>
                        <TableCell align="center">{t("name", { ns: Namespaces.commons })}</TableCell>
                        <TableCell align="center">{t("email", { ns: Namespaces.glossary })}</TableCell>
                        <TableCell align="center">{t("score", { ns: Namespaces.glossary })}</TableCell>
                        <TableCell align="center">{t("cocon_pin", { ns: Namespaces.glossary })}</TableCell>
                        <TableCell align="center">{t("number_of_batches", { ns: Namespaces.glossary })}</TableCell>
                        <TableCell align="center">{t("actions", { ns: Namespaces.actions })}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, index) => (
                        <TableRow key={user.id}>
                            <TableCell component="th" scope="row">
                                {index + 1}
                            </TableCell>
                            <TableCell align="center">{user.firstName} {user.lastName}</TableCell>
                            <TableCell align="center">{user.email}</TableCell>
                            <TableCell align="center">{user.score}</TableCell>
                            <TableCell align="center">{user.pin}</TableCell>
                            <TableCell align="center">{user.batchesCount}</TableCell>
                            <TableCell align='center'>
                                <Link to={user.id}>
                                    <Button variant="contained" color="primary">
                                        {t("details", { ns: Namespaces.actions })}
                                    </Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default UsersList;
