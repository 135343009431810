import { useEffect, } from 'react';
import { Typography, Container, } from '@mui/material';
import Reward, { NewRewardData, RewardsMethods } from 'models/Reward';
import RewardForm from '../RewardForm';
import { useParams } from 'react-router-dom'
import SectionLoader from 'components/_include/SectionLoader';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';

type EditRewardPageProps = {

}

function EditRewardPage(props: EditRewardPageProps) {

    const { loading, error, reward, } = useAppSelector(state => {
        const selectedReward = state.rewards.selected;
        return {
            loading: selectedReward.loading,
            error: selectedReward.error,
            reward: selectedReward.data,
        };
    });
    const dispatch = useAppDispatch();

    const { rewardId } = useParams();

    const navigate = useNavigate();

    const { t } = useTranslation([Namespaces.titles]);

    useEffect(() => {
        // load reward to be edited
        if (rewardId) {
            dispatch(RewardsMethods.retrieve(rewardId));
        }
    }, [rewardId]);

    if (loading || !reward) {
        return (
            <Container>
                <Typography variant="h1">
                    {t("edit_reward", { ns: Namespaces.titles })}
                </Typography>

                <SectionLoader />
            </Container>
        );
    }

    const handleSubmit = (data: NewRewardData, image?: File, promoCodes?: string[]) => {
        dispatch(RewardsMethods.update(reward, data, image))
            .then(() => {
                navigate(`/reward`);
            });
    }

    const initialData = {
        partnerId: reward.partner.id,
        name: reward.name,
        description: reward.description,
        quantity: reward.quantity,
        expiryDate: reward.expiryDate,
        category: reward.category,
        cost: reward.cost,
        realPrice: reward.realPrice,
        promoCode: reward.promoCode || "",
        referralLink: reward.referralLink || "",
        image: null,
        imageURL: reward.imageURL,
        exclusiveCoconsClusters: reward.exclusiveCoconsClusters
    };

    return (
        <Container>
            <Typography variant="h1">
                {t("edit_reward", { ns: Namespaces.titles })}
            </Typography>

            <RewardForm
                type="edit"
                loading={loading}
                rewardData={initialData}
                submitForm={handleSubmit}
                />
        </Container>
    )
}

export default EditRewardPage;