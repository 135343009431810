import moment from 'moment';

export function getCurrentTimestamp() {
    return Number(moment().format('x'));
}

interface DisplayDateOptions {
    day?: boolean;
    month?: boolean;
    year?: boolean;
    time?: boolean;
}

export function getDisplayableFrenchDate(timestamp: number, displayOptions: DisplayDateOptions = {}) {
    const options = {
        day: displayOptions.day !== undefined ? displayOptions.day : true, 
        month: displayOptions.month !== undefined ? displayOptions.month : true, 
        year: displayOptions.year !== undefined ? displayOptions.year : true, 
        time: displayOptions.time !== undefined ? displayOptions.time : false, 
    };

    // les noms de jours / mois
    var mois = new Array("janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre");
    // on recupere la date
    var date = new Date(timestamp);
    // on construit le message
    let message = "";
    if (options.day) {
        message += date.getDate() + " ";   // numero du jour
    }
    if (options.month) {
        message += mois[date.getMonth()] + " ";   // mois
    }
    if (options.year) {
        message += date.getFullYear();
    }

    if (options.time) {
        message += ` à ${date.getHours()}:${date.getMinutes()}`;
    }
    
    return message;
}

export enum DATE_SELECT_TYPES {
    TODAY = "today",
    YESTERDAY = "yesterday",
    THIS_WEEK = "this_week",
    LAST_WEEK = "last_week",
    LAST_TWO_WEEKS = "last_two_weeks",
    THIS_MONTH = "this_month",
    LAST_MONTH = "last_month",
    LAST_THREE_MONTHS = "last_three_months",
    THIS_YEAR = "this_year",
    SINCE_INSTALLATION = "since_installation",
    CUSTOM = "custom",
}

export type DatesInterval = {
    start: moment.Moment | null;
    end: moment.Moment | null;
};

export function getDatesFromRange(range: DATE_SELECT_TYPES, dateDeployed?: moment.Moment): DatesInterval {
    let start: moment.Moment | null = null, end: moment.Moment | null = null;
    switch (range) {
        case DATE_SELECT_TYPES.TODAY:
            start = moment().startOf("day");
            end = moment().endOf("day");
            break;

        case DATE_SELECT_TYPES.YESTERDAY:
            start = moment().subtract(1, "day").startOf("day");
            end = moment().subtract(1, "day").endOf("day");
            break;

        case DATE_SELECT_TYPES.THIS_WEEK:
            start = moment().startOf("week");
            end = moment().endOf("week");
            break;

        case DATE_SELECT_TYPES.LAST_WEEK:
            start = moment().subtract(1, "week").startOf("week");
            end = moment().subtract(1, "week").endOf("week");
            break;

        case DATE_SELECT_TYPES.LAST_TWO_WEEKS:
            start = moment().subtract(1, "week").startOf("week");
            end = moment().endOf("week");
            break;

        case DATE_SELECT_TYPES.THIS_MONTH:
            start = moment().startOf("month");
            end = moment().endOf("month");
            break;

        case DATE_SELECT_TYPES.LAST_MONTH:
            start = moment().subtract(1, "month").startOf("month");
            end = moment().subtract(1, "month").endOf("month");
            break;

        case DATE_SELECT_TYPES.LAST_THREE_MONTHS:
            start = moment().subtract(3, "month").endOf("week");
            end = moment().endOf("week");
            break;

        case DATE_SELECT_TYPES.THIS_YEAR:
            start = moment().startOf("year");
            end = moment().endOf("year");
            break;

        case DATE_SELECT_TYPES.SINCE_INSTALLATION:
            start = dateDeployed ? dateDeployed.startOf("day") : null;
            end = moment().endOf("day");
            break;

        case DATE_SELECT_TYPES.CUSTOM:
            break;
    }

    return { start: start, end: end };
}