import { List } from "@mui/material";
import LastMessage from "models/LastMessage";
import { useAppSelector } from "store/hooks";
import UserChatItem from "./UserChatItem";

interface UsersChatListProps {
    lastMessages: LastMessage[];
}

function UsersChatList(props: UsersChatListProps) {
    const { lastMessages, } = props;

    const { data: selectedUser } = useAppSelector(state => state.users.selectedUser);

    return (
        <List sx={{
            padding: 0,
            width: "100%",
            overflowY: "scroll",
            bottom: 0,
        }}>
            {lastMessages.map((lastMessage) => {
                const selected = selectedUser?.id === lastMessage.user.id;
                return (
                    <UserChatItem
                        key={lastMessage.user.id}
                        user={lastMessage.user}
                        message={lastMessage.message.message}
                        read={lastMessage.read}
                        selected={selected}
                    />
                )
            })}
        </List>
    );
}

export default UsersChatList;