import { Paper, Grid, useTheme } from "@mui/material";
import _ from "lodash";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import SectionLoader from "components/_include/SectionLoader";
import DemoBatchImage from "./DemoBatchImage";

type DemoBatchCardProps = {

};

function DemoBatchCard({ }: DemoBatchCardProps) {

    const { t } = useTranslation([Namespaces.actions]);

    const theme = useTheme();

    const imageURL = useAppSelector(state => state.batches.selected.data?.imageURL);

    if (!imageURL) return <SectionLoader />;

    return (
        // <Paper
        //     sx={{
        //         flex: 1,
        //         margin: 1.25,
        //         border: `1px solid ${theme.palette.primary.main}`,
        //         overflow: "hidden",
        //     }}
        // >
            <Grid container>
                <Grid item xs={12} md={12}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <DemoBatchImage
                        imageURL={imageURL}
                    />
                </Grid>
            </Grid>
        // </Paper>
    );
};

export default DemoBatchCard;