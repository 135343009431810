import { Box, Typography } from "@mui/material";
import React from "react";

type ChartTitleProps = {
    title: string;
}

export default (props: ChartTitleProps) => {
    const { title } = props;

    return (
        <Box textAlign="center">
            <Typography variant="h6">
                {title}
            </Typography>
        </Box>
    );
};