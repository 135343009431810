import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Typography } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { Timestamp } from 'firebase/firestore';
import { Namespaces } from 'locales/translations';
import { ToAnnotateArchivesMethods } from 'models/Batches/ImagesToAnnotateArchive';
import moment from 'moment';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllToAnnotateArchives } from 'store/reducers/to_annotate';

type ElementTemplateProps = {
    open: boolean;
    onClose: () => void;
}

function ElementTemplate(props: ElementTemplateProps) {
    const { open, onClose, } = props;

    const { t } = useTranslation([Namespaces.glossary, Namespaces.stats, Namespaces.titles]);
    
    const formatDate = (datetime: Timestamp) => t("date_full_day", { ns: Namespaces.stats, date: moment(datetime.toDate()) });
    const formatDatetime = (datetime: Timestamp) => t("datetime_full_day", { ns: Namespaces.stats, datetime: moment(datetime.toDate()) });

    const loading = useAppSelector(state => state.toAnnotate.archives.loading);
    const archives = useAppSelector(state => selectAllToAnnotateArchives(state));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (open) { // refresh list of archives at each opening
            dispatch(ToAnnotateArchivesMethods.list());
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                {t("to_annotate.history", { ns: Namespaces.titles })}
            </DialogTitle>
            <DialogContent>
                <List>
                    {archives.map(archive => (
                        <ListItem
                            key={archive.id}
                        >
                            <ListItemText
                                primary={
                                    <Fragment>
                                        <Typography component="span" fontWeight="bold">{`${formatDatetime(archive.from)} - ${formatDatetime(archive.to)}`}</Typography>
                                        {` : ${t("file_count", { ns: Namespaces.glossary, count: archive.files.length })} - ${Math.round(archive.size / 100000) / 10}MB`}
                                    </Fragment>
                                }
                                secondary={`${t("downloaded_on", { ns: Namespaces.glossary })} ${formatDate(archive.addedAt)}`}
                                />
                        </ListItem>
                    ))}
                    {loading && (
                        <SectionLoader />
                    )}
                </List>
            </DialogContent>
        </Dialog>
    );
}

export default ElementTemplate;