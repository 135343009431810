import GoogleMapReact from 'google-map-react';
import { useState, useEffect } from 'react';
import { Coordinates } from 'helpers/geo';
import { DEFAULT_CENTER } from 'constants/geo';

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

interface PlaceMapProps {
    address: string;
    startAddressLookup: () => void;
    endAddressLookup: (coordinates: Coordinates | null) => void;
}

export function PlaceMap(props: PlaceMapProps) {

    const { address, startAddressLookup, endAddressLookup } = props;

    const [center, setCenter] = useState<{ lat: number, lng: number }>(DEFAULT_CENTER); // centered on Grenoble by default
    const [map, setMap] = useState<any>();
    const [mapsAPI, setMapsAPI] = useState<any>(null);
    const [geocoder, setGeocoder] = useState<any>();
    const [marker, setMarker] = useState<any>();

    useEffect(() => {
        if (address && geocoder) {
            geocodeAddress(address);
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            geocodeAddress(address);
        }
    }, [geocoder]);

    const geocodeAddress = (address: string) => {

        if (geocoder) {
            startAddressLookup();
            geocoder.geocode(
                {
                    address: address
                },
                (results: { geometry: { location: any; }; }[], status: string) => {
                    if (status === "OK") {
                        const latitude = results[0].geometry.location.lat();
                        const longitude = results[0].geometry.location.lng();
                        const newCenter = { lat: latitude, lng: longitude };
                        setCenter(newCenter);
                        marker?.setMap(null);
                        setMarker(new mapsAPI.Marker({
                            map: map,
                            position: newCenter
                        }));
                        endAddressLookup({
                            latitude: latitude,
                            longitude: longitude,
                        })
                    } else {
                        alert(
                            "Geocode was not successful for the following reason: " + status
                        );
                        endAddressLookup(null);
                    }
                }
            );
        }
    }

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: FIREBASE_CONFIG.apiKey }}
            center={center}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
                setMap(map);
                setMapsAPI(maps);
                setGeocoder(new maps.Geocoder());
            }}
        >
            {/* <MapMarker
                lat={center.lat}
                lng={center.lng} /> */}
        </GoogleMapReact>
    )
}