import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PartnerCompaniesMethods from "actions/partner_companies";
import { QueryFilter } from "constants/types";
import { Timestamp } from "firebase/firestore";
import { Namespaces } from "locales/translations";
import PartnerCompany from "models/PartnerCompany";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllPartnerCompanies } from "store/reducers/partnerCompanies";

type SelectProps = {
    value: string;
    parentLoading: boolean;
    noneSelectedLabel: string;
    required?: boolean;
    onChange: (value: PartnerCompany) => void;
}

export default function PartnerCompanySelect(props: SelectProps) {
    const { value, noneSelectedLabel, parentLoading, required, onChange } = props;

    const { t } = useTranslation([Namespaces.cocons, Namespaces.glossary, ]);

    const partnerCompanies = useAppSelector(state => selectAllPartnerCompanies(state));
    const partnerCompaniesLoading = useAppSelector(state => state.partnerCompanies.loading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // load list of partnerCompanies
        dispatch(PartnerCompaniesMethods.list());
    }, [dispatch]);

    const handleChange = (partnerCompanyId: string) => {
        const selectedCompany = partnerCompanies.find(c => c.id === partnerCompanyId);
        if (selectedCompany) onChange(selectedCompany);
    };

    const label = value ? t("props.managedBy", { ns: Namespaces.cocons }) : noneSelectedLabel;

    return (
        <FormControl
            variant="outlined"
            disabled={parentLoading || partnerCompaniesLoading}
            sx={{
                mt: 2,
            }}
            required={required}
        >
            <InputLabel 
                id="partner-company-select-label"
                sx={!value ? { fontStyle: "italic", overflow: "visible" } : {}}
                >
                {label}
            </InputLabel>
            <Select
                id="partner-company-select"
                labelId="partner-company-select-label"
                label={label}
                value={value}
                onChange={(event) => handleChange(event.target.value as string)}
                required={required}
            >
                {!value && (
                    <MenuItem
                        value=""
                        sx={{ fontStyle: "italic" }}
                    >
                        {noneSelectedLabel}
                    </MenuItem>
                )}
                {partnerCompanies.map(partnerCompany => (
                    <MenuItem
                        value={partnerCompany.id}
                        key={partnerCompany.id}
                    >
                        {partnerCompany.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}