import { User as FirebaseUser } from "firebase/auth";
import { CoconManager } from "models/Cocons/Cocon";
import { DataContext } from "store/store";
import User from "models/User";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timestamp } from "firebase/firestore";

export type SerializedUser = Omit<User, "dateJoined"> & {
    dateJoined: number;
};

function serialize(user: User): SerializedUser;
function serialize(user: Partial<User>): Partial<SerializedUser>;
function serialize(user: User | Partial<User>) {
    return {
        ...user,
        dateJoined: user.dateJoined?.toMillis(),
    };
}

export function deserializeUser(user: SerializedUser): User;
export function deserializeUser(user: Partial<SerializedUser>): Partial<User>;
export function deserializeUser(user: SerializedUser | Partial<SerializedUser>) {
    return {
        ...user,
        dateJoined: user.dateJoined ? Timestamp.fromMillis(user.dateJoined) : undefined,
    };
}

export type UsersContext = {
    user: DataContext<FirebaseUser | null>;
    selectedUser: DataContext<User | null>;
    users: DataContext<User[]>;
    managers: DataContext<CoconManager[]>;
};

const initialState: UsersContext = {
    user: {
        data: null,
        loading: false,
        error: null,
    },
    selectedUser: {
        data: null,
        loading: false,
        error: null,
    },
    users: {
        data: [],
        loading: false,
        error: null,
    },
    managers: {
        data: [],
        loading: false,
        error: null,
    },
};


export const usersSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        // sign in actions
        startSignIn: (state) => {
            state.user.loading = true;
            state.user.error = null;
        },
        setSignedIn: (state, { payload }: PayloadAction<FirebaseUser>) => {
            state.user.loading = false;
            state.user.data = payload;
        },
        setSignInError: (state, { payload }: PayloadAction<string>) => {
            state.user.loading = false;
            state.user.error = payload;
        },
        signOut: (state) => {
            state.user.data = null;
        },
        // select user actions
        startLoadingSelected: (state) => {
            state.selectedUser.loading = true;
            state.selectedUser.error = null;
        },
        setSelected: (state, { payload: user }: PayloadAction<User>) => {
            state.selectedUser.loading = false;
            state.selectedUser.data = user;
        },
        updateSelected: (state, { payload: data }: PayloadAction<Partial<User>>) => {
            if (state.selectedUser.data) {
                state.selectedUser.data = {
                    ...state.selectedUser.data,
                    ...data,
                };
            }
        },
        setSelectedError: (state, { payload }: PayloadAction<string>) => {
            state.selectedUser.loading = false;
            state.selectedUser.error = payload;
        },
        // list users actions
        startLoadingList: (state) => {
            state.users.loading = true;
            state.users.error = null;
        },
        setList: (state, { payload: users }: PayloadAction<User[]>) => {
            state.users.loading = false;
            state.users.data = users;
        },
        setListError: (state, { payload }: PayloadAction<string>) => {
            state.users.loading = false;
            state.users.error = payload;
        },
        // list managers actions
        startLoadingManagers: (state) => {
            state.managers.loading = true;
            state.managers.error = null;
        },
        setManagers: (state, action: PayloadAction<CoconManager[]>) => {
            state.managers.loading = false;
            state.managers.data = action.payload;
        },
        setManagersError: (state, { payload }: PayloadAction<string>) => {
            state.managers.loading = false;
            state.managers.error = payload;
        },
        // complete profile actions
        startLoadingLoggedOut: (state) => {
            state.user.loading = true;
            state.user.error = null;
        },
        doneLoadingLoggedOut: (state) => {
            state.user.loading = false;
        },
        setLoggedOutError: (state, { payload }: PayloadAction<string>) => {
            state.user.loading = false;
            state.user.error = payload;
        },
    },
});

export const UsersActions = usersSlice.actions;

const UsersReducer = usersSlice.reducer;

export default UsersReducer;