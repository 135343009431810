import { WhereFilterOp } from "firebase/firestore";

export type QueryFilters = {
    [key: string]: any,
};

export type QueryFilter = {
    fieldPath: string;
    opStr: WhereFilterOp;
    value: any;
}

export enum ChatTabTypes {
    MESSAGE = "message",
    CONVERSATION = "conversation",
};

export type ChatRecipients = {
    count: number,
    name: string,
};

export type Rect = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type Point = number[]; // 2 elements

export type Box = number[]; // 4 elements: (left, top, right, bottom)

export type Polygon = Point[][];

export type Dictionary = {
    [key: string]: any,
};