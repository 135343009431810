import { Box } from "@mui/material";
import { TrashType } from "constants/trash";
import BatchResultCountInput from "./BatchResultCountInput";

type BatchCardFormProps = {
    batchId: string;
}

/**
 * List of inputs to indicate how many instances of each class of waste
 * is present in a batch
 */
function BatchCardForm({ batchId }: BatchCardFormProps) {

    return (
        <Box 
            display="flex"
            flexDirection="column"
            gap={.5}
            m={2}
            flexGrow={1}
        >
            {Object.values(TrashType).map((trashType) => (
                <BatchResultCountInput
                    key={trashType}
                    batchId={batchId}
                    trashType={trashType}
                    />
            ))}
        </Box>
    )
};


export default BatchCardForm;
