import { Hidden, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Drawers from './Drawers';
import { useMatch } from 'react-router-dom';

const drawerWidth = 256;

function Navigator() {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const theme = useTheme();
    const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const isDemoRoute = useMatch('/batch/demo');

    if (isDemoRoute) return <></>;

    return (
        <nav style={isUpSm && !isDemoRoute ? {
            width: drawerWidth,
            flexShrink: 0,
        } : {}}>
            <Hidden smUp implementation="js">
                <Drawers
                    PaperProps={{style: {width: drawerWidth}}}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                />
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawers PaperProps={{style: {width: drawerWidth}}}/>
            </Hidden>
        </nav>
    );
}

export default Navigator;
