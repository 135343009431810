import { ResponsiveBar } from '@nivo/bar';
import React, { ReactElement } from 'react';
import { BarChartDataInterface, TimeUnit } from 'store/reducers/stats';
import { ChartsStrings, ColorsInterface, LegendsInterface } from 'constants/charts';
import { Namespaces } from 'locales/translations';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface BarChartProps {
    data: BarChartDataInterface[];
    colors: ColorsInterface;
    margin: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
    timeUnit: TimeUnit;
    legends: LegendsInterface;
    tooltip: (index: number, value: number, dates: moment.Moment[]) => ReactElement<any, any>;
};


function BarChart(props: BarChartProps) {
    const { data, colors, margin, timeUnit, legends, tooltip, } = props;

    const { t } = useTranslation([Namespaces.commons, Namespaces.stats]);

    const getColor = (line: any) => { return colors[line.id] };

    const dates = data.map(dataPerTimeUnit => {
        return dataPerTimeUnit[ChartsStrings.DATES];
    });

    const chartData = data.map(momentStat => {
        return {
            [ChartsStrings.DATES]: t(
                "date",
                {
                    ns: Namespaces.stats,
                    context: timeUnit,
                    date: momentStat[ChartsStrings.DATES],
                }
            ),
            [ChartsStrings.COUNT]: momentStat[ChartsStrings.COUNT],
        };
    });

    return (

        <ResponsiveBar
            data={chartData}
            keys={[ChartsStrings.COUNT]}
            indexBy={`${[ChartsStrings.DATES]}`}
            margin={margin}
            padding={0.3}
            colors={getColor}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 8,
                tickPadding: 8,
                tickRotation: -45,
                legend: t(timeUnit, { ns: Namespaces.commons }),
                legendOffset: 80,
                legendPosition: 'middle'

            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: legends.axisLeft,
                legendPosition: 'middle',
                legendOffset: -48,
            }}
            gridYValues={5}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            animate={true}
            tooltip={({ index, value }) => tooltip(index, value ,dates)}
        />
    );
}

export default BarChart;