import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { LoadingButton } from "@mui/lab";

type SendUserNotificationBtnProps = {
    checked: boolean;
    onConfirm: () => Promise<void>; // Added a callback for when the confirmation button is clicked
    loading: boolean;
}

function SendUserNotificationBtn({ checked, onConfirm, loading }: SendUserNotificationBtnProps) {
    const { t } = useTranslation([Namespaces.actions, Namespaces.cocons, Namespaces.forms]);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setConfirmDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirm = () => {
        onConfirm();
        handleCloseDialog();
    };

    return (
        <>
            <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
                loading={loading}
                sx={{ mt: 2 }}
            >
                <span>{t("cocon.notify_users_about_cocon_status", { ns: Namespaces.forms })}</span>
            </LoadingButton>

            <Dialog open={confirmDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>
                    {t(`cocon.in_maintenance.dialog.${checked ? "false" : "true"}.title`, { ns: Namespaces.forms })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`cocon.in_maintenance.dialog.${checked ? "false" : "true"}.body`, { ns: Namespaces.forms })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        color="info"
                        variant="contained"
                    >
                        {t("cancel", { ns: Namespaces.actions })}
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        color="error"
                        variant="contained"
                    >
                        {t("confirm", { ns: Namespaces.actions })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SendUserNotificationBtn;
