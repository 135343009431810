import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import UsersChatList from "./UsersChatList";
import { Namespaces } from "locales/translations";
import LastMessage, { LastMessagesMethods } from "models/LastMessage";
import SectionLoader from "components/_include/SectionLoader";
import { useSearchParams } from "react-router-dom";
import SearchBar from "components/_include/SearchBar";
import { useAppDispatch, useAppSelector } from "store/hooks";

type ChatUsersNavProps = {

}

function ChatUsersNav(props: ChatUsersNavProps) {
    const { t } = useTranslation([Namespaces.actions, Namespaces.titles]);

    const {  } = props;

    const { lastMessages, lastMessagesLoading } = useAppSelector(state => {
        const lastMessages = state.messages.lastMessages;
        return {
            lastMessages: lastMessages.data,
            lastMessagesLoading: lastMessages.loading,
        };
    });
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState("");
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        setSearchTimeout(
            setTimeout(function () {
                dispatch(LastMessagesMethods.list(search));
            }, 300)
        );
    }, [search]);

    return (
        <Box
            boxShadow={"0 4px 4px rgba(0,0,0,0.2)"}
            position="relative"
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box
                bgcolor={(theme) => theme.palette.primary.main}
                padding={2}
            >
                <Typography
                    variant="h1"
                    color="#ffffff"
                >
                    {t("chats", { ns: Namespaces.titles })}
                </Typography>
                <SearchBar
                    placeholder={t("search_user", { ns: Namespaces.actions })}
                    value={search}
                    onChange={setSearch}
                    />
            </Box>
            {lastMessagesLoading ?
                <SectionLoader />
                :
                <UsersChatList
                    lastMessages={lastMessages}
                    />
            }
        </Box>
    );
}

export default ChatUsersNav;