import { Box, Slider, Typography } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BatchesActions, selectBatchById } from "store/reducers/batches/batch";

type BatchImageZoomSliderProps = {
    batchId: string;
}

function BatchImageZoomSlider({ batchId }: BatchImageZoomSliderProps) {

    const { t } = useTranslation([Namespaces.commons]);

    const zoomLevel = useAppSelector(state => selectBatchById(state, batchId)!.zoomLevel || 1);

    const dispatch = useAppDispatch();

    /**
     * Change the zoom value in the Redux store when the slider moves
     */
    const handleZoomChange = (value: number) => {
        dispatch(BatchesActions.updateZoomLevel({ batchId, zoomLevel: value }));
    }

    return (
        <Box
            textAlign="center"
            width="100%"
            position="relative"
        >
            <Typography fontWeight="bold">{t("zoom", { ns: Namespaces.commons })}</Typography>
            <Slider
                value={zoomLevel}
                valueLabelDisplay="auto"
                min={1}
                max={3}
                onChange={(e, v) => handleZoomChange(v as number)}
                sx={{
                    maxWidth: theme => theme.spacing(40),
                    width: "50%",
                }}
                step={0.1}
                valueLabelFormat={(value) => `x ${value}`}
            />
        </Box>
    );
}

export default BatchImageZoomSlider;