import React from "react";
import { Slider as MuiSlider, SliderProps } from "@mui/material";

function Slider(props: SliderProps) {
    return (
        <MuiSlider
            {...props}
            componentsProps={{
                track: {
                    style: {
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "none",
                        borderRight: "2px solid white",
                    }
                },
                thumb: {
                    style: {
                        transform: "translate(-42%, -50%)",
                        cursor: "grab",
                    }
                },
                rail: {
                    style: {
                        opacity: 0,
                    },
                },
            }}
            sx={{
                color: "white",
                borderRadius: 0,
                position: "absolute",
                appearance: "none",
                width: "100%",
                height: "100%",
                outline: "none",
                margin: 0,
            }}
        />
    )
}

export default Slider;