import React from "react";
import { DatePicker, DatePickerProps, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { SxProps, TextField, Theme } from "@mui/material";

type PickerProps = Omit<DatePickerProps, "onChange" | "renderInput"> & {
    inputStyle?: SxProps<Theme>;
    onChange: (date: moment.Moment | null) => void;
}

function Datepicker(props: PickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                {...props}
                onChange={(date) => { props.onChange(date as moment.Moment | null) }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        helperText={params?.inputProps?.placeholder}
                        sx={{
                            "& input": {
                                cursor: "pointer",
                            },
                            ...props.inputStyle,
                        }} />
                )}
            />
        </LocalizationProvider>
    );
}

export default Datepicker;