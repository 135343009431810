import { CodeError } from "actions/actions";
import { Collections, DBIdentifiers } from "constants/db";
import { QueryFilter } from "constants/types";
import { getFirestore, writeBatch, collection, Timestamp, doc, WriteBatch } from "firebase/firestore";
import { createDocument, deleteDocument, getDocumentReference, listDocs, retrieveDocument, updateDocument } from "helpers/db";
import { StorageType, uploadFile } from "helpers/storage";
import { normalizeFilename } from "helpers/strings";
import _ from "lodash";
import { RewardsActions } from "store/reducers/reward";
import { showError, showSuccess } from "store/reducers/snacks";
import { AppDispatch } from "store/store";
import { PartnerDbData } from "./Partner";

export const INFINITY_QUANTITY = 999999;

export enum RewardCategory {
    E_COMMERCE = "e-commerce",
    PHYSICAL = "physical",
    SENT_BY_FICHA = "sent_by_ficha",
    LOTTRI = "lottri",
}

type PartnerData = Pick<PartnerDbData, "name" | "website" | "imageURL"> & {
    id: string;
};

type PromoCode = {
    value: string;
    dateAdded: Timestamp;
    used: boolean;
}

export type RewardDbData = {
    name: string;
    description: string;
    category: RewardCategory;
    tags?: string[];
    imageURL: string;
    cost: number;
    realPrice?: number;
    partner: PartnerData;
    promoCode?: string;
    singleUsePromoCodes?: boolean;
    quantity?: number;
    expiryDate?: Timestamp;
    referralLink?: string;
    dateAdded: Timestamp;
    exclusiveCoconsClusters: string[];
}

export type NewRewardData = Omit<RewardDbData, "imageURL" | "dateAdded">;

type Reward = DBIdentifiers & RewardDbData;

const COLLECTION = Collections.REWARDS;

function getDocRef(id: string) {
    return getDocumentReference(id, COLLECTION);
}

const list = (filters: QueryFilter[]) => async (dispatch: AppDispatch) => {
    dispatch(RewardsActions.startLoadingList());

    try {
        const rewards = await listDocs<Reward>(COLLECTION, filters, { fieldPath: "partner.name" });
        dispatch(RewardsActions.setList(rewards));
        return rewards;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(RewardsActions.setListError(error.message));
        return [];
    }
}

const create = (data: NewRewardData, image: File, promoCodes?: string[]) => async (dispatch: AppDispatch) => {
    dispatch(RewardsActions.startLoadingSelected());

    try {
        // upload reward image in "[...]/<partner_name>/rewards/<reward_name>.<extension>"
        const normalizedPartnerName = normalizeFilename(data.partner.name);
        const normalizedRewardName = normalizeFilename(data.name);
        const fileExtension = image.name.split('.').pop();
        const imageFullPath = `${normalizedPartnerName}/rewards/${normalizedRewardName}.${fileExtension}`;
        const imageURL = await uploadFile(image, StorageType.PARTNERS, imageFullPath);

        // save reward data
        let rewardData: RewardDbData = {
            ...data,
            imageURL: imageURL,
            dateAdded: Timestamp.now(),
        };
        if (promoCodes && promoCodes.length > 0) {
            // set default promo code
            rewardData.promoCode = promoCodes[0];
            rewardData.singleUsePromoCodes = true;
        }

        const reward = await createDocument<Reward>(COLLECTION, rewardData);

        if (promoCodes && promoCodes.length > 0) {
            // add promo codes subcollection
            const rewardRef = getDocRef(reward.id);
            const codesSubcollection = collection(rewardRef, Collections.PROMO_CODES);
            const now = Timestamp.now();

            const promoCodesChunks = _.chunk(promoCodes, 500); // max batch write of 500 items
            for (const promoCodesChunk of promoCodesChunks) {
                const batchInsert: WriteBatch = writeBatch(getFirestore());
                promoCodesChunk.forEach((code: string) => {
                    const promoCode: PromoCode = {
                        value: code,
                        dateAdded: now,
                        used: false,
                    };
                    batchInsert.set(doc(codesSubcollection, code), promoCode);
                });
                await batchInsert.commit();
            }
        }

        dispatch(RewardsActions.setSelected(reward));

        // display success snackbar
        dispatch(showSuccess(`La récompense '${reward.name}' a bien été ajoutée!`));

        return reward;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(RewardsActions.setSelectedError(error.message));

        // display error snackbar
        dispatch(showError(error.message));

        return null;
    }
}

const retrieve = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(RewardsActions.startLoadingSelected());

    try {
        const reward = await retrieveDocument<Reward>(COLLECTION, id);
        dispatch(RewardsActions.setSelected(reward));
        return reward;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(RewardsActions.setSelectedError(error.message));
        return null;
    }
}

const update = (oldReward: Reward, data: Partial<RewardDbData>, image?: File) => async (dispatch: AppDispatch) => {
    dispatch(RewardsActions.startLoadingSelected());

    try {
        if (image) { // upload updated image
            const normalizedPartnerName = normalizeFilename(oldReward.partner.name);
            const normalizedRewardName = normalizeFilename(oldReward.name);
            const fileExtension = image.name.split('.').pop();
            const imageFullPath = `${normalizedPartnerName}/rewards/${normalizedRewardName}.${fileExtension}`;
            const imageURL = await uploadFile(image, StorageType.PARTNERS, imageFullPath);
            if (imageURL) {
                data.imageURL = imageURL;
            } else {
                throw new Error("Failed to upload image and retrieve its URL.");
            }
        }

        // update Firestore document
        const rewardRef = getDocRef(oldReward.id);
        const reward = await updateDocument(rewardRef, oldReward, data);

        // update state
        dispatch(RewardsActions.setSelected(reward));
        dispatch(RewardsActions.updateListItem(reward));

        // display success message
        dispatch(showSuccess(`La récompense '${reward.name}' a bien été modifiée !`));
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(RewardsActions.setSelectedError(error.message));

        // display error message
        dispatch(showError(error.message));
    }

    return this;
}

const deleteReward = (reward: Reward) => async (dispatch: AppDispatch) => {
    dispatch(RewardsActions.startLoadingSelected());

    try {
        // delete Firestore document
        await deleteDocument(getDocRef(reward.id));

        // update state
        dispatch(RewardsActions.deleteSelected(reward));

        // display success message
        dispatch(showSuccess(`La récompense '${reward.name}' a bien été supprimée !`));
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(RewardsActions.setSelectedError(error.message));

        // display error message
        dispatch(showError(error.message));
    }
}

export default Reward;

export const RewardsMethods = {
    list,
    create,
    retrieve,
    update,
    deleteReward,
}