import { CodeError, fetchAPI } from "actions/actions";
import { Collections, DBIdentifiers } from "constants/db";
import urls from "constants/urls";
import { Timestamp } from "firebase/firestore";
import { getDocumentReference, listDocs, updateDocument } from "helpers/db";
import { MessagesActions } from "store/reducers/message";
import { AppDispatch } from "store/store";
import { APIMessageData, MessageDbData } from "./Message";

export type LastMessageUserData = {
    id: string;
    pin: number;
    coconName: string;
    email?: string;
    firstName?: string;
    lastName?: string;
}

type MessageData = MessageDbData;

export type LastMessageDbData = {
    user: LastMessageUserData;
    message: MessageData;
    read: boolean; // true if unread, false if read
}

type APILastMessageData = Omit<LastMessageDbData, "message"> & {
    id: string;
    message: APIMessageData;
}

type LastMessage = LastMessageDbData & DBIdentifiers;

const COLLECTION = Collections.LAST_MESSAGES;

function getDocRef(id: string) {
    return getDocumentReference(id, COLLECTION);
}

function fromAPIData(lastMessageData: APILastMessageData): LastMessage {
    return {
        ...lastMessageData,
        message: {
            ...lastMessageData.message,
            timestamp: Timestamp.fromMillis(lastMessageData.message.timestamp),
        },
        // ref: getDocRef(lastMessageData.id),
    };
}

const list = (search: string) => async (dispatch: AppDispatch) => {
    dispatch(MessagesActions.startLoadingLastMessages());

    try {
        const lastMessagesData: APILastMessageData[] = await fetchAPI(`${urls.API}/message/last?search=${search}`);
        const messages = lastMessagesData.map(messageData => fromAPIData(messageData));
        dispatch(MessagesActions.setLastMessagesList(messages));
        return messages;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(MessagesActions.setLastMessagesError(error.message));
        return [];
    }
}

const getUserLastMessage = async (userId: string) => {
    const lastMessages = await listDocs<LastMessage>(
        COLLECTION,
        [{ fieldPath: "user.id", opStr: "==", value: userId, }], 
        { fieldPath: "timestamp", directionStr: "desc" },
        1
    );
    if (lastMessages.length > 0) return lastMessages[0];
    return null;
}

const setRead = async (lastMessage: LastMessage, dispatch: AppDispatch) => {
    dispatch(MessagesActions.setLastMessageRead(lastMessage));
    return await updateDocument(getDocRef(lastMessage.id), lastMessage, { read: true });
}

export default LastMessage;

export const LastMessagesMethods = {
    list,
    getUserLastMessage,
    setRead,
}