import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import PartnerCompany from "models/PartnerCompany";
import { LoadableContext, RootState } from "store/store";

const partnerCompaniesAdapter = createEntityAdapter<PartnerCompany>({
    selectId: c => c.id,
    sortComparer: (c1, c2) => c1.name.localeCompare(c2.name), // sort by name alphabetically
});

const initialState: LoadableContext = {
    loading: false,
    error: null,
};

const partnerCompaniesSlice = createSlice({
    name: "partner_companies",
    initialState: partnerCompaniesAdapter.getInitialState(initialState),
    reducers: {
        startLoading: (state) => {
            partnerCompaniesAdapter.removeAll(state);
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        setList: (state, { payload: partnerCompanies }: PayloadAction<PartnerCompany[]>) => {
            state.loading = false;
            partnerCompaniesAdapter.setAll(state, partnerCompanies);
        },
        setError: (state, { payload: errorMessage }: PayloadAction<string>) => {
            state.loading = false;
            state.error = errorMessage;
        }
    }
});

export const PartnerCompaniesActions = partnerCompaniesSlice.actions;

const PartnerCompaniesReducer =  partnerCompaniesSlice.reducer;

export const {
    selectAll: selectAllPartnerCompanies,
    selectIds: selectPartnerCompaniesIds,
} = partnerCompaniesAdapter.getSelectors((state: RootState) => state.partnerCompanies);

export default PartnerCompaniesReducer;