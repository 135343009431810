


import TrashCountDisplay from "components/Batches/Statistics/TrashCount/TrashCountDisplay";
import SectionLoader from "components/_include/SectionLoader";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { initTrashCount } from "helpers/trash";
import { selectAllBatches } from "store/reducers/batches/batch";

type TrashCountProps = {

}

function TrashCount(props: TrashCountProps) {
    const { } = props;

    const loading = useAppSelector(state => state.batches.list.loading);
    const batches = useAppSelector(state => selectAllBatches(state));

    if (loading) {
        return (
            <SectionLoader />
        );
    }

    let trashCount = initTrashCount();
    batches.forEach(batch => {
        batch.result.forEach(trash => {
            trashCount[trash]++;
        });
    })

    return (
        <TrashCountDisplay trashCount={trashCount} />
    );
}

export default TrashCount;