import React, { MouseEvent } from 'react';
import { Box, Button, CircularProgress, SvgIcon, SxProps, Theme, useTheme, } from '@mui/material';

interface ActionButtonProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    children: any;
    color: "inherit" | "primary" | "secondary" | undefined;
    disabled?: boolean;
    loading?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    sx?: SxProps<Theme>;
    icon?: React.ReactNode;
    target?: "_blank";
    href?: string;
    download?: string;
}

function ActionButton(props: ActionButtonProps) {

    const { children, color, disabled, loading, sx, icon, type, href, target, download, onClick } = props;

    const theme = useTheme();

    return (
        <Box
            position="relative"
            >
            {href ? // link
                <Button         
                    color={color}
                    onClick={onClick}
                    variant="contained"
                    disabled={disabled || loading}
                    type={type}
                    href={href}
                    target="_blank"
                    download={download}
                    startIcon={icon}
                    sx={{
                        flex: 1,
                        ...sx,
                    }}
                >
                    {children}
                </Button>
                : // simple button
                <Button
                    color={color}
                    onClick={onClick}
                    variant="contained"
                    disabled={disabled || loading}
                    type={type}
                    startIcon={icon}
                    sx={{
                        flex: 1,
                        ...sx,
                    }}
                >
                    {children}
                </Button>
            }
            {loading ?
                <CircularProgress 
                    size={theme.spacing(3)}
                    sx={{
                        position: 'absolute',
                        top: (theme) => `calc(50% - ${theme.spacing(1.5)})`,
                        left: (theme) => `calc(50% - ${theme.spacing(1.5)})`,
                    }}
                />
                :
                null
            }
        </Box>
    );
}

export default ActionButton;