import { Container } from "@mui/material";
import SectionLoader from "../../_include/SectionLoader";
import { useAppSelector } from "store/hooks";
import BatchesToDoList from "./BatchesToDoList";
import BatchesToDoSortAndFilter from "./SortingAndFilters/BatchesToDoSortAndFilter";

type BatchesToDoPageProps = {}

/**
 * Page presenting the list of all of the batches that need to be validated
 */
function BatchesToDoPage(props: BatchesToDoPageProps) {
  const loading = useAppSelector(state => state.batches.list.loading);

  return (
    <Container
      sx={{
        px: 0,
        my: 0,
        maxHeight: "100vh",
        overflow: "hidden",
        gap: 4,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <BatchesToDoSortAndFilter 
        />
      {loading ? (
        <SectionLoader />
      ) : (
        <BatchesToDoList 
          />
      )}
    </Container>
  );
}

export default BatchesToDoPage;
