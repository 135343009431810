import { useAppDispatch, useAppSelector } from "store/hooks";
import SearchIcon from "@mui/icons-material/Search";
import { selectAllCocons } from "store/reducers/cocons/cocons";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { SelectedCoconActions } from "store/reducers/cocons/selected";
import { CoconsMapActions } from "store/reducers/cocons/map";

const WIDTH = 60; // theme.spacing(60) = 480

type CoconsSearchBarProps = {

}

function CoconsSearchBar(props: CoconsSearchBarProps) {
    const { } = props;

    const { t } = useTranslation([Namespaces.cocons]);

    const cocons = useAppSelector(state => selectAllCocons(state));
    const dispatch = useAppDispatch();

    const onSelect = (coconName: string | null) => {
        const cocon = cocons.find(c => c.name === coconName);
        if (!cocon) return;

        dispatch(SelectedCoconActions.setSelectedCocon(cocon)); // select cocon

        // zoom on cocon
        const coords = { lat: cocon.coordinates.latitude, lng: cocon.coordinates.longitude, };
        dispatch(CoconsMapActions.setMapBounds({
            center: coords,
            bounds: {
                nw: { lat: coords.lat + 0.001, lng: coords.lng - 0.001 },
                ne: { lat: coords.lat + 0.001, lng: coords.lng + 0.001 },
                se: { lat: coords.lat - 0.001, lng: coords.lng + 0.001 },
                sw: { lat: coords.lat - 0.001, lng: coords.lng - 0.001 },
            }
        }));
    };

    return (
        <Box
            position="absolute"
            left={theme => theme.spacing(2)}
            top={theme => theme.spacing(2)}
        >
            <Autocomplete
                options={cocons.map(cocon => cocon.name)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={t("search.placeholder", { ns: Namespaces.cocons })}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            sx: {
                                boxShadow: theme => theme.shadows[3],
                            }
                        }}
                        sx={{
                            maxWidth: "initial"
                        }}
                    />
                )}
                sx={{
                    width: theme => theme.spacing(WIDTH),
                }}
                noOptionsText={t("search.no_matching_cocon", { ns: Namespaces.cocons })}
                onChange={(e, value) => onSelect(value)}
            />
        </Box>
    );
}

export default CoconsSearchBar;