import { SyntheticEvent, useEffect, useState } from "react";
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { CoconManager } from "models/Cocons/Cocon";
import { isValidEmail } from "helpers/strings";
import { useAppDispatch, useAppSelector } from "store/hooks";
import User, { UsersMethods } from "models/User";

type ManagersInputProps = {
    managers: CoconManager[];
    onChange: (managers: CoconManager[]) => void;
}

function ManagersInput(props: ManagersInputProps) {
    const { managers, onChange } = props;

    const { data: matchingManagers } = useAppSelector(state => state.users.managers);
    const dispatch = useAppDispatch();
    
    const { t } = useTranslation([Namespaces.commons, Namespaces.glossary, Namespaces.forms]);

    const [text, setText] = useState("");
	const [autocompleteTimeout, setAutocompleteTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if (autocompleteTimeout) {
			clearTimeout(autocompleteTimeout);
		}

        setAutocompleteTimeout(
            setTimeout(function () {
                dispatch(UsersMethods.autocompleteManagers(text));
            }, 300)
        );
    }, [text]);
    
    const handleChange = (e: SyntheticEvent<Element, Event>, changedManagers: (string | CoconManager)[], reason: AutocompleteChangeReason) => {
        switch (reason) {
            case "createOption": // email entered manually
                const newEmail = changedManagers[changedManagers.length - 1] as string;

                // check if user not already added
                if (managers.find(manager => manager.email === newEmail)) {
                    return;
                }

                // check if a manager with this email already exists
                const existingMatching = matchingManagers.find(manager => manager.email === newEmail);
                if (existingMatching) {
                    onChange([...managers, existingMatching]);
                }
                else { // new manager
                    if (isValidEmail(newEmail)) {
                        const newManager: CoconManager = { email: newEmail };
                        onChange([...managers, newManager]);
                        setText("");
                    }
                    else { // invalid email
                        e.preventDefault();
                        alert(t("invalid_email", { ns: Namespaces.commons, email: newEmail }));
                        setText(newEmail);
                    }
                }
                
                break;

            case "selectOption": // existing manager
                onChange([...managers, changedManagers[changedManagers.length - 1] as CoconManager]);
                break;

            case "removeOption":
                onChange(changedManagers as CoconManager[]);
                break;

            case "clear":
                onChange([]);
                break;
        }
    }

    return (
        <Autocomplete
            multiple
            freeSolo
            value={managers}
            onChange={(e, emails, reason) => handleChange(e, emails, reason)}
            id="managers-input"
            options={matchingManagers}
            getOptionLabel={(manager) => manager.email}
            filterOptions={(matchingManagers) => matchingManagers.filter(manager => !managers.find(m => m.email === manager.email))}
            inputValue={text}
            onInputChange={(e, value) => setText(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("manager_plural", { ns: Namespaces.glossary })}
                    helperText={t("cocon.managers.instructions", { ns: Namespaces.forms })}
                    sx={{
                        maxWidth: "none"
                    }}
                />
            )}
            sx={{ 
                mt: 2,
                display: "inline-block",
                width: "100%",
            }}
        />
    );
}

export default ManagersInput;
