import { Box } from "@mui/material";
import { MessageAttachment, MessagesMethods } from "models/Message";
import { UsersMethods } from "models/User";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ChatInput from "./ChatInput";

type UserChatFooterProps = {

}

function UserChatFooter({ }: UserChatFooterProps) {
    
    const userId = useAppSelector(state => state.users.selectedUser.data?.id);
    const displayName = useAppSelector(state => {
        const selectedUser = state.users.selectedUser.data;
        return selectedUser ? UsersMethods.getDisplayName(selectedUser) : "N/A";
    });
    const sending = useAppSelector(state => state.messages.sendingMessage.loading);
    const messagesLoading = useAppSelector(state => state.messages.messages.loading);
    
    const dispatch = useAppDispatch();

    const onSendMessage = async (message: string, attachment?: MessageAttachment) => {
        await dispatch(MessagesMethods.send(userId!, message, attachment));
    }

    return (
        <Box
            px={3}
            position="absolute"
            bottom={0}
            left={0}
            right={0}
        >
            <Box
                position="relative"
                height="100%"
            >
                <Box
                    position="absolute"
                    bottom={8}
                    width="100%"
                >
                    <ChatInput
                        sending={sending}
                        disabled={messagesLoading || sending}
                        recipients={{
                            count: 1,
                            name: displayName,
                        }}
                        onSendPressed={onSendMessage}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default UserChatFooter;