import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, ButtonBase, ButtonBaseProps, InputBase, styled } from '@mui/material';

type NumberInputProps = {
    value: number;
    onChange: (value: number) => void;
    size: 'medium' | 'small';
    min?: number;
    max?: number;
}

const Button = styled(ButtonBase)<ButtonBaseProps>(({ theme }) => ({
    padding: theme.spacing(.25),
}));

const FONT_COLOR = "rgba(0,0,0,.7)";

/**
 * Numerical input with decrease button on the left and increase button on the right.
 */
export default function NumberInput({ value, onChange, min, max, size, }: NumberInputProps) {
    
    const handleIncrement = () => {
        if (max === undefined || value < max) onChange(value + 1);
    }

    const handleDecrement = () => {
        if (min === undefined || value > min) onChange(value - 1);
    }

    /**
     * Handle changing directly the input value with keyboard
     * Ensures that a number in entered
     */
    const handleChange = (value: string) => {
        const numberValue = Number(value);
        if (isNaN(numberValue)) return;
        onChange(numberValue);
    }
    
    return (
        <Box
            display="inline-flex"
            alignItems="center"
            borderRadius={1}
            bgcolor="#fff"
            border={`1px solid rgba(0,0,0,0.16)`}
            >
            <Button onClick={handleDecrement}>
                <RemoveRoundedIcon 
                    fontSize={size}
                    sx={{
                        color: FONT_COLOR,
                    }}
                    />
            </Button>

            <InputBase
                onFocus={(e) => e.target.select()} // automatically select entered value on click
                sx={{
                    width: size === 'medium' ? 40 : 20
                }}
                inputProps={{
                    style: {
                        textAlign: "center",
                        cursor: "pointer",
                        padding: size === 'medium' ? 6 : 2,
                    },
                }}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
            />

            <Button onClick={handleIncrement}>
                <AddRoundedIcon 
                    fontSize={size}
                    sx={{
                        color: FONT_COLOR,
                    }}
                    />
            </Button>
        </Box>
    )
}