import { DatesInterval } from 'helpers/dates';
import { Namespaces } from 'locales/translations';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'react-rainbow-components';
import { Value } from 'react-rainbow-components/components/DatePicker';

type DateRangePickerProps = {
    start: moment.Moment | null;
    end: moment.Moment | null;
    loading?: boolean;
    onChange: (dates: { start: moment.Moment, end: moment.Moment }) => void;
}

function DateRangePicker(props: DateRangePickerProps) {
    const { start, end, loading, onChange, } = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons,]);

    const defaultValue = start
        ? (
            end ? [start.toDate(), end.toDate()] : [start.toDate()]
        ) : [];
    const [rangeValue, setRangeValue] = useState<Date[]>(defaultValue);

    useEffect(() => {
        const value = start
        ? (
            end ? [start.toDate(), end.toDate()] : [start.toDate()]
        ) : [];
        setRangeValue(value);
    }, [start, end]);

    const handleChange = (value: Date | Date[]) => {
        if (Array.isArray(value)) {
            if (!rangeValue[1] && value.length == 1 && moment(value[0]).unix() == moment(rangeValue[0])?.unix()) {
                // same start and end day
                setRangeValue([value[0], value[0]]);
                onChange({
                    start: moment(value[0]),
                    end: moment(value[0]),
                });
                document.getElementById("modal-close-button")?.click(); // close datepicker
            }
            else if (value.length == 1) { // start date selected
                setRangeValue(value);
            }
            else if (value.length == 2) { // end date selected
                setRangeValue(value);
                onChange({
                    start: moment(value[0]),
                    end: moment(value[1]),
                });
            }
        }
    }

    const handleClose = (event: Value) => {
        if (rangeValue.length < 2) { // not both start and end dates selected
            setRangeValue(defaultValue);
        }
    }

    return (
        <DatePicker
            onFocus={handleClose}
            placeholder={t("select_dates_range", { ns: Namespaces.actions })}
            selectionType="range"
            formatStyle="medium"
            variant="single"
            locale="fr"
            value={rangeValue}
            onChange={handleChange}
            className="datepicker-range"
            disabled={loading}
        />
    );
}

export default DateRangePicker;