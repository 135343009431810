import { useEffect } from "react";
import { Typography, Container, } from "@mui/material";
import User, { UsersMethods } from 'models/User';
import UsersList from './UsersList';
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";

type UsersListPageProps = {
    
}

function UsersListPage(props: UsersListPageProps) {
    const { t } = useTranslation([Namespaces.titles]);

    const { users, usersLoading, } = useAppSelector(state => {
        const users = state.users.users;
        return {
            users: users.data,
            usersLoading: users.loading,
        };
    });
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(UsersMethods.list([], { fieldPath: 'score', directionStr: 'desc' }));
    }, []);

    return (
        <Container sx={{
            position: "relative",
        }}>
            <Typography variant="h1">
                {t("users.main", { ns: Namespaces.titles })}
            </Typography>
            <UsersList 
                users={users}
                loading={usersLoading} />
        </Container>
    );
}


export default UsersListPage;
