import { TrashColors } from "constants/trash";
import { drawBox, drawPolygon } from "helpers/canvas";
import _ from "lodash";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "store/hooks";
import { selectBatchById } from "store/reducers/batches/batch";

type CanvasProps = {
    batchId: string;
    width: number;
    naturalWidth: number;
    height: number;
    naturalHeight: number;
    previousBatch?: boolean;
}

function AIResultsCanvas(props: CanvasProps) {
    const { batchId, width, naturalWidth, height, naturalHeight, previousBatch } = props;

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const aiResults = useAppSelector(state => selectBatchById(state, batchId)!.aiResults);
    const aiResultsVisible = useAppSelector(state => selectBatchById(state, batchId)!.aiResultsVisible);

    const [canvasDrawn, setCanvasDrawn] = useState(false);

    useEffect(() => { // draw the masks or bounding boxes
        if (!canvasDrawn) {
            const ctx = canvasRef.current?.getContext("2d");
            if (ctx && aiResults) {
                setCanvasDrawn(true);
                for (let result of aiResults) {
                    // only draw previous batch results on "before" canva
                    // and batch results on "after" canva
                    if (Boolean(previousBatch) !== Boolean(result.inPrevious)) continue; 
                    
                    const points = result.points;
                    const box = result.box;
                    if (points) {
                        drawPolygon(ctx, points, TrashColors[result.class], ); 
                    }
                    else if (box) {
                        drawBox(ctx, box, TrashColors[result.class], );
                    }
                }
            }
            else { // hide AI results
                ctx?.clearRect(0, 0, width, height);
            }
        }
    }, [aiResults, canvasDrawn,]);

    return (
        <canvas
            ref={canvasRef}
            width={naturalWidth}
            height={naturalHeight}
            hidden={!aiResultsVisible}
            style={{
                position: "absolute",
                objectFit: "contain",
                ...(!previousBatch && {
                    top: 0,
                    left: 0,
                    width: '100%',
                    maxHeight: '100%',
                }),
            }} />
    );
}

export default memo(AIResultsCanvas, (prev, next) => _.isEqual(prev, next));
// export default AIResultsCanvas;