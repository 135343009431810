import { combineReducers, configureStore } from "@reduxjs/toolkit";
import BatchesReducer from "./reducers/batches/batch";
import SelectedCoconReducer from "./reducers/cocons/selected";
import CoconJoinCodeReducer from "./reducers/cocons/joinCode";
import CoconsClustersReducer from "./reducers/cocons/clusters";
import CoconsReducer from "./reducers/cocons/cocons";
import MessagesReducer from "./reducers/message";
import PartnersReducer from "./reducers/partner";
import RewardsReducer from "./reducers/reward";
import SnackReducer from "./reducers/snacks";
import StatsReducer from "./reducers/stats";
import StoresReducer from "./reducers/stores";
import ToAnnotateReducer from "./reducers/to_annotate";
import UsersReducer from "./reducers/users";
import CoconsMapReducer from "./reducers/cocons/map";
import CoconsIssuesReducer from "./reducers/cocons/issues";
import SelectedCoconsClusterReducer from "./reducers/cocons/selectedCluster";
import PartnerCompaniesReducer from "./reducers/partnerCompanies";
import CurrentPurchasesReducer from "./reducers/purchases/list_current";
import PastPurchasesReducer from "./reducers/purchases/list_past";
import SelectedPurchaseReducer from "./reducers/purchases/selected";
import SelectedBatchReducer from "./reducers/batches/selected";

const BatchesCombinedReducer = combineReducers({
    list: BatchesReducer,
    selected: SelectedBatchReducer,
});

const CoconsCombinedReducer = combineReducers({
    cocons: CoconsReducer,
    clusters: CoconsClustersReducer,
    issues: CoconsIssuesReducer,
    selected: SelectedCoconReducer,
    selectedCluster: SelectedCoconsClusterReducer,
    joinCode: CoconJoinCodeReducer,
    map: CoconsMapReducer,
});

const PurchasesCombinedReducer = combineReducers({
    current: CurrentPurchasesReducer,
    past: PastPurchasesReducer,
    selected: SelectedPurchaseReducer,
});

const store = configureStore({
    reducer: {
        batches: BatchesCombinedReducer,
        cocons: CoconsCombinedReducer,
        messages: MessagesReducer,
        partners: PartnersReducer,
        partnerCompanies: PartnerCompaniesReducer,
        purchases: PurchasesCombinedReducer,         
        rewards: RewardsReducer,
        snacks: SnackReducer,
        stats: StatsReducer,
        stores: StoresReducer,
        toAnnotate: ToAnnotateReducer,
        users: UsersReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false, // HOTFIX: do not enforce serialization of data 
    }),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type LoadableContext = {
    loading: boolean;
    error: string | null;
}

export type DataContext<T> = LoadableContext & {
    data: T;
} 