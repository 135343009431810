import { FormControl, FormControlLabel, Switch } from "@mui/material";
import SectionTitle from "components/_include/Titles/SectionTitle";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";

type InMaintenanceSwitchProps = {
    checked: boolean;
    onChange: (value: boolean) => void;
}

function InMaintenanceSwitch({ checked, onChange }: InMaintenanceSwitchProps) {
    const { t } = useTranslation([Namespaces.actions, Namespaces.cocons, Namespaces.forms]);

    return (
        <>
            <SectionTitle title={t("props.inMaintenance", { ns: Namespaces.cocons })} />
            <FormControl
                variant="outlined"
                sx={{ mt: 2 }}
            >
                <FormControlLabel
                    control={
                        <Switch 
                            color='warning' 
                            checked={checked}
                            />
                    }
                    label={t(`cocon.in_maintenance.${checked ? "true" : "false"}`, { ns: Namespaces.forms })}
                    onChange={()=>onChange(!checked)}
                />
            </FormControl>
        </>
    );
}

export default InMaintenanceSwitch;