import moment from "moment";
import _ from "lodash";
import { Timestamp } from "@firebase/firestore";
import { Collections, DBIdentifiers } from "constants/db";
import { AppDispatch } from "store/store";
import { ImagesToAnnotateActions, ToAnnotateArchivesActions } from "store/reducers/to_annotate";
import { listDocs } from "helpers/db";
import { showError } from "store/reducers/snacks";
import { StringifiableRecord, stringifyUrl } from "query-string";
import urls from "constants/urls";
import { CodeError, fetchAPI } from "actions/actions";

export type ImagesToAnnotateArchiveDbData = {
    link: string;
    from: Timestamp;
    to: Timestamp;
    files: string[];
    size: number;
    includeAnnotations: boolean;
    addedAt: Timestamp;
}

type ImagesToAnnotateArchive = ImagesToAnnotateArchiveDbData & DBIdentifiers;

const COLLECTION = Collections.IMAGES_TO_ANNOTATE;

const list = () => async (dispatch: AppDispatch) => {
    dispatch(ToAnnotateArchivesActions.startLoadingList());

    try {
        // directly query Firestore
        const archives = await listDocs<ImagesToAnnotateArchive>(COLLECTION, [], { fieldPath: "addedAt", directionStr: "desc" });
    
        dispatch(ToAnnotateArchivesActions.setList(archives));
        return archives;
    }
    catch (e) {
        const { message } = e as Error;
        dispatch(ToAnnotateArchivesActions.setListError(message));
        dispatch(showError(message));
        return []; 
    }
}

const create = (from: moment.Moment, to: moment.Moment, includeAnnotations: boolean) => async (dispatch: AppDispatch) => {
    dispatch(ImagesToAnnotateActions.startLoading());
    
    let params: StringifiableRecord = {
        from: from.format("x"),
        to: to.format("x"),
    };
    if (includeAnnotations) params.annotations = 1;

    const url = stringifyUrl({
        url: `${urls.API}/batch/to-annotate`,
        query: params,
    });

    try {
        // send request to Ficha API
        const archive: ImagesToAnnotateArchive = await fetchAPI(url);

        dispatch(ImagesToAnnotateActions.setLink(archive.link));
        return archive;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(ImagesToAnnotateActions.setError(error.message));
        dispatch(showError(error.message));
        return null;
    }
}

export const ToAnnotateArchivesMethods = {
    list,
    create,
}

export default ImagesToAnnotateArchive;