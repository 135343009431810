import { createDocument, listDocs } from "helpers/db";
import PartnerCompany, { PartnerCompanyData } from "models/PartnerCompany";
import { PartnerCompaniesActions } from "store/reducers/partnerCompanies";
import { showError } from "store/reducers/snacks";
import { AppDispatch } from "store/store";
import { CodeError } from "./actions";
import { Collections } from "constants/db";

/**
 * Fetches the list of partner companies from the database.
 * @returns {Promise<Array<PartnerCompany>>} A promise that resolves with an array of partner companies.
 */
const list = () => async (dispatch: AppDispatch): Promise<Array<PartnerCompany>> => {
    dispatch(PartnerCompaniesActions.startLoading());

    try {
        const partnerCompanies = await listDocs<PartnerCompany>(Collections.PARTNER_COMPANIES, [], { fieldPath: "name" });
        dispatch(PartnerCompaniesActions.setList(partnerCompanies));

        console.log("loaded partnerCompanies", partnerCompanies);
        return partnerCompanies;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(PartnerCompaniesActions.setError(error.message));
        dispatch(showError(error.message));
        return [];
    }
}

/**
 * Creates a new partner company.
 * @param {PartnerCompanyData} partnerCompany The data of the partner company to be created.
 * @returns {Promise<PartnerCompany | undefined>} A promise that resolves with the created partner company or undefined if an error occurs.
 */
const create = (partnerCompany: PartnerCompanyData) => async (dispatch: AppDispatch): Promise<PartnerCompany | undefined> => {
    dispatch(PartnerCompaniesActions.startLoading());
    try {
        // Create the document in Firestore
        const createdPartner = await createDocument(Collections.PARTNER_COMPANIES, partnerCompany);

         // Cast the created document to the PartnerCompany type
         const createdPartnerCompany: PartnerCompany = createdPartner as PartnerCompany;

        // Update the list of partner companies
        await list()(dispatch);

        return createdPartnerCompany;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(PartnerCompaniesActions.setError(error.message));
        dispatch(showError(error.message));
    }
}

/**
 * Object containing methods for handling partner companies.
 */
const PartnerCompaniesMethods = {
    list,
    create
};

export default PartnerCompaniesMethods;
