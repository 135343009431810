import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6045 40.0256H29.3068C29.4344 40.0256 29.5568 39.9749 29.647 39.8847L31.6079 37.9238C31.911 37.6207 31.6963 37.1024 31.2676 37.1024H9.64364C9.21496 37.1024 9.00027 37.6207 9.3034 37.9238L11.2643 39.8847C11.3545 39.9749 11.4769 40.0256 11.6045 40.0256Z" fill="#CCCCCC" />
            <path d="M13.1708 20.5369C15.4759 21.0546 17.6527 21.5437 21.2028 20.1852C24.7534 18.8267 26.0477 17.0094 27.4181 15.0854C28.6216 13.3958 29.8619 11.6568 32.6408 10.2716C32.8052 10.1897 32.9119 10.0235 32.9119 9.83988L32.9119 8.31391V6.00755C32.9119 5.59133 32.7466 5.19215 32.4523 4.89784L30.4483 2.89392C30.154 2.59961 29.7548 2.43427 29.3386 2.43427H11.5726C11.1563 2.43427 10.7572 2.59961 10.4628 2.89392L8.45893 4.89784C8.16461 5.19215 7.99927 5.59133 7.99927 6.00755V8.31391L7.99927 19.5366C7.99927 19.8146 8.23524 20.0343 8.51256 20.0154C10.2919 19.8937 11.7473 20.2173 13.1708 20.5369Z" fill="#336699" />
            <path d="M14.3383 26.9496C16.9587 27.3554 19.4346 27.7391 23.6015 26.145C27.7679 24.5508 29.3551 22.6126 31.0356 20.5605C31.58 19.8958 32.1305 19.2238 32.7745 18.5618C32.8615 18.4723 32.9119 18.3523 32.9119 18.2274V12.0391C32.9119 11.6645 32.5011 11.4329 32.1806 11.6268C30.2324 12.8052 29.2378 14.1944 28.2043 15.6454C26.811 17.6014 25.3704 19.6242 21.5479 21.0869C19.7085 21.7907 18.1929 22.0298 16.8548 22.0298C15.4118 22.0298 14.175 21.7518 12.9594 21.4788C11.5261 21.1568 10.1412 20.8478 8.4459 20.9856C8.19471 21.006 7.99927 21.2147 7.99927 21.4667V26.2637C7.99927 26.556 8.25907 26.7808 8.54833 26.7388C10.823 26.4089 12.6016 26.6805 14.3383 26.9496Z" fill="#D32F2F" />
            <path d="M23.9465 27.0467C19.5407 28.7326 16.821 28.3112 14.1903 27.9035C12.3897 27.6245 10.6544 27.3597 8.39499 27.7364C8.16542 27.7747 7.99927 27.9773 7.99927 28.2101V35.6278C7.99927 35.9091 8.22732 36.1372 8.50863 36.1372H32.4024C32.6837 36.1372 32.9118 35.9091 32.9118 35.6278L32.9119 21.1412C32.9119 20.6894 32.346 20.4866 32.0589 20.8353C31.9667 20.9472 31.8747 21.0596 31.7825 21.1722C30.096 23.2314 28.3523 25.3608 23.9465 27.0467Z" fill="#336699" />
            <path d="M30.497 0.747311C30.497 0.348742 30.1739 0.0256348 29.7753 0.0256348H10.8942C10.4956 0.0256348 10.1725 0.348739 10.1725 0.747309C10.1725 1.14588 10.4956 1.46899 10.8942 1.46899H29.7753C30.1739 1.46899 30.497 1.14588 30.497 0.747311Z" fill="#CCCCCC" />
        </svg>
    </SvgIcon>
);