import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Store from "models/Store";
import { DataContext } from "store/store";

export type StoreContext = {
    list: DataContext<Store[]>,
    selected: DataContext<Store | null>,
}

const initialState: StoreContext = {
    list: {
        data: [],
        loading: false,
        error: null,
    },
    selected: {
        data: null,
        loading: false,
        error: null,
    },
};

export const storesSlice = createSlice({
    name: 'stores',
    initialState: initialState,
    reducers: {
        startLoadingList: (state) => {
            state.list.loading = true;
            state.list.error = null;
        },
        startLoadingSelected: (state) => {
            state.selected.loading = true;
            state.selected.error = null;
        },
        setList: (state, action: PayloadAction<Store[]>) => {
            state.list.loading = false;
            state.list.data = action.payload;
        },
        setSelected: (state, action: PayloadAction<Store>) => {
            state.selected.loading = false;
            state.selected.data = action.payload;
        },
        setListError: (state, action: PayloadAction<string>) => {
            state.list.loading = false;
            state.list.error = action.payload;
        },
        setSelectedError: (state, action: PayloadAction<string>) => {
            state.selected.loading = false;
            state.selected.error = action.payload;
        },
    },
});

export const StoresActions = storesSlice.actions;

const StoresReducer = storesSlice.reducer;

export default StoresReducer;