import urls from "constants/urls";
import { CodeError, fetchAPI } from "./actions";
import queryString from 'query-string';
import { QueryFilters } from "../constants/types";
import { AppDispatch } from "store/store";
import { StatsActions, StatsInterface } from "store/reducers/stats";
import { showError } from "store/reducers/snacks";

const getStats = (filters: QueryFilters) => async (dispatch: AppDispatch) => {
    dispatch(StatsActions.startLoading());

    try {
        const stats: StatsInterface = await fetchAPI(`${urls.API}/statistics?${queryString.stringify(filters)}`);
        dispatch(StatsActions.setStats(stats));
        return stats;
    } 
    catch (e) {
        const error = e as CodeError;
        dispatch(StatsActions.setError(error.message));
        dispatch(showError(error.message)); // show error message
        return [];
    }
}

const getCoconStats = (coconId: string, filters: QueryFilters) => async (dispatch: AppDispatch) => {
    dispatch(StatsActions.startLoading());

    try {
        const stats = await fetchAPI(`${urls.API}/cocon/${coconId}/statistics?${queryString.stringify(filters)}`);
        dispatch(StatsActions.setStats(stats));
        return stats;
    } catch (e) {
        const error = e as CodeError;
        dispatch(StatsActions.setError(error.message));
        dispatch(showError(error.message)); // show error message
        return [];
    }
}

const LoadStatsActions = {
    getStats,
    getCoconStats,
};

export default LoadStatsActions;