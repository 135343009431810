import { Table, TableHead, TableRow, TableCell, TableBody, styled } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import { Namespaces } from "locales/translations";
import Purchase from "models/Purchase";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks"
import { selectAllCurrentPurchases } from "store/reducers/purchases/list_current";
import PurchaseListItem from "./PurchaseListItem";

const Cell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#FFFFFF", 
    color: theme.palette.primary.main,
}));

export default function CurrentPurchasesList() {
    const { t } = useTranslation([Namespaces.glossary, Namespaces.actions]);

    const purchasesLoading = useAppSelector(state => state.purchases.current.loading);
    const purchases = useAppSelector(selectAllCurrentPurchases);

    if (purchasesLoading) return <SectionLoader />;

    return (
        <Table>
            <TableHead sx={{ backgroundColor: "#FFFFFF", color: theme => theme.palette.secondary.main }}>
                <TableRow>
                    <Cell>{t("user", { ns: Namespaces.glossary })}</Cell>
                    <Cell>{t("reward", { ns: Namespaces.glossary })}</Cell>
                    <Cell>{t("status", { ns: Namespaces.glossary })}</Cell>
                    <Cell>{t("actions", { ns: Namespaces.actions })}</Cell>
                </TableRow>
            </TableHead>
            <TableBody>
                {purchases.map((purchase: Purchase) => (
                    <PurchaseListItem
                        key={purchase.id}
                        purchase={purchase}
                    />
                ))}
            </TableBody>
        </Table>
    )
}