import { Container, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper, } from "@mui/material";
import { TrashCountInterface } from "helpers/trash";
import { TrashType } from "constants/trash";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";

interface TrashCountDisplayProps {
    trashCount: TrashCountInterface
}

function TrashCountDisplay(props: TrashCountDisplayProps) {
    const { trashCount } = props;

    const { t } = useTranslation([Namespaces.commons, Namespaces.stats, Namespaces.wastes]);

    return (
        <Container>
            <Paper sx={{
                margin: 2,
                border: (theme) => `2px solid ${theme.palette.primary.main}`,
            }}>
                <TableContainer>
                    <Table size='small' aria-label="customized table">
                        <TableHead>
                            <TableRow >
                                <TableCell>{t("trash_types", { ns: Namespaces.stats })}</TableCell>
                                <TableCell>{t("quantity", { ns: Namespaces.commons })}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(trashCount).map(trash => (
                                <TableRow key={trash}>
                                    <TableCell component={'th'} scope={'row'}>
                                        {t(trash, { ns: Namespaces.wastes })}
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        {trashCount[trash as (TrashType | "TOTAL")]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>


                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    )
};

export default TrashCountDisplay;
