import { CodeError, fetchAPI } from "actions/actions";
import { Collections, DBIdentifiers } from "constants/db";
import urls from "constants/urls";
import { Timestamp } from "firebase/firestore";
import { getCurrentTimestamp } from "helpers/dates";
import { getDocumentReference } from "helpers/db";
import moment from "moment";
import { MessagesActions } from "store/reducers/message";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { AppDispatch } from "store/store";
import LastMessage, { LastMessagesMethods } from "./LastMessage";

export enum MessageAttachmentType {
    IMAGE = "image",
    VIDEO = "video",
    LINK = "link",
    INTERNAL_LINK = "internal_link",
}

export interface MessageAttachment {
    type: MessageAttachmentType;
    url: string;
}

export type MessageDbData = {
    timestamp: Timestamp;
    from: string;
    message: string;
    attachment?: MessageAttachment;
    conversationId?: string;
}

export type APIMessageData = Omit<MessageDbData, "timestamp"> & {
    id: string;
    timestamp: number;
}

export type NewMessageData = Omit<MessageDbData, "timestamp"> & {
    timestamp: number;
}

type Message = MessageDbData & DBIdentifiers;

const COLLECTION = Collections.MESSAGES;

function getDocRef(id: string, userId: string) {
    return getDocumentReference(id, COLLECTION, `${Collections.USERS}/${userId}`);
}

function fromAPIData(messageData: APIMessageData, userId: string): Message {
    return {
        ...messageData,
        timestamp: Timestamp.fromMillis(messageData.timestamp),
        // ref: getDocRef(messageData.id, userId),
    };
}

const getTimestamp = (message: Message) => moment(message.timestamp.toDate());
    
const getAttachmentVideo = (message: Message) => {
    const url = message.attachment?.url;
    if (!url) return null; // no attachment

    // youtube
    let youtubeMatch = url.match(/https:\/\/www\.youtube\.com\/watch\?v=(.*)/);
    if (!youtubeMatch) {
        youtubeMatch = url.match(/https:\/\/youtu\.be\/(.*)/);
    }
    if (youtubeMatch) {
        return {
            platform: "youtube",
            videoId: youtubeMatch[1],
        }
    }

    // vimeo
    const vimeoMatch = url.match(/https:\/\/player\.vimeo\.com\/video\/(.*)/);
    if (vimeoMatch) {
        return {
            platform: "vimeo",
            videoId: vimeoMatch[1],
        }
    }

    return null; // not found
}

const list = (userId: string, newer: boolean, timestamp?: Timestamp, limit?: number,) => async (dispatch: AppDispatch) => {
    const loadNewMessages = newer || !timestamp;
    if (loadNewMessages) {
        dispatch(MessagesActions.startLoadingMessages);
    }
    else {
        dispatch(MessagesActions.startLoadingOlderMessages);
    }

    let queryParams = ``;

    if (limit) { // load older messages or first time loading messages
        queryParams += `limit=${limit}`;
    }

    if (timestamp && newer) {
        queryParams += `&order=asc&after=${timestamp.toMillis()}`;
    }
    else if (timestamp) { // load older messages
        queryParams += `&order=asc&before=${timestamp.toMillis()}`;
    }
    else { // first time loading messages
        queryParams += `&order=asc&before=${Timestamp.now().toMillis()}`;
    }

    try {
        const messagesData: APIMessageData[] = await fetchAPI(`${urls.API}/user/${userId}/message?${queryParams}`);

        const messages: Message[] = messagesData.map(messageData => fromAPIData(messageData, userId));

        if (loadNewMessages) {
            dispatch(MessagesActions.setMessagesList(messages));
        }
        else {
            dispatch(MessagesActions.addOlderMessages(messages));
        }
        return messages;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(MessagesActions.setMessagesError(error.message));
        return [];
    };
}

const send = (userId: string, text: string, attachment?: MessageAttachment) => async (dispatch: AppDispatch) => {
    let newMessageData: NewMessageData = {
        timestamp: getCurrentTimestamp(),
        from: "cocon",
        message: text,
        attachment: attachment,
    };

    dispatch(MessagesActions.startSendingMessage(newMessageData));

    try {
        const messageData: APIMessageData = await fetchAPI(`${urls.API}/user/${userId}/message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newMessageData),
        });

        const message = fromAPIData(messageData, userId);

        const lastMessage = await LastMessagesMethods.getUserLastMessage(userId);

        dispatch(MessagesActions.finishSendingMessage({ usersIds: [userId], message: message }));
        if (lastMessage) dispatch(MessagesActions.updateLastMessages([lastMessage]));
        return message;
    }
    catch (e) {
        const error = e as CodeError;
        console.error(error);
        dispatch(showError(error.message));
        dispatch(MessagesActions.setSendingMessageError(error.message));
        return null;
    };
};

const batchSend = (usersIds: string[], text: string, attachment?: MessageAttachment) => async (dispatch: AppDispatch) => {
    let newMessageData: NewMessageData = {
        timestamp: getCurrentTimestamp(),
        from: "cocon",
        message: text,
        attachment: attachment,
    };

    dispatch(MessagesActions.startSendingMessage(newMessageData));

    try {
        const messagesData: APIMessageData[] = await fetchAPI(`${urls.API}/message/batch`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: newMessageData,
                users: usersIds,
            }),
        });

        let lastMessages: LastMessage[] = [];
        for (const userId of usersIds) {
            const lastMessage = await LastMessagesMethods.getUserLastMessage(userId);
            if (lastMessage) lastMessages.push(lastMessage);
        }

        dispatch(MessagesActions.finishSendingMessage({ usersIds: usersIds }));
        dispatch(MessagesActions.updateLastMessages(lastMessages));

        // display success snackbar
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "send_batch_message.success",
        }));

        return messagesData;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(MessagesActions.setSendingMessageError(error.message));
        
        // display error snackbar
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "send_batch_message.error",
        }));

        return [];
    };
};

export default Message;

export const MessagesMethods = {
    getTimestamp,
    getAttachmentVideo,
    list,
    send,
    batchSend,
};