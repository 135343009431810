import { Box, Button, Grid, GridProps, FormControlLabel, Switch } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { BatchesMethods } from "models/Batches/Batch";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ActionButton from "components/_include/ActionButton";
import { BatchesActions, selectBatchById } from "store/reducers/batches/batch";
import { useCallback } from "react";
import { StorageType, getPictureURL } from "helpers/storage";
import BatchImageZoomSlider from "./BatchImageZoomSlider";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

type BatchImageActionsProps = {
    batchId: string;
}

const ButtonWrapper = (props: GridProps) => (
    <Grid
        item
        xs={6}
        md={4}
        display="flex"
        justifyContent="center"
        {...props}
    />
);

function BatchImageActions({ batchId, }: BatchImageActionsProps) {
    const { t } = useTranslation([Namespaces.actions]);

    const loading = useAppSelector(state => selectBatchById(state, batchId)!.loading);
    const aiResults = useAppSelector(state => selectBatchById(state, batchId)!.aiResults);
    const aiResultsVisible = useAppSelector(state => selectBatchById(state, batchId)!.aiResultsVisible);
    const toAnnotate = useAppSelector(state => selectBatchById(state, batchId)!.toAnnotate ?? false);

    const imageURL = useAppSelector(state => selectBatchById(state, batchId)!.imageURL);

    /**
     * Download batch's image on the user's device
     */
    const handleDownload = useCallback(() => {
        if (imageURL) {
            getPictureURL(StorageType.BATCHES, imageURL).then(image => {
                const link = document.createElement('a');
                link.href = image!;
                link.setAttribute('download', "image");
                link.setAttribute('target', 'new');
                document.body.appendChild(link);
                link.click();
            });
        }
    }, [imageURL]);

    const dispatch = useAppDispatch();

    /**
     * Load batch's AI results if not already loaded
     */
    const handleAIButtonPressed = () => {
        if (!aiResults) { // load AI results if never loaded
            dispatch(BatchesMethods.getAIResults(batchId));
        }

        // update visibility status of the AI canvas
        dispatch(BatchesActions.updateListItem({
            batchId,
            data: {
                aiResultsVisible: !aiResultsVisible,
            }
        }));
    }

    /**
     * Update the batch's "toAnnotate" boolean field
     */
    const handleToAnnotateChanged = (checked: boolean) => {
        dispatch(BatchesMethods.update(batchId, {
            toAnnotate: checked,
        }));
    }

    return (
        <Box
            alignItems="center"
            justifyContent="space-evenly"
            display='flex'
            flexGrow={1}
            width="100%"
        >
            <Grid container justifyContent="space-evenly" pt={2}>
                <Grid item xs={12}>
                    <BatchImageZoomSlider batchId={batchId} />
                </Grid>

                <ButtonWrapper>
                    <ActionButton
                        loading={loading}
                        icon={aiResultsVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        onClick={() => { handleAIButtonPressed() }}
                        color="inherit"
                        sx={{
                            backgroundColor: (theme) => theme.palette.success.light,
                            color: "#ffffff",
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.success.main,
                            }
                        }}
                    >
                        {t("view_ai_results", { ns: Namespaces.actions })}
                    </ActionButton>
                </ButtonWrapper>

                <ButtonWrapper>
                    <FormControlLabel
                        sx={{
                            color: (theme) => !toAnnotate ? "rgba(0,0,0,0.38)" : theme.palette.success.light,
                        }}
                        control={
                            <Switch
                                readOnly={loading}
                                checked={toAnnotate}
                                color="success"
                                onChange={(e, checked) => handleToAnnotateChanged(checked)}
                            />
                        }
                        label={t("to_annotate", { ns: Namespaces.actions }).toString()}
                    />
                </ButtonWrapper>

                <ButtonWrapper>
                    <Button variant="contained" onClick={handleDownload} startIcon={<DownloadRoundedIcon />}>
                        {t("download", { ns: Namespaces.actions })}
                    </Button>
                </ButtonWrapper>
            </Grid>
        </Box>
    )
};


export default BatchImageActions;
