import { useCallback, useEffect, useState, useRef } from "react";
import { Box, CircularProgress, styled, Typography } from "@mui/material";
import Slider from "./Slider";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import AIResultsCanvas from "components/Batches/BatchComponents/Card/Image/AIResultsCanvas";
import { getPictureURL, StorageType } from "helpers/storage";
import { useAppSelector } from "store/hooks";
import { selectBatchById } from "store/reducers/batches/batch";

type BeforeAfterSliderProps = {
    batchId: string;
    imageURL: string;
    previousImageURL: string;
}

const CoverImage = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    overflow: "hidden"
}));

function BeforeAfterSlider({ batchId, imageURL, previousImageURL, }: BeforeAfterSliderProps) {

    const { t } = useTranslation([Namespaces.commons]);

    const zoomLevel = useAppSelector(state => selectBatchById(state, batchId)!.zoomLevel || 1);

    const [sliderValue, setSliderValue] = useState(50);
    const [loading, setLoading] = useState(true);
    const handleChange = (event: Event, value: number | number[]) => {
        setSliderValue(value as number);
    };

    const [originalImageDimensions, setOriginalImageDimensions] = useState({
        width: 600,
        height: 600 / 1.5,
        ratio: 1.5,
    });

    const [afterImage, setAfterImage] = useState<HTMLImageElement | null>(null);
    const handleAfterImageRef = useCallback((node: HTMLImageElement) => {
        setAfterImage(node);
    }, []);

    useEffect(() => {
        if (afterImage) {
            afterImage.onload = () => {
                setOriginalImageDimensions({
                    width: afterImage.naturalWidth,
                    height: afterImage.naturalHeight,
                    ratio: afterImage.naturalWidth / afterImage.naturalHeight,
                });
                setLoading(false); // set loading to false when image has loaded
            }
        }
    }, [afterImage]);

    const [before, setBefore] = useState("");
    const [after, setAfter] = useState("");

    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        getPictureURL(StorageType.BATCHES, imageURL).then((url) => {
            if (url && isMounted.current) setAfter(url);
        });

        return () => {
            isMounted.current = false;
        };
    }, [imageURL]);

    useEffect(() => {
        isMounted.current = true;
        if (previousImageURL) {
            getPictureURL(StorageType.BATCHES, previousImageURL).then((url) => {
                if (url && isMounted.current) setBefore(url);
            });
        }
        return () => {
            isMounted.current = false;
        };
    }, [previousImageURL]);

    return (
        <Box 
            width="100%"
            position="relative"
            maxWidth={600}
            maxHeight={600 / originalImageDimensions.ratio}
            overflow="hidden"
            sx={{
                aspectRatio: `${originalImageDimensions.ratio}`,
            }}
        >
            {loading && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                    position="absolute"
                    zIndex={1}
                >
                    <CircularProgress />
                </Box>
            )}

            <Box position="relative" width="100%" height="100%" sx={{ scale: `${zoomLevel}` }}>
                <img
                    alt="afterimage"
                    ref={handleAfterImageRef}
                    src={after}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }} />
                {afterImage && (
                    <AIResultsCanvas
                        batchId={batchId}
                        width={afterImage.width}
                        height={afterImage.height}
                        naturalWidth={afterImage.naturalWidth}
                        naturalHeight={afterImage.naturalHeight}
                    />
                )}
                <Typography
                    variant="caption"
                    sx={{
                        color: "#ffffff",
                        position: "absolute",
                        right: theme => theme.spacing(2),
                        bottom: theme => theme.spacing(1),
                    }}>
                    {t("after", { ns: Namespaces.commons })}
                </Typography>
                <CoverImage
                    sx={{
                        backgroundImage: `url(${before})`,
                        width: `${sliderValue}%`,
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            color: "#ffffff",
                            position: "absolute",
                            left: theme => theme.spacing(2),
                            bottom: theme => theme.spacing(1),
                        }}>
                        {t("before", { ns: Namespaces.commons })}
                    </Typography>

                    {afterImage && (
                        <AIResultsCanvas
                            batchId={batchId}
                            width={afterImage.width}
                            height={afterImage.height}
                            naturalWidth={afterImage.naturalWidth}
                            naturalHeight={afterImage.naturalHeight}
                            previousBatch={true}
                        />
                    )}
                </CoverImage>

                <Slider
                    min={0}
                    max={100}
                    value={sliderValue}
                    onChange={handleChange}
                />
            </Box>
        </Box>
    );
}

export default BeforeAfterSlider;