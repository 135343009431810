import moment from "moment";
import { ChartsStrings } from 'constants/charts';
import { TrashType } from "constants/trash";
import i18next from "i18next";
import { Namespaces } from "locales/translations";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { initTrashCount, TrashCountInterface } from "helpers/trash";

// export interface BatchesByUserInterface {
//     [userPin: string]: {
//         [date: string]: number,
//     },
// };

export type TimeUnit = "day" | "week" | "month";

export interface MomentStats {
    date: number;
    batches: number;
    activeUsers: {
        count: number;
        withMistakes: number;
    };
    co2_saved: number;
    quality: number;
    timeUnit: TimeUnit;
}

export type StatsInterface = {
    batches: MomentStats[],
    trashes: TrashCountInterface,
    timeUnit: TimeUnit;
};

export interface BarChartDataInterface {
    [ChartsStrings.DATES]: moment.Moment;
    [ChartsStrings.COUNT]: number;
}

export interface LineChartPointInterface {
    id: string;
    data: Array<{
        x: moment.Moment;
        y: number;
    }>;
}

export interface PieChartSliceInterface {
    id: string | number,
    value: number,
    [key: string]: string | number
}

export type StatsContext = {
    data: StatsInterface;
    loading: boolean;
    error: string | null;
    batches: BarChartDataInterface[];
    activeUsers: BarChartDataInterface[];
    co2_saved: LineChartPointInterface[];
    quality: LineChartPointInterface[];
    trashes: PieChartSliceInterface[];
    totalTrashes: number;
    totalBatches: number;
}

const initialState: StatsContext = {
    data: {
        batches: [],
        trashes: initTrashCount(),
        timeUnit: "week" as TimeUnit,
    },
    loading: false,
    error: null,
    batches: [],
    activeUsers: [],
    co2_saved: [],
    quality: [],
    trashes: [],
    totalTrashes: 0,
    totalBatches: 0,
};

function formatBatchesStatsForDislay(momentStats: MomentStats[]): BarChartDataInterface[] {
    return momentStats.map(momentBatches => {
        return {
            [ChartsStrings.DATES]: moment(momentBatches.date, 'x'),
            [ChartsStrings.COUNT]: momentBatches.batches,
        };
    });
};

function formatActiveUsersStatsForDislay(momentStats: MomentStats[]): BarChartDataInterface[] {
    return momentStats.map(momentBatches => {
        return {
            [ChartsStrings.DATES]: moment(momentBatches.date, 'x'),
            [ChartsStrings.COUNT]: momentBatches.activeUsers.count,
        };
    });
};

function formatCO2StatsForDislay(momentStats: MomentStats[]): LineChartPointInterface[] {
    let momentCO2Data = Array<{ x: moment.Moment, y: number }>();
    let totalCO2Data = Array<{ x: moment.Moment, y: number }>();
    let totalCO2 = 0;

    momentStats.map(momentBatches => {
        const date = moment(momentBatches.date, 'x');
        const momentCO2 = Math.round(momentBatches.co2_saved * 100) / 100;
        totalCO2 += momentCO2;
        momentCO2Data.push({
            x: date,
            y: momentCO2,
        });
        totalCO2Data.push({
            x: date,
            y: totalCO2,
        });
    });

    return [
        {
            id: ChartsStrings.CO2_SAVED,
            data: momentCO2Data,
        },
        {
            id: ChartsStrings.CUMULATIVE_CO2_SAVED,
            data: totalCO2Data,
        },
    ];
};

function formatBatchesQualityForDisplay(momentStats: MomentStats[]): LineChartPointInterface[] {
    let momentWellSorted = Array<{ x: moment.Moment, y: number }>();
    let momentBadSorted = Array<{ x: moment.Moment, y: number }>();

    momentStats.map(momentBatches => {
        const date = moment(momentBatches.date, 'x');
        const wellSorted = Math.round(momentBatches.quality * 100);
        momentWellSorted.push({
            x: date,
            y: wellSorted,
        });
        momentBadSorted.push({
            x: date,
            y: 100 - wellSorted,
        });
    });

    return [
        {
            id: ChartsStrings.WELL_SORTED_TRASH,
            data: momentWellSorted,
        },
        // {
        //     id: ChartsStrings.BADLY_SORTED_TRASH,
        //     data: momentBadSorted,
        // },
    ];
};

function formatTrashesStatsForDisplay(trashes: TrashCountInterface): PieChartSliceInterface[] {
    const totalTrashes = trashes.TOTAL;
    return Object.keys(trashes)
        .filter(trashType => {return trashType !== "TOTAL"})
        .map(trashType => {
            const trash = trashType as TrashType;
            const trashName = i18next.t(trash, { ns: Namespaces.wastes });
            return {
                id: trashType,
                label: trashName,
                value: Math.round(trashes[trash] / totalTrashes * 100),
            }
        });
}

export const statsSlice = createSlice({
    name: 'stats',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setStats: (state, { payload: stats }: PayloadAction<StatsInterface>) => {
            let nbBatches = 0;
            stats.batches.forEach(momentBatches => {
                nbBatches += momentBatches.batches;
            });
            state.loading = false;
            state.data = stats;
            state.batches = formatBatchesStatsForDislay(stats.batches);
            state.activeUsers = formatActiveUsersStatsForDislay(stats.batches);
            state.co2_saved = formatCO2StatsForDislay(stats.batches);
            state.quality = formatBatchesQualityForDisplay(stats.batches);
            state.trashes = formatTrashesStatsForDisplay(stats.trashes);
            state.totalTrashes = stats.trashes.TOTAL;
            state.totalBatches = nbBatches;
        },
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.loading = false;
            state.error = error;
        },
    },
});

export const StatsActions = statsSlice.actions;

const StatsReducer = statsSlice.reducer;

export default StatsReducer;