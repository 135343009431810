import { Collections, DBIdentifiers } from "constants/db";
import { TrashType } from "constants/trash";
import { Box, Polygon } from "constants/types";
import { listSubcollectionDocs } from "helpers/db";

type BatchAIResultsDbData = {
    class: TrashType;
    box: Box;
    points?: string;
    score: number;
    inPrevious?: boolean;
}

type BatchAIResults = DBIdentifiers & Omit<BatchAIResultsDbData, "points"> & {
    points?: Polygon;
};

const COLLECTION = Collections.BATCH_AI_RESULTS;

export const listAIResults = async (batchId: string) => {
    const dbAIResults = await listSubcollectionDocs<DBIdentifiers & BatchAIResultsDbData>(COLLECTION, `${Collections.BATCH}/${batchId}`);
    const aiResults: BatchAIResults[] = dbAIResults.map(result => ({
        ...result,
        points: result.points ? JSON.parse(result.points) : undefined,
    }));
    return aiResults;
}

export default BatchAIResults;