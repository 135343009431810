import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import BatchCard from "../BatchComponents/Card/BatchCard";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { FixedSizeList, FixedSizeList as List, ListOnScrollProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { BatchesActions, BatchesToDoSorting, selectBatchesIds } from "store/reducers/batches/batch";
import { useEffect, useMemo, useRef } from "react";
import { EntityId } from "@reduxjs/toolkit";
import store from "store/store";

// replace with the height of each row
const LARGE_DISPLAY_CARD_HEIGHT = 704;
const SMALL_DISPLAY_CARD_HEIGHT = 1360;

type AutoSizerProps = Pick<AutoSizer["state"], "width" | "height">;

type ListProps = {

}

/**
 * List of batches that need to be validated.
 * The list is virtualized for faster loading.
 * If the design of the cards change, also modify the `LARGE_DISPLAY_CARD_HEIGHT`
 * and `SMALL_DISPLAY_CARD_HEIGHT` values above accordingly
 */
function BatchesToDoList({ }: ListProps) {
    const { t } = useTranslation([Namespaces.batches]);

    const batchesSorting = useAppSelector(state => state.batches.list.todoListSorting);
    const batchesIds = useAppSelector(selectBatchesIds);

    /** List of batches IDs sorted by user preference */
    const sortedBatchesIds = useMemo(() => {
        if (batchesSorting === BatchesToDoSorting.IDENTIFIED) {
            const identifiedBatchesIds: EntityId[] = [];
            const autoBatchesIds: EntityId[] = [];
            const batches = store.getState().batches.list.entities;
            batchesIds.forEach(batchId => {
                if (batches[batchId]?.user?.id) {
                    identifiedBatchesIds.push(batchId);
                }
                else {
                    autoBatchesIds.push(batchId);
                }
            });
            return [...identifiedBatchesIds, ...autoBatchesIds];
        }
        return batchesIds;
    }, [batchesSorting, batchesIds]);

    const dispatch = useAppDispatch();

    /** Handle list scroll to hide or show sorting and filtering header */
    const handleScroll = ({ scrollOffset, scrollDirection }: ListOnScrollProps) => {
        dispatch(BatchesActions.toggleToDoHeader(scrollOffset <= 80 || scrollDirection === "backward"));
    }

    /** Scroll to top of the list when sorting changes */
    const listRef = useRef<FixedSizeList>(null);
    useEffect(() => {
        listRef.current?.scrollTo(0);
    }, [batchesSorting]);

    const theme = useTheme();
    const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        sortedBatchesIds.length > 0 ? (
            <AutoSizer>
                {({ height, width }: AutoSizerProps) => (
                    <List
                        ref={listRef}
                        height={height}
                        itemCount={sortedBatchesIds.length}
                        itemSize={isUpLg ? LARGE_DISPLAY_CARD_HEIGHT : SMALL_DISPLAY_CARD_HEIGHT}
                        width={width}
                        onScroll={handleScroll}
                        style={{
                            paddingBottom: theme.spacing(10),
                        }}
                    >
                        {({ index, style, }) => (
                            <div style={style}>
                                <BatchCard
                                    batchId={sortedBatchesIds[index].toString()}
                                />
                            </div>
                        )}
                    </List>
                )}
            </AutoSizer>
        ) : (
            <Box
                justifyContent="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                height="100%"
            >
                <Typography variant="h2" sx={{ mb: 4 }}>
                    {t("all_clear", { ns: Namespaces.batches })}
                </Typography>
                <Typography variant="h4">
                    {t("all_batches_processed", { ns: Namespaces.batches })}&nbsp;🙂
                </Typography>
            </Box>
        )
    )
}

export default BatchesToDoList;