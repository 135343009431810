import { Box, keyframes } from "@mui/material";
import { NetworkStatus } from "models/Cocons/Cocon";

const ripple = keyframes`
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(3);
    }
`;

type RippleProps = {
    status: NetworkStatus;
}

export default function NetworkStatusRipple(props: RippleProps) {
    const { status } = props;

    return (
        <Box
            component="span"
            display="inline-block"
            borderRadius="50%"
            width={(theme) => theme.spacing(2)}
            height={(theme) => theme.spacing(2)}
            ml={2}
            bgcolor={(theme) => theme.palette[status === NetworkStatus.ONLINE ? "success" : "error"].light}
            position="relative"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 2,
                "&::before": {
                    content: status === NetworkStatus.ONLINE ? '""' : 'none',
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "inherit",
                    borderRadius: "50%",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    animation: `${ripple} 1.5s ease-out infinite`,
                }
            }}
        />
    );
}