import { Typography, Box } from "@mui/material";
import Message from "models/Message";

interface MessageBubbleProps {
    message: Message;
}

function MessageBubble(props: MessageBubbleProps) {
    const { message } = props;

    const isCoconMessage = message.from !== "user";

    return (
        <Box
            pb={2}
            display="flex"
            pl={isCoconMessage ? 4 : 8}
            pr={isCoconMessage ? 8 : 4}
            justifyContent={isCoconMessage ? "flex-start" : "flex-end"}
            >
            <Box 
                padding={2}
                borderRadius={2}
                boxShadow="0 4px 20px rgba(0,0,0,0.2)"
                {...(isCoconMessage ? {} : {
                    bgcolor: theme => theme.palette.secondary.main,
                    color: "#ffffff",
                })}
                >
                <Typography variant="body1">
                    {message.message}
                </Typography>
            </Box>
        </Box>
    );
}

export default MessageBubble;