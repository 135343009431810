import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.0216 31.2426H15.6912C15.2691 31.2426 14.8644 31.4102 14.5659 31.7087C14.2675 32.0071 14.0999 32.4119 14.0999 32.8339V38.4469C14.0999 38.8724 14.2689 39.2805 14.5698 39.5814C14.8706 39.8823 15.2787 40.0513 15.7042 40.0513H25.0085C25.434 40.0513 25.8421 39.8823 26.143 39.5814C26.4439 39.2805 26.6129 38.8724 26.6129 38.4469V32.8339C26.6129 32.4119 26.4452 32.0071 26.1468 31.7087C25.8484 31.4102 25.4436 31.2426 25.0216 31.2426Z" fill="#5C4437" />
            <path d="M15.539 23.8338C15.1581 23.8338 14.7927 23.9849 14.5229 24.2538C14.2531 24.5228 14.101 24.8877 14.0999 25.2686V28.8338C14.0999 29.2409 14.2616 29.6313 14.5494 29.9191C14.8372 30.2069 15.2276 30.3686 15.6346 30.3686H25.0781C25.4852 30.3686 25.8755 30.2069 26.1634 29.9191C26.4512 29.6313 26.6129 29.2409 26.6129 28.8338V25.2686C26.6129 24.8869 26.4613 24.5209 26.1914 24.251C25.9215 23.9811 25.5554 23.8295 25.1738 23.8295H15.539V23.8338Z" fill="#5C4437" />
            <path d="M17.6302 3.29475H23.0823C23.1204 3.29475 23.1569 3.27963 23.1838 3.25272C23.2107 3.22582 23.2258 3.18932 23.2258 3.15127V0.442574C23.2258 0.338794 23.1846 0.239264 23.1112 0.16588C23.0378 0.092496 22.9383 0.0512695 22.8345 0.0512695H17.878C17.7742 0.0512695 17.6747 0.092496 17.6013 0.16588C17.5279 0.239264 17.4867 0.338794 17.4867 0.442574V3.15127C17.4867 3.18932 17.5018 3.22582 17.5287 3.25272C17.5556 3.27963 17.5921 3.29475 17.6302 3.29475Z" fill="#CCCCCC" />
            <path d="M15.5738 15.6469H25.1781C25.5672 15.6205 25.9318 15.4473 26.1981 15.1624C26.4644 14.8775 26.6127 14.5021 26.6129 14.1121V10.573L22.7781 4.16431H17.9346L14.0999 10.573V14.1121C14.0995 14.5089 14.2529 14.8903 14.5277 15.1764C14.8024 15.4626 15.1774 15.6312 15.5738 15.6469Z" fill="#5C4437" />
            <path d="M25.1738 16.5165H15.539C15.1577 16.5176 14.7923 16.6696 14.5226 16.9393C14.253 17.2089 14.101 17.5743 14.0999 17.9556V21.4339C14.101 21.8406 14.2631 22.2303 14.5506 22.5179C14.8382 22.8054 15.2279 22.9675 15.6346 22.9687H25.0781C25.4848 22.9675 25.8745 22.8054 26.1621 22.5179C26.4497 22.2303 26.6117 21.8406 26.6129 21.4339V17.9556C26.6117 17.5743 26.4598 17.2089 26.1901 16.9393C25.9205 16.6696 25.5551 16.5176 25.1738 16.5165Z" fill="#228844" />
        </svg>
    </SvgIcon>
);