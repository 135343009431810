import { useState } from "react";
import { Box, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, GridProps } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { BatchesMethods } from "models/Batches/Batch";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectBatchById } from "store/reducers/batches/batch";
import ActionButton from "components/_include/ActionButton";

type BatchCardFormActionsProps = {
    batchId: string,
}

const ButtonWrapper = (props: GridProps) => (
    <Grid
        item
        xs={6}
        display="flex"
        justifyContent="center"
        {...props}
    />
);

function BatchCardFormActions({ batchId, }: BatchCardFormActionsProps) {
    const { t } = useTranslation([Namespaces.actions, Namespaces.wastes]);

    const results = useAppSelector(state => selectBatchById(state, batchId)?.result ?? []);
    const removeFromListOnUpdate = useAppSelector(state => !selectBatchById(state, batchId)?.isProcessed); // remove batch from "todo" list when saved
    const loading = useAppSelector(state => selectBatchById(state, batchId)?.loading ?? false);
    const dispatch = useAppDispatch();

    const [deleteDialogOpen, toggleDeleteDialog] = useState(false);

    const handleDeletePressed = () => {
        dispatch(BatchesMethods.deleteBatch(batchId));
        toggleDeleteDialog(false);
    }

    const handleSubmit = () => {
        dispatch(BatchesMethods.updateScore(
            batchId.toString(),
            { result: results, isProcessed: true, },
            removeFromListOnUpdate,
        ));
    }

    return (
        <Box
            alignItems="flex-end"
            justifyContent="space-evenly"
            display='flex'
            flexGrow={1}
        >
            <Grid container justifyContent="space-evenly" pt={2}>
                <ButtonWrapper>
                    <ActionButton
                        color="secondary"
                        icon={<DeleteIcon />}
                        loading={loading}
                        onClick={() => toggleDeleteDialog(true)}
                    >
                        {t("delete", { ns: Namespaces.actions })}
                    </ActionButton>
                </ButtonWrapper>

                <ButtonWrapper>
                    <ActionButton
                        color="primary"
                        icon={<SaveIcon />}
                        loading={loading}
                        onClick={() => handleSubmit()}
                    >
                        {t("save", { ns: Namespaces.actions })}
                    </ActionButton>
                </ButtonWrapper>
            </Grid>

            <Dialog
                open={deleteDialogOpen}
                onClose={(event) => toggleDeleteDialog(false)} >
                <DialogTitle>
                    {t("delete_something.title", { ns: Namespaces.actions, something: t("this_batch", { ns: Namespaces.glossary }) })}
                </DialogTitle>
                <DialogContent>
                    {t("delete_something.body", { ns: Namespaces.actions })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(event) => toggleDeleteDialog(false)}
                        color="primary"
                    >
                        {t("cancel", { ns: Namespaces.actions })}
                    </Button>
                    <Button
                        onClick={handleDeletePressed}
                        color="error"
                    >
                        {t("delete", { ns: Namespaces.actions })}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};


export default BatchCardFormActions;
