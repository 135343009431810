import { Grid, GridProps, IconButton, Tooltip } from '@mui/material';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { QueryFilter } from "constants/types";
import { DatesInterval, DATE_SELECT_TYPES, getDatesFromRange } from 'helpers/dates';
import moment from 'moment';
import { Timestamp } from '@firebase/firestore';
import DownloadIcon from '@mui/icons-material/Download';
import CachedIcon from '@mui/icons-material/Cached';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ActionButton from 'components/_include/ActionButton';
import DownloadImagesToAnnotateDialog from './DownloadImagesToAnnotateDialog';
import ImagesToAnnotateHistoryDialog from './ImagesToAnnotateHistoryDialog';
import DatesSelect from 'components/_include/Filters/DatesSelect';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DateRangePicker from 'components/_include/Filters/DateRangePicker';
import { BatchesMethods } from 'models/Batches/Batch';

// const mapStateToProps = (state: StoreInterface) => ({
//     imagesSelected: state.BatchReducer.batches.data.filter(batch => batch.toAnnotate).length,
// });

// const mapDispatchToProps = (dispatch: any) => ({
//     loadImages: (filters: QueryFilter[], order: DbOrder, limit?: number,) => dispatch(BatchActions.getAllBatches(filters, order, limit)),
// });

type FiltersProps = {
    loadingLimit: number;
}

const FilterWrapper = (props: GridProps) => (
    <Grid
        item
        justifyContent="center"
        display="flex"
        {...props}
    />
);

function ImagesFilterAndAction(props: FiltersProps) {
    const { loadingLimit, /*imagesSelected, loadImages,*/ } = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.glossary]);

    const [searchParams, setSearchParams] = useSearchParams();
    const fromParam = searchParams.get("from");
    const toParam = searchParams.get("to");
    const needToSelectDates = !fromParam || !toParam;
    const start = fromParam ? moment(fromParam.toString()) : null;
    const end = toParam ? moment(toParam.toString()) : null;

    const batchesLoading = useAppSelector(state => state.batches.list.loading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (needToSelectDates) { // set by default last month
            const now = moment();
            const monthAgo = moment().subtract(1, "month");
            searchParams.set("from", monthAgo.format("YYYY-MM-DD"));
            searchParams.set("to", now.format("YYYY-MM-DD"));
            setSearchParams(searchParams);
        }
    }, [needToSelectDates]);

    const getImages = () => {
        if (!start || !end) return;

        let filters: QueryFilter[] = [
            {
                fieldPath: "toAnnotate",
                opStr: "==",
                value: true,
            },
            {
                fieldPath: "timestamp",
                opStr: ">=",
                value: Timestamp.fromDate(start.toDate()),
            },
            {
                fieldPath: "timestamp",
                opStr: "<=",
                value: Timestamp.fromDate(end.endOf("day").toDate()),
            }
        ];

        dispatch(BatchesMethods.list(filters, loadingLimit,));
    };

    // reload collections when filters change
    useEffect(() => {
        getImages();
    }, [fromParam, toParam]);

    // controls the opening of the dialog to confirm downloading of images to annotate
    const [downloadDialogOpen, toggleDownloadDialog] = useState(false);

    // controls the opening of the dialog to list history of images to annotate
    const [historyDialogOpen, toggleHistoryDialog] = useState(false);

    const handleDateChange = (dates: { start: moment.Moment, end: moment.Moment }) => {
        searchParams.set("from", dates.start.format("YYYY-MM-DD"));
        searchParams.set("to", dates.end.format("YYYY-MM-DD"));
        setSearchParams(searchParams);
    }

    return (
        <Grid
            container
            display="flex"
            columnSpacing={1}
        >
            <FilterWrapper>
                <DateRangePicker
                    loading={batchesLoading}
                    start={start}
                    end={end}
                    onChange={handleDateChange}
                />
            </FilterWrapper>
            <FilterWrapper
                display="inline-block"
                flexGrow={1}
            >
                <IconButton
                    onClick={() => getImages()}
                >
                    <CachedIcon />
                </IconButton>
            </FilterWrapper>

            <Grid item xs="auto">
                <ActionButton
                    color='secondary'
                    icon={<ManageSearchIcon />}
                    onClick={() => toggleHistoryDialog(true)}
                >
                    {t("history", { ns: Namespaces.actions })}
                </ActionButton>
            </Grid>
            <Grid item xs="auto">
                <Tooltip
                    title={needToSelectDates
                        ? t("select_date_range", { ns: Namespaces.glossary }).toString()
                        // : !imagesSelected
                        //     ? t("select_images_to_annotate", { ns: Namespaces.glossary }).toString()
                        : ""}
                >
                    <span>
                        <ActionButton
                            color='primary'
                            icon={<DownloadIcon />}
                            // disabled={needToSelectDates || !imagesSelected}
                            onClick={() => toggleDownloadDialog(true)}
                        >
                            {t("download", { ns: Namespaces.actions })}
                        </ActionButton>
                    </span>
                </Tooltip>
            </Grid>

            <DownloadImagesToAnnotateDialog
                open={downloadDialogOpen}
                onClose={() => toggleDownloadDialog(false)}
            />

            <ImagesToAnnotateHistoryDialog
                open={historyDialogOpen}
                onClose={() => toggleHistoryDialog(false)}
            />
        </Grid>
    );
}

export default ImagesFilterAndAction;