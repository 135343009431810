import Reward from "models/Reward";
import { createSlice, PayloadAction, } from "@reduxjs/toolkit";
import { DataContext } from "store/store";
import { Timestamp } from "firebase/firestore";

export type SerializedReward = Omit<Reward, "dateAdded"> & {
    dateAdded: number;
}

function serialize(reward: Reward): SerializedReward;
function serialize(reward: Partial<Reward>): Partial<SerializedReward>;
function serialize(reward: Reward | Partial<Reward>) {
    const serializedReward = {
        ...reward,
        dateAdded: reward.dateAdded?.toMillis(),
    };
    return serializedReward;
}

export function deserializeReward(reward: SerializedReward): Reward;
export function deserializeReward(reward: Partial<SerializedReward>): Partial<Reward>;
export function deserializeReward(reward: SerializedReward | Partial<SerializedReward>) {
    const serializedReward = {
        ...reward,
        dateAdded: reward.dateAdded ? Timestamp.fromMillis(reward.dateAdded) : undefined,
    };
    return serializedReward;
}

export type RewardsContext = {
    list: DataContext<Reward[]>;
    selected: DataContext<Reward | null>;
}

const initialState: RewardsContext = {
    list: {
        data: [],
        loading: false,
        error: null,
    },
    selected: {
        data: null,
        loading: false,
        error: null,
    },
};

export const rewardsSlice = createSlice({
    name: 'rewards',
    initialState: initialState,
    reducers: {
        startLoadingList: (state) => {
            state.list.loading = true;
            state.list.error = null;
        },
        startLoadingSelected: (state) => {
            state.selected.loading = true;
            state.selected.error = null;
        },
        setList: (state, { payload: rewards }: PayloadAction<Reward[]>) => {
            state.list.loading = false;
            state.list.data = rewards;
        },
        setSelected: (state, { payload: reward }: PayloadAction<Reward | null>) => {
            state.selected.loading = false;
            state.selected.data = reward;
        },
        updateListItem: (state, { payload: reward }: PayloadAction<Reward>) => {
            state.list.data = state.list.data.map(r => r.id === reward.id ? reward : r);
        },
        deleteSelected: (state, { payload: reward }: PayloadAction<Reward>) => {
            state.selected.data = null;
            state.selected.loading = false;
            state.list.data = state.list.data.filter(r => r.id !== reward.id);
        },
        setListError: (state, { payload }: PayloadAction<string>) => {
            state.list.loading = false;
            state.list.error = payload;
        },
        setSelectedError: (state, { payload }: PayloadAction<string>) => {
            state.selected.loading = false;
            state.selected.error = payload;
        },
    },
});

export const RewardsActions = rewardsSlice.actions;

const RewardsReducer =  rewardsSlice.reducer;

export default RewardsReducer;