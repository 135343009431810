import { Box, Card, CardContent, TextField } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import PartnerCompaniesMethods from 'actions/partner_companies';
import PartnerCompany, { PartnerCompanyData } from 'models/PartnerCompany';
import PartnerLogoButton from './PartnerLogoButton';
import { showSuccess } from 'store/reducers/snacks';
import { LoadingButton } from '@mui/lab';

/**
 * Props for the PartnerCard component.
 */
type PartnerCardProps = {
    onPartnerCreated: (partner: PartnerCompany) => void; // Callback function invoked when a partner is created
}

/**
 * Component for displaying a card to create a new partner.
 * @param {PartnerCardProps} props - Props for the PartnerCard component.
 * @returns {JSX.Element} JSX element representing the PartnerCard component.
 */
function PartnerCard({ onPartnerCreated }: PartnerCardProps): JSX.Element {
    const [logoURL, setLogoURL] = useState<string>();
    const [partnerCompanyName, setpartnerCompanyName] = useState<string>();
    const loading = useAppSelector(state => state.partnerCompanies.loading);
    const dispatch = useAppDispatch();

    const validForm = logoURL && partnerCompanyName;

    const { t } = useTranslation([Namespaces.snacks, Namespaces.actions, Namespaces.forms]);

    /**
     * Handles the save action when creating a new partner.
     */
    const handleSave = async () => {
        if (!validForm) return;

        const newPartner: PartnerCompanyData = {
            name: partnerCompanyName,
            imageURL: logoURL
        }

        const partnerCompany = await dispatch(PartnerCompaniesMethods.create(newPartner));

        if (partnerCompany) {
            if (onPartnerCreated) {
                onPartnerCreated(partnerCompany);
            }
            showSuccess(t("partner_company_created", { ns: Namespaces.snacks, name: partnerCompany.name }));
        }
    }

    return (
        <Card>
            <CardContent sx={{ p: 4 }}>
                <Box>
                    {/* Render the PartnerLogoButton component to select partner logo */}
                    <PartnerLogoButton onChange={setLogoURL} />
                </Box>
                <Box mt={3}>
                    {/* Text field to enter partner company name */}
                    <TextField
                        label={t('partner_company_name_label', { ns: Namespaces.forms })}
                        value={partnerCompanyName}
                        onChange={(e) => setpartnerCompanyName(e.target.value)}
                        fullWidth
                    />
                </Box>
                <Box mt={2}>
                    {/* LoadingButton to save the new partner */}
                    <LoadingButton
                        color="secondary"
                        onClick={handleSave}
                        disabled={!validForm}
                        loading={loading}
                    >
                        {t("save", { ns: Namespaces.actions })}
                    </LoadingButton>
                </Box>
            </CardContent>
        </Card>
    );
}

export default PartnerCard;
