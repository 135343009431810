import { CodeError, fetchAPI } from "actions/actions";
import urls from "constants/urls";
import { Locale } from "locales/translations";
import LastMessage, { LastMessagesMethods } from "models/LastMessage";
import { MessagesActions } from "store/reducers/message";
import { showSuccess, showTranslatedMessage } from "store/reducers/snacks";
import { AppDispatch } from "store/store";
import Reply, { ReplyData } from "./Reply";

export default class Conversation {
    id: string;
    label: string;
    replies: Reply[];

    constructor(data: ConversationData, context?: { [key: string]: string }) {
        this.id = data.id;
        this.label = data.label;
        this.replies = data.replies.map(replyData => new Reply(replyData, context));
    }

    public getReply(replyId: number) {
        const matchingReplies = this.replies.filter(reply => reply.id === replyId);
        if (matchingReplies.length !== 1) return null;
        return matchingReplies[0];
    }

    static list = () => async (dispatch: AppDispatch) => {
        dispatch(MessagesActions.startLoadingConversations());

        try {
            const conversationsDataList: ConversationsDataList = await fetchAPI(`${urls.API}/conversation/`);
            const conversations: Conversation[] = Object.entries(conversationsDataList).map(conversationIdAndData => {
                return new Conversation(conversationIdAndData[1][Locale.FRENCH]);
            });

            dispatch(MessagesActions.setConversationsList(conversations));
            return conversations;
        }
        catch (e) {
            const error = e as CodeError;
            dispatch(MessagesActions.setConversationsError(error.message));
            return [];
        }
    };

    batchSend = (usersIds: string[]) => async (dispatch: AppDispatch) => {
        dispatch(MessagesActions.startSendingConversation(this));

        try {
            await fetchAPI(`${urls.API}/conversation/${this.id}/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    users: usersIds,
                }),
            });

            let lastMessages: LastMessage[] = [];
            for (const userId of usersIds) {
                const lastMessage = await LastMessagesMethods.getUserLastMessage(userId);
                if (lastMessage) lastMessages.push(lastMessage);
            }

            dispatch(MessagesActions.finishSendingConversation({ usersIds: usersIds }));
            dispatch(MessagesActions.updateLastMessages(lastMessages));

            // display success snackbar
            dispatch(showTranslatedMessage({
                variant: "success",
                messageKey: "send_conversation.success",
            }));

            return true;
        }
        catch (e) {
            const error = e as CodeError;
            dispatch(MessagesActions.setSendingConversationnError(error.message));

            // display error snackbar
            dispatch(showTranslatedMessage({
                variant: "error",
                messageKey: "send_conversation.error",
            }));

            return false;
        }
    }
}

export interface ConversationData {
    id: string;
    label: string;
    replies: ReplyData[];
}

export type ConversationsDataList = {
    [conversationId: string]: {
        [locale: string]: ConversationData
    }
}
