import { useEffect } from 'react';
import { Box, Container, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { Namespaces } from 'locales/translations';
import SectionLoader from '../../_include/SectionLoader';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CoconIssueRow from './CoconIssueRow';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCoconIssuesIds } from 'store/reducers/cocons/issues';
import { CoconsMethods } from 'models/Cocons/Cocon';
import { CoconIssuesMethods } from 'models/Cocons/CoconIssue';

type CoconIssuesListProps = {
};

function CoconIssuesList(props: CoconIssuesListProps) {

    const { } = props;

    const { t } = useTranslation([Namespaces.issues]);

    const urlParams = useParams();

    const paramCoconId = urlParams.coconId;
    const paramClusterId = urlParams.clusterId;

    const dispatch = useAppDispatch();

    const selectedCocon = useAppSelector(state => state.cocons.selected.data);

    const issuesIds = useAppSelector(state => selectCoconIssuesIds(state));
    const loading = useAppSelector(state => state.cocons.selected.loading || state.cocons.issues.loading);
    const error = useAppSelector(state => state.cocons.issues.error);

    useEffect(() => {
        if (paramCoconId) { // load cocon issues
            if (selectedCocon?.id) { // cocon loaded, load issues
                dispatch(CoconIssuesMethods.list(selectedCocon.clusterId, selectedCocon.id, []));
            }
            else { // load cocon first (to get cluster ID)
                dispatch(CoconsMethods.retrieve(paramCoconId));
            }
        }
    }, [paramCoconId, selectedCocon?.id]);

    useEffect(() => {
        if (paramClusterId) { // load cluster issues
            dispatch(CoconIssuesMethods.list(paramClusterId, undefined, []));
        }
    }, [paramClusterId]);

    return (
        <Container>
            {
                loading ? (
                    <SectionLoader />
                ) : (
                    issuesIds.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{
                                    backgroundColor: 'background.paper',
                                    "& th": {
                                        backgroundColor: 'secondary.main',
                                    }
                                }}>
                                    <TableRow>
                                        <TableCell>{t("picture", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("type", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("description", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("reported_on", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("status", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("actions", { ns: Namespaces.issues })}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {issuesIds.map(issueId => (
                                        <CoconIssueRow
                                            key={issueId}
                                            issueId={issueId}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box
                            sx={{
                                marginTop: 6,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                flexGrow: 1,
                            }}>
                            <Typography
                                variant="h2"
                                textAlign="center"
                            >
                                {t("all_good", { ns: Namespaces.issues, count: paramCoconId ? 1 : 2 })}
                            </Typography>
                        </Box>
                    )
                )}
        </Container>
    )
}

export default CoconIssuesList;