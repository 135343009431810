import Cocon, { CoconDbData } from "models/Cocons/Cocon";
import CoconsCluster from "models/Cocons/CoconsCluster";
import { DataContext, LoadableContext } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

type CoconContext = DataContext<Cocon | undefined>;

const initialState: CoconContext = {
    loading: false,
    data: undefined,
    error: null,
};

export const selectedCoconSlice = createSlice({
    name: 'selected_cocon',
    initialState: initialState,
    reducers: {
        startLoadingSelectedCocon: (state) => {
            state.loading = true;
            state.error = null;
        },
        setSelectedCocon: (state, { payload: cocon }: PayloadAction<Cocon | undefined>) => {
            state.loading = false;
            state.data = cocon;
        },
        setSelectedCoconError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedCoconActions = selectedCoconSlice.actions;

const SelectedCoconReducer =  selectedCoconSlice.reducer;

export default SelectedCoconReducer;