import { Typography } from '@mui/material';


type SectionTitleProps = {
    title: string;
}

function SectionTitle(props: SectionTitleProps) {
    const { title } = props;

    return (
        <Typography 
            variant="h4" 
            mt={3}
            mb={1}>
            {title}
        </Typography>
    );
}

export default SectionTitle;