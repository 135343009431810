import { Card, Typography, useTheme } from "@mui/material";
import { ChartsStrings } from "constants/charts";
import { Namespaces } from "locales/translations";
import SectionLoader from "../../../_include/SectionLoader";
import { useTranslation } from "react-i18next";
import ChartTitle from "./ChartTitle";
import ChartWrapper from "../ChartWrapper";
import { useAppSelector } from "store/hooks";
import LineChart from "./LineChart";
import moment from "moment";
import TooltipWrapper from "./TooltipWrapper";

type ChartProps = {

}

function CO2SavedChart(props: ChartProps) {

    const { t } = useTranslation([Namespaces.glossary, Namespaces.stats]);

    const theme = useTheme();

    const timeUnit = useAppSelector(state => state.stats.data.timeUnit);
    const co2Stats = useAppSelector(state => state.stats.co2_saved);

    const loading = useAppSelector(state => state.stats.loading);

    return (
        <ChartWrapper>
            <ChartTitle
                title={t("kg_co2_saved_per", {
                    ns: Namespaces.stats,
                    context: timeUnit,
                })}
            />
            {
                loading ?
                    <SectionLoader />
                    :
                    <LineChart
                        data={co2Stats}
                        margin={{ top: 50, right: 60, bottom: 88, left: 68 }}
                        colors={{
                            [ChartsStrings.CO2_SAVED]: theme.palette.secondary.main,
                            [ChartsStrings.CUMULATIVE_CO2_SAVED]: theme.palette.primary.main,
                        }}
                        stacked={false}
                        axisLeft={{
                            legend: "Kg de CO\u2082 économisés"
                        }}
                        axisBottom={{
                            legend: t(timeUnit, { ns: Namespaces.commons }),
                            format: (value: moment.Moment) =>
                                t("date", {
                                    ns: Namespaces.stats,
                                    context: timeUnit,
                                    date: moment(value),
                                })
                        }}
                        legends={[
                            {
                                anchor: 'top',
                                direction: 'column',
                                justify: false,
                                translateY: -40,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 188,
                                itemHeight: 20,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            }
                        ]}
                        tooltip={({ point }) => {
                            if (point.serieId === ChartsStrings.CO2_SAVED) {
                                const date: moment.Moment = point.data.x;
                                const value: number = point.data.y;
                                return (
                                    <TooltipWrapper>
                                        <Typography variant="caption">
                                            {t("date_full", {
                                                ns: Namespaces.stats,
                                                context: timeUnit,
                                                date: date,
                                            })}
                                        </Typography>
                                        <Typography variant="body1">
                                            {t("kg_co2_saved_plural", { ns: Namespaces.stats, count: value })}
                                        </Typography>
                                    </TooltipWrapper>
                                );
                            }
                            else { // cumulated co2
                                const nbUnits = point.index - co2Stats[0].data.length + 1;
                                const value = point.data.y;
                                return (
                                    <Card style={{ paddingTop: 4, paddingBottom: 4, paddingRight: 8, paddingLeft: 8, borderRadius: 2 }}>
                                        <Typography variant="caption">
                                            {t("after", {
                                                ns: Namespaces.stats,
                                                context: timeUnit,
                                                count: nbUnits,
                                            })}
                                        </Typography>
                                        <Typography variant="body1">
                                            {t("kg_co2_saved_plural", { ns: Namespaces.stats, count: value })}
                                        </Typography>
                                    </Card>
                                );
                            }
                        }}
                    />
            }
        </ChartWrapper>
    )
};

export default CO2SavedChart;