import { useState, FormEvent } from 'react';
import { TextField, Typography, Container, useTheme, Box } from '@mui/material';
import ActionButton from '../_include/ActionButton';
import { isValidEmail } from 'helpers/strings';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import User, { UsersMethods } from 'models/User';

type LoginPageProps = {
    
}

function LoginPage(props: LoginPageProps) {

    const theme = useTheme();

    const { loading, error } = useAppSelector(state => state.users.user);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.titles]);

    const [inputs, setInputs] = useState<{
        email: {
            value: string,
            error: string | null
        },
        password: {
            value: string,
            error: string | null
        },
    }>({
        email: {
            value: "",
            error: null
        },
        password: {
            value: "",
            error: null
        },
    });

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let { email, password } = inputs;
        let error = false;
        
        if (!email.value) {
            error = true;
            email.error = "";
        }
        else if (!isValidEmail(email.value)) {
            error = true;
            email.error = t("invalid_email", { ns: Namespaces.commons });
        }

        if (!password.value) {
            error = true;
            password.error = "";
        }

        if (error) {
            setInputs({
                email: email,
                password: password,
            });
        }
        else {
            dispatch(UsersMethods.signIn(email.value, password.value));
        }
    }

    const { email, password } = inputs;
    const formIsValid = email.value.length && password.value.length;

    return (
        <Container sx={{
            marginTop: '2rem',
            maxWidth: 1280,
            margin: "0 auto",
            padding: "2rem",
            paddingTop: "3rem",
            textAlign: 'center',
        }}>
            <Typography variant="h1">
                {t("ficha_dashboard", { ns: Namespaces.titles })}
            </Typography>
            <form
                style={{
                    marginTop: theme.spacing(5),
                }}
                autoComplete="on"
                method="post"
                action="#"
            >
                <TextField
                    id="email"
                    label="Adresse e-mail"
                    value={email.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => {handleInputChange('email', event.target.value)}
                    }}
                    error={Boolean(email.error)}
                    helperText={email.error}
                />

                <br />

                <TextField
                    id="password"
                    label="Mot de passe"
                    value={password.value}
                    margin="normal"
                    variant="outlined"
                    type='password'
                    InputProps={{
                        onChange: (event) => {handleInputChange('password', event.target.value)}
                    }}
                    error={Boolean(password.error)}
                    helperText={password.error}
                />

                <Box textAlign="center">
                    <ActionButton
                        color="primary"
                        disabled={!formIsValid}
                        loading={loading}
                        onClick={(event) => handleSubmitPressed(event)}
                        type="submit"
                        >
                        {t("login", { ns: Namespaces.actions })}
                    </ActionButton>
                </Box>
            </form>
        </Container>
    )
}

export default LoginPage;