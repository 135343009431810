import Answer, { AnswerData } from "./Answer";
import { MessageAttachment } from "../Message";
import Conversation from "./Conversation";
import { populateTemplate } from "helpers/strings";

export interface ReplyMessage {
    message: string;
    attachment?: MessageAttachment;
}

export interface ReplyData {
    id: number;
    messages: ReplyMessage[];
    answers: AnswerData[];
}

/**
 * A reply from the Cocon to the user
 */
export default class Reply {
    id: number;
    messages: ReplyMessage[];
    answers: Answer[];

    constructor(data: ReplyData, context?: { [key: string]: string }) {
        this.id = data.id;
        this.messages = data.messages.map(replyMessage => ({
            message: context ? populateTemplate(replyMessage.message, context) : replyMessage.message,
            attachment: replyMessage.attachment,
        }));
        this.answers = data.answers.map((answerData: AnswerData) => new Answer(answerData, context));
    }

    public data(fields?: string[]): ReplyData {
        let data: any = {
            id: this.id,
            messages: this.messages,
            answers: this.answers.map(answer => answer.data()),
        };

        if (fields) {
            Object.keys(data).forEach(field => {
                if (fields.indexOf(field) < 0) { // field not in fields to return
                    delete data[field];
                }
            });
        }

        return data;
    }
}
