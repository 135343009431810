import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7833 8.80322H12.8472V11.0336H25.7833V8.80322Z" fill="#607D8B" />
            <path d="M26.4813 12.3718H12.5299C6.33509 16.8326 2 26.5473 2 32.6282C2 40.8076 9.83711 39.9763 19.5062 39.9763C29.175 39.9763 37.0121 40.7873 37.0121 32.6081C37.0121 26.5271 32.677 16.8326 26.4813 12.3718Z" fill="#333333" />
            <path d="M19.5063 2.78735C17.5524 2.94341 12.0034 -1.54488 9.50258 0.565327C7.00174 2.67521 12.5299 7.46504 12.5299 7.46504H26.4813C26.4813 7.46504 32.0105 2.67521 29.5096 0.565327C27.0088 -1.54488 21.4598 2.94341 19.5063 2.78735Z" fill="#333333" />
        </svg>
    </SvgIcon>
);