import { IconButton, Tooltip } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type ButtonProps = {
    text: string;
    size: "small" | "medium" | "large";
}

export default function CopyToClipboardButton(props: ButtonProps) {
    const { text, size } = props;

    const { t } = useTranslation([Namespaces.actions]);

    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
    }

    return (
        <Tooltip 
            title={t(copied ? "copied" : "copy", { ns: Namespaces.actions})}
            onMouseLeave={() => setCopied(false)}
            >
            <IconButton
                size={size}
                color="info"
                onClick={copyToClipboard}
                sx={{
                    mt: -1,
                }}
                >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}