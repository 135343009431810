import { Box, Modal } from '@mui/material';
import PartnerCard from './PartnerCard';

/**
 * Props for the NewPartnerModal component.
 */
type NewPartnerModalProps = {
    open: boolean;          // Indicates whether the modal is open or not
    onClose: () => void;    // Function to handle modal close event
}

/**
 * Component for displaying a modal to create a new partner.
 * @param {NewPartnerModalProps} props - Props for the NewPartnerModal component.
 * @returns {JSX.Element} JSX element representing the NewPartnerModal component.
 */
export default function NewPartnerModal({ open, onClose }: NewPartnerModalProps): JSX.Element {

    return (
        <Modal
            open={open}     // Prop to control the modal open state
            onClose={onClose}   // Prop to handle modal close event
        >
            <Box
                position='absolute'
                top='50%'
                left='50%'
                width={theme => theme.spacing(40)}
                sx={{
                    transform: 'translate(-50%, -50%)'
                }}
            >
                {/* Render the PartnerCard component within the modal */}
                <PartnerCard onPartnerCreated={onClose} />
            </Box>
        </Modal>
    );
}
