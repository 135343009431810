import { Stepper, Typography, Step, StepLabel, Button, Box } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type AddCoconStepperProps = {
    steps: string[];
    activeStep: number;
    onBackClicked: () => void;
}

function AddCoconStepper(props: AddCoconStepperProps) {
    const { steps, activeStep, onBackClicked, } = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.cocons]);

    return (
        <Box
            mb={1}
            textAlign="center"
            >
            <Stepper
                activeStep={activeStep}
                sx={{
                    maxWidth: "sm",
                    mx: "auto",
                }}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{t(`add_cocon.${label}`, { ns: Namespaces.cocons })}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep > 0 && (
                <Button
                    variant="text"
                    onClick={onBackClicked}
                    startIcon={<ChevronLeftIcon />}
                    >
                    {t("previous", { ns: Namespaces.actions })}
                </Button>
            )}
        </Box>
    );
}

export default AddCoconStepper;