import { useState, useEffect } from 'react';
import { Typography, Container, CircularProgress, } from '@mui/material';
import { CoconsMethods } from 'models/Cocons/Cocon';
import { useParams } from 'react-router-dom'
import CoconForm, { AddCoconFormData } from './CoconForm/CoconForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import _ from 'lodash';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';

type EditCoconPageProps ={
}

function EditCoconPage(props: EditCoconPageProps) {

    const cocon = useAppSelector(state => state.cocons.selected.data);
    const loading = useAppSelector(state => state.cocons.selected.loading);
    const dispatch = useAppDispatch();

    const { coconId } = useParams();

    const { t } = useTranslation([Namespaces.titles]);

    useEffect(() => {
        if (coconId) {
            dispatch(CoconsMethods.retrieve(coconId));
        }
    }, [coconId]);

    const [coconLoaded, setCoconLoaded] = useState(false);
    useEffect(() => {
        if (cocon) {            
            setCoconLoaded(true);
        }
    }, [cocon]);

    if (loading || !coconId || !cocon) {
        return (
            <CircularProgress />
        )
    }

    const handleFormSubmit = (coconData: AddCoconFormData) => {
        return dispatch(CoconsMethods.update(cocon.id, coconData));
    };

    return (
        <Container>
            <Typography variant="h1">
                {t("view_cocon", { ns: Namespaces.titles })}
            </Typography>

            {loading || !cocon ? (
                <CircularProgress />
            ) : (
                <CoconForm
                    type="edit"
                    coconId={coconId!}
                    loading={loading || !cocon}
                    coconData={_.pick(cocon, ["clusterId", "name", "deviceId", "address", "postcode", "city", "status", "inMaintenance", "managers", "joinCode", "unitsCount", "dateDeployed"])}
                    submitForm={handleFormSubmit}
                    />
            )}
        </Container>
    )
}

export default EditCoconPage;