import { memo } from "react";
import { Paper, Grid, useTheme } from "@mui/material";
import BatchInfo from "./BatchInfo";
import _ from "lodash";
import BatchImageActions from "./Image/BatchImageActions";
import BatchImage from "./Image/BatchImage";
import BatchCardFormActions from "./Form/BatchCardFormActions";
import BatchCardForm from "./Form/BatchCardForm";

type BatchCardProps = {
    batchId: string;
};

function BatchCard({ batchId }: Readonly<BatchCardProps>) {

    const theme = useTheme();

    return (
        <Paper
            elevation={10}
            sx={{
                flex: 1,
                margin: 1.25,
                border: `2px solid ${theme.palette.primary.main}`,
                overflow: "hidden",
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <BatchInfo
                        batchId={batchId}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={4} display="flex">
                    <BatchCardForm
                        batchId={batchId}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={8}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <BatchImage
                        batchId={batchId}
                    />
                    <BatchImageActions
                        batchId={batchId}
                    />

                    <BatchCardFormActions
                        batchId={batchId}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

// use memo to only re-render card if props have changed
function areEquals(props1: BatchCardProps, props2: BatchCardProps) {
    return _.isEqual(props1.batchId, props2.batchId);
}

export default memo(BatchCard, areEquals);