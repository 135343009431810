import { Box, Typography } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";

type PartnerLogoBackdropProps = {
    noLogo?: boolean;
}

export default function PartnerLogoBackdrop({ noLogo }: PartnerLogoBackdropProps) {
    const { t } = useTranslation([Namespaces.actions]);

    return (
        <Box
            position='absolute'
            left={0}
            right={0}
            top={0}
            bottom={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(0,0,0,0.7)"
            sx={{
                opacity: noLogo ? 0.7 : 0, // show by default if no logo
                transition: theme => theme.transitions.create('opacity'),
                "&:hover": {
                    opacity: 1,
                }
            }}
        >
            <Typography
                fontWeight="bold"
                fontSize="1.1rem"
                textAlign="center"
                sx={{
                    color: "#fff"
                }}
            >
                {t("select_logo", { ns: Namespaces.actions })}
            </Typography>
            <Typography
                fontSize="0.8rem"
                textAlign="center"
                sx={{
                    color: "#fff"
                }}
            >
                {t("recommended_dimensions", { ns: Namespaces.actions })}
                <br />
                1248 x 623
            </Typography>
        </Box>
    )
}
