import { Coordinates, getMapState, MapPointFeature } from "helpers/geo";
import { initTrashCount, TrashCountInterface } from "helpers/trash";
import { DocumentData, DocumentSnapshot, GeoPoint, getDocs, QueryDocumentSnapshot, Timestamp } from "firebase/firestore";
import { Collections, DBIdentifiers } from "constants/db";
import { QueryFilter } from "constants/types";
import { AppDispatch } from "store/store";
import { CoconsActions } from "store/reducers/cocons/cocons";
import { SelectedCoconActions } from "store/reducers/cocons/selected";
import { CodeError, fetchAPI } from "actions/actions";
import urls from "constants/urls";
import { NOT_FOUND_ERROR, NOT_FOUND_ERROR_CODE } from "constants/api_errors";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { applyQueryFilter, formatQuery, getCollectionGroupRef, getDocumentReference, listSubcollectionDocs } from "helpers/db";
import { CoconJoinCodeActions } from "store/reducers/cocons/joinCode";
import { CoconsMapActions } from "store/reducers/cocons/map";

export enum CoconStatus {
    DEV = "dev",
    PREPARING = "preparing",
    DEPLOYED = "deployed",
    DEMO = "demo",
    FINISHED = "finished",
}

export type NotificationData = {
    working: boolean;
}

export enum NetworkStatus {
    ONLINE = "online",
    OFFLINE = "offline",
}

export type CoconManager = {
    id?: string;
    email: string;
}

export type CoconStats = {
    batchesCount: number;
    usersCount: number;
    savedCO2: number;
    savedMoney: number;
    wastes: TrashCountInterface;
    quality: number; // between 0 and 1
    pendingIssues: number;
}

export const initCoconStats: () => CoconStats = () => ({
    batchesCount: 0,
    usersCount: 0,
    savedCO2: 0,
    savedMoney: 0,
    wastes: initTrashCount(),
    quality: 1,
    pendingIssues: 0,
});

export type NewCoconData = {
    clusterId: string;
    name: string;
    deviceId?: string;
    address: string;
    postcode: string;
    city: string;
    coordinates?: GeoPoint;
    status: CoconStatus;
    dateDeployed?: number; // milliseconds timestamp
    managers: CoconManager[];
    joinCode: string;
    unitsCount?: number;
};

export type CoconDbData = Omit<NewCoconData, "coordinates" | "dateDeployed"> & CoconStats & {
    coordinates: GeoPoint;
    networkStatus: NetworkStatus;
    token: string;
    dateDeployed?: Timestamp;
    inMaintenance?: boolean;
};

export type APICoconData = Omit<CoconDbData, "coordinates" | "dateDeployed" | "lastCheckIn"> & {
    id: string;
    dateDeployed?: number;
    lastCheckIn?: number;
    coordinates: Coordinates;
}

type Cocon = Omit<CoconDbData, "coordinates" | "dateDeployed" | "lastCheckIn"> & DBIdentifiers & {
    coordinates: Coordinates;
    dateDeployed?: number;
    lastCheckIn?: number;
};

const COLLECTION = Collections.COCONS;

function getDocRef(id: string, clusterId: string) {
    return getDocumentReference(id, COLLECTION, `${Collections.COCONS_CLUSTERS}/${clusterId}`);
}

export function fromAPIData(coconData: APICoconData): Cocon {
    const coordinates = coconData.coordinates;
    return {
        ...coconData,
        coordinates: new GeoPoint(coordinates.latitude, coordinates.longitude),
    };
}

export function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Cocon;
export function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): Cocon | null;
export function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as CoconDbData;
    if (!data || !dbDoc.ref.parent.parent) return null;
    const collectionData: Cocon = {
        ...data,
        id: dbDoc.id,
        coordinates: new GeoPoint(data.coordinates.latitude, data.coordinates.longitude),
        dateDeployed: data.dateDeployed?.toMillis(),
    }
    return collectionData;
}

const getFullAddress = (cocon: Pick<CoconDbData, "address" | "city" | "postcode">) => `${cocon.address}, ${cocon.postcode} ${cocon.city}`;

const create = (data: NewCoconData) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(SelectedCoconActions.startLoadingSelectedCocon());

    try {
        // add Cocon through API (need to send emails to managers)
        const addedCoconData: APICoconData = await fetchAPI(`${urls.API}/cocon`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        const cocon = fromAPIData(addedCoconData);
        
        // dispatch loaded list to state
        dispatch(SelectedCoconActions.setSelectedCocon(cocon));

        // show success message
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "add_cocon.success"
        }));

        return cocon;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(SelectedCoconActions.setSelectedCoconError(error.message));

        // show error message
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "add_cocon.error"
        }));

        return null;
    }
}

const list = (filters: QueryFilter[]) => async (dispatch: AppDispatch) => {
    dispatch(CoconsActions.startLoadingCocons());

    try {
        // query database ordering by clusterId to group Cocons by cluster
        const collectionRef = getCollectionGroupRef(COLLECTION);
        const { query, inequalityFilters } = formatQuery(collectionRef, filters, { fieldPath: "clusterId", directionStr: "asc" });
        const snapshot = await getDocs(query);

        // apply inequality filters if more than 1 was specified
        let documents = snapshot.docs;
        inequalityFilters?.forEach(filter => {
            documents = applyQueryFilter(filter, documents);
        })

        const cocons = documents.map(doc => fromDbDoc(doc));
        dispatch(CoconsActions.setCoconsList(cocons));

        console.log("loaded cocons", cocons);

        if (cocons.length === 0) { // no Cocon
            dispatch(CoconsMapActions.removeMapPoints());
        }
        else { // generate Map Markers for Cocons
            const points: MapPointFeature[] = cocons.map(cocon => {
                const [lat, lng] = [cocon.coordinates.latitude, cocon.coordinates.longitude];
                return {
                    type: "Feature",
                    properties: {
                        coconId: cocon.id,
                        position: { lat, lng },
                        pendingIssues: cocon.pendingIssues,
                        status: cocon.status,
                        networkStatus: cocon.networkStatus,
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [lng, lat],
                    },
                };
            });
            const { center, bounds } = getMapState(points);
            dispatch(CoconsMapActions.setMapPoints({
                center,
                bounds,
                points,
            }));
        }

        return cocons;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(CoconsActions.setCoconsListError(error.message));
        dispatch(showError(error.message));
        return [];
    }
}

const retrieve = (coconId: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCoconActions.startLoadingSelectedCocon());

    try {
        const subcollectionRef = getCollectionGroupRef(COLLECTION);
        const snapshot = await getDocs(subcollectionRef);
        let cocon: Cocon | null = null;
        for (let doc of snapshot.docs) {
            if (doc.id === coconId) cocon = fromDbDoc(doc);
        }
        
        if (!cocon) { // no cocon matchin coconId
            throw new CodeError(NOT_FOUND_ERROR_CODE, NOT_FOUND_ERROR);
        }
        
        dispatch(SelectedCoconActions.setSelectedCocon(cocon));
        return cocon;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(CoconsActions.setCoconsListError(error.message));
        return null;
    }
}
    
const update = (coconId: string, data: Partial<NewCoconData>) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(SelectedCoconActions.startLoadingSelectedCocon());

    try {
        // make request to server as we need to update nested references to Cocon (users, batches...)
        const coconData: APICoconData = await fetchAPI(`${urls.API}/cocon/${coconId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        // update state
        dispatch(SelectedCoconActions.setSelectedCocon(coconData));
        dispatch(CoconsActions.updateListItem({ coconId: coconId, data: coconData }));

        // display success message
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "update_cocon.success",
        }));

        return true;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(SelectedCoconActions.setSelectedCoconError(error.message));

        // display error message
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "update_cocon.error",
        }));

        return false;
    }
}

const getJoinCode = () => async (dispatch: AppDispatch) => {
    dispatch(CoconJoinCodeActions.startLoadingJoinCode());

    try {
        const { joinCode }: { joinCode: string } = await fetchAPI(`${urls.API}/cocon/join-code`);
        dispatch(CoconJoinCodeActions.setJoinCode(joinCode));
        return joinCode;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(CoconJoinCodeActions.setJoinCodeError(error.message));
        return "";
    }
}

export const sendNotification = (coconId: string, notification: NotificationData) => async (dispatch: AppDispatch) => {
    try {
        await fetchAPI(`${urls.API}/cocon/${coconId}/notification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(notification),
        });
        return true;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "something_went_wrong",
        }));
        return false;
    }
}

export default Cocon;

export const CoconsMethods = {
    getFullAddress,
    create,
    list,
    retrieve,
    update,
    getJoinCode,
    sendNotification,
}