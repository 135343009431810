import { TableRow, TableCell, Typography, useTheme } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { getPictureURL, StorageType } from "helpers/storage";
import { ucFirst } from "helpers/strings";
import { Namespaces } from "locales/translations";
import CoconIssue, { CoconIssuesMethods, IssueCoconData, IssueStatus } from "models/Cocons/CoconIssue";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectCoconIssueById } from "store/reducers/cocons/issues";
import CoconIssueActions from "./CoconIssueActions";
import IssueChip from "./IssueChip";

type CoconIssueRowProps = {
    issueId: EntityId;
}

function CoconIssueRow(props: CoconIssueRowProps) {
    const { issueId, } = props;

    const { t } = useTranslation([Namespaces.issues]);

    const theme = useTheme();

    const issue = useAppSelector(state => selectCoconIssueById(state, issueId)!);
    const dispatch = useAppDispatch();

    const handleUpdateClicked = (newStatus: IssueStatus) => {
        dispatch(CoconIssuesMethods.update(issue.cocon.clusterId, issue.cocon.id, issue.id, { status: newStatus }));
    }

    const [pictureURL, setPictureURL] = useState<string>();

    useEffect(() => {
        if (issue.pictureURL) {
            getPictureURL(StorageType.ISSUES, issue.pictureURL)
                .then((imageURL) => {
                    if (imageURL) setPictureURL(imageURL);
                });
        }
    }, [issue.pictureURL]);

    return (
        <TableRow>
            <TableCell>
                {pictureURL &&
                    <img 
                        src={pictureURL} 
                        style={{
                            maxWidth: theme.spacing(40),
                        }}
                        />
                }
            </TableCell>
            <TableCell>
                <Typography variant="body1">
                    {ucFirst(t(`${issue.type}`, { ns: Namespaces.issues }))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1">
                    {issue.description}
                </Typography>
            </TableCell>
            <TableCell>
                {t(`date`, { ns: Namespaces.issues, date: issue.date })}
            </TableCell>
            <TableCell>
                <IssueChip
                    status={issue.status}
                    />
            </TableCell>
            <TableCell style={{ position: "relative" }}>
                <CoconIssueActions
                    loading={issue.loading}
                    status={issue.status}
                    onClick={(status) => handleUpdateClicked(status)}
                />
            </TableCell>
        </TableRow>
    );
}

export default CoconIssueRow;