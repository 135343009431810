import { Typography, Container, } from '@mui/material';
import Reward, { NewRewardData, RewardCategory, RewardsMethods } from 'models/Reward';
import RewardForm, { RewardFormData } from '../RewardForm';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showError } from 'store/reducers/snacks';

type AddRewardPageProps = {
    
}

function AddRewardPage(props: AddRewardPageProps) {

    const { loading, error } = useAppSelector(state => state.rewards.selected);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { t } = useTranslation([Namespaces.titles]);
    
    const [searchParams] = useSearchParams();
    let partnerIdParam = searchParams.get("partnerId") || "";

    const initialData: RewardFormData = {
        partnerId: partnerIdParam,
        name: "",
        description: "",
        category: RewardCategory.E_COMMERCE,
        cost: 0,
        realPrice: 0,
        promoCode: "",
        referralLink: "",
        image: null,
        imageURL: "",
        exclusiveCoconsClusters: []
    };

    const handleSubmit = (data: NewRewardData, image?: File | undefined, promoCodes?: string[] | undefined) => {
        if (!image) {
            dispatch(showError("Une image est nécessaire"));
        }
        else {
            dispatch(RewardsMethods.create(data, image, promoCodes))
                .then((reward) => {
                    if (reward) navigate(`/reward`); // redirect to rewards page after successful creation
                });;
        }
    }

    return (
        <Container>
            <Typography variant="h1">
                {t("add_reward", { ns: Namespaces.titles })}
            </Typography>

            <RewardForm 
                type="create"
                loading={loading}
                rewardData={initialData}
                submitForm={handleSubmit}
                />
        </Container>
    )
}

export default AddRewardPage;