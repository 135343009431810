import { ResponsiveLine } from '@nivo/line';
import React from 'react';

interface LineChartProps {
    data: any[];
    margin: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    },
    colors: {
        [dataName: string]: string,
    },
    min?: number | 'auto';
    max?: number | 'auto';
    stacked: boolean;
    areaBlendMode?: string;
    areaOpacity?: number;
    enablePoints?: boolean;
    lineWidth?: number;
    tooltip?: React.FunctionComponent<any>;
    axisBottom: any;
    axisLeft: any;
    legends?: any[],
    defs?: any[],
    fill?: any[],
};

function LineChart(props: LineChartProps) {
    let { data, colors, areaBlendMode, areaOpacity, margin, min, max, stacked, enablePoints, lineWidth, axisLeft, axisBottom, legends, defs, fill, tooltip } = props;
    min = min !== undefined ? min : 'auto';
    max = max !== undefined ? max : 'auto';
    areaBlendMode = areaBlendMode || "normal";
    areaOpacity = areaOpacity !== undefined ? areaOpacity : 0.2;
    enablePoints = enablePoints !== undefined ? enablePoints : true;
    lineWidth = lineWidth !== undefined ? lineWidth : 2;

    const getColor = (line: any) => { return colors[line.id] };

    return (
        <ResponsiveLine
            data={data}
            margin={margin}
            curve='monotoneX'
            enablePoints={enablePoints}
            lineWidth={lineWidth}
            enableArea={true}
            areaBlendMode={areaBlendMode}
            areaOpacity={areaOpacity}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: min, max: max, stacked: stacked, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 8,
                tickPadding: 8,
                tickRotation: -45,
                legend: axisBottom.legend,
                legendOffset: 80,
                legendPosition: 'middle',
                format: axisBottom.format,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisLeft.legend,
                legendOffset: -48,
                legendPosition: 'middle',
                format: axisLeft.format,
            }}
            colors={getColor}
            pointSize={6}
            useMesh={true}
            legends={legends}
            enablePointLabel={false}
            enableGridY={true}
            tooltip={tooltip}
            defs={defs}
            fill={fill}
        />
    );
}

export default LineChart;