import { Box } from "@mui/material";
import { QueryFilter } from "constants/types";
import { BatchesMethods } from "models/Batches/Batch";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import DemoBatchCard from "../BatchComponents/DemoCard/DemoBatchCard";
import MatrixBackground from "./MatrixBackground";
import BatchDemoDetail from "./BatchDemoDetail";

type BatchDemoPageProps = {

}

function BatchDemoPage({ }: BatchDemoPageProps) {

    return (
        <Box 
            width="100%" 
            height="100%" 
            display="flex" 
            justifyContent="center"
            alignItems="center"
            bgcolor="#111"
            >
            {/* <MatrixBackground /> */}
            
            <BatchDemoDetail />
        </Box>
    );
}

export default BatchDemoPage;