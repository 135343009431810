import { Box, ImageList, } from "@mui/material";
import ImageToAnnotateItem from "./ImageToAnnotateItem";
import LoadMoreBatches from "./LoadMoreBatches";
import { useEffect, useState } from "react";
import { usePrevious } from "helpers/refs";
import { useAppSelector } from "store/hooks";
import SectionLoader from "components/_include/SectionLoader";
import SelectedBatchDialog from "./SelectedBatchDialog";

type ImagesToAnnotateProps = {

}

export const BATCH_IMAGE_HEIGHT = 246;

function ImagesToAnnotatePage(props: ImagesToAnnotateProps) {

    const batchesLoading = useAppSelector(state => state.batches.list.loading);
    const batchesIds = useAppSelector(state => state.batches.list.ids);
    const nextQuery = useAppSelector(state => state.batches.list.next);

    const [hasLoaded, setHasLoaded] = useState(false);

    const wasLoading = usePrevious(batchesLoading);
    useEffect(() => {
        if (!hasLoaded && wasLoading && !batchesLoading) {
            console.debug("has loaded")
            setHasLoaded(true);
        }
        console.debug("batches", batchesIds.length);
    }, [batchesLoading]);

    const [selectedBatchId, setSelectedBatchId] = useState<string>();

    return (
        <Box>
            {batchesLoading && (
                <SectionLoader />
            )}

            {hasLoaded && (
                <Box>
                    <ImageList cols={3} rowHeight={BATCH_IMAGE_HEIGHT}>
                        {batchesIds.map(batchId => (
                            <ImageToAnnotateItem
                                key={batchId}
                                batchId={batchId.toString()}
                                onSelect={(batchId) => setSelectedBatchId(batchId)}
                            />
                        ))}
                    </ImageList>

                    {nextQuery && (
                        <LoadMoreBatches />
                    )}
                </Box>
            )}

            <SelectedBatchDialog
                batchId={selectedBatchId}
                onClose={() => setSelectedBatchId(undefined)}
                />
        </Box>
    );
};

export default ImagesToAnnotatePage;
