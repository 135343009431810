import { Box, IconButton } from "@mui/material";
import urls from "constants/urls";
import { MarkerColor } from "helpers/geo";
import _ from "lodash";

type MarkerProps = {
    color: MarkerColor;
    lat: number;
    lng: number;
    selected: boolean;
    onClick: () => void;
}

const MARKER_HEIGHT = 50;
const SELECTED_MARKER_HEIGHT = 75;
const MARKER_RATIO = 5 / 3;
const BOTTOM_SHADOW_RATIO = 2 / 15;

export default function MapMarker(props: MarkerProps) {
    const { color, selected, onClick } = props;

    const height = selected ? SELECTED_MARKER_HEIGHT : MARKER_HEIGHT;
    const width = height / MARKER_RATIO;

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <IconButton
                onClick={onClick}
                sx={{
                    width: width,
                    height: height,
                    marginTop: `${-height * (1 - BOTTOM_SHADOW_RATIO)}px`, // some part of the marker image is it's shadow
                    // marginLeft: `${-width/2}px`,
                    padding: 0,
                    background: "none",
                    "&:hover": {
                        background: "none",
                    }
                }}>
                <img
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                    src={`${urls.ICONS_FOLDER}/markers/marker_${color}.svg`}
                />
            </IconButton>
        </Box>
    );
}