import i18next from "i18next";
import { Namespaces } from "../../locales/translations";
import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ActionResultInterface {
    variant: AlertColor;
    message: string;
}

const initialState: ActionResultInterface = {
    variant: "info" as "info",
    message: "",
};

const tContext = { ns: Namespaces.snacks };

export const snacksSlice = createSlice({
    name: 'snacks',
    initialState: initialState,
    reducers: {
        showSuccess: (state, { payload }: PayloadAction<string>) => {
            state.variant = "success";
            state.message = payload;
        },
        showError: (state, { payload }: PayloadAction<string>) => {
            state.variant = "error";
            state.message = payload;
        },
        showMessage: (state, { payload }: PayloadAction<{ variant: AlertColor, message: string }>) => {
            state.variant = payload.variant;
            state.message = payload.message;
        },
        showTranslatedMessage: (state, { payload }: PayloadAction<{ variant: AlertColor, messageKey: string }>) => {
            state.variant = payload.variant;
            state.message = i18next.t(payload.messageKey, tContext);
        },
        hideMessage: (state) => {
            console.debug("dispatching");
            // state.variant = "info";
            // state.message = "";
            return {
                variant: "info",
                message: "",
            };
        },
    },
});

export const { showSuccess, showError, showMessage, showTranslatedMessage, hideMessage, } = snacksSlice.actions;

const SnackReducer =  snacksSlice.reducer;

export default SnackReducer;