import { useState, useEffect } from 'react';
import { Typography, Container, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, Avatar, Box, Link as MuiLink } from '@mui/material';
import Partner, { PartnersMethods } from 'models/Partner';
import EmailIcon from '@mui/icons-material/Email';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import PartnerDetailDialog from './PartnerDetailDialog';
import SectionLoader from '../../_include/SectionLoader';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Link } from 'react-router-dom';

type ListPartnersPageProps = {

}

function ListPartnersPage(props: ListPartnersPageProps) {

    const { partners, loading } = useAppSelector(state => state.partners);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.glossary, Namespaces.titles,]);

    useEffect(() => {
        dispatch(PartnersMethods.list([]));
    }, []);

    const [partnerSelected, setPartnerSelected] = useState<Partner | null>(null);

    return (
        <Container>
            <Typography variant="h1">
                {t("partners.main", { ns: Namespaces.titles })}
            </Typography>
            {
                loading ?
                    <SectionLoader />
                    :
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("name", { ns: Namespaces.commons })}</TableCell>
                                    <TableCell>{t("category", { ns: Namespaces.glossary })}</TableCell>
                                    <TableCell>{t("email", { ns: Namespaces.commons })}</TableCell>
                                    {/* <TableCell>Téléphone</TableCell> */}
                                    <TableCell>{t("website", { ns: Namespaces.commons })}</TableCell>
                                    <TableCell>{t("actions", { ns: Namespaces.actions })}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partners.map((partner: Partner, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Avatar
                                                    src={partner.imageURL}
                                                    sx={{
                                                        width: (theme) => theme.spacing(7),
                                                        height: (theme) => theme.spacing(7),
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    sx={{ ml: 2 }}
                                                >
                                                    {partner.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {partner.category}
                                        </TableCell>
                                        <TableCell>
                                            <MuiLink
                                                href={`mailto:${partner.email}`}>
                                                {partner.email}
                                                <EmailIcon sx={{ margin: "-8px 8px" }} />
                                            </MuiLink>
                                        </TableCell>
                                        {/* <TableCell>
                                        {partner.phone}
                                    </TableCell> */}
                                        <TableCell>
                                            <MuiLink
                                                target="_blank"
                                                href={partner.website}>
                                                {partner.website}
                                            </MuiLink>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={(event) => setPartnerSelected(partner)}
                                                startIcon={<DescriptionIcon />}>
                                                {t("details", { ns: Namespaces.actions })}
                                            </Button>

                                            <Link
                                                to={`/reward/create/?partnerId=${partner.id}`}
                                            >
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    startIcon={<AddBoxIcon />}>
                                                    {t("reward", { ns: Namespaces.glossary })}
                                                </Button>
                                            </Link>

                                            <Link
                                                to={`/store/create/?partnerId=${partner.id}`}
                                            >
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    startIcon={<AddBoxIcon />}>
                                                    {t("store", { ns: Namespaces.glossary })}
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
            <PartnerDetailDialog
                partner={partnerSelected}
                onClose={(event) => setPartnerSelected(null)} />
        </Container>
    )
}

export default ListPartnersPage;