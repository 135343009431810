import { Container, Typography, Grid } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import StatsDatesSelect from "./StatsDatesSelect";
import SortingQualityChart from "./Charts/SortingQualityChart";
import BatchesCountChart from "./Charts/BatchesCountChart";
import ActiveUsersChart from "./Charts/ActiveUsersChart";
import CO2SavedChart from "./Charts/CO2SavedChart";
import TrashTypesChart from "./Charts/TrashTypesChart";

type StatisticsProps = {

}

function StatisticsPage(props: StatisticsProps) {

    const { t } = useTranslation([Namespaces.commons, Namespaces.glossary, Namespaces.stats, Namespaces.titles]);

    return (
        <Container>
            <div>
                <Typography variant="h1">{t("statistics", { ns: Namespaces.titles })}</Typography>
            </div>
            
            <StatsDatesSelect />

            <Grid container>
                <BatchesCountChart />

                <ActiveUsersChart />

                <CO2SavedChart />

                <TrashTypesChart />

                <SortingQualityChart />
            </Grid>
        </Container>
    )
};

export default StatisticsPage;
