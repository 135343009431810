import { Dialog, DialogTitle, DialogContent, Tabs, Tab, AppBar, Box } from "@mui/material";
import { ChatRecipients, ChatTabTypes } from "constants/types";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { MessageAttachment, MessagesMethods } from "models/Message";
import User, { UsersMethods } from "models/User";
import { useState } from "react";
import SendConversationPanel from "./SendConversationPanel";
import SendMessagePanel from "./SendMessagePanel";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface ContactsChatDialogProps {
    recipients: User[];
    open: boolean;
    onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

function ContactsChatDialog(props: ContactsChatDialogProps) {
    const { open, onClose, recipients, } = props;

    const { sendingMessage, sendingConversation, conversations } = useAppSelector(state => ({
        sendingMessage: state.messages.sendingMessage.loading,
        sendingConversation: state.messages.sendingConversation.loading,
        conversations: state.messages.conversations.data,
    }));
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.glossary,]);

    const recipientsInfo: ChatRecipients = {
        count: recipients.length,
        name: recipients.length > 0 ? UsersMethods.getDisplayName(recipients[0]) : "",
    };

    const [selectedTab, setSelectedTab] = useState<ChatTabTypes>(ChatTabTypes.MESSAGE);

    const sendMessage = async (message: string, attachment?: MessageAttachment | undefined) => {
        await dispatch(MessagesMethods.batchSend(recipients.map(user => user.id), message, attachment));
    }

    const sendConversation = async (conversationId: string) => {
        const selectedConversation = conversations.find(c => c.id === conversationId);
        if (selectedConversation) {
            await dispatch(selectedConversation.batchSend(recipients.map(user => user.id)));
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                {t(`send_message_to_user`, {
                    ns: Namespaces.actions,
                    ...recipientsInfo,
                })}
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={selectedTab}
                        onChange={(e, value) => setSelectedTab(value)}
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                        sx={{
                            ml: 1,
                        }}
                    >
                        {Object.values(ChatTabTypes).map((type) => (
                            <Tab
                                key={type}
                                value={type}
                                label={t(type, { ns: Namespaces.glossary })}
                                aria-controls={`tabpannel-${type}`}
                            />
                        ))}
                    </Tabs>
                </AppBar>

                {Object.values(ChatTabTypes).map((type) => (
                    <Box
                        key={type}
                        role="tabpanel"
                        hidden={type !== selectedTab}
                        id={`tabpanel-${type}`}
                        padding={2}
                    >
                        {type === ChatTabTypes.MESSAGE &&
                            <SendMessagePanel
                                recipients={recipientsInfo}
                                sendingMessage={sendingMessage}
                                onSendMessage={sendMessage}
                            />
                        }
                        {type === ChatTabTypes.CONVERSATION &&
                            <SendConversationPanel
                                // recipients={recipientsInfo}
                                sendingConversation={sendingConversation}
                                onSendConversation={sendConversation}
                            />
                        }
                    </Box>
                ))}
            </DialogContent>
        </Dialog>
    );
}

export default ContactsChatDialog;

