import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.8492 4.08813C30.8387 4.02949 30.8333 3.97003 30.8333 3.91045V1C30.8333 0.447715 30.3856 0 29.8333 0H10.1667C9.61438 0 9.16667 0.447715 9.16667 1V3.91045C9.16667 3.97003 9.16134 4.02949 9.15075 4.08813L7.01591 15.9119C7.00533 15.9705 7 16.03 7 16.0896V39C7 39.5523 7.44772 40 8 40H32C32.5523 40 33 39.5523 33 39V16.0896C33 16.03 32.9947 15.9705 32.9841 15.9119L30.8492 4.08813ZM28.6667 2V4H26.4203C26.1272 4 25.8537 3.86609 25.6351 3.67098C24.602 2.7493 22.7382 2.09792 20.5685 2H28.6667ZM11.3333 2H19.4315C17.2618 2.09792 15.398 2.7493 14.3649 3.67098C14.1463 3.8661 13.8728 4 13.5797 4H11.3333V2ZM11.0023 6H13.5V8.666C13.5 10.5096 16.4094 12 20 12C23.5906 12 26.5 10.5096 26.5 8.666V6H28.9977L30.4433 14H9.5571L11.0023 6ZM30.8333 38H9.16667V26C9.16667 24.6528 14.9413 21.4212 20.4823 24.832C26.2092 28.3564 28.788 28.9008 30.8333 28.9848V38Z" fill="#EFEFEF" />
            <path d="M19.4315 2H11.3334V4H13.5798C13.8729 4 14.1463 3.8661 14.365 3.67098C15.3981 2.7493 17.2618 2.09792 19.4315 2Z" fill="#CCCCCC" />
            <path d="M28.6666 4V2H20.5685C22.7382 2.09792 24.6019 2.7493 25.635 3.67098C25.8537 3.86609 26.1271 4 26.4202 4H28.6666Z" fill="#CCCCCC" />
            <path d="M13.5 6H11.0023L9.55713 14H30.4434L28.9978 6H26.5V8.666C26.5 10.5096 23.5906 12 20 12C16.4094 12 13.5 10.5096 13.5 8.666V6Z" fill="#CCCCCC" />
            <path d="M9.16663 38H30.8333V28.9848C28.788 28.9008 26.2092 28.3564 20.4823 24.832C14.9412 21.4212 9.16663 24.6528 9.16663 26V38Z" fill="#CCCCCC" />
            <path d="M20 6.666C22.7161 6.666 24.2224 5.6836 24.3368 5.3124C24.2367 5.0292 22.7443 4 20 4C17.2761 4 15.7858 5.014 15.6667 5.348V5.3624C15.7988 5.6972 17.3025 6.666 20 6.666Z" fill="#336699" />
            <path d="M20 10C22.7443 10 24.2367 8.9708 24.3368 8.6444L24.3359 6.8164C23.1859 7.3444 21.6666 7.666 20 7.666C18.3347 7.666 16.8163 7.3452 15.6667 6.8176V8.666C15.7629 8.9708 17.2557 10 20 10Z" fill="#336699" />
            <path d="M21.6843 23.168C26.8328 26.336 29.0545 26.8852 30.8333 26.98V16H9.16663V23.024C10.4636 22.1708 15.7247 19.4984 21.6843 23.168Z" fill="#336699" />
        </svg>
    </SvgIcon>
);