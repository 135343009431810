import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M9 17.9104C13.9706 17.9104 18 13.9011 18 8.95522C18 4.00939 13.9706 0 9 0C4.02944 0 0 4.00939 0 8.95522C0 13.9011 4.02944 17.9104 9 17.9104Z" fill="#336699" />
                <path d="M14.4 21.8906H3.6C1.6 21.8906 0 23.4827 0 25.4727V36.418C0 38.408 1.6 40.0001 3.6 40.0001H14.6C16.6 40.0001 18.2 38.408 18.2 36.418V25.4727C18 23.4827 16.4 21.8906 14.4 21.8906Z" fill="#FF5722" />
                <path d="M36.4001 0H25.4C23.4 0 21.8 1.59204 21.8 3.58209V14.5274C21.8 16.5174 23.4 18.1095 25.4 18.1095H36.4001C38.4001 18.1095 40.0001 16.5174 40.0001 14.5274V3.58209C40.0001 1.59204 38.4001 0 36.4001 0Z" fill="#FF3399" />
                <path d="M37.0001 27.8608H34.0001V24.8757C34.0001 23.2837 32.6001 21.8906 31.0001 21.8906C29.4001 21.8906 28.0001 23.2837 28.0001 24.8757V27.8608H25.0001C23.4001 27.8608 22.0001 29.2538 22.0001 30.8458C22.0001 32.4379 23.4001 33.8309 25.0001 33.8309H28.0001V36.816C28.0001 38.408 29.4001 39.8011 31.0001 39.8011C32.6001 39.8011 34.0001 38.408 34.0001 36.816V33.8309H37.0001C38.6001 33.8309 40.0001 32.4379 40.0001 30.8458C40.0001 29.2538 38.6001 27.8608 37.0001 27.8608Z" fill="#673AB7" />
            </g>
        </svg>
    </SvgIcon>
);