import { Box, Typography } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import IdentifiedBatchesFilter, { IdentifiedBatchesOptions } from "./IdentifiedBatchesFilter";
import { useSearchParams } from "react-router-dom";
import DateRangePicker from "components/_include/Filters/DateRangePicker";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { BatchesMethods } from "models/Batches/Batch";
import { QueryFilter } from "constants/types";
import { Timestamp } from "firebase/firestore";
import { BatchesToDoSorting } from "store/reducers/batches/batch";

type BatchesToDoFiltersProps = {

}

const LOAD_LIMIT = 1000;

/**
 * Filtersing options for the header of the "to do" batches list 
 */
function BatchesToDoFilters({ }: BatchesToDoFiltersProps) {

    const { t } = useTranslation([Namespaces.batches]);

    const batchesCount = useAppSelector(state => state.batches.list.ids.length);

    const sorting = useAppSelector(state => state.batches.list.todoListSorting);

    const [searchParams, setSearchParams] = useSearchParams();

    const startParam = searchParams.get("start");
    const endParam = searchParams.get("end");

    const startMoment = startParam ? moment(startParam) : null;
    const endMoment = endParam ? moment(endParam) : null;

    const identifiedParam = (searchParams.get("identified") || IdentifiedBatchesOptions.ALL) as IdentifiedBatchesOptions;

    const handleDatesChange = (dates: { start: moment.Moment, end: moment.Moment }) => {
        searchParams.set("start", dates.start.format("YYYY-MM-DD"));
        searchParams.set("end", dates.end.format("YYYY-MM-DD"));
        setSearchParams(searchParams);
    }

    const handleIdentifiedFilterChange = (value: IdentifiedBatchesOptions) => {
        searchParams.set("identified", value);
        setSearchParams(searchParams);
    }

    const dispatch = useAppDispatch();

    /** Set default dates to last week */
    useEffect(() => {
        if (!startMoment || !endMoment) {
            let end = endMoment ?? moment();
            let start = startMoment ?? end.clone().subtract(7, "days");
            handleDatesChange({
                start,
                end,
            });
        }
    }, [startMoment, endMoment]);

    /** Load list of batches "to do" */
    useEffect(() => {
        if (!startParam || !endParam) return; // need some dates

        const filters: QueryFilter[] = [
            { fieldPath: "isProcessed", opStr: "==", value: false },
            { fieldPath: "timestamp", opStr: ">=", value: Timestamp.fromDate(moment(startParam).startOf("day").toDate()) },
            { fieldPath: "timestamp", opStr: "<", value: Timestamp.fromDate(moment(endParam).endOf("day").toDate()) },
        ];

        if (identifiedParam === IdentifiedBatchesOptions.IDENTIFIED_ONLY) {
            filters.push({ fieldPath: "user.id", opStr: "!=", value: "" }); // will also filter batches without user.id
        }

        dispatch(BatchesMethods.list(filters, LOAD_LIMIT, { fieldPath: "timestamp", directionStr: sorting === BatchesToDoSorting.OLDEST_FIRST ? "asc" : "desc" }));
    }, [startParam, endParam, identifiedParam, sorting]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
            flexGrow={1}
        >
            <Typography variant="h5">
                {t("filters.title", { ns: Namespaces.batches })}
            </Typography>

            <Box
                display="flex"
                gap={2}
                flexGrow={1}
                alignItems="center"
            >
                <Box
                    display="flex"
                    gap={2}
                    flexGrow={1}
                >
                    <Box width={208}>
                        <DateRangePicker
                            start={startMoment}
                            end={endMoment}
                            onChange={handleDatesChange}
                        />
                    </Box>

                    <IdentifiedBatchesFilter
                        value={identifiedParam}
                        onChange={handleIdentifiedFilterChange}
                    />
                </Box>

                <Typography fontStyle="italic">
                    {t("filters.matching_result", { ns: Namespaces.batches, count: batchesCount })}
                </Typography>
            </Box>
        </Box>
    );
}

export default BatchesToDoFilters;