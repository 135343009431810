import { Button, Typography } from "@mui/material";
import { Bounds, Coords } from "google-map-react";
import { MarkerColor } from "helpers/geo";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";

type CoconsMarkersClusterProps = {
    id: string;
    coconsCount: number;
    coconsIds: string[];
    color: MarkerColor;
    lat: number;
    lng: number;
    bounds: Bounds;
    isSelected: boolean;
    onClick: () => void;
}

const MARKER_HEIGHT = 40;
const SELECTED_MARKER_HEIGHT = 50;

function CoconsMarkersCluster(props: CoconsMarkersClusterProps) {
    const { id, coconsCount, coconsIds, lat, lng, color, bounds, isSelected, onClick, } = props;

    const height = isSelected ? SELECTED_MARKER_HEIGHT : MARKER_HEIGHT;

    return (
        <Button
            onClick={onClick}
            sx={{
                border: `2px #ffffff solid`,
                borderRadius: height / 2,
                backgroundColor: `${color}.light`,
                width: height,
                minWidth: height,
                height: height,
                marginLeft: `${-height / 2}px`,
                marginTop: `${-height / 2}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                    backgroundColor: `${color}.light`,
                    // borderColor: "rgba(0,0,0,0.2)",
                }
            }}
        >
            <Typography color="#ffffff">
                {coconsCount}
            </Typography>
        </Button>
    );
}

export default CoconsMarkersCluster;