import { useEffect } from "react";
import { Typography, Container } from "@mui/material";
import { UsersMethods } from 'models/User';
import UsersList from './UsersList';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Namespaces } from "locales/translations";
import { useAppDispatch, useAppSelector } from "store/hooks";

type UsersListPageProps = {

}

function UsersListPage(props: UsersListPageProps) {

    const { t } = useTranslation([Namespaces.titles]);

    const { users, usersLoading, } = useAppSelector(state => {
        const users = state.users.users;
        return {
            users: users.data,
            usersLoading: users.loading,
        };
    });
    const dispatch = useAppDispatch();

    const { coconId } = useParams();

    useEffect(() => {
        if (coconId) {
            // load cocon users list
            dispatch(UsersMethods.list([{
                fieldPath: "cocon.id",
                opStr: "==",
                value: coconId,
            }],
                { fieldPath: 'score', directionStr: 'desc' }
            ));
        }
    }, [coconId]);

    return (
        <Container>
            <Typography variant="h1">
                {t("cocon_users", { ns: Namespaces.titles })}
            </Typography>
            <UsersList
                users={users}
                loading={usersLoading} />
        </Container>
    );
}


export default UsersListPage;
