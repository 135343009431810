import { CodeError } from "actions/actions";
import { Collections, DBIdentifiers } from "constants/db";
import { QueryFilter } from "constants/types";
import { Timestamp } from "firebase/firestore";
import { getDocumentReference, listSubcollectionDocs, updateDocument } from "helpers/db";
import { Coordinates } from "helpers/geo";
import moment from "moment";
import { CurrentPurchasesActions } from "store/reducers/purchases/list_current";
import { PastPurchasesActions } from "store/reducers/purchases/list_past";
import { SelectedPurchaseActions } from "store/reducers/purchases/selected";
import { showTranslatedMessage } from "store/reducers/snacks";
import { AppDispatch } from "store/store";
import { RewardCategory } from "./Reward";
import { UserDbData } from "./User";

export enum PurchaseStatuses {
    PURCHASED = "purchased",
    SENT = "sent",
    RECEIVED = "received",
};

export type Timeline = Partial<Record<PurchaseStatuses, Timestamp>>;
type MomentTimeline = Partial<Record<PurchaseStatuses, moment.Moment>>;

type UserData = Pick<UserDbData, "email" | "firstName" | "lastName" | "locale" | "pin" | "address"> & {
    id: string;
}

type RewardData = {
    id: string;
    name: string;
    description: string;
    category: RewardCategory;
    cost: number;
    imageURL: string;
    partner: {
        id: string;
        name: string;
        website: string;
        imageURL: string;
    },
    stores: {
        id: string;
        address: string;
        coordinates: Coordinates;
        name: string;
    },
}

export type PurchaseDbData = {
    reward: RewardData;
    user: UserData;
    status: PurchaseStatuses;
    timeline: Timeline;
}

type Purchase = PurchaseDbData & DBIdentifiers;

const COLLECTION = Collections.PURCHASES;

function getDocRef(id: string, userId: string) {
    return getDocumentReference(id, COLLECTION, `${Collections.USERS}/${userId}`);
}

const compareChronologically = (purchase1: Purchase, purchase2: Purchase) => {
    const statusesOrder = {
        [PurchaseStatuses.PURCHASED]: 1,
        [PurchaseStatuses.SENT]: 2,
        [PurchaseStatuses.RECEIVED]: 3,
    };

    const status1 = purchase1.status;
    const status2 = purchase2.status;
    if (status1 !== status2) {
        return statusesOrder[status1] < statusesOrder[status2] ? -1 : 1;
    }

    // same status, compare timestamps
    const t1 = purchase1.timeline[status1] || 0;
    const t2 = purchase2.timeline[status2] || 0;
    return t1 === t2 ? 0 : (t1 < t2 ? 1 : -1);
};

function getTimeline(purchase: Purchase): MomentTimeline {
    let timeline: MomentTimeline = {};
    Object.entries(purchase.timeline).forEach(([status, timestamp]) => {
        timeline[status as PurchaseStatuses] = moment(timestamp.toDate());
    });
    
    return timeline;
}

const list = (filters: QueryFilter[]) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(CurrentPurchasesActions.startLoadingList());
    dispatch(PastPurchasesActions.startLoadingList());

    try {
        // query database
        const purchases = await listSubcollectionDocs<Purchase>(COLLECTION, undefined, filters);

        // separate between current and past ones
        let currentPurchases: Purchase[] = [];
        let pastPurchases: Purchase[] = [];

        for (let purchase of purchases) {
            if (purchase.reward.category !== RewardCategory.SENT_BY_FICHA || purchase.status === PurchaseStatuses.RECEIVED) pastPurchases.push(purchase);
            else currentPurchases.push(purchase);
        }

        // dispatch loaded list to state
        dispatch(CurrentPurchasesActions.setList(currentPurchases));
        dispatch(PastPurchasesActions.setList(pastPurchases));

        return purchases;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(CurrentPurchasesActions.setListError(error.message));
        dispatch(PastPurchasesActions.setListError(error.message));
        return [];
    }
}

const update = (oldPurchase: Purchase, data: Partial<PurchaseDbData>) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(SelectedPurchaseActions.startLoadingSelected());

    try {
        const ref = getDocRef(oldPurchase.id, oldPurchase.user.id);
        const purchase = await updateDocument(ref, oldPurchase, data);

        // update state
        dispatch(SelectedPurchaseActions.setSelected(null));
        dispatch(CurrentPurchasesActions.updateListItem(purchase));

        // display success snackbar
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "update_purchase.success",
        }));

        return purchase;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(SelectedPurchaseActions.setSelectedError(error.message));

        // display error snackbar
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "update_purchase.error",
        }));
        
        return oldPurchase;
    }
}

const updateStatus = (purchase: Purchase, status: PurchaseStatuses) => async (dispatch: AppDispatch) => {
    return dispatch(update(purchase, {
        status: status,
        timeline: {
            ...purchase.timeline,
            [status]: Timestamp.now()
        }
    }));
}

export default Purchase;

export const PurchasesMethods = {
    compareChronologically,
    getTimeline,
    list,
    updateStatus,
}