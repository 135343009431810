import { Box, Typography } from "@mui/material";
import Message, { MessageAttachmentType, MessagesMethods } from "../../../../models/Message";
import YouTube from 'react-youtube';
import { makeStyles } from "@mui/styles";

interface MediaBubbleProps {
    message: Message;
}

function MediaBubble(props: MediaBubbleProps) {
    const classes = useStyles();

    const { message } = props;

    const getMedia = () => {
        if (!message.attachment) return undefined;

        // attachment can be image or video URL
        switch (message.attachment.type) {
            case MessageAttachmentType.IMAGE:
                return (
                    <img 
                        src={message.attachment.url} 
                        className={classes.mediaWrapper}
                        />
                );

            case MessageAttachmentType.VIDEO:
                const attachmentVideo = MessagesMethods.getAttachmentVideo(message);
                if (attachmentVideo && attachmentVideo.platform === "youtube") {
                    return (
                        <YouTube
                            videoId={attachmentVideo.videoId}
                            className={classes.mediaWrapper}
                        />
                    );
                }
                break;
        }

        return undefined;
    }

    return (
        <Box
            pb={2}
            pl={4}
            pr={8}
            display="flex"
            justifyContent={"flex-start"}
        >
            <Box 
                borderRadius={2}
                boxShadow="0 4px 20px rgba(0,0,0,0.2)"
                bgcolor={theme => theme.palette.secondary.main}
                color="#ffffff"
                overflow="hidden"
                >
                <Typography
                    variant="body1"
                    sx={{
                        margin: 2,
                    }}
                >
                    {message.message}
                </Typography>
                {getMedia()}
            </Box>
        </Box>
    );
}

const useStyles = makeStyles({
    mediaWrapper: {
        mb: '-5px',
        width: "100%"
    }
});

export default MediaBubble;