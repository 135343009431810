import { Paper, Typography, useTheme } from "@mui/material";
import BarChart from "./BarChart";
import { ChartsStrings } from "constants/charts";
import { Namespaces } from "locales/translations";
import SectionLoader from "../../../_include/SectionLoader";
import { useTranslation } from "react-i18next";
import ChartTitle from "./ChartTitle";
import ChartWrapper from "../ChartWrapper";
import { useAppSelector } from "store/hooks";
import TooltipWrapper from "./TooltipWrapper";

type ChartProps = {

}

function BatchesCountChart(props: ChartProps) {

    const { t } = useTranslation([Namespaces.glossary, Namespaces.stats]);

    const theme = useTheme();

    const timeUnit = useAppSelector(state => state.stats.data.timeUnit);
    const batchesStats = useAppSelector(state => state.stats.batches);

    const loading = useAppSelector(state => state.stats.loading);

    return (
        <ChartWrapper>
            <ChartTitle
                title={t("number_of_batches_per", {
                    ns: Namespaces.stats,
                    context: timeUnit,
                })}
            />
            {
                loading ?
                    <SectionLoader />
                    :
                    <BarChart
                        timeUnit={timeUnit}
                        data={batchesStats}
                        colors={{ [ChartsStrings.COUNT]: theme.palette.secondary.main }}
                        legends={{ axisLeft: t("number_of_batches", { ns: Namespaces.glossary }) }}
                        margin={{ top: 50, right: 60, bottom: 88, left: 60 }}
                        tooltip={(index, value, dates) => (
                            <TooltipWrapper>
                                <Typography variant="caption">
                                    {t("date_full", {
                                        ns: Namespaces.stats,
                                        context: timeUnit,
                                        date: dates[index],
                                    })}
                                </Typography>
                                <Typography variant="body1">
                                    {t("batches_received_count", { ns: Namespaces.stats, count: value })}
                                </Typography>
                            </TooltipWrapper>
                        )}
                    />
            }
        </ChartWrapper>
    )
};

export default BatchesCountChart;