import MapMarker from "components/_include/Map/MapMarker";
import { MarkerColor } from "helpers/geo";
import Cocon from "models/Cocons/Cocon";
import { useAppSelector } from "store/hooks";
import { selectCoconById } from "store/reducers/cocons/cocons";

type CoconMarkerProps = {
    coconId: string;
    color: MarkerColor;
    lat: number;
    lng: number;
    onClick: (cocon: Cocon) => void;
}

export default function CoconMarker(props: CoconMarkerProps) {
    const { coconId, color, lat, lng, onClick } = props;

    const cocon = useAppSelector(state => selectCoconById(state, coconId)!);
    const isSelected = useAppSelector(state => state.cocons.selected.data?.id === coconId);

    return (
        <MapMarker
            color={color}
            lat={lat}
            lng={lng}
            selected={isSelected}
            onClick={() => onClick(cocon)}
            />
    );
}