import { useEffect, useState } from "react";
import { Select, MenuItem, Divider, Box, useTheme, InputLabel, FormControl } from "@mui/material";
import { useParams, useSearchParams } from 'react-router-dom';
import moment from "moment";
import { Namespaces } from "locales/translations";
import { QueryFilters } from "../../../constants/types";
import { useTranslation } from "react-i18next";
import Datepicker from "components/_include/DateTimePickers/Datepicker";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CoconsMethods } from "models/Cocons/Cocon";
import LoadStatsActions from "actions/stats";
import { DATE_SELECT_TYPES, getDatesFromRange } from "helpers/dates";

type StatsDatesSelectProps = {

}

function StatsDatesSelect(props: StatsDatesSelectProps) {

    const { t } = useTranslation([Namespaces.commons]);

    const cocon = useAppSelector(state => state.cocons.selected.data);

    const dispatch = useAppDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const { coconId } = useParams();

    let defaultDateSelectType = DATE_SELECT_TYPES.LAST_THREE_MONTHS;
    let defaultStartDate: moment.Moment | null = null;
    let defaultEndDate: moment.Moment | null = null;
    const dateSelectParam = searchParams.get("dateSelect");
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");
    if (dateSelectParam) {
        if (dateSelectParam === DATE_SELECT_TYPES.CUSTOM) {
            if (startDateParam && endDateParam) {
                const tmpStart = moment(startDateParam);
                const tmpEnd = moment(endDateParam);

                if (tmpStart.isValid() && tmpEnd.isValid()) {
                    defaultDateSelectType = DATE_SELECT_TYPES.CUSTOM;
                    defaultStartDate = tmpStart;
                    defaultEndDate = tmpEnd;
                }
            }
        }
        else if (dateSelectParam === DATE_SELECT_TYPES.SINCE_INSTALLATION) {
            if (dateSelectParam) {
                const tmpStart = moment(dateSelectParam);

                if (tmpStart.isValid()) {
                    defaultDateSelectType = DATE_SELECT_TYPES.SINCE_INSTALLATION;
                    defaultStartDate = tmpStart;
                }
            }
        }
        else {
            for (let dateSelectType of Object.values(DATE_SELECT_TYPES)) {
                if (dateSelectType === dateSelectParam) {
                    defaultDateSelectType = dateSelectType;
                }
            }
        }
    }

    useEffect(() => {
        if (coconId) {
            dispatch(CoconsMethods.retrieve(coconId));
        }
    }, [coconId]);

    const [startDate, setStartDate] = useState<moment.Moment | null>(defaultStartDate);
    const [endDate, setEndDate] = useState<moment.Moment | null>(defaultEndDate);

    const [dateSelectType, setDateSelectType] = useState(defaultDateSelectType);

    useEffect(() => {
        if (startDate && endDate) {
            const startDay = startDate.format("YYYY-MM-DD");
            const endDay = endDate.format("YYYY-MM-DD")

            if (dateSelectType === DATE_SELECT_TYPES.CUSTOM) {
                searchParams.set("dateSelect", DATE_SELECT_TYPES.CUSTOM);
                searchParams.set("startDate", startDay);
                searchParams.set("endDate", endDay);
                setSearchParams(searchParams);
            }
            else if (dateSelectType === DATE_SELECT_TYPES.SINCE_INSTALLATION) {
                searchParams.set("dateSelect", DATE_SELECT_TYPES.SINCE_INSTALLATION);
                searchParams.set("startDate", startDay);
                searchParams.delete("endDate");
                setSearchParams(searchParams);
            }

            const filters: QueryFilters = { from: startDate.format('YYYY-MM-DD'), to: endDate.format('YYYY-MM-DD'), };
            if (coconId) { // load cocon stats
                dispatch(LoadStatsActions.getCoconStats(coconId, filters));
            }
            else { // load global stats
                dispatch(LoadStatsActions.getStats(filters));
            }
        }
    }, [startDate, endDate]);

    useEffect(() => {
        const { start, end } = getDatesFromRange(dateSelectType, cocon?.dateDeployed ? moment(cocon.dateDeployed) : undefined);
        if (start) setStartDate(start);
        if (end) setEndDate(end);

        searchParams.set("dateSelect", dateSelectType);
        searchParams.delete("startDate");
        searchParams.delete("endDate");
        setSearchParams(searchParams);
    }, [dateSelectType]);

    const setSelectedDate = (date: moment.Moment | null, type: "start" | "end") => {
        if (date) {
            switch (type) {
                case "start":
                    setStartDate(date);
                    break;

                case "end":
                    setEndDate(date);
                    break;
            }
        }
    }

    return (
        <Box textAlign="center">
            <FormControl>
                <InputLabel id="date-type-select-label">Dates</InputLabel>
                <Select
                    labelId="date-type-select-label"
                    label="Dates"
                    value={dateSelectType}
                    onChange={(e) => setDateSelectType(e.target.value as any)}
                >
                    {Object.values(DATE_SELECT_TYPES).map(dateSelectType => {
                        if (dateSelectType === DATE_SELECT_TYPES.SINCE_INSTALLATION) {
                            return [
                                ...[<Divider light variant="middle" sx={{ my: 1 }} />], // add divider
                                cocon?.dateDeployed ?
                                    <MenuItem
                                        key={dateSelectType}
                                        value={dateSelectType}
                                    >
                                        {t(dateSelectType, { ns: Namespaces.commons })}
                                    </MenuItem>
                                    : undefined
                            ];
                        }

                        return (
                            <MenuItem
                                key={dateSelectType}
                                value={dateSelectType}
                            >
                                {t(dateSelectType, { ns: Namespaces.commons })}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <Box
                display="flex"
                justifyContent="center"
                my={2}
            >
                {dateSelectType === DATE_SELECT_TYPES.CUSTOM && (
                    <div>
                        <Datepicker
                            value={startDate}
                            onChange={(date) => setSelectedDate(date, "start")}
                            inputFormat="LL"
                            label={t("from_day", { ns: Namespaces.commons })}
                            inputStyle={{
                                width: (theme) => theme.spacing(25),
                                ml: 4,
                            }}
                        />
                        <Datepicker
                            value={endDate}
                            onChange={(date) => setSelectedDate(date, "end")}
                            inputFormat="LL"
                            label={t("to_day", { ns: Namespaces.commons })}
                            inputStyle={{
                                width: (theme) => theme.spacing(25),
                                ml: 4,
                            }}
                        />
                    </div>
                )}
            </Box>
        </Box>
    )
};

export default StatsDatesSelect;
