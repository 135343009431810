import { useState, FormEvent, useEffect, useRef, MouseEventHandler } from 'react';
import { TextField, Button, InputLabel, FormControl, Select, MenuItem, FormControlLabel, FormGroup, OutlinedInput, InputAdornment, Radio, FormLabel, RadioGroup, Card, CardMedia, CardActions, Box, Grid, Checkbox, ListItemText } from '@mui/material';
import ActionButton from 'components/_include/ActionButton';
import { FormInput, FileInput, OptionalInput, } from 'helpers/forms';
import Partner, { PartnersMethods } from 'models/Partner';
import Reward, { INFINITY_QUANTITY, NewRewardData, RewardCategory, RewardDbData } from 'models/Reward';
import { Namespaces } from '../../locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import _ from 'lodash';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import Datetimepicker from 'components/_include/DateTimePickers/Datetimepicker';
import UploadPromoCodesButton from './UploadPromoCodesButton';
import { selectAllCoconsClusters } from 'store/reducers/cocons/clusters';
import { QueryFilter } from 'constants/types';
import { CoconsClustersMethods } from 'models/Cocons/CoconsCluster';

export type RewardFormData = Pick<Reward, "name" | "description" | "quantity" | "expiryDate" | "category" | "cost" | "realPrice" | "promoCode" | "referralLink" | "imageURL" | "exclusiveCoconsClusters"> & {
    partnerId: string;
    image: File | null;
}

type FormInputs = {
    partnerId: FormInput<string>;
    name: FormInput<string>;
    description: FormInput<string>;
    quantity: OptionalInput<number>;
    expiryDate: OptionalInput<moment.Moment>;
    category: FormInput<RewardCategory>;
    cost: FormInput<number>;
    realPrice: OptionalInput<number>;
    promoCode: OptionalInput<string>;
    promoCodes: string[];
    referralLink: OptionalInput<string>;
    image: FileInput;
    exclusiveCoconsClusters: string[];
};

type RewardFormProps = {
    type: "create" | "edit";
    rewardData: RewardFormData;
    loading: boolean;
    submitForm: (data: NewRewardData, image?: File, promoCodes?: string[]) => void;
}

function RewardForm(props: RewardFormProps) {

    const { type, rewardData, loading, submitForm } = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.forms, Namespaces.glossary]);

    const { partners, loading: partnersLoading } = useAppSelector(state => state.partners);
    const coconsClusters = useAppSelector(state => selectAllCoconsClusters(state));
    const dispatch = useAppDispatch();
    const loadPartners = () => dispatch(PartnersMethods.list([]));
    const loadcluster = () => dispatch(CoconsClustersMethods.list([]));
    const initialInputs: FormInputs = {
        partnerId: {
            value: rewardData.partnerId,
            error: null,
        },
        name: {
            value: rewardData.name,
            error: null,
        },
        description: {
            value: rewardData.description,
            error: null,
        },
        quantity: {
            value: rewardData.quantity,
            error: null,
        },
        expiryDate: {
            value: rewardData.expiryDate ? moment(rewardData.expiryDate.toDate()) : undefined,
            error: null,
        },
        category: {
            value: rewardData.category,
            error: null,
        },
        cost: {
            value: rewardData.cost,
            error: null,
        },
        realPrice: {
            value: rewardData.realPrice,
            error: null,
        },

        promoCode: {
            value: rewardData.promoCode,
            error: null,
        },
        promoCodes: [],
        referralLink: {
            value: rewardData.referralLink,
            error: null,
        },
        image: {
            file: rewardData.image,
            error: null,
        },
        exclusiveCoconsClusters: rewardData.exclusiveCoconsClusters ?? [],
    };

    const [imageURL, setImageURL] = useState(rewardData.imageURL);

    const [inputs, setInputs] = useState<FormInputs>(initialInputs);

    const noClusterSelectedLabel = t("add_cocon.select_existing_cluster", { ns: Namespaces.cocons });
    const label = inputs.exclusiveCoconsClusters ? t("cocon", { ns: Namespaces.glossary }) : noClusterSelectedLabel;

    useEffect(() => {
         // load list of cocons
        loadcluster();
        // load list of partners
        loadPartners();
    }, []);

    // useEffect(() => {
    //     // after reward successfully saved
    //     if (rewardSaved) {
    //         setInputs(initialInputs); // clear form
    //     }
    // }, [rewardSaved]);

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string | number | boolean | unknown) => {
        // props.resetUserError();

        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleOptionChange = (name: string, value: string[]) => {
        setInputs({
            ...inputs,
            [name]: value,
        });
    }

    const handleImageSelected = (input: HTMLInputElement) => {
        if (input.files && input.files.length > 0) {
            setInputs({
                ...inputs,
                image: {
                    file: input.files[0],
                    error: null,
                },
            });
        }
        else {
            setInputs({
                ...inputs,
                image: {
                    file: null,
                    error: null,
                },
            });
        }
    }

    const handleSubmitPressed = (event: FormEvent) => {
        console.debug("submit pressed");
        event.preventDefault();

        let { partnerId, name, description, quantity, expiryDate, category, cost, realPrice, promoCode, promoCodes, referralLink, image, exclusiveCoconsClusters } = inputs;
        let error = false;

        if (!partnerId.value) {
            error = true;
            name.error = "";
        }

        if (!name.value) {
            error = true;
            name.error = "";
        }

        if (!description.value) {
            error = true;
            description.error = "";
        }

        if (!category.value) {
            error = true;
            category.error = "";
        }

        if (!cost.value || cost.value <= 0) {
            error = true;
            cost.error = "";
        }

        if (type === "create" && !image.file) {
            error = true;
            image.error = "Une image est nécessaire";
        }

        if (error) {
            setInputs({
                ...inputs,
                partnerId: partnerId,
                name: name,
                description: description,
                category: category,
                cost: cost,
                image: image,
            });
        }
        else {
            let rewardData: NewRewardData = {
                name: name.value,
                description: description.value,
                category: category.value,
                cost: Number(cost.value),
                partner: _.pick(partners.find(p => p.id === partnerId.value)!, ["id", "name", "website", "imageURL"]),
                quantity: (quantity.value || quantity.value === 0) ? Number(quantity.value) : INFINITY_QUANTITY,
                exclusiveCoconsClusters: [],
            };

            if (expiryDate.value) {
                rewardData.expiryDate = Timestamp.fromDate(expiryDate.value.toDate());
            }

            if (realPrice.value) {
                rewardData.realPrice = Number(realPrice.value);
            }
            if (category.value === RewardCategory.E_COMMERCE) {
                if (promoCodes.length > 0) { // mulitple single use promo codes
                    // rewardData.promoCodes = promoCodes;
                }
                else { // only one promo code
                    rewardData.promoCode = promoCode.value;
                }
                rewardData.referralLink = referralLink.value;
            }
            if(exclusiveCoconsClusters.length > 0){
                rewardData.exclusiveCoconsClusters = inputs.exclusiveCoconsClusters
            }

            // logFormData(rewardData);
            submitForm(rewardData, image.file || undefined, promoCodes.length > 0 ? promoCodes : undefined);
        }
    }

    const getPlaceholder = (field: string) => {
        return t(`reward.${field}`, { ns: Namespaces.forms });
    }

    const { partnerId, name, description, quantity, expiryDate, category, cost, realPrice, promoCode, promoCodes, referralLink, image } = inputs;
    const formIsValid = partnerId.value !== ""
        && name.value !== ""
        && (category.value !== RewardCategory.E_COMMERCE || (promoCode.value !== "" || promoCodes.length > 0)) // at least one promo code for e-commerce rewards
        && (category.value !== RewardCategory.LOTTRI || (promoCodes.length > 0) && expiryDate.value !== undefined) // list of tickets numbers and end date for lot'tri
        && description.value !== ""
        && cost.value > 0
        && (type === "edit" || image.file !== null);

    const needPromoCode = [RewardCategory.E_COMMERCE, RewardCategory.LOTTRI,].includes(category.value);

    return (
        <form
            method="post"
            action="#"
            onSubmit={(event) => handleSubmitPressed(event)}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Grid container maxWidth="md" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        label={getPlaceholder("name")}
                        value={name.value}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            onChange: (event) => { handleInputChange('name', event.target.value) }
                        }}
                        error={Boolean(name.error)}
                        helperText={name.error}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl
                        variant="outlined"
                        disabled={partnersLoading}
                        margin="normal">
                        <InputLabel id="partner-select-label">
                            {t("partner.partner", { ns: Namespaces.forms })} *
                        </InputLabel>
                        <Select
                            required
                            id="partner-select"
                            labelId="partner-select-label"
                            label={`${t("partner.partner", { ns: Namespaces.forms })} *`}
                            value={partnerId.value}
                            onChange={(event) => handleInputChange('partnerId', event.target.value)}
                        >
                            {
                                partners.map((partner: Partner) => (
                                    <MenuItem
                                        value={partner.id}
                                        key={partner.id}>
                                        {partner.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id="description"
                        margin="normal"
                        multiline
                        rows={3}
                        value={description.value}
                        variant="outlined"
                        label={getPlaceholder("description")}
                        placeholder="Description précise de la récompense..."
                        InputProps={{
                            onChange: (event) => handleInputChange('description', event.target.value),
                        }}
                        error={Boolean(description.error)}
                        helperText={description.error}
                        sx={{ maxWidth: "initial" }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box>
                        <FormControl sx={{ maxWidth: (theme) => theme.spacing(25) }} variant="outlined">
                            <InputLabel htmlFor="cost-input">{getPlaceholder("cost")} *</InputLabel>
                            <OutlinedInput
                                required
                                id="cost-input"
                                label={`${getPlaceholder("cost")} *`}
                                type="number"
                                value={cost.value > 0 ? cost.value : ""}
                                endAdornment={<InputAdornment position="end">Pts</InputAdornment>}
                                onChange={(event) => handleInputChange('cost', event.target.value)}
                                error={Boolean(cost.error)}
                            />
                        </FormControl>
                    </Box>

                    <Box>
                        <FormControl sx={{ maxWidth: (theme) => theme.spacing(25) }} variant="outlined">
                            <InputLabel htmlFor="realPrice-input">{getPlaceholder("real_price")}</InputLabel>
                            <OutlinedInput
                                id="realPrice-input"
                                label={getPlaceholder("real_price")}
                                type="number"
                                value={realPrice.value !== undefined ? realPrice.value.toString() : ""}
                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                onChange={(event) => handleInputChange('realPrice', event.target.value)}
                                error={Boolean(realPrice.error)}
                            />
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="quantity-input"
                        sx={{ maxWidth: (theme) => theme.spacing(25) }}
                        label={t("reward.quantity", { ns: Namespaces.forms })}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        placeholder="Infinie"
                        onChange={(event) => handleInputChange('quantity', event.target.value)}
                        value={quantity.value !== undefined ? quantity.value.toString() : ""}
                        disabled={promoCodes.length > 0}
                    />
                    
                    <Datetimepicker 
                        clearable
                        value={expiryDate.value ?? null}
                        onChange={(date) => handleInputChange('expiryDate', date)}
                        inputFormat="LLL"
                        label={t("reward.expiry_date.label", { ns: Namespaces.forms })}
                        InputProps={{
                            placeholder: t("reward.expiry_date.helper_text", { ns: Namespaces.forms }),
                        }}
                    />
                </Grid>

                <Grid item mb={2} xs={12}>
                    <FormLabel component="legend">{t("category", { ns: Namespaces.glossary })}</FormLabel>
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <RadioGroup
                            aria-label="Catégorie"
                            name="category"
                            value={category.value}
                            onChange={(event, value) => handleInputChange('category', value)} >
                            {Object.values(RewardCategory).map(category => (
                                <FormControlLabel
                                    key={category}
                                    value={category}
                                    control={<Radio color="primary" />}
                                    label={t(`rewards.category.${category}`, { ns: Namespaces.glossary }).toString()} />
                            ))}
                        </RadioGroup>

                        {needPromoCode && ( // e-commerce or lot'tri
                            <FormGroup
                                sx={{
                                    ml: 6,
                                    mt: 2,
                                    flex: 1,
                                }} >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                >
                                    {category.value === RewardCategory.E_COMMERCE && (
                                        <TextField
                                            id="promoCode"
                                            style={{ marginBottom: 0 }}
                                            label={`${getPlaceholder("promo_code.unique")}`}
                                            value={promoCode.value}
                                            margin="none"
                                            variant="outlined"
                                            InputProps={{
                                                onChange: (event) => { handleInputChange('promoCode', event.target.value) }
                                            }}
                                            error={Boolean(promoCode.error)}
                                            helperText={promoCode.error}
                                            disabled={promoCodes.length > 0}
                                        />
                                    )}

                                    <UploadPromoCodesButton
                                        onUpload={(promoCodes) => {
                                            setInputs({
                                                ...inputs,
                                                quantity: {
                                                    value: promoCodes.length > 0 ? promoCodes.length : inputs.quantity.value,
                                                    error: null,
                                                },
                                                promoCodes: promoCodes,
                                            });
                                        }}
                                        />
                                </Box>

                                {category.value === RewardCategory.E_COMMERCE && (
                                    <TextField
                                        id="referralLink"
                                        label={getPlaceholder("referral_link")}
                                        value={referralLink.value}
                                        margin="none"
                                        variant="outlined"
                                        InputProps={{
                                            onChange: (event) => { handleInputChange('referralLink', event.target.value) }
                                        }}
                                        error={Boolean(referralLink.error)}
                                        helperText={referralLink.error}
                                    />
                                )}

                                {/* <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={singleUse.value}
                                    onChange={(event) => { handleInputChange('singleUse', event.target.checked) }}
                                />
                            }
                            label="Une seule utilisation"
                        /> */}
                            </FormGroup>
                        )}
                    </Box>
                </Grid>

                <Grid item mb={2} xs={12}>
                    <FormLabel component="legend">{t("exclusiveCoconCluster", { ns: Namespaces.glossary })}</FormLabel>
                        <Box marginTop={2}>
                            <FormControl variant="outlined" fullWidth>
                            <InputLabel 
                                id="cocon-select-label"
                                >
                                {label}
                            </InputLabel>
                            <Select
                                id="cocon-select"
                                labelId="cocon-select-label"
                                multiple
                                label={label}
                                value={inputs.exclusiveCoconsClusters}
                                onChange={(event) => handleOptionChange('exclusiveCoconsClusters', event.target.value as string[])}
                                renderValue={(selected) => {
                                    // Find the corresponding addresses for the selected IDs
                                    const selectedName = coconsClusters.filter(cluster => selected.includes(cluster.id)).map(cluster => cluster.name);
                                    return selectedName.join(', ');
                                }}
                            >
                                <MenuItem
                                    value=""
                                    sx={{ fontStyle: "italic" }}
                                >
                                    {noClusterSelectedLabel}
                                </MenuItem>
                                {coconsClusters.map(cluster => (
                                    <MenuItem
                                        value={cluster.id}
                                        key={cluster.id}
                                        >
                                            <Checkbox checked={inputs.exclusiveCoconsClusters.includes(cluster.id)} />
                                            <ListItemText primary={cluster.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Box>
                </Grid>

                <Grid item xs={12}>
                    {
                        type === "edit" && !image.file ?
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{
                                        height: 0,
                                        paddingTop: '100%', // square images
                                    }}
                                    image={imageURL}
                                />
                                <CardActions sx={{ justifyContent: "center" }}>
                                    <Button
                                        sx={{ maxWidth: "initial" }}
                                        color="primary"
                                        component="label"
                                    >
                                        {t("reward.image.select_another", { ns: Namespaces.forms })}
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(event) => { handleImageSelected(event.target) }}
                                        />
                                    </Button>
                                </CardActions>
                            </Card>
                            :
                            <Button
                                variant="contained"
                                component="label"
                                color="primary"
                            >
                                {image.file ?
                                    image.file.name
                                    :
                                    `${getPlaceholder("image.pick")} *`
                                }
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event) => { handleImageSelected(event.target) }}
                                />
                            </Button>
                    }
                </Grid>

                <Grid item xs={12} textAlign="center" mt={4}>
                    <ActionButton
                        color="primary"
                        disabled={!formIsValid}
                        loading={loading}
                        type="submit"
                    >
                        {t("save", { ns: Namespaces.actions })}
                    </ActionButton>
                </Grid>
            </Grid>
        </form >
    )
}

export default RewardForm;
