import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { useEffect, useState } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import Conversation from "models/Conversations/Conversation";
import ActionButton from "components/_include/ActionButton";
import SendIcon from '@mui/icons-material/Send';
import { useAppDispatch, useAppSelector } from "store/hooks";

interface SendConversationPanelProps {
    sendingConversation: boolean;
    onSendConversation: (conversationId: string) => Promise<void>;
}

function SendConversationPanel(props: SendConversationPanelProps) {
    const { sendingConversation, onSendConversation, } = props;
    
    const { data: conversations, loading: conversationsLoading } = useAppSelector(state => state.messages.conversations);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions]);

    useEffect(() => {
        dispatch(Conversation.list());
    }, []);

    const [selectedConversation, setSelectedConversation] = useState("");

    return (
        <Box display="flex">
            <Select
                displayEmpty
                value={selectedConversation}
                variant="outlined"
                disabled={conversationsLoading}
                onChange={(e) => setSelectedConversation(e.target.value as string)}
                sx={{
                    flex: 1,
                    height: (theme) => theme.spacing(7),
                    mt: 1,
                }}
            >
                <MenuItem
                    key="none"
                    value=""
                >
                    <em>{t("select_conversation", { ns: Namespaces.actions })}</em>
                </MenuItem>
                {conversations.map(conversation => (
                    <MenuItem
                        key={conversation.id}
                        value={conversation.id}
                    >
                        {conversation.label}
                    </MenuItem>
                ))}
            </Select>
            <ActionButton
                color="primary"
                icon={<SendIcon />}
                disabled={selectedConversation === "" || sendingConversation}
                loading={sendingConversation}
                onClick={() => onSendConversation(selectedConversation)}
                >
                {t("send", { ns: Namespaces.actions })}
            </ActionButton>
        </Box>
    );
}

export default SendConversationPanel;