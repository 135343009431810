import { useState, FormEvent, useEffect } from 'react';
import { TextField, InputLabel, FormControl, Select, MenuItem, OutlinedInput, Chip, CircularProgress, ListItem, ListItemText, List, Box } from '@mui/material';
import ActionButton from 'components/_include/ActionButton';
import { FormInput } from 'helpers/forms';
import Partner, { PartnersMethods } from 'models/Partner';
import Store, { StoreDbData } from 'models/Store';
import { PlaceMap } from '../../_include/Map/PlaceMap';
import { Coordinates } from 'helpers/geo';
import Reward, { RewardsMethods } from 'models/Reward';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import _ from 'lodash';

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

export type StoreFormData = {
    partnerId: string;
    name: string;
    address: string;
}

type StoreFormProps = {
    storeData: StoreFormData
    submitForm: (data: StoreDbData) => void;
}

function StoreForm(props: StoreFormProps) {

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons]);

    const navigate = useNavigate();

    const { partnersList, store, availableRewards, rewardsLoading } = useAppSelector(state => {
        const rewardsList = state.rewards.list;
        return {
            partnersList: state.partners,
            store: state.stores.selected,
            availableRewards: rewardsList.data,
            rewardsLoading: rewardsList.loading,
        };
    });
    const { partners, loading: partnersLoading } = partnersList;
    const { data: storeSaved, loading } = store;
    const dispatch = useAppDispatch();

    const { storeData, submitForm, } = props;

    const initialInputs = {
        partnerId: {
            value: storeData.partnerId,
            error: null,
        },
        name: {
            value: storeData.name,
            error: null,
        },
        address: {
            value: storeData.address,
            error: null,
        },
        rewards: new Map(),
    };

    const [inputs, setInputs] = useState<{
        partnerId: FormInput<string>;
        name: FormInput<string>;
        address: FormInput<string>;
        rewards: Map<string, {
            reward: Reward,
            quantity: number,
        }>;
    }>(initialInputs);

    const { partnerId, name, address, rewards, } = inputs;

    const [lookUpAddress, setLookUpAddress] = useState("");
    const [addressLoading, setAddressLoading] = useState(false);
    const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

    useEffect(() => {
        // load list of partners
        dispatch(PartnersMethods.list([]));
    }, []);

    useEffect(() => {
        // load list of rewards
        dispatch(RewardsMethods.list([{
            fieldPath: "partner.id",
            opStr: "==",
            value: partnerId.value
        }]));
    }, [partnerId.value])

    const displayRewardsQuantitiesInput = () => {
        let inputs: JSX.Element[] = [];
        rewards.forEach(({ reward, quantity }, rewardId) => {
            inputs.push(
                <ListItem
                    key={rewardId}
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <ListItemText
                        primary={reward.name}
                        sx={{
                            mb: 2,
                        }}
                    />
                    <FormControl sx={{ maxWidth: 200, }} variant="outlined">
                        <InputLabel htmlFor={`quantity-${rewardId}`}>
                            {t("quantity", { ns: Namespaces.commons })} *
                        </InputLabel>
                        <OutlinedInput
                            required
                            id={`quantity-${rewardId}`}
                            label={`${t("quantity", { ns: Namespaces.commons })} *`}
                            type="number"
                            value={quantity}
                            onChange={(event) => handleRewardQuantityChange(rewardId, event.target.value)}
                        />
                    </FormControl>
                </ListItem>
            );
        });
        return inputs;
    }

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string | string[] | number | boolean | unknown) => {
        // props.resetUserError();

        let newInputs = {
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        };

        if (name === 'partnerId') {
            newInputs.rewards.clear();
        }

        setInputs(newInputs);
    }

    const handleRewardsChange = (rewardsIds: string[]) => {
        if (rewardsIds.length > rewards.size) { // reward added
            rewardsIds.forEach(rewardId => {
                if (!rewards.has(rewardId)) {
                    rewards.set(rewardId, {
                        reward: availableRewards.filter(r => { return r.id === rewardId })[0],
                        quantity: 1,
                    });
                }
            });
        }
        else { // reward removed
            const iterator = rewards.keys();
            let result = iterator.next();
            let found = false;
            while (!found && !result.done) {
                let rewardId = result.value;
                if (rewardsIds.indexOf(rewardId) < 0) { // reward was removed
                    found = true;
                    rewards.delete(rewardId);
                }
            }
        }

        setInputs({
            ...inputs,
            rewards: rewards,
        });
    }

    const handleRewardQuantityChange = (rewardId: string, quantity: string) => {
        let updatedReward = rewards.get(rewardId);
        if (!updatedReward) return;
        rewards.set(rewardId, {
            reward: updatedReward.reward,
            quantity: Number(quantity),
        });

        setInputs({
            ...inputs,
            rewards: rewards,
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let { partnerId, name, address, } = inputs;
        let error = false;

        if (!partnerId.value) {
            error = true;
            name.error = "";
        }

        if (!name.value) {
            error = true;
            name.error = "";
        }

        if (!address.value) {
            error = true;
            address.error = "";
        }

        if (error) {
            setInputs({
                ...inputs,
                partnerId: partnerId,
                name: name,
            });
        }
        else {
            const rewardsInStore = [...rewards.values()];

            const selectedPartner = partners.find(partner => partner.id === partnerId.value);

            if (selectedPartner && coordinates) {
                let storeData: StoreDbData = {
                    name: name.value,
                    address: address.value,
                    partner: _.pick(selectedPartner, ['id', 'name', 'imageURL', 'description',]),
                    coordinates: coordinates,
                    rewards: rewardsInStore.map(rewardInStore => ({
                            reward: _.pick(rewardInStore.reward, ['id', 'name', 'imageURL', 'description', 'cost']),
                            quantity: rewardInStore.quantity,
                    })),
                };

                // console.debug(storeData);
                submitForm(storeData);
            }
        }
    }

    useEffect(() => {
        // after store successfully saved
        if (storeSaved) {
            setInputs(initialInputs); // clear form
        }
    }, [storeSaved]);

    const formIsValid = partnerId.value !== "" && name.value !== "" && address.value !== "" && coordinates;

    return (
        <form
            method="post"
            action="#"
            onSubmit={(event) => handleSubmitPressed(event)} >
            <script
                src={`https://maps.googleapis.com/maps/api/js?key=${FIREBASE_CONFIG.apiKey}&callback=initMap&libraries=&v=weekly`}
                defer
            ></script>

            <div>
                <FormControl
                    variant="outlined"
                    disabled={partnersLoading}>
                    <InputLabel id="partner-select-label">
                        {t("partner.partner", { ns: Namespaces.forms })} *
                    </InputLabel>
                    <Select
                        required
                        id="partner-select"
                        labelId="partner-select-label"
                        value={partnerId.value}
                        onChange={(event) => handleInputChange('partnerId', event.target.value)}
                    >
                        {
                            partners.map((partner: Partner) => (
                                <MenuItem
                                    value={partner.id}
                                    key={partner.id}>
                                    {partner.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>

            <div>
                <TextField
                    required
                    id="name"
                    label="Nom"
                    value={name.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('name', event.target.value) }
                    }}
                    error={Boolean(name.error)}
                    helperText={name.error}
                />
            </div>

            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <TextField
                    required
                    id="description"
                    margin="normal"
                    value={address.value}
                    variant="outlined"
                    label="Adresse"
                    InputProps={{
                        onChange: (event) => handleInputChange('address', event.target.value),
                    }}
                    error={Boolean(address.error)}
                    helperText={address.error}
                />
                <ActionButton
                    color="primary"
                    disabled={!address.value || addressLoading}
                    loading={addressLoading}
                    onClick={() => { setLookUpAddress(address.value) }}
                    sx={{
                        marginBottom: ".5rem",
                        marginLeft: ".8rem",
                    }}
                >
                    Rechercher
                </ActionButton>
            </div>

            <div style={{ height: '40vh', width: '50%' }}>
                <PlaceMap
                    address={lookUpAddress}
                    startAddressLookup={() => { setAddressLoading(true) }}
                    endAddressLookup={(coordinates) => {
                        setCoordinates(coordinates);
                        setAddressLoading(false);
                    }} />
            </div>

            <div style={{ marginTop: '2rem' }}>
                {
                    rewardsLoading ?
                        <CircularProgress />
                        :
                        <FormControl
                            variant="outlined"
                            disabled={rewardsLoading}>
                            <InputLabel id="rewards-select-label">Récompenses disponibles</InputLabel>
                            <Select
                                required
                                multiple
                                id="rewards-select"
                                labelId="rewards-select-label"
                                value={[...rewards.keys()]}
                                onChange={(event) => {
                                    handleRewardsChange(event.target.value as string[]);
                                }}
                                renderValue={(selected: any) => {
                                    return (
                                        <Box
                                            display="flex"
                                            flexWrap="wrap"
                                        >
                                            {selected.map((rewardId: string, index: number) => {
                                                const rewardName = rewards.get(rewardId)?.reward.name;
                                                return (
                                                    <Chip
                                                        key={rewardId}
                                                        label={rewardName}
                                                        sx={{
                                                            margin: 0.25,
                                                        }}
                                                    />
                                                );
                                            })}
                                        </Box>
                                    );
                                }}
                            >
                                {
                                    availableRewards.map((reward: Reward) => (
                                        <MenuItem
                                            value={reward.id}
                                            key={reward.id}>
                                            {reward.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                }
            </div>

            <div>
                <List dense={true}>
                    {displayRewardsQuantitiesInput()}
                </List>
            </div>

            <Box textAlign="center" mt={4}>
                <ActionButton
                    color="primary"
                    disabled={!formIsValid}
                    loading={loading}
                    type="submit"
                >
                    {t("save", { ns: Namespaces.actions })}
                </ActionButton>
            </Box>
        </form >
    )
}

export default StoreForm;