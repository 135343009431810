import { Box, Grid, Typography } from "@mui/material";
import { NOT_ACCEPTED_TRASH, TrashColors, TrashType } from "constants/trash";
import { getTrashIcon } from "helpers/trash";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BatchesActions, selectBatchById } from "store/reducers/batches/batch";
import NumberInput from "./NumberInput";

type InputProps = {
    batchId: string;
    trashType: TrashType;
}

/**
 * A row with input to indicate number of instances of a given waste type.
 */
export default function BatchResultCountInput({ batchId, trashType }: InputProps) {
    const { t } = useTranslation([Namespaces.wastes]);

    const value = useAppSelector((state) => {
        const batch = selectBatchById(state, batchId);
        if (!batch) return 0;
        return batch.result.filter(trash => trash === trashType).length;
    });

    const dispatch = useAppDispatch();
    
    const Icon = getTrashIcon(trashType);

    const onChangeValue = (value: number, name: TrashType) => {
        dispatch(BatchesActions.updateItemResult({
            batchId,
            trashType: name,
            value,
        }));
    }

    return (
        <Grid container>
            <Grid item xs={9}>
                <Box
                    width={theme => theme.spacing(2)}
                    height={theme => theme.spacing(2)}
                    display="inline-block"
                    mr={1}
                    mb="-1px"
                    borderRadius="50%"
                    sx={{
                        backgroundColor: TrashColors[trashType],
                    }} />
                <Typography
                    display={"inline"}
                    color={(theme) => NOT_ACCEPTED_TRASH.includes(trashType) ? theme.palette.error.main : theme.palette.success.main}
                >
                    {t([trashType], { ns: Namespaces.wastes })}
                </Typography>
            </Grid>

            <Grid 
                item 
                xs={3} 
                textAlign="right"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
                >
                <Icon fontSize="small" />
                <NumberInput
                    size="small"
                    min={0}
                    max={100}
                    value={value}
                    onChange={(value: number) => onChangeValue(value, trashType)}
                />
            </Grid>
        </Grid>
    )
}