import React from "react";
import { Link } from "@mui/material";
import urls from "../../constants/urls";
import { Namespaces } from "../../locales/translations";
import { useTranslation } from "react-i18next";

function BackToWebsiteLink() {
    const { t } = useTranslation([Namespaces.actions]);
    
    return (
        <Link
            href={urls.WEBSITE}
        >
            {t("back_to_website", { ns: Namespaces.actions })}
        </Link>
    );
}

export default BackToWebsiteLink;