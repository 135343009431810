import { MapPointFeature, Coordinates } from "helpers/geo";
import { Bounds, Coords } from "google-map-react";
import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_BOUNDS, DEFAULT_CENTER } from "constants/geo";

export interface BatchesMapContextInterface {
    defaultCenter: Coords;
    defaultBounds: Bounds;
    defaultZoom: number;
    points: MapPointFeature[];
};

const initialState: BatchesMapContextInterface = {
    defaultCenter: DEFAULT_CENTER,
    defaultBounds: DEFAULT_BOUNDS,
    defaultZoom: -1,
    points: [],
};

export const coconsMapSlice = createSlice({
    name: 'cocons_map',
    initialState: initialState,
    reducers: {
        removeMapPoints: (state) => {
            state.points = [];
        },
        setMapPoints: (state, { payload: { center, bounds, points } }: PayloadAction<{ center: Coords, bounds: Bounds, points: MapPointFeature[] }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
            state.points = points;
        },
        setMapBounds: (state, { payload: { center, bounds } }: PayloadAction<{ center: Coords, bounds: Bounds }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
        },
    },
});

export const CoconsMapActions = coconsMapSlice.actions;

const CoconsMapReducer = coconsMapSlice.reducer;

export default CoconsMapReducer;