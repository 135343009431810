import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5994 18.2976C19.6352 18.3139 19.6581 18.3495 19.6581 18.3887V40.8961C19.6581 40.9688 19.583 41.0172 19.5168 40.9872L0.586139 32.3993C0.228781 32.2371 -0.000732422 31.881 -0.000732422 31.4886V9.56118C-0.000732422 9.48848 0.0743774 9.44008 0.14058 9.47011L19.5994 18.2976Z" fill="#795548" />
            <path d="M39.3203 8.78871C39.3986 8.82421 39.3986 8.93537 39.3203 8.97086L32.9648 11.8525C32.9385 11.8644 32.9084 11.8644 32.8822 11.8525L13.6024 3.10817C13.5242 3.07268 13.5242 2.96151 13.6024 2.92603L19.5863 0.212896C19.8488 0.0938878 20.1499 0.0939057 20.4123 0.212946L39.3203 8.78871Z" fill="#795548" />
            <path d="M27.3982 14.1974C27.4764 14.2329 27.4764 14.3441 27.3982 14.3795L20.0406 17.7153C20.0143 17.7272 19.9842 17.7272 19.958 17.7153L0.678252 8.97093C0.599986 8.93543 0.599993 8.82427 0.678265 8.78878L8.03586 5.45305C8.0621 5.44115 8.09221 5.44115 8.11845 5.45306L27.3982 14.1974Z" fill="#795548" />
            <path d="M31.9033 12.1541C31.9815 12.1896 31.9815 12.3007 31.9033 12.3362L28.4596 13.8984C28.4334 13.9103 28.4033 13.9103 28.377 13.8984L9.0971 5.15397C9.01887 5.11849 9.01883 5.0074 9.09704 4.97186L12.5408 3.40709C12.5671 3.39515 12.5973 3.39514 12.6235 3.40706L31.9033 12.1541Z" fill="#FFB74D" />
            <path d="M32.4409 12.8328C32.5071 12.8028 32.5822 12.8512 32.5822 12.9239V17.365C32.5822 17.4043 32.5593 17.4399 32.5236 17.4561L28.8982 19.1014C28.832 19.1315 28.7568 19.0831 28.7568 19.0104V14.5692C28.7568 14.53 28.7798 14.4944 28.8155 14.4782L32.4409 12.8328Z" fill="#FFB74D" />
            <path d="M28.4184 20.0283C28.4721 20.0283 28.5178 20.0123 28.5661 19.9963L33.0524 17.961C33.1786 17.9103 33.2646 17.7821 33.2646 17.6485V12.526C33.2646 12.4868 33.2875 12.4511 33.3233 12.4349L39.858 9.47012C39.9242 9.44008 39.9993 9.48849 39.9993 9.56119V31.4886C39.9993 31.881 39.7698 32.2371 39.4124 32.3993L20.4818 40.9872C20.4156 41.0172 20.3405 40.9688 20.3405 40.8961V18.3887C20.3405 18.3495 20.3634 18.3139 20.3992 18.2977L27.9386 14.8787C28.0048 14.8487 28.0799 14.8971 28.0799 14.9698V19.6918C28.0772 19.8761 28.233 20.0283 28.4184 20.0283Z" fill="#795548" />
        </svg>
    </SvgIcon>
);