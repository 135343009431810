import { Typography, Container } from '@mui/material';
import StoreForm from '../StoreForm/StoreForm';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "store/hooks";
import Store, { StoreDbData, StoresMethods } from 'models/Store';

type AddStorePageProps = {
    
}

function AddStorePage(props: AddStorePageProps) {

    const { t } = useTranslation([Namespaces.titles]);

    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    let partnerIdParam = searchParams.get("partnerId") || "";

    const initialData = {
        partnerId: partnerIdParam,
        name: "",
        address: "",
    };

    const handleSubmit = (data: StoreDbData) => {
        dispatch(StoresMethods.create(data));
    }

    return (
        <Container>
            <Typography variant="h1">
                {t("add_store", { ns: Namespaces.titles })}
            </Typography>

            <StoreForm 
                storeData={initialData}
                submitForm={handleSubmit}
                />
        </Container>
    )
}

export default AddStorePage;