import { TableRow, TableCell, Typography, Box, Avatar, Chip, IconButton } from "@mui/material";
import { Namespaces } from "locales/translations";
import Purchase, { PurchasesMethods, PurchaseStatuses } from "models/Purchase";
import { useTranslation } from "react-i18next";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PurchaseActionButton from "./PurchaseActionButton";
import { RewardCategory } from "models/Reward";
import { UsersMethods } from "models/User";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";

type ItemProps = {
    purchase: Purchase;
}

export default (props: ItemProps) => {
    const { purchase } = props;
    const { user } = purchase;

    const { t } = useTranslation([Namespaces.glossary, Namespaces.purchases]);

    const userName = user.firstName && user.firstName ? `${user.firstName} ${user.lastName}` : null;
    const userAddress = user.address;

    return (
        <TableRow>
            <TableCell>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="body1">#{UsersMethods.formatPin(purchase.user.pin)}</Typography>
                        <Typography variant="body2">
                            {userName ?? t("incomplete_profile", { ns: Namespaces.purchases })}
                        </Typography>
                        <Typography variant="caption">
                            {userAddress ? 
                                UsersMethods.formatAddress(userAddress) 
                            :
                                t("missing_address", { ns: Namespaces.purchases })
                            }
                        </Typography>
                    </Box>

                    <IconButton
                        href={`/user/${purchase.user.id}`}
                        target="_blank"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
            </TableCell>
            <TableCell>
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <Avatar
                        src={purchase.reward.imageURL}
                        sx={{
                            width: (theme) => theme.spacing(7),
                            height: (theme) => theme.spacing(7),
                        }}
                    />
                    <div>
                        <Typography variant="body1" sx={{ ml: 2 }}>{purchase.reward.name}</Typography>
                        <Typography variant="body2" sx={{ ml: 2 }}>{purchase.reward.partner.name}</Typography>
                    </div>
                </Box>
            </TableCell>
            <TableCell>
                <Chip
                    sx={{
                        color: "#ffffff",
                        "& .MuiChip-icon": {
                            color: "#ffffff",
                        },
                        padding: 1,
                        height: (theme) => theme.spacing(5),
                        backgroundColor: (theme) => {
                            switch (purchase.status) {
                                case PurchaseStatuses.PURCHASED: return theme.palette.warning.dark;
                                case PurchaseStatuses.SENT: return theme.palette.info.main;
                                case PurchaseStatuses.RECEIVED: return theme.palette.success.main;
                            }
                        }
                    }}
                    icon={purchase.status === PurchaseStatuses.PURCHASED ?
                        <ShoppingBasketIcon />
                        :
                        <AssignmentTurnedInIcon />
                    }
                    label={t(`status.status_on`, {
                        ns: Namespaces.purchases,
                        lastUpdate: PurchasesMethods.getTimeline(purchase)[purchase.status],
                        status: purchase.status
                    })}
                />
            </TableCell>
            <TableCell>
                {purchase.reward.category === RewardCategory.SENT_BY_FICHA && purchase.status !== PurchaseStatuses.RECEIVED && (
                    <PurchaseActionButton
                        purchase={purchase}
                    />
                )}
            </TableCell>
        </TableRow>
    );
}