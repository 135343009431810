import { Box, MenuItem, Select, Typography } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BatchesActions, BatchesToDoSorting } from "store/reducers/batches/batch";

type BatchesToDoSortProps = {

}

/**
 * Sorting options for the header of the "to do" batches list 
 */
function BatchesToDoSort({ }: BatchesToDoSortProps) {

    const { t } = useTranslation([Namespaces.batches]);

    const value = useAppSelector(state => state.batches.list.todoListSorting);

    const dispatch = useAppDispatch();

    const handleChange = (value: string) => {
        dispatch(BatchesActions.setToDoListSorting(value as BatchesToDoSorting));
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
        >
            <Typography variant="h5">
                {t("sort_by.title", { ns: Namespaces.batches })}
            </Typography>

            <Select
                value={value}
                onChange={(event) => handleChange(event.target.value)}
                size="small"
            >
                {Object.values(BatchesToDoSorting).map(type => (
                    <MenuItem
                        value={type}
                        key={type}
                        >
                        {t(`sort_by.${type}`, { ns: Namespaces.batches })}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}

export default BatchesToDoSort;