export const DEFAULT_CENTER = { lat: 46.66458199187559, lng: 3.8610751223968975 };

export const DEFAULT_ZOOM = 7;
export const MAX_ZOOM = 20;

export const DEFAULT_BOUNDS = {
    "nw": {
        "lat": 48.72364722519461,
        "lng": -2.6428311276031025
    },
    "se": {
        "lat": 44.5239936221509,
        "lng": 10.364981372396898
    },
    "sw": {
        "lat": 44.5239936221509,
        "lng": -2.6428311276031025
    },
    "ne": {
        "lat": 48.72364722519461,
        "lng": 10.364981372396898
    }
};