import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 38 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.2332 18.2778L2.16036 12.2513C2.46056 10.2999 4.13956 8.8595 6.11384 8.8595H17.4202L15.5983 13.0239L13.0931 18.7499H15.4356H17.7781H20.2805C21.5987 18.7499 22.784 17.9474 23.2735 16.7236C23.4258 16.3428 23.5182 15.936 23.4862 15.5272C23.4017 14.4463 22.9179 13.0253 20.1474 13.0239L21.8706 8.8595H26.6274L26.7275 9.38005L29.2301 22.3937H24.5452H4.76436C2.5747 22.3937 0.900247 20.442 1.2332 18.2778Z" fill="#C9D7EB" />
            <path d="M9.75654 18.7499H12.4391C12.8364 18.7499 13.1961 18.5147 13.3553 18.1507L15.5983 13.0239H9.75654C8.37288 13.0239 7.18722 14.0134 6.93971 15.3748C6.62017 17.1322 7.97029 18.7499 9.75654 18.7499Z" fill="#24476B" />
            <path d="M17.7781 18.7499H20.2806C21.5987 18.7499 22.784 17.9474 23.2735 16.7235C23.4258 16.3428 23.5191 15.9353 23.4708 15.528C23.3425 14.4466 22.747 13.0239 20.1475 13.0239L17.7781 18.7499Z" fill="#24476B" />
            <path d="M24.5452 34.6379V22.3937H4.7644V34.6379C4.7644 34.8037 4.77322 34.9682 4.82332 35.1264C5.10397 36.012 6.6502 39.0513 14.6548 39.0513C22.6594 39.0513 24.2057 36.012 24.4863 35.1264C24.5364 34.9682 24.5452 34.8037 24.5452 34.6379Z" fill="#336699" />
            <path d="M29.0472 7.59734L26.7274 9.38003L29.1299 21.8732L31.5233 19.9407C32.0604 19.507 32.7299 19.2704 33.4202 19.2704C35.3041 19.2704 36.7281 17.5643 36.3911 15.7108L35.3581 10.0296C35.1088 8.65814 34.1569 7.5187 32.8517 7.02925C31.5705 6.54881 30.1321 6.76359 29.0472 7.59734Z" fill="#CCCCCC" />
            <path d="M20.0542 2.83891L13.0931 18.7499H15.4356H17.1096C17.5142 18.7499 17.879 18.5061 18.0336 18.1322L20.1475 13.0239L23.7147 4.40312C23.9134 3.92283 23.9162 3.38277 23.7231 2.90016C23.3153 1.88056 22.157 1.38189 21.1374 1.78973C20.6526 1.98366 20.2635 2.36052 20.0542 2.83891Z" fill="#795548" />
            <path d="M13.0931 18.7499H9.7565C7.97026 18.7499 6.62014 17.1322 6.93967 15.3747V15.3747C7.18718 14.0134 8.37285 13.0239 9.7565 13.0239H15.5983M13.0931 18.7499L20.0542 2.83891C20.2635 2.36052 20.6526 1.98366 21.1374 1.78973V1.78973C22.157 1.38189 23.3153 1.88056 23.7231 2.90016V2.90016C23.9161 3.38277 23.9134 3.92283 23.7147 4.40312L20.1474 13.0239M13.0931 18.7499H15.4356H17.7781M17.7781 18.7499H20.2805C21.5987 18.7499 22.784 17.9474 23.2735 16.7236V16.7236C23.4258 16.3428 23.5191 15.9353 23.4708 15.528C23.3425 14.4466 22.747 13.0239 20.1474 13.0239M17.7781 18.7499L20.1474 13.0239M26.7275 9.38004V9.38004C26.6694 9.07788 26.405 8.85949 26.0973 8.85949H21.8706M26.7275 9.38004L29.0473 7.59734C30.1322 6.7636 31.5706 6.54882 32.8518 7.02926V7.02926C34.157 7.5187 35.1088 8.65815 35.3582 10.0296L36.3911 15.7108C36.7281 17.5643 35.3042 19.2704 33.4203 19.2704V19.2704C32.7299 19.2704 32.0604 19.507 31.5233 19.9407L29.13 21.8732M26.7275 9.38004L29.13 21.8732M29.13 21.8732V21.8732C29.1819 22.1432 28.975 22.3937 28.7 22.3937H24.5452M24.5452 22.3937V34.6379C24.5452 34.8037 24.5364 34.9682 24.4863 35.1264C24.2056 36.012 22.6594 39.0513 14.6548 39.0513C6.65015 39.0513 5.10393 36.012 4.82328 35.1264C4.77318 34.9682 4.76436 34.8037 4.76436 34.6379V22.3937M24.5452 22.3937H4.76436M4.76436 22.3937V22.3937C2.5747 22.3937 0.900247 20.442 1.2332 18.2778L2.16036 12.2513C2.46056 10.2999 4.13956 8.85949 6.11384 8.85949H17.4202" stroke="white" strokeWidth="0.5" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);