import BeforeAfterSlider from "components/_include/BeforeAfterSlider/BeforeAfterSlider";
import _ from "lodash";
import { useAppSelector } from "store/hooks";
import { selectBatchById } from "store/reducers/batches/batch";
import BatchImageOnly from "./BatchImageOnly";

type BatchImageProps = {
    batchId: string;
};

function BatchImage(props: BatchImageProps) {
    const { batchId, } = props;

    const { imageURL, previousBatchImage } = useAppSelector(state => selectBatchById(state, batchId)!);

    if (previousBatchImage) { // show previous and current image side-by-side
        return (
            <BeforeAfterSlider
                batchId={batchId}
                previousImageURL={previousBatchImage}
                imageURL={imageURL}
            />
        );
    }

    return ( // only show current image
        <BatchImageOnly
            batchId={batchId}
            imageURL={imageURL}
            />
    );
};

export default BatchImage;