import { Timestamp, updateDoc } from "firebase/firestore";
import { Collections, DBIdentifiers } from "constants/db";
import { QueryFilter } from "constants/types";
import { AppDispatch } from "store/store";
import { SelectedCoconActions } from "store/reducers/cocons/selected";
import { CodeError, fetchAPI } from "actions/actions";
import urls from "constants/urls";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { getCollectionGroupRef, getDocumentReference, listSubcollectionDocs, updateDocument } from "helpers/db";
import { CoconIssuesActions } from "store/reducers/cocons/issues";
import _ from "lodash";

export enum IssueType {
    BULKY_ITEM = "bulky_item",
    ILLEGAL_DUMP = "illegal_dump",
    ISSUE_ON_COCON = "issue_on_cocon",
    SUGGESTION = "suggestion",
}

export enum IssueStatus {
    REPORTED = "reported",
    FIXING = "fixing",
    FIXED = "fixed",
}

type CoconIssueUserData = {
    id: string;
    pin: number;
}

export type IssueCoconData = {
    clusterId: string;
    id?: string;
    address: string;
};

export type CoconIssueDbData = {
    cocon: IssueCoconData;
    reportedBy: CoconIssueUserData;
    type: IssueType;
    description: string;
    status: IssueStatus;
    date: Timestamp;
    pictureURL?: string;
};

type CoconIssue = Omit<CoconIssueDbData, "date"> & DBIdentifiers & {
    date: number;
    loading: boolean;
};

const COLLECTION = Collections.REPORTED_ISSUES;

function getCollectionPath(clusterId: string, coconId?: string) {
    if (coconId) return `${Collections.COCONS_CLUSTERS}/${clusterId}/${Collections.COCONS}/${coconId}`;
    return `${Collections.COCONS_CLUSTERS}/${clusterId}`;
}

function getDocRef(id: string, clusterId: string, coconId: string | undefined) {
    return getDocumentReference(id, COLLECTION, getCollectionPath(clusterId, coconId));
}

const list = (clusterId: string, coconId: string | undefined, filters: QueryFilter[]) => async (dispatch: AppDispatch) => {
    dispatch(CoconIssuesActions.startLoadingList());

    const collectionPath = getCollectionPath(clusterId, coconId);

    try {
        // query database ordering by clusterId to group Cocons by cluster
        const coconIssuesData = await listSubcollectionDocs<CoconIssueDbData & DBIdentifiers>(COLLECTION, collectionPath, filters);
        const coconIssues: CoconIssue[] = coconIssuesData.map(data => ({
            ...data,
            date: data.date.toMillis(),
            loading: false,
        }));
        dispatch(CoconIssuesActions.setList(coconIssues));

        console.log("loaded cocon issues", coconIssues);

        return coconIssues;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(CoconIssuesActions.setError(error.message));
        dispatch(showError(error.message));
        return [];
    }
}

const getUserIssues = (userId: string, statuses: IssueStatus[]) => async (dispatch: AppDispatch) => {
    dispatch(CoconIssuesActions.startLoadingList());

    const filters: QueryFilter[] = [
        { fieldPath: "reportedBy.id", opStr: "==", value: userId },
        { fieldPath: "status", opStr: "in", value: statuses },
    ];

    try {
        // query database ordering by clusterId to group Cocons by cluster
        const coconIssuesData = await listSubcollectionDocs<CoconIssueDbData & DBIdentifiers>(COLLECTION, undefined, filters);
        const coconIssues: CoconIssue[] = coconIssuesData.map(data => ({
            ...data,
            date: data.date.toMillis(),
            loading: false,
        }));
        dispatch(CoconIssuesActions.setList(coconIssues));

        console.log("loaded cocon issues", coconIssues);

        return coconIssues;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(CoconIssuesActions.setError(error.message));
        dispatch(showError(error.message));
        return [];
    }
}
    
const update = (clusterId: string, coconId: string | undefined, id: string, data: Partial<CoconIssue>) => async (dispatch: AppDispatch) => {
    // set loading state
    dispatch(CoconIssuesActions.updateItem({ id, data: { loading: true } }));

    try {
        let dbData: Partial<CoconIssueDbData> = {
            ..._.omit(data, "date"),
            ...(data.date ? { date: Timestamp.fromMillis(data.date) } : {}),
        };
        await updateDoc(getDocRef(id, clusterId, coconId), dbData);

        dispatch(CoconIssuesActions.updateItem({ id, data: { loading: false, ...data } }));

        // display success message
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "update_cocon_issue.success",
        }));

        return this;
    }
    catch (e) {
        const error = e as CodeError;
        console.error("failed updating issue", error);
        dispatch(CoconIssuesActions.setError(error.message));

        // display error message
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "update_cocon_issue.error",
        }));

        return this;
    }
}

export default CoconIssue;

export const CoconIssuesMethods = {
    list,
    update,
    getUserIssues,
}