import { populateTemplate } from "helpers/strings";

/**
 * An answer choice from the user to the Cocon
 */
export default class Answer {
    id: string;
    message: string;
    index: number; // index of the answer in the list displayed to user
    nextReply?: number;
    openLink?: string; // link to open when answer is pressed

    constructor(data: AnswerData, context?: { [key: string]: string }) {
        this.id = data.id;
        this.message = context ? populateTemplate(data.message, context) : data.message;
        this.index = data.index;
        this.nextReply = data.nextReply;
        this.openLink = data.openLink;
    }

    public data(fields?: string[]): AnswerData {
        let data: any = {
            id: this.id,
            message: this.message,
            index: this.index,
            nextReply: this.nextReply,
            openLink: this.openLink,
        };

        if (fields) {
            Object.keys(data).forEach(field => {
                if (fields.indexOf(field) < 0) { // field not in fields to return
                    delete data[field];
                }
            });
        }

        return data;
    }
}

export interface AnswerData {
    id: string;
    message: string;
    index: number;
    nextReply?: number;
    openLink?: string;
}