import { useState, FormEvent } from 'react';
import { TextField, InputLabel, FormControl, Select, MenuItem, Grid, Box, Typography } from '@mui/material';
import ActionButton from 'components/_include/ActionButton';
import { OptionalInput, FormInput } from 'helpers/forms';
import Cocon, { CoconManager, CoconsMethods, CoconStatus, NewCoconData, NotificationData } from 'models/Cocons/Cocon';
import { PlaceMap } from 'components/_include/Map/PlaceMap';
import { Coordinates } from 'helpers/geo';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SectionTitle from 'components/_include/Titles/SectionTitle';
import ManagersInput from '../ManagersInput';
import Datetimepicker from 'components/_include/DateTimePickers/Datetimepicker';
import moment from 'moment';
import ConfirmationDialog from 'components/_include/Dialogs/ConfirmationDialog';
import { useAppDispatch } from 'store/hooks';
import { GeoPoint } from 'firebase/firestore';
import PartnerCompany from 'models/PartnerCompany';
import InMaintenanceSwitch from './InMaintenanceSwitch';
import SendUserNotificationBtn from './SendUsersNotificationBtn';

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

export type AddCoconFormData = Omit<NewCoconData, "clusterId" | "joinCode"> & Pick<Cocon, "inMaintenance">;

type FormFieldsType = {
    name: FormInput<string>;
    deviceId: FormInput<string>;
    address: FormInput<string>;
    postcode: FormInput<string>;
    city: FormInput<string>;
    status: FormInput<string>;
    inMaintenance: FormInput<boolean | undefined>;
    unitsCount: FormInput<number>;
    dateDeployed: OptionalInput<moment.Moment>;
    managers: FormInput<CoconManager[]>;
};

type CoconFormProps = {
    type: "create" | "edit";
    coconId?: string,
    coconData: AddCoconFormData;
    loading: boolean;
    submitForm: (data: AddCoconFormData) => Promise<boolean>;
}

function CoconForm({ type, coconData, loading, submitForm, coconId }: CoconFormProps) {

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.cocons, Namespaces.commons, Namespaces.forms, Namespaces.glossary, Namespaces.titles]);

    const navigate = useNavigate();

    const initialInputs: FormFieldsType = {
        name: {
            value: coconData.name,
            error: null,
        },
        deviceId: {
            value: coconData.deviceId || "",
            error: null,
        },
        address: {
            value: coconData.address,
            error: null,
        },
        postcode: {
            value: coconData.postcode,
            error: null,
        },
        city: {
            value: coconData.city,
            error: null,
        },
        unitsCount: {
            value: coconData.unitsCount ?? -1,
            error: null,
        },
        status: {
            value: coconData.status,
            error: null,
        },
        inMaintenance: {
            value: type === "edit" && coconData.inMaintenance === true,
            error: null,
        },
        dateDeployed: {
            value: coconData.dateDeployed ? moment(coconData.dateDeployed) : undefined,
            error: null,
        },
        managers: {
            value: coconData.managers,
            error: null,
        },
    };

    const [inputs, setInputs] = useState<FormFieldsType>(initialInputs);

    const [noManagerDialogVisible, toggleNoManagerDialog] = useState(false);

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: keyof FormFieldsType, value: string | number | boolean | PartnerCompany | unknown) => {
        // props.resetUserError();

        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let { name, address, city, postcode, status, dateDeployed, managers, unitsCount, deviceId, inMaintenance, } = inputs;

        let error = false;

        if (!address.value) {
            error = true;
            address.error = "";
        }

        if (!city.value) {
            error = true;
            city.error = "";
        }

        if (!postcode.value) {
            error = true;
            postcode.error = "";
        }

        if (!status.value) {
            error = true;
            status.error = "";
        }

        if (error) {
            setInputs({
                ...inputs,
                name: name,
                address: address,
                city: city,
                postcode: postcode,
                status: status,
            });
        }
        else {
            const coords = coordinates ? new GeoPoint(coordinates.latitude, coordinates.longitude) : coconData.coordinates
            let enteredCoconData: AddCoconFormData = {
                name: name.value,
                deviceId: deviceId.value,
                address: address.value,
                postcode: postcode.value,
                city: city.value,
                coordinates: coords,
                status: status.value as CoconStatus,
                dateDeployed: dateDeployed.value ? Number(dateDeployed.value.format("x")) : undefined,
                managers: managers.value,
            };

            if (inMaintenance.value !== coconData.inMaintenance) { // inMaintenance has changed
                enteredCoconData.inMaintenance = inMaintenance.value;
            }

            if (unitsCount.value >= 0) { // add units count if provided
                enteredCoconData.unitsCount = unitsCount.value;
            }

            // console.debug(enteredCoconData);
            if (managers.value.length > 0) {
                submitForm(enteredCoconData)
                    .then((success) => {
                        if (success) onFormSaved(); // clear form if cocon successfully added
                    });
            }
            else {
                toggleNoManagerDialog(true);
            }
        }
    }

    const { name, address, postcode, city, status, dateDeployed, managers, unitsCount, deviceId, inMaintenance } = inputs;

    const onFormSaved = () => {
        // after cocon successfully saved
        if (type === "create") {
            setInputs({
                ...initialInputs,
                managers: managers, // keep managers which will probably not change between 2 cocons
                status: status, // same, shouldn't change
                dateDeployed: dateDeployed, // same, shouldn't change
            }); // clear form
            dispatch(CoconsMethods.getJoinCode());
        }
        else {  // redirect to cocons list
            navigate(`/cocon`);
        }
    }

    const submitFormWithoutManagers = () => {
        const coords = coordinates ? new GeoPoint(coordinates.latitude, coordinates.longitude) : coconData.coordinates

        let enteredCoconData: AddCoconFormData = {
            name: name.value,
            deviceId: deviceId.value,
            address: address.value,
            postcode: postcode.value,
            city: city.value,
            coordinates: coords,
            status: status.value as CoconStatus,
            dateDeployed: dateDeployed.value ? Number(dateDeployed.value.format("x")) : undefined,
            managers: managers.value,
        };

        if (inMaintenance.value !== coconData.inMaintenance) { // inMaintenance has changed
            enteredCoconData.inMaintenance = inMaintenance.value;
        }

        if (unitsCount.value >= 0) { // add units count if provided
            enteredCoconData.unitsCount = unitsCount.value;
        }

        // hide dailog
        toggleNoManagerDialog(false);

        // submit form
        // console.debug("cocon data", enteredCoconData);
        submitForm(enteredCoconData)
            .then(() => { onFormSaved(); });
    }

    const postalAddress = address.value ? `${address.value}, ${postcode.value}, ${city.value}` : "";

    const [lookUpAddress, setLookUpAddress] = useState(postalAddress);
    const [addressLoading, setAddressLoading] = useState(false);
    const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

    // State to track if notification has been sent
    const [notificationSent, setNotificationSent] = useState(false);

    // State to track the loading status for the sendnotificationbtn 
    const [sendingNotification, setSendingNotification] = useState(false);

    // Handler for sending notification
    const handleNotifyOnline = async () => {
        setSendingNotification(true); // Start loading
        const notification: NotificationData = {
            working: !inMaintenance.value!,
        };
        console.log("Notifacation",notification);
        const notificationResponse = await dispatch(CoconsMethods.sendNotification(coconId!, notification));
        if (notificationResponse) {
            console.log(notificationResponse);
            setNotificationSent(true); // Set the flag to true on success
        } else {
            console.log('Notification not sent:', notificationResponse);
            setNotificationSent(false);
        }
        setSendingNotification(false); // End loading
    }

    const addressIsValid = address.value && postcode.value && city.value;
    const formIsValid = name.value && addressIsValid && coordinates && status.value;

    return (
        <form
            method="post"
            action="#"
            onSubmit={(event) => handleSubmitPressed(event)} >
            <script
                src={`https://maps.googleapis.com/maps/api/js?key=${FIREBASE_CONFIG.apiKey}&callback=initMap&libraries=&v=weekly`}
                defer
            ></script>
{"ID:" + coconId}
            <Grid container columnSpacing={3}>
                <Grid item xs={12} md={6}>
                    <SectionTitle title={t("name", { ns: Namespaces.commons })} />
                    <TextField
                        required
                        margin="normal"
                        value={name.value}
                        variant="outlined"
                        label={t("name", { ns: Namespaces.commons })}
                        InputProps={{
                            onChange: (event) => handleInputChange('name', event.target.value),
                        }}
                        error={Boolean(name.error)}
                        helperText={name.error || "Ex: CDC - 1 rue Dupont - Grenoble"}
                        style={{ maxWidth: "none" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SectionTitle title={t("location", { ns: Namespaces.titles })} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        margin="normal"
                        value={address.value}
                        variant="outlined"
                        label={t("address", { ns: Namespaces.commons })}
                        InputProps={{
                            onChange: (event) => handleInputChange('address', event.target.value),
                        }}
                        error={Boolean(address.error)}
                        helperText={address.error}
                        style={{ maxWidth: "none" }}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextField
                        required
                        margin="normal"
                        value={postcode.value}
                        variant="outlined"
                        label={t("postcode", { ns: Namespaces.commons })}
                        InputProps={{
                            onChange: (event) => handleInputChange('postcode', event.target.value),
                        }}
                        error={Boolean(postcode.error)}
                        helperText={postcode.error}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextField
                        required
                        margin="normal"
                        value={city.value}
                        variant="outlined"
                        label={t("city", { ns: Namespaces.commons })}
                        InputProps={{
                            onChange: (event) => handleInputChange('city', event.target.value),
                        }}
                        error={Boolean(city.error)}
                        helperText={city.error}
                    />
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <ActionButton
                        color="primary"
                        disabled={!addressIsValid || addressLoading}
                        loading={addressLoading}
                        onClick={() => { setLookUpAddress(postalAddress) }}
                        sx={{
                            marginBottom: "1rem",
                        }}
                    >
                        {t("search", { ns: Namespaces.actions })}
                    </ActionButton>
                </Grid>

                <Grid item xs={12} height='50vh' mb={3} justifySelf='center'>
                    <PlaceMap
                        address={lookUpAddress}
                        startAddressLookup={() => { setAddressLoading(true) }}
                        endAddressLookup={(coordinates) => {
                            setCoordinates(coordinates);
                            setAddressLoading(false);
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <SectionTitle title={t("props.mac_address", { ns: Namespaces.cocons })} />
                    <TextField
                        sx={{ mt: 2 }}
                        label={t("props.mac_address", { ns: Namespaces.cocons })}
                        type="string"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        placeholder={t("add_cocon.enter_mac_address", { ns: Namespaces.cocons })}
                        onChange={(event) => handleInputChange('deviceId', event.target.value)}
                        value={deviceId.value}
                        margin="normal"
                        error={Boolean(deviceId.error)}
                        helperText={deviceId.error}
                    />
                </Grid>

                <Grid item xs={type === "edit" ? 6 : 12}>
                    <SectionTitle title={t("status", { ns: Namespaces.glossary })} />
                    <FormControl
                        variant="outlined"
                        sx={{ mt: 2 }}
                    >
                        <InputLabel id="status-select-label">
                            {t("status", { ns: Namespaces.glossary })} *
                        </InputLabel>
                        <Select
                            required
                            labelId="status-select-label"
                            label={`${t("status", { ns: Namespaces.glossary })} *`}
                            value={status.value}
                            onChange={(event) => handleInputChange('status', event.target.value)}
                        >
                            {
                                Object.values(CoconStatus).map((status: CoconStatus) => (
                                    <MenuItem
                                        value={status}
                                        key={status}>
                                        {t(`cocon_status.${status}`, { ns: Namespaces.glossary })}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>

                {type === "edit" && ( // inMaintenance cannot be changed at creation
                    <>
                        <Grid item xs={6}>
                            <InMaintenanceSwitch
                                checked={inMaintenance.value === true}
                                onChange={(checked) => handleInputChange('inMaintenance', checked)}
                            />
                            {notificationSent ? (
                                <Typography>Notification Sent</Typography>
                            ) : (
                                <SendUserNotificationBtn
                                    checked={!inMaintenance.value!}
                                    onConfirm={()=>handleNotifyOnline()}
                                    loading={sendingNotification}
                                />
                            )}
                        </Grid>
                    </>

                )}

                <Grid item xs={6}>
                    <SectionTitle title={t("props.unitsCount", { ns: Namespaces.cocons })} />
                    <TextField
                        sx={{ maxWidth: (theme) => theme.spacing(25) }}
                        label={t("props.unitsCount", { ns: Namespaces.cocons })}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        placeholder={t("unknown", { ns: Namespaces.commons })}
                        onChange={(event) => handleInputChange('unitsCount', event.target.value)}
                        value={unitsCount.value >= 0 ? unitsCount.value : ""}
                        margin="normal"
                    />
                </Grid>

                <Grid item xs={6}>
                    <SectionTitle title={t("date_deployed", { ns: Namespaces.glossary })} />
                    <Datetimepicker
                        value={dateDeployed.value}
                        onChange={(date) => handleInputChange('dateDeployed', date)}
                        inputFormat="LLL"
                        label={t("date_deployed", { ns: Namespaces.glossary })}
                        inputStyle={{
                            mt: 2,
                        }}
                    />
                </Grid>

                {[CoconStatus.PREPARING, CoconStatus.DEPLOYED].includes(status.value as CoconStatus) && (
                    <Grid item xs={12}>
                        <SectionTitle title={t("manager_plural", { ns: Namespaces.glossary })} />
                        <ManagersInput
                            managers={managers.value}
                            onChange={(managers) => handleInputChange('managers', managers)}
                        />
                        {/* <TextField
                            multiline
                            rows={4}
                            margin="normal"
                            value={""}
                            variant="outlined"
                            label={t("manager_plural", { ns: Namespaces.glossary })}
                            InputProps={{
                                onChange: (event) => handleInputChange('managers', event.target.value),
                            }}
                            // error={Boolean(city.error)}
                            helperText={t("cocon.managers.instructions", { ns: Namespaces.forms })}
                        >
                            <Chip label="ok" />
                        </TextField> */}
                    </Grid>
                )}

                <Grid
                    item
                    xs={12}
                    textAlign="center"
                    mt={4}
                >
                    <Box>
                        <ActionButton
                            color="primary"
                            disabled={!formIsValid}
                            loading={loading}
                            type="submit"
                        >
                            {t("save", { ns: Namespaces.actions })}
                        </ActionButton>
                    </Box>
                </Grid>
            </Grid>

            <ConfirmationDialog
                open={noManagerDialogVisible}
                title={t("cocon.managers.none_selected_dialog.title", { ns: Namespaces.forms })}
                content={t("cocon.managers.none_selected_dialog.body", { ns: Namespaces.forms })}
                confirmColor="success"
                onClose={() => toggleNoManagerDialog(false)}
                onConfirm={() => submitFormWithoutManagers()}
            />
        </form >
    )
}

export default CoconForm;