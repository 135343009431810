import { Box, Divider } from "@mui/material";
import ActionButton from "components/_include/ActionButton";
import CoconsClusterSelect from "components/_include/Filters/CoconsClusterSelect";
import { Namespaces } from "locales/translations";
import CoconsCluster, { NewCoconsClusterData } from "models/Cocons/CoconsCluster";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import CoconsClusterForm, { AddCoconsClusterData } from "./CoconsClusterForm";

type SelectClusterStepProps = {
    selectCluster: (cluster: CoconsCluster | AddCoconsClusterData) => void;
}

function SelectClusterStep(props: SelectClusterStepProps) {
    const { selectCluster } = props;

    const { t } = useTranslation([Namespaces.cocons]);

    const [selectedCluster, setSelectedCluster] = useState<CoconsCluster>();

    const initialData: AddCoconsClusterData = {
        joinCode: "",
        name: "",
        displayAddress: "",
        managedBy: {
            id: "",
            name: "",
        },
    };

    const handleNextClicked = (cluster: CoconsCluster | AddCoconsClusterData | undefined) => {
        if (!cluster) return;
        selectCluster(cluster);
    }

    return (
        <Box mt={6}>
            <Box 
                display="flex"
                flexDirection="row"
                justifyContent="center"
                >
                <CoconsClusterSelect
                    parentLoading={false}
                    value={selectedCluster?.id || ""}
                    noClusterSelectedLabel={t("add_cocon.select_existing_cluster", { ns: Namespaces.cocons })}
                    onChange={setSelectedCluster}
                />
                <ActionButton
                    color="primary"
                    disabled={!selectedCluster}
                    loading={false}
                    onClick={() => handleNextClicked(selectedCluster)}
                    sx={{ ml: 2 }}
                >
                    {t("next", { ns: Namespaces.actions })}
                </ActionButton>
            </Box>

            <Divider sx={{ my: 6 }} variant="middle">
                {t("add_cocon.or_add_cluster", { ns: Namespaces.cocons })}
            </Divider>

            <CoconsClusterForm
                clusterData={initialData}
                type="create"
                loading={false}
                submitForm={handleNextClicked}
            />
        </Box>
    );
}

export default SelectClusterStep;