import { Button, Box, Typography } from "@mui/material";
import Message from "models/Message";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";

interface LinkBubbleProps {
    message: Message;
}

function LinkBubble(props: LinkBubbleProps) {
    const { t } = useTranslation([Namespaces.glossary])

    const { message } = props;

    return (
        <Box
            pb={4}
            pl={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            >
            <Button
                disabled
                color="primary"
                variant="contained"
                >
                {message.message}
            </Button>
            <Typography variant="caption">
                {t("link_to", { ns: Namespaces.glossary, link: message.attachment?.url })}
            </Typography>
        </Box>
    );
}

export default LinkBubble;