import { useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import { BatchesMethods } from "models/Batches/Batch";
import { Namespaces } from "locales/translations";
import SectionLoader from "../../_include/SectionLoader";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";
import BatchCard from "components/Batches/BatchComponents/Card/BatchCard";
import { CoconsMethods } from "models/Cocons/Cocon";
import _ from "lodash";

type CoconBatchesListProps = {

}

function CoconBatchesList(props: CoconBatchesListProps) {
    const { coconId } = useParams();

    const dispatch = useAppDispatch();

    const cocon = useAppSelector(state => state.cocons.selected.data);

    useEffect(() => { // load cocon details
        if (coconId && !cocon) {
            dispatch(CoconsMethods.retrieve(coconId));
        }
    }, [coconId]);

    const loading = useAppSelector(state => state.cocons.selected.loading || state.batches.list.loading);
    const batchesIds = useAppSelector(state => state.batches.list.ids);

    useEffect(() => { // load cocon's batches
        if (coconId) {
            dispatch(BatchesMethods.list([{ fieldPath: "cocon.id", opStr: "==", value: coconId }], 100));
        }
    }, [coconId]);

    const { t } = useTranslation([Namespaces.batches, Namespaces.commons, Namespaces.titles,])

    return (
        <Container>
            <Box
                mb={5}
            >
                <Typography variant="h1">{t("batches_list", { ns: Namespaces.titles })}</Typography>
                {cocon && (
                    <Typography variant="subtitle1">
                        {cocon.name ?
                            t("at_name", { ns: Namespaces.commons, name: cocon.name })
                            :
                            t("at_address_city", { ns: Namespaces.commons, address: cocon.address, city: cocon.city })
                        }
                    </Typography>
                )}
            </Box>
            <Box>
                {loading ? (
                    <SectionLoader />
                ) : (
                    batchesIds.map((batchId) => (
                        <BatchCard
                            key={batchId}
                            batchId={batchId.toString()}
                        />
                    ))
                )}
            </Box>
        </Container>
    )
};

export default CoconBatchesList;
