export enum ChartsStrings {
    DATES = 'Date',
    COUNT = 'count',
    CO2_SAVED = 'Kg de CO\u2082 économisés',
    CUMULATIVE_CO2_SAVED = 'Kg cumulés de CO\u2082 économisés',
    WELL_SORTED_TRASH = 'Bon tri',
    BADLY_SORTED_TRASH = 'Mauvais tri',
};

export interface ColorsInterface {
    [dataName: string]: string,
};

export interface LegendsInterface {
    axisTop?: string;
    axisRight?: string;
    axisBottom?: string;
    axisLeft?: string;
}