import Cocon, { CoconDbData } from "models/Cocons/Cocon";
import CoconIssue from "models/Cocons/CoconIssue";
import { DataContext, LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

const issuesAdapter = createEntityAdapter<CoconIssue>({
    selectId: cluster => cluster.id,
    // sortComparer: (a, b) => b.date.localeCompare(a.date)
});

type IssuesContext = LoadableContext;

const initialState: IssuesContext = {
    loading: false,
    error: null,
};

export const issuesSlice = createSlice({
    name: 'cocon_issues',
    initialState: issuesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: issues }: PayloadAction<CoconIssue[]>) => {
            state.loading = false;
            issuesAdapter.setAll(state, issues);
        },
        updateItem: (state, { payload: { id, data }}: PayloadAction<{ id: string, data: Partial<CoconIssue> }>) => {
            issuesAdapter.updateOne(state, {
                id: id, 
                changes: data,
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CoconIssuesActions = issuesSlice.actions;

export const {
    selectAll: selectAllCoconIssues,
    selectById: selectCoconIssueById,
    selectIds: selectCoconIssuesIds,
} = issuesAdapter.getSelectors((state: RootState) => state.cocons.issues);

const CoconIssuesReducer =  issuesSlice.reducer;

export default CoconIssuesReducer;