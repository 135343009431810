import { Badge, Box, ListItemButton, ListItemText, } from "@mui/material";
import { LastMessageUserData } from "models/LastMessage";
import { useSearchParams } from "react-router-dom";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

type UserChatItemProps = {
    user: LastMessageUserData;
    message: string;
    selected: boolean;
    read: boolean;
}

function UserChatItem({ user, message, selected, read, }: UserChatItemProps) {

    const [searchParams, setSearchParams] = useSearchParams();

    const handleClick = () => {
        searchParams.set("id", user.id);
        setSearchParams(searchParams);
    };

    return (
        <ListItemButton
            onClick={handleClick}
            sx={{
                alignItems: "stretch",
                ...(selected ? {
                    backgroundColor: theme => theme.palette.primary.main,
                    "&:hover": {
                        backgroundColor: theme => theme.palette.primary.main,
                    },
                    color: "#ffffff",
                    boxShadow: "inset 0 4px 4px rgba(0,0,0,0.25)",
                } : {})
            }}
        >
            <ListItemText
                primary={`${user.pin?.toString().padStart(4, '0')} - ${user.coconName}`}
                secondary={message}
                secondaryTypographyProps={{
                    sx: {
                        whiteSpace: "nowrap",
                        height: "1.43rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        ...(selected ? {
                            color: "rgba(255, 255, 255, 0.54)",
                        } : {})
                    }
                }}
            />
            <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                flexDirection="column"
            >
                {!read &&
                        <Badge
                            color="secondary"
                            badgeContent=" "
                            sx={{
                                margin: 1,
                            }}
                        />
                }
                {/* <WarningRoundedIcon
                    color="warning"
                    /> */}
            </Box>
        </ListItemButton>
    )
}

export default UserChatItem;