import BrownCardboard from "components/_include/Icons/BrownCardboard";
import CollectionBag from "components/_include/Icons/CollectionBag";
import DarkPETBottle from "components/_include/Icons/DarkPETBottle";
import ElectronicWaste from "components/_include/Icons/ElectronicWaste";
import FoodLeftovers from "components/_include/Icons/FoodLeftovers";
import GrossStorePaper from "components/_include/Icons/GrossStorePaper";
import LightPETBottle from "components/_include/Icons/LightPETBottle";
import LiquidFoodPackaging from "components/_include/Icons/LiquidFoodPackaging";
import NestedItems from "components/_include/Icons/NestedItems";
import OpaquePETBottle from "components/_include/Icons/OpaquePETBottle";
import PlasticFilm from "components/_include/Icons/PlasticFilm";
import PlasticPot from "components/_include/Icons/PlasticPot";
import PrintedCardboard from "components/_include/Icons/PrintedCardboard";
import Glass from "components/_include/Icons/Glass";
import Mask from "components/_include/Icons/Mask";
import Metal from "components/_include/Icons/Metal";
import Other from "components/_include/Icons/Other";
import Paper from "components/_include/Icons/Paper";
import { TrashType } from "constants/trash";
import _ from "lodash";

/**
 * Type used to count each type of trash.
 * @example 
 * {
 *  light_PET_bottle: 2,
 *  dark_PET_bottle: 0,
 *  ...
 * }
 */
export type WastesValues = {
    [trashType in TrashType]: number;
}

/**
 * Type used to count each type of trash, 
 * including a TOTAL value which is the some of all the other values
 * @example 
 * {
 *  light_PET_bottle: 2,
 *  dark_PET_bottle: 0,
 *  ...
 *  TOTAL: 2
 * }
 */
export type TrashCountInterface = WastesValues & {
    TOTAL: number;
};

/**
 * Initialize a TrashCountInterface object with all values to 0, including the TOTAL.
 */
export const initTrashCount: () => TrashCountInterface = () => ({
    ..._.reduce(Object.values(TrashType), (acc, val) => {
        acc[val] = 0;
        return acc;
    }, {} as Partial<WastesValues>) as WastesValues,
    TOTAL: 0,
});

/**
 * Get the colored SVG icon matching a given class of waste
 */
export const getTrashIcon = (trashType: TrashType) => {
    switch (trashType) {
        case TrashType.BROWN_CARDBOARD: return BrownCardboard;
        case TrashType.COLLECTION_BAG: return CollectionBag;
        case TrashType.DARK_PET_BOTTLE: return DarkPETBottle;
        case TrashType.ELECTRONIC_WASTE: return ElectronicWaste;
        case TrashType.FOOD_LEFTOVERS: return FoodLeftovers;
        case TrashType.GLASS: return Glass;
        case TrashType.GROSS_STORE_PAPER: return GrossStorePaper;
        case TrashType.LIGHT_PET_BOTTLE: return LightPETBottle;
        case TrashType.LIQUID_FOOD_PACKAGING: return LiquidFoodPackaging;
        case TrashType.MASK: return Mask;
        case TrashType.METAL: return Metal;
        case TrashType.NESTED_ITEMS: return NestedItems;
        case TrashType.OPAQUE_PET_BOTTLE: return OpaquePETBottle;
        case TrashType.OTHER: return Other;
        case TrashType.PAPER: return Paper;
        case TrashType.PLASTIC_FILM: return PlasticFilm;
        case TrashType.PLASTIC_POT: return PlasticPot;
        case TrashType.PRINTED_CARDBOARD: return PrintedCardboard;
    }
}