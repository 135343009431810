import { Container, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import { UsersMethods } from "models/User";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { Namespaces } from "../../../locales/translations";
import AddPointsButton from "./AddPointsButton";
import { useCallback } from "react";

interface UserDataProps {

}

function UserData({ }: UserDataProps) {
    const { t } = useTranslation([Namespaces.commons, Namespaces.glossary, Namespaces.stats]);

    const user = useAppSelector(state => state.users.selectedUser?.data);
    const loading = useAppSelector(state => state.users.selectedUser?.loading);

    const Row = useCallback(({ label, value }: { label: string, value: number | string | JSX.Element }) => (
        <TableRow key={label}>
            <TableCell>{t(label, { ns: [Namespaces.commons, Namespaces.glossary, Namespaces.stats] })}</TableCell>
            <TableCell>{value}</TableCell>
        </TableRow>
    ), [t]);

    return (
        <Container>
            {!user || loading ? (
                <SectionLoader />
            ) : (
                <Paper sx={{
                    border: (theme) => `2px solid ${theme.palette.primary.main}`,
                    margin: 2,
                }}>
                    <Table size='small' aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("data", { ns: Namespaces.commons })}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Row label="first_name" value={user.firstName} />
                            <Row label="last_name" value={user.lastName} />
                            <Row label="email" value={user.email ?? ""} />
                            <Row label="phone" value={user.phone ?? ""} />
                            <Row label="date_joined" value={UsersMethods.getDateJoined(user).format("LL")} />
                            <Row label="locale" value={user.locale.toUpperCase()} />
                            <Row label="cocon_address" value={user.cocon ? user.cocon.address : ""} />
                            <Row label="full_address" value={UsersMethods.formatAddress(user.address)} />
                            <Row label="cocon_pin" value={UsersMethods.formatPin(user.pin)} />
                            <Row label="number_of_batches" value={user.batchesCount} />
                            <Row
                                label="score"
                                value={
                                    <Box display="flex" gap={4} alignItems="center">
                                        {user.score}
                                        <AddPointsButton />
                                    </Box>
                                }
                            />
                            <Row label="balance" value={user.balance} />
                            <Row label='co2_saved' value={t("kg", {
                                ns: Namespaces.stats,
                                count: user.savedCO2,
                            }).toString()} />
                            <Row label='reward_plural' value={t("money_saved", {
                                ns: Namespaces.stats,
                                count: user.savedMoney,
                            }).toString()} />
                        </TableBody>
                    </Table>
                </Paper>
            )}
        </Container>
    )
};

export default UserData;
