import { useEffect, } from 'react';
import { Alert, Box, styled } from '@mui/material';
import SectionLoader from '../../_include/SectionLoader';
import { usePrevious } from '../../../helpers/refs';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '../../../locales/translations';
import { CONFLICT_ERROR_CODE } from '../../../constants/api_errors';
import BackToWebsiteLink from '../../_include/BackToWebsiteLink';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import User, { UsersMethods } from 'models/User';

const Content = styled(Box)({
    maxWidth: 1280,
    margin: "0 auto",
    mt: 4,
    padding: 4,
    pt: 6,
    textAlign: 'center',
});

type VerifyEmailPageProps = {

}

function VerifyEmailPage(props: VerifyEmailPageProps) {

    const { t } = useTranslation([Namespaces.snacks]);

    const { loading: userLoading, error: userError } = useAppSelector(state => state.users.user);
    const dispatch = useAppDispatch();
    
    const [searchParams] = useSearchParams();

    const token = searchParams.get("t");

    useEffect(() => {
        if (token) {
            dispatch(UsersMethods.verifyEmailAddress(token.toString()));
        }
    }, [token]);

    const wasLoading = usePrevious(userLoading);

    if (!token) {
        return (
            <Content>
                <Alert
                    severity='error'
                    sx={{
                        mb: 2,
                    }}
                >
                    {t("invalid_link", { ns: Namespaces.snacks })}
                </Alert>
                <BackToWebsiteLink />
            </Content>
        );
    }

    if (wasLoading && !userLoading) { // request to verify email address done
        if (!userError) { // request completed successfully
            return (
                <Content>
                    <Alert
                        severity='success'
                        sx={{
                            mb: 2,
                        }}
                    >
                        {t("verify_email.success", { ns: Namespaces.snacks })}
                    </Alert>
                    <BackToWebsiteLink />
                </Content>
            );
        }
        else { // something went wrong
            return (
                <Content>
                    <Alert
                        severity={userError === "already_done" ? 'warning' : 'error'}
                        sx={{
                            mb: 2,
                        }}
                    >
                        {t(userError, { ns: Namespaces.snacks })}
                    </Alert>
                    <BackToWebsiteLink />
                </Content>
            );
        }
    }

    return (
        <Content>
            <SectionLoader />
        </Content>
    );
}

export default VerifyEmailPage;