import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import ActionButton from "components/_include/ActionButton";
import SectionLoader from "components/_include/SectionLoader";
import { DATE_SELECT_TYPES, getDatesFromRange } from "helpers/dates";
import { Namespaces } from "locales/translations";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { showError } from "store/reducers/snacks";
import DownloadIcon from "@mui/icons-material/Download";
import { ToAnnotateArchivesMethods } from "models/Batches/ImagesToAnnotateArchive";

type DialogProps = {
    open: boolean;
    onClose: () => void;
}

function DownloadImagesToAnnotateDialog(props: DialogProps) {
    const { open, onClose,} = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.snacks, Namespaces.titles]);

    const { generating, archiveLink } = useAppSelector(state => ({
        generating: state.toAnnotate.download.loading,
        archiveLink: state.toAnnotate.download.link,
    }));
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();

    const handleDownloadClicked = (includeAnnotations: boolean) => {
        const range = searchParams.get("dateSelect") as DATE_SELECT_TYPES | null;

        let from: moment.Moment | null = null, to: moment.Moment | null = null;

        from = searchParams.has("from") ? moment(searchParams.get("from")) : null;
        to = searchParams.has("to") ? moment(searchParams.get("to")) : null;
        
        if (!from || !to) {
            dispatch(showError(t("select_dates", { ns: Namespaces.snacks })));
        }
        else {
            dispatch(ToAnnotateArchivesMethods.create(from, to, includeAnnotations));
        }
    }

    let dialogTitleKey = "include_annotations";
    if (generating) dialogTitleKey = "generating";
    if (archiveLink) dialogTitleKey = "success";

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: (theme) => theme.spacing(18)
                }
            }}
        >
            <DialogTitle>
                {t(`to_annotate.dialog.${dialogTitleKey}`, { ns: Namespaces.titles })}
            </DialogTitle>
            {generating ? (    // generating images
                <DialogContent>
                    <SectionLoader />
                </DialogContent>
            ) : (archiveLink ? (     // link to download
                <DialogActions sx={{
                    justifyContent: "center",
                }}>
                    <ActionButton
                        color='primary'
                        icon={<DownloadIcon />}
                        href={archiveLink}
                    >
                        {t("download", { ns: Namespaces.actions })}
                    </ActionButton>
                </DialogActions>
            ) : (                               // ask to include LabelMe annotations
                <DialogActions sx={{
                    justifyContent: "space-evenly",
                }}>
                    <Button
                        color="warning"
                        sx={{ fontSize: "1rem" }}
                        onClick={() => handleDownloadClicked(false)}
                    >
                        {t("include_not", { ns: Namespaces.actions })}
                    </Button>
                    <Button
                        color="info"
                        sx={{ fontSize: "1rem" }}
                        onClick={() => handleDownloadClicked(true)}
                    >
                        {t("include", { ns: Namespaces.actions })}
                    </Button>
                </DialogActions>
            ))}
        </Dialog >
    );
}

export default DownloadImagesToAnnotateDialog;